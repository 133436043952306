import { ReactElement, useContext, useEffect, useState, SyntheticEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import cms from '../../data/cms.json';
import styles from './Progress.module.scss';
import config from '../../config.json';
import { useComponentVisible } from '../../hooks/useComponentVisible';
import { triggerGtmEvent } from '../../utils/triggerGtmEvent';

import { AppContext } from '../Layout/Layout';
import { Box } from '../Box/Box';
import { CheckButton } from '../CheckButton/CheckButton';
import { ToggleButton } from '../ToggleButton/ToggleButton';
import { Title } from '../Title/Title';
import { Paragraph } from '../Paragraph/Paragraph';
import { Button } from '../Button/Button';
import { resetProcess } from '../../utils/resetProcess';
import { unlockScroll } from '../../utils/bodyScrollLock';

export interface ListItemProps {
    name: string;
    url: string;
    completed?: boolean;
    type?: string;
}

export interface ProgressProps {
    items: ReadonlyArray<ListItemProps>;
    defaultOpened?: boolean;
    className?: string;
}

export const Progress = ({ items, defaultOpened, className }: ProgressProps): ReactElement | null => {
    const ctx = useContext(AppContext);
    const navigate = useNavigate();
    const [opened, setOpened] = useState(defaultOpened || false);
    const { ref, isComponentVisible } = useComponentVisible(false);

    const completed = ctx.formStep.completed;
    const current = ctx.formStep.current;
    const newCompleted = current > 0 ? completed + 1 : 0;

    const ProgressPopup = (
        <div className={styles.progressPopup}>
            <Title tag="h2" className="mb-4">
                {cms.progress.title}
            </Title>

            <Paragraph>{cms.progress.text}</Paragraph>

            <Button
                className={cn(styles.button, 'mb-4')}
                variant="primary"
                onClick={() => {
                    ctx.setPopupVisible(false);
                    unlockScroll();
                }}
            >
                {cms.common.continueButton}
            </Button>

            <a
                onClick={() => {
                    resetProcess(navigate);
                }}
                className="text--underline"
                href="#"
            >
                {cms.common.resetButton}
            </a>
        </div>
    );

    useEffect(() => {
        !isComponentVisible && setOpened(false);
    }, [isComponentVisible]);

    const handleClick = (e: SyntheticEvent, index: number) => {
        setOpened(false);

        // od kroku 8 nemůže uživatel jít zpět kromě posledního kroku 12 (vítejte u nás),
        // kdy může jít zpět na krok 11 (platební údaje)
        if (
            index > completed + 1 ||
            index <= 1 ||
            (current >= 8 && !!localStorage.getItem('evaluateErrorPageCode') && index !== 11)
        ) {
            e.preventDefault();
            e.stopPropagation();

            if (index <= completed) {
                ctx.setPopupContent(ProgressPopup);
                ctx.setPopupContentVariables({ uncloseable: false });
                ctx.setPopupVisible(true);
                ctx.setCloseButton(true);
            }
        } else {
            triggerGtmEvent('progress_go');
        }
    };

    return (
        <div className={cn(styles.wrapper, opened && styles.opened, className)} ref={ref}>
            <div
                className={styles.progressbar}
                onClick={() => {
                    setOpened(!opened);
                    triggerGtmEvent(opened ? 'progress_end' : 'progress');
                }}
            >
                <span className={styles.progressBarBorder}></span>
                <span className={styles.text}>{((newCompleted / config.STEP_COUNT) * 100).toFixed(0)}&thinsp;%</span>
                <span
                    className={styles.value}
                    style={{
                        width: `${((newCompleted / config.STEP_COUNT) * 100).toFixed(0)}%`,
                    }}
                ></span>
                <ToggleButton className={styles.toggleButton} opened={opened} secondary />
            </div>

            {opened && (
                <Box className={styles.box} dark>
                    <ul className={styles.list}>
                        {items
                            .filter((f) => f.type === ctx.insuranceType || f.type === 'default')
                            .map((item, i) => (
                                <li
                                    className={cn(
                                        styles.item,
                                        i === current && styles.bold,
                                        (i > completed + 1 || i <= 1 || (current >= 8 && i !== 11)) && styles.disabled,
                                        i > completed + 1 && styles.unclickable
                                    )}
                                    key={item.name}
                                >
                                    <Link to={item.url} onClick={(e) => handleClick(e, i)}>
                                        <CheckButton
                                            className={styles.checkButton}
                                            size="small"
                                            checked={i <= completed}
                                        />
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                    </ul>
                </Box>
            )}
        </div>
    );
};
