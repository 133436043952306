import { ReactElement } from 'react';
import classnames from 'classnames';

import banan from '../../../images/banan.png';
import cms from '../../../data/cms.json';
import styles from './Error.module.scss';
import { Title } from '../../Title/Title';
import { Paragraph } from '../../Paragraph/Paragraph';

export interface ErrorProps {
    code: '404' | '500';
    className?: string;
}

export const Error = ({ className, code }: ErrorProps): ReactElement | null => {
    return (
        <div className={classnames(styles.wrapper, styles[`page-${code}`], className)}>
            <div className={styles.inner}>
                <div className={styles.content}>
                    <Title className={styles.title} tag="span" size="xl">
                        <>Error {code}</>
                    </Title>

                    <Paragraph className={styles.text}>
                        {code === '404' ? cms.common.error404Text : cms.common.error500Text}
                    </Paragraph>
                </div>

                <img src={banan} alt={'404 image'} className={styles.image} />
            </div>
        </div>
    );
};
