import { ReactElement } from 'react';
import cn from 'classnames';

import { Button } from '../../components/Button/Button';

import styles from './ToggleInput.module.scss';
import { FieldProps } from 'formik';
import { SexType } from '../../enums/sexType';

interface ToggleInputProps {
    id?: string;
    className?: string;
    field?: FieldProps['field'];
    value: string;
    label?: string;
    error?: string;
    active?: boolean;
    onClick?: () => void;
}

export const ToggleInput = ({
    className,
    id,
    field,
    value,
    label,
    error,
    onClick,
    active,
}: ToggleInputProps): ReactElement | null => {
    return (
        <div className={cn(styles.wrapper, className)} onClick={onClick}>
            {!onClick && <input id={id} type="radio" className={styles.checkbox} {...field} />}
            <Button
                variant="primary"
                outlined={!active || !onClick}
                small
                renderAsLabel
                className={cn(
                    styles.label,
                    error && styles.hasError,
                    (value === 'Ano' ||
                        value === 'Ne' ||
                        value === SexType.Male ||
                        value === SexType.Female ||
                        value === 'Nevím') &&
                        'radio-binary'
                )}
                htmlFor={id}
            >
                {label || value}
            </Button>
        </div>
    );
};
