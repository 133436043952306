import { ReactElement } from 'react';
import classnames from 'classnames';

import styles from './Loader.module.scss';

export interface LoaderProps {
    size: 'xsmall' | 'small' | 'medium' | 'large';
    color?: 'purple' | 'white' | 'unset';
    className?: string;
}

export const Loader = ({ size, color = 'purple', className }: LoaderProps): ReactElement | null => {
    const sizePx = size === 'xsmall' ? 18 : size === 'small' ? 26 : size === 'medium' ? 80 : 160;
    const resizeRatio = 1.1764705882; // ### Because everyting is downsized to 85% (see global.scss)
    const sizeRem = `${(sizePx / 16) * resizeRatio}rem`;

    return (
        <div
            className={classnames(styles.wrapper, color && styles[color], className)}
            style={{ width: sizeRem, height: sizeRem }}
        >
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
