import { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import axios from 'axios';

import cms from '../../../data/cms.json';
import config from '../../../config.json';
import styles from './Step10.module.scss';
import { setCompleted } from '../../../utils/setCompleted';
import { triggerGtmEvent } from '../../../utils/triggerGtmEvent';

import { Step } from '../../Step/Step';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { Box } from '../../Box/Box';
import { OrderedList } from '../../OrderedList/OrderedList';
import { Button } from '../../Button/Button';
import { Download } from '../../Download/Download';
import { AppContext } from '../../Layout/Layout';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import { CustomLocation } from '../../../types/common';
import { Title } from '../../Title/Title';
import { RetryButton } from '../../RetryButton/RetryButton';
import { Loader } from '../../Loader/Loader';

interface Step10Props {
    className?: string;
}

interface PaymentInstructionProps {
    BankAccountNoFull: string;
    PremiumByFrequency: number;
    VariableSymbol: string;
    QrCode: string;
}

export interface DocumentProps {
    ExternalId: string;
    FileName: string;
    DisplayName: string;
    ContentType: string;
    Length: number;
    IsValid: boolean;
}

interface ResultDataProps {
    PaymentInstruction: PaymentInstructionProps;
    Documents: ReadonlyArray<DocumentProps>;
}

export const Step10 = ({ className }: Step10Props): ReactElement | null => {
    const ctx = useContext(AppContext);
    const location: CustomLocation = useLocation();
    const navigate = useNavigate();
    const [formError, setFormError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [passedId, setPassedId] = useState<string | null>(null);
    const [resultData, setResultData] = useState<ResultDataProps | null>(null);
    const BASE_URL = getBaseUrl();

    useEffect(() => {
        localStorage.setItem('currentStep', '11');
        ctx.setIsBackButtonVisible(false);
    }, []);

    useEffect(() => {
        const state = location.state;
        const externalId = state ? state.ExternalId : ctx.formResult.ExternalId;

        externalId && setPassedId(externalId);

        axios
            .get(`${BASE_URL}api/online/support/get-confirmation-screen-data/${externalId}`)
            .then((response) => {
                setLoading(false);
                triggerGtmEvent('loading_end');

                if (response.data) {
                    setResultData(response.data);
                } else {
                    console.error('NO DATA');
                    setFormError(true);
                    triggerGtmEvent('error');
                }
            })
            .catch((error) => {
                setFormError(true);
                console.error(error.response);
                triggerGtmEvent('error');
            });
    }, [location]);

    if (formError) {
        return (
            <Step title={cms.steperror.title} text={null} className={cn(styles.wrapper, className)}>
                <RetryButton />
                <ErrorForm />
            </Step>
        );
    }

    if (loading) {
        return (
            <Step title={cms.step10.title} text={cms.step10.text} className={cn(styles.wrapper, className)}>
                <Loader size="medium" />
                <ErrorForm />
            </Step>
        );
    }

    return (
        <div className={cn(styles.wrapper, className)}>
            <Step title={cms.step10.title} text={cms.step10.text}>
                <Box className="mb-6 mb-3-m">
                    <Title tag="h4" className="mb-7 mb-5-m">
                        Dokumenty zaslané na váš e-mail
                    </Title>
                    {resultData && resultData?.Documents?.length > 0 && <Download files={resultData?.Documents} />}
                </Box>
                <Box>
                    {resultData?.PaymentInstruction && (
                        <div className={cn('mb-6', styles.paymentTable)}>
                            <div className={styles.paymentTableItem}>
                                <strong className={styles.paymentTableTitle}>Částka</strong>
                                <div className={styles.paymentTableValue}>
                                    {resultData?.PaymentInstruction.PremiumByFrequency} {config.CURRENCIES.CZK}
                                </div>
                            </div>

                            <div className={styles.paymentTableItem}>
                                <strong className={styles.paymentTableTitle}>Na účet</strong>
                                <div className={styles.paymentTableValue}>
                                    {resultData?.PaymentInstruction.BankAccountNoFull}
                                </div>
                            </div>

                            <div className={styles.paymentTableItem}>
                                <strong className={styles.paymentTableTitle}>Variabilní symbol</strong>
                                <div className={styles.paymentTableValue}>
                                    {resultData?.PaymentInstruction.VariableSymbol}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* 
                    // Temporary disabled due https://dev.azure.com/SimpleaDevOps/SimpleaOnlineCZ/_workitems/edit/127/
                    // till implementation will be completed
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setCompleted(11);
                            navigate(`/${config.SLUGS.STEP11_SLUG}/`, { state: { ExternalId: passedId } });
                        }}
                    >
                        {cms.common.ibButton}
                    </Button> */}

                    <div className={cn(styles.qrText, 'mt-4', 'mb-4')}>{cms.step10.qr}</div>

                    <img className="mb-7 mb-5-m" src={resultData?.PaymentInstruction.QrCode} />

                    <OrderedList className={cn(styles.list, 'mb-7 mb-5-m')} items={cms.step10.list} />

                    <Button
                        variant="primary"
                        onClick={() => {
                            setCompleted(11);
                            navigate(`/${config.SLUGS.STEP11_SLUG}/`, { state: { ExternalId: passedId } });
                        }}
                    >
                        {cms.common.paidButton}
                    </Button>
                </Box>

                <ErrorForm />
            </Step>
        </div>
    );
};
