import { useState, useEffect, useRef } from 'react';

// ### Function (hook) handles outside click to hide bounded components (example: Progress)

export const useComponentVisible = (initialIsVisible: boolean) => {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (event: Event) => {
        if (event.target instanceof HTMLElement && ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        } else {
            setIsComponentVisible(true);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        () => document.removeEventListener('click', handleClickOutside, true);
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
};
