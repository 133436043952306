import { useContext, useEffect, useCallback } from 'react';
import { History, Transition } from 'history';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { AppContext } from '../components/Layout/Layout';

type ExtendNavigator = Navigator & Pick<History, 'block'>;
// ### Blocks all navigation attempts
// https://reactrouter.com/api/useBlocker
// https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743

export const useBlocker = (blocker: (tx: Transition) => void, when = true) => {
    const { navigator } = useContext(NavigationContext);
    const ctx = useContext(AppContext);

    useEffect(() => {
        if (!when) {
            return;
        }

        const unblock = (navigator as any as ExtendNavigator).block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };

            if (!ctx.showBlockPopup) {
                ctx.setShowBlockPopup(true);
            }

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [ctx.navigationLocked, navigator, blocker, when]);
};

export const usePrompt = (message: string, when = true) => {
    const blocker = useCallback(
        (tx: Transition) => {
            // eslint-disable-next-line no-alert
            if (window.confirm(message)) tx.retry();
        },
        [message]
    );

    useBlocker(blocker, when);
};
