import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './ToggleButton.module.scss';
import { Icon } from '../Icon/Icon';

export interface ToggleButtonProps {
    opened: boolean;
    secondary?: boolean;
    className?: string;
    onClick?: () => void;
}

export const ToggleButton = ({ opened, secondary, className, onClick }: ToggleButtonProps): ReactElement | null => {
    return (
        <div
            onClick={onClick}
            className={cn(styles.wrapper, opened && styles.opened, secondary && styles.secondary, className)}
        >
            <div className={styles.icon}>
                <Icon size="small" name="next" />
            </div>
        </div>
    );
};
