import config from '../config.json';

// ### Function resolves base url for API calls, but returns test url on localhost

export const getBaseUrl = () => {
    if (window && window.location.origin) {
        if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
            return config.TEST_BASE_URL;
        }
        return `${window.location.origin}/`;
    }
    return '/';
};
