import { ReactElement, ReactNode, useContext } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';

import cms from '../../data/cms.json';
import styles from './Step.module.scss';
import { Title } from '../Title/Title';
import { AppContext } from '../Layout/Layout';
import { translate } from '../../utils/translator';

interface StepProps {
    title: string;
    text: string | null;
    children?: ReactNode;
    className?: string;
}

export const Step = ({ title, text, children, className }: StepProps): ReactElement | null => {
    const ctx = useContext(AppContext);

    return (
        <div className={cn(styles.wrapper, className, 'step')}>
            <div className={styles.header}>
                <Title tag="h1" className="mb-6 mb-4-m">
                    {title}
                </Title>
                {text && <p className={cn(styles.text, 'mb-9 mb-6-m')}>{parse(text)}</p>}
            </div>

            {ctx.globalError.length > 0 && (
                <div className={cn(styles.errorBox, 'mb-6', 'mb-3-m', 'formItemError')}>
                    <Title tag="span" size="md" className="mb-4">
                        {cms.common.errorTitle}
                    </Title>
                    <ul className={styles.errorList}>
                        {ctx.globalError.map((e: { target: string; message: string }, i: number) => (
                            <li key={i}>
                                &bull; {translate(e.target)} - {e.message}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {children && <div className={styles.content}>{children}</div>}
        </div>
    );
};
