import { ReactElement, ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './Countdown.module.scss';

import { Box } from '../Box/Box';

export interface CountdownProps {
    initialTime: number;
    onCountdownEnd?: () => void;
    countdownFinished?: ReactNode;
    className?: string;
}

const renderCountdown = (t: number) => {
    return {
        minutes: Math.trunc(t / 60),
        seconds: t - Math.trunc(t / 60) * 60,
    };
};

export const Countdown = ({
    className,
    initialTime,
    countdownFinished,
    onCountdownEnd,
}: CountdownProps): ReactElement | null => {
    const [time, setTime] = useState(initialTime);
    const INTERVAL = 1000; // ### one second

    useEffect(() => {
        const interval = setTimeout(() => {
            if (time < 1) {
                clearTimeout(interval);
                onCountdownEnd && onCountdownEnd();
            } else {
                setTime(time - 1);
            }
        }, INTERVAL);

        return () => {
            clearTimeout(interval);
        };
    }, [time, onCountdownEnd]);

    if (time === 0 && countdownFinished) {
        return <Box className={cn(styles.wrapper, styles.finished, className)}>{countdownFinished}</Box>;
    }

    return (
        <Box className={cn(styles.wrapper, className)}>
            <>
                {renderCountdown(time).minutes}:
                {renderCountdown(time).seconds.toString().length > 1
                    ? renderCountdown(time).seconds
                    : `0${renderCountdown(time).seconds}`}
            </>
        </Box>
    );
};
