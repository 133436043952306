import { Dispatch, ReactElement } from 'react';
import { FieldInputProps, FormikValues } from 'formik';
import ReactSelect from 'react-select';
import cn from 'classnames';

import styles from './Select.module.scss';

export interface OptionProps {
    label: string;
    value: string | number;
}

export interface SportLevelSelectProps {
    code?: string;
    value: string | number;
}

export interface SelectProps {
    // eslint-disable-next-line
    options: Array<OptionProps> | any;
    id?: string;
    name?: string;
    field?: FieldInputProps<string | number>;
    form?: FormikValues;
    value?: string | number | boolean;
    setFieldValue?: (field: string, value: string | number, shouldValidate?: boolean) => void;
    setLevel?: Dispatch<React.SetStateAction<SportLevelSelectProps>>;
    className?: string;
    error?: boolean;
    level?: SportLevelSelectProps;
    placeholder?: string;
    isSearchable?: boolean;
}

export const Select = ({
    name,
    id,
    options,
    className,
    field,
    form,
    setFieldValue,
    setLevel,
    error,
    level,
    placeholder = 'Vyberte...',
    isSearchable = true,
}: SelectProps): ReactElement | null => (
    <ReactSelect
        id={id}
        name={name}
        className={cn(error && styles.hasError, styles.wrapper, className)}
        classNamePrefix="react-select"
        options={options}
        onChange={(option: OptionProps) => {
            setLevel && setLevel({ code: id, value: option.value });
            setFieldValue 
                ? setFieldValue(id || '', option.value) 
                : form?.setFieldValue(id, option.value);
        }}
        value={
            level
                ? options.find((o: OptionProps) => o.value === level.value)
                : options
                    ? options.find((option: OptionProps) => option.value === field?.value)
                    : ''
        }
        placeholder={placeholder}
        menuPortalTarget={document.body}
        isSearchable={isSearchable}
    />
);
