import { Fragment, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import cn from 'classnames';

import styles from './Step4.module.scss';
import cms from '../../../data/cms.json';
import config from '../../../config.json';
import { QuestionProps } from '../../../types/initData';
import { findQuestionHeaderId, findQuestionRange } from '../../../utils/formFiltering';
import { updateGlobalFormState } from '../../../utils/updateGlobalFormState';
import { scrollToError } from '../../../utils/scrollToError';
import { renderTextField } from '../../../utils/renderTextField';
import { renderToggleField } from '../../../utils/renderToggleField';
import { resolveFieldMin, resolveFieldText, resolveFieldType } from '../../../utils/externalDataHandling';
import { getFilledData } from '../../../utils/getFilledData';
import { getInitialValues } from '../../../utils/getInitialValues';
import { setCompleted } from '../../../utils/setCompleted';

import { Step } from '../../Step/Step';
import { Box } from '../../Box/Box';
import { Button } from '../../Button/Button';
import { Grid } from '../../Grid/Grid';
import { Label } from '../../Label/Label';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { ValuesProps } from '../Step6/Step6';
import { FormItemError } from '../../FormItemError/FormItemError';
import { AppContext } from '../../Layout/Layout';
import { Loader } from '../../Loader/Loader';
import { KeyValueProps } from '../../../types/common';

interface Step4Props {
    className?: string;
}

export const Step4 = ({ className }: Step4Props): ReactElement | null => {
    const stepCodeName = 'Step4';
    const navigate = useNavigate();
    const ctx = useContext(AppContext);
    const formikRef = useRef<FormikProps<any>>(null);
    const [submitted, setSubmitted] = useState(false);
    const [questionsRange, setQuestionsRange] = useState<Array<number>>();
    const [currentStepId, setCurrentStepId] = useState<null | number>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [initialValues, setInitialValues] = useState<KeyValueProps | undefined>(undefined);
    const initDataQ = ctx.initDataQuestionaire?.Questionnaires.Questions;

    // ### Effect sets currentStep to localStorage on mount
    useEffect(() => {
        localStorage.setItem('currentStep', '4');
        ctx.setIsBackButtonVisible(true);
    }, []);

    // ### Effect sets initialValues for Formik form when we know which slice of initDataQuestionnaire we want
    useEffect(() => {
        if (questionsRange && initDataQ) {
            const data = getFilledData(questionsRange, ctx);
            setInitialValues(data);
        }
    }, [questionsRange, initDataQ]);

    // ### Effect sets current step order (number) to state
    useEffect(() => {
        if (ctx.initData?.Lovs) {
            const step = findQuestionHeaderId(stepCodeName, ctx.initData?.Lovs?.QuestionHeaders);
            step && setCurrentStepId(step.Id);
        }
    }, [ctx.initData]);

    // ### Effect sets question range, after we resolve, in which step we currently are
    useEffect(() => {
        if (currentStepId) {
            const range = findQuestionRange(currentStepId, initDataQ);
            range && setQuestionsRange(range);
        }
    }, [currentStepId, initDataQ]);

    // ### Effect runs when submitting a form
    useEffect(() => {
        const values = formikRef?.current?.values;

        if ((isSubmitting || ctx.isSaving) && values && questionsRange && ctx.formResult) {
            updateGlobalFormState(initDataQ?.slice(...questionsRange) || [], values, ctx, config.STEP_NAMES.STEP4);
            if (isSubmitting) {
                setCompleted(4);
                navigate(`/${config.SLUGS.STEP5_SLUG}/`);
                setIsSubmitting(false);
            }
            if (ctx.isSaving) {
                ctx.setIsSaving(false);
            }
        }
    }, [ctx.formResult, ctx.isSaving, isSubmitting, questionsRange, formikRef]);

    return (
        <Step title={cms.step4.title} text={cms.step4.text} className={cn(styles.wrapper, className)}>
            {initDataQ && initDataQ.length && ctx.initData?.Lovs && questionsRange ? (
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues || getInitialValues(initDataQ.slice(...questionsRange)) || {}}
                    onSubmit={() => {
                        setIsSubmitting(true);
                    }}
                    validateOnBlur={false}
                    validateOnChange={submitted}
                    validateOnMount={false}
                    enableReinitialize
                >
                    {({ values, errors, touched, handleSubmit }: ValuesProps) => {
                        return (
                            <Form className={styles.form}>
                                <Box innerWrapper className="mb-6 mb-3-m">
                                    {initDataQ.slice(...questionsRange).map((formItem: QuestionProps, i: number) => (
                                        <Fragment key={i}>
                                            <Label htmlFor={formItem?.Code} help={formItem.Help}>
                                                {formItem?.Name}
                                            </Label>
                                            <div
                                                className={
                                                    initDataQ.slice(...questionsRange).length !== i + 1
                                                        ? 'mb-8 mb-6-m w-100'
                                                        : 'w-100'
                                                }
                                            >
                                                <Grid cols="auto">
                                                    {formItem.PossibleAnswersIds.length > 0
                                                        ? formItem.PossibleAnswersIds.map((a, x: number) => (
                                                              <Fragment key={x}>
                                                                  {renderToggleField(
                                                                      formItem?.Code,
                                                                      `${formItem?.Code}-${a}`,
                                                                      resolveFieldType(
                                                                          formItem?.QuestionAnswerFormatId,
                                                                          ctx.initData?.Lovs
                                                                      ),
                                                                      resolveFieldText(a, ctx.initData?.Lovs),
                                                                      '',
                                                                      false,
                                                                      '',
                                                                      false,
                                                                      touched[formItem?.Code] && errors[formItem?.Code]
                                                                  )}
                                                              </Fragment>
                                                          ))
                                                        : renderTextField(
                                                              formItem?.Code,
                                                              resolveFieldType(
                                                                  formItem?.QuestionAnswerFormatId,
                                                                  ctx.initData?.Lovs
                                                              ),
                                                              '',
                                                              '',
                                                              '',
                                                              'field-small',
                                                              resolveFieldMin(formItem?.Code),
                                                              250,
                                                              touched[formItem?.Code] && errors[formItem?.Code],
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              true
                                                          )}
                                                </Grid>

                                                {touched[formItem?.Code] && errors[formItem?.Code] && (
                                                    <FormItemError text={errors[formItem?.Code]} />
                                                )}
                                            </div>

                                            {formItem.Questions.filter(
                                                (q) =>
                                                    resolveFieldText(
                                                        q.FilterByQuestionAnswerListId || 0,
                                                        ctx.initData?.Lovs
                                                    ) === values[formItem.Code]
                                            ).map((formItemQuestion, j: number) => (
                                                <Fragment key={j}>
                                                    <Label htmlFor={formItemQuestion.Code} help={formItemQuestion.Help}>
                                                        {formItemQuestion.Name}
                                                    </Label>

                                                    <div className="mb-8 mb-6-m w-100">
                                                        <Grid cols="auto">
                                                            {formItemQuestion.PossibleAnswersIds?.map((a) =>
                                                                renderToggleField(
                                                                    formItemQuestion.Code,
                                                                    `${formItemQuestion.Code}-${a}`,
                                                                    resolveFieldType(
                                                                        formItemQuestion?.QuestionAnswerFormatId,
                                                                        ctx.initData?.Lovs
                                                                    ),
                                                                    resolveFieldText(a, ctx.initData?.Lovs),
                                                                    '',
                                                                    false,
                                                                    undefined,
                                                                    false,
                                                                    touched[formItemQuestion?.Code] &&
                                                                        errors[formItemQuestion?.Code]
                                                                )
                                                            )}
                                                        </Grid>

                                                        {touched[formItemQuestion?.Code] &&
                                                            errors[formItemQuestion?.Code] && (
                                                                <FormItemError text={errors[formItemQuestion?.Code]} />
                                                            )}
                                                    </div>

                                                    {formItemQuestion.Questions.length > 0 &&
                                                        formItemQuestion.Questions.filter(
                                                            (q) =>
                                                                resolveFieldText(
                                                                    q.FilterByQuestionAnswerListId || 0,
                                                                    ctx.initData?.Lovs
                                                                ) === values[formItemQuestion.Code]
                                                        ).map((subquestion, k: number) => (
                                                            <Fragment key={k}>
                                                                <Label
                                                                    htmlFor={subquestion.Code}
                                                                    help={subquestion.Help}
                                                                >
                                                                    {subquestion.Name}
                                                                </Label>

                                                                <div className="mb-8 mb-6-m w-100">
                                                                    <Grid cols="auto">
                                                                        {subquestion.PossibleAnswersIds?.map((a) =>
                                                                            renderToggleField(
                                                                                subquestion.Code,
                                                                                `${subquestion.Code}-${a}`,
                                                                                resolveFieldType(
                                                                                    formItemQuestion?.QuestionAnswerFormatId,
                                                                                    ctx.initData?.Lovs
                                                                                ),
                                                                                resolveFieldText(a, ctx.initData?.Lovs),
                                                                                undefined,
                                                                                false,
                                                                                '',
                                                                                false,
                                                                                touched[subquestion?.Code] &&
                                                                                    errors[subquestion?.Code]
                                                                            )
                                                                        )}
                                                                    </Grid>

                                                                    {touched[subquestion?.Code] &&
                                                                        errors[subquestion?.Code] && (
                                                                            <FormItemError
                                                                                text={errors[subquestion?.Code]}
                                                                            />
                                                                        )}
                                                                </div>
                                                            </Fragment>
                                                        ))}
                                                </Fragment>
                                            ))}
                                        </Fragment>
                                    ))}
                                </Box>

                                <Button
                                    variant="primary"
                                    loading={isSubmitting}
                                    onClick={() => {
                                        scrollToError();
                                        setSubmitted(true);
                                        handleSubmit();
                                    }}
                                >
                                    {cms.common.continueButton}
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                <Loader size="medium" />
            )}
            <ErrorForm />
        </Step>
    );
};
