import { ReactElement, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from './Button.module.scss';
import { Loader } from '../Loader/Loader';

export interface ButtonProps {
    children: ReactElement | string;
    variant: 'primary' | 'secondary' | 'tertiary' | 'link' | 'white';
    submit?: boolean;
    small?: boolean;
    outlined?: boolean;
    href?: string;
    onClick?: (e?: SyntheticEvent) => void;
    className?: string;
    renderAsLabel?: boolean;
    htmlFor?: string;
    external?: boolean;
    disabled?: boolean;
    loading?: boolean;
    active?: boolean;
}

export const Button = ({
    children,
    variant,
    outlined,
    disabled,
    loading,
    small,
    href,
    submit,
    onClick,
    className,
    renderAsLabel,
    htmlFor,
    external,
    active,
}: ButtonProps): ReactElement => {
    const classnames = cn(
        styles.wrapper,
        className,
        disabled && styles.disabled,
        variant && styles[variant],
        outlined && styles.outlined,
        loading && styles.loading,
        small && styles.small,
        active && styles.active
    );
    const content = (
        <>
            <Loader className={styles.loader} size={small ? 'xsmall' : 'small'} color="unset" />
            <span className={styles.content}>{children}</span>
        </>
    );
    if (!onClick && !href) {
        href = '#';
    }

    if (renderAsLabel && htmlFor) {
        return (
            <label htmlFor={htmlFor} className={classnames}>
                {content}
            </label>
        );
    }

    if (submit) {
        return (
            <button type="submit" className={classnames} disabled={disabled}>
                {content}
            </button>
        );
    }

    if (onClick) {
        return (
            <button type="button" onClick={onClick} className={classnames} disabled={disabled}>
                {content}
            </button>
        );
    }

    if (external && href) {
        return (
            <a href={href} className={classnames}>
                {content}
            </a>
        );
    }

    return (
        <Link to={href ?? '/'} className={classnames}>
            {content}
        </Link>
    );
};
