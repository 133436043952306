import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';

import cms from '../../data/cms.json';
import styles from './LoadingCountdown.module.scss';
import { Carousel } from '../Carousel/Carousel';
import { Countdown } from '../Countdown/Countdown';

interface LoadingCountdownProps {
    initialTime: number;
    countdownFinished?: ReactNode;
    className?: string;
}

export const LoadingCountdown = ({
    initialTime,
    countdownFinished,
    className,
}: LoadingCountdownProps): ReactElement | null => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const BREAKPOINT = 800;

    useEffect(() => {
        videoRef.current && initVideo();
        // ### Re-init video on resize
        window.addEventListener('resize', initVideo);
        return () => window.removeEventListener('resize', initVideo);
    }, [videoRef]);

    const initVideo = () => {
        if (window && videoRef.current) {
            const source = videoRef.current.querySelectorAll('source');
            if (source) {
                // ### Serve correct video version according to current breakpoint
                if (window.innerWidth >= BREAKPOINT) {
                    source[0].setAttribute('src', '/video/long-1170x.mp4');
                    source[0].setAttribute('type', 'video/mp4');
                    source[1].setAttribute('src', '/video/long-1170x.webm');
                    source[1].setAttribute('type', 'video/webm');
                } else {
                    source[0].setAttribute('src', '/video/short-375x.mp4');
                    source[0].setAttribute('type', 'video/mp4');
                    source[1].setAttribute('src', '/video/short-375x.webm');
                    source[1].setAttribute('type', 'video/webm');
                }
            }
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            videoRef.current.load();
        }
    };

    return (
        <div className={cn(styles.wrapper, className)}>
            <Countdown className={styles.countdown} initialTime={initialTime} countdownFinished={countdownFinished} />

            <div className={styles.animation}>
                <video ref={videoRef} className={styles.animationVideo} autoPlay loop muted>
                    <source />
                    <source />
                </video>
            </div>

            <Carousel className={styles.carousel} data={cms.step9.carousel} />
        </div>
    );
};
