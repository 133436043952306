import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './Textarea.module.scss';
import { FieldInputProps } from 'formik';

interface TextareaProps {
    field: FieldInputProps<string | number>;
    placeholder?: string;
    id?: string;
    className?: string;
    maxLenght?: number;
}

export const Textarea = ({ field, id, className, placeholder, maxLenght }: TextareaProps): ReactElement | null => {
    return (
        <div className={cn(styles.wrapper, className)}>
            <textarea id={id} 
                      className={cn(styles.input)} 
                      placeholder={placeholder} 
                      maxLength= {maxLenght}
                      {...field} />
        </div>
    );
};
