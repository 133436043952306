import { useContext } from 'react';
import cn from 'classnames';
import cms from '../../data/cms.json';
import { useNavigate } from 'react-router-dom';

import styles from './Logo.module.scss';
import { Title } from '../Title/Title';
import { Paragraph } from '../Paragraph/Paragraph';
import { Button } from '../Button/Button';
import { AppContext } from '../Layout/Layout';
import { resetProcess } from '../../utils/resetProcess';
import { unlockScroll } from '../../utils/bodyScrollLock';

export const LeavingPopup = () => {
    const navigate = useNavigate();
    const ctx = useContext(AppContext);
    return (
        <div>
            <Title tag="h2" className="mb-4">
                {cms.leaving.title}
            </Title>

            <Paragraph>{cms.leaving.text}</Paragraph>

            <div className={cn(styles.buttonGroupWrapper, 'flex--row')}>
                <div className={cn(styles.buttonWrapper, 'flex--end')}>
                    <Button
                        className={cn(styles.button, 'mb-4')}
                        variant="primary"
                        onClick={() => {
                            ctx.setPopupVisible(false);
                            ctx.setIsBackButtonVisible(false);
                            unlockScroll();
                        }}
                    >
                        {cms.common.continueButton}
                    </Button>
                </div>
                <div className={cn(styles.buttonWrapper, 'flex--start')}>
                    <Button
                        className={cn(styles.button, 'mb-4')}
                        variant="secondary"
                        onClick={() => window && window.open('https://www.simplea.cz', '_self')}
                    >
                        {cms.common.navigateToSimplea}
                    </Button>
                </div>
            </div>

            <div>
                <a
                    onClick={() => {
                        resetProcess(navigate);
                    }}
                    className="text--underline"
                    href="#"
                >
                    {cms.common.resetButton}
                </a>
            </div>
        </div>
    );
};
