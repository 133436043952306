import { ParticipantProps } from '../../../types/model';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import config from '../../../config.json';

type CountryValidation = {
    [key in PrefixesWithOwnRegexEnum]: string;
};

enum PrefixesWithOwnRegexEnum {
    CZECH = '+420',
    SLOVAK = '+421',
    POLAND = '+48',
}

const prefixesWithRegex: PrefixesWithOwnRegexEnum[] = Object.values(PrefixesWithOwnRegexEnum).map((value) => value);

export const participantInitialValues = (participant: ParticipantProps, isSupportedPrefix: boolean | undefined) => {
    return {
        titleBefore: participant.Identification.PersonalIdentificationDetail?.TitleBefore ?? '',
        titleAfter: participant.Identification.PersonalIdentificationDetail?.TitleAfter ?? '',
        birthplace: participant.Identification.PersonalIdentificationDetail?.Birthplace ?? '',
        birthCountry: participant.Identification.PersonalIdentificationDetail?.BirthCountry ?? null,
        citizenship: participant.Identification.PersonalIdentificationDetail?.Citizenship ?? null,
        secondCitizenship: participant.Identification.PersonalIdentificationDetail?.SecondCitizenship ?? null,
        issuer: participant.Identification.IdCard?.Issuer ?? '',
        validateSecondCitizenship:
            (participant.Identification.PersonalIdentificationDetail
                ? participant.Identification.PersonalIdentificationDetail.SecondCitizenship &&
                  participant.Identification.PersonalIdentificationDetail.SecondCitizenship.length > 0
                : false) ?? false,
        bankAccountPrefix: participant.Identification.BankAccount?.Prefix ?? '',
        bankAccountNo: participant.Identification.BankAccount?.BankAccountNo ?? '',
        bankAccountCode: participant.Identification.BankAccount?.Code ?? null,
        email: validateEmail(participant.Contact.Email),
        phone: validatePhone(
            participant.Contact.Phone && isSupportedPrefix ? formatPhoneNumber(participant.Contact.Phone) : '',
            participant.Contact.PhonePrefix.Prefix as PrefixesWithOwnRegexEnum
        ),
        phonePrefix: isSupportedPrefix ? participant.Contact.PhonePrefix.Prefix : '',
    };
};

const validationPerCountry: CountryValidation = {
    [PrefixesWithOwnRegexEnum.CZECH]: config.REGEX.CZECH_CELLPHONE_REGEX,
    [PrefixesWithOwnRegexEnum.SLOVAK]: config.REGEX.SLOVAK_CELLPHONE_REGEX,
    [PrefixesWithOwnRegexEnum.POLAND]: config.REGEX.POLAND_CELLPHONE_REGEX,
};

const validateEmail = (email: string | null) =>
    !email || !new RegExp(config.REGEX.EMAIL_REGEX).test(email) ? '' : email;

const validatePhone = (phone: string | null, prefix: PrefixesWithOwnRegexEnum) => {
    if (!phone || !prefix) return '';

    // the biggest number of splitted elements is 3 because of the phone format "777 777 777"
    const hasTooManySpaces = phone.split(' ').length > 3;
    const formattedPhone = phone.replaceAll(' ', '');

    const validation = prefixesWithRegex.includes(prefix)
        ? validationPerCountry[prefix]
        : config.REGEX.OTHER_CELLPHONE_REGEX;

    if (!formattedPhone || !new RegExp(validation).test(formattedPhone) || hasTooManySpaces) return '';

    return formattedPhone;
};
