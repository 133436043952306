import { ReactElement, useContext } from 'react';
import cn from 'classnames';

import config from '../../config.json';
import styles from './Footer.module.scss';

import { Logo } from '../Logo/Logo';
import { ContactItem } from '../ContactItem/ContactItem';
import { AppContext } from '../Layout/Layout';

interface FooterProps {
    className?: string;
    disableLogoLink?: boolean;
}

export const Footer = ({ className, disableLogoLink }: FooterProps): ReactElement | null => {
    const ctx = useContext(AppContext);
    return (
        <footer className={cn(styles.wrapper, className)} id="page-footer">
            <Logo className={styles.logo} withLink={!disableLogoLink} />
            <div className={styles.links}>
                <span className={styles.copyright}>
                    {ctx.formResult.ExternalId
                        ? 'Kód nabídky: ' + ctx.formResult.ExternalId?.substring(0, 8)
                        : new Date().getFullYear() + ' © Simplea'}
                </span>
                <ContactItem className={styles.linkItem} value={config.SIMPLEA_EMAIL} type="email" />
            </div>
        </footer>
    );
};
