import { ReactElement, useContext, useEffect, useState } from 'react';

import cms from '../../../data/cms.json';

import { Step } from '../../Step/Step';
import { AppContext } from '../../Layout/Layout';

import axios from 'axios';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import { Formik } from 'formik';
import { Box } from '../../Box/Box';
import { Button } from '../../Button/Button';
import { FormItemError } from '../../FormItemError/FormItemError';
import { renderTextField } from '../../../utils/renderTextField';
import { Label } from '../../Label/Label';

interface TestProps {
    className?: string;
}

export const Test = ({ className }: TestProps): ReactElement | null => {
    const [formError, setFormError] = useState(false);
    const ctx = useContext(AppContext);
    const [serverResponse, setServerResonse] = useState<string>();
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        ctx.setIsBackButtonVisible(true);
    }, []);

    if (formError) {
        return (
            <Step title={cms.steperror.title} text={null} className={className}>
                <Label>{serverResponse ?? ''}</Label>
            </Step>
        );
    }

    return (
        <Formik
            validateOnBlur={false}
            validateOnChange={submitted}
            validateOnMount={false}
            initialValues={{
                interval: '',
            }}
            onSubmit={(values) => {
                setSubmitted(true);
                setFormError(false);
                axios
                    .get(`${getBaseUrl()}api/online/misc/wait-for-local/${values.interval}`)
                    .then((response) => {
                        if (response) {
                            setServerResonse(response.data);
                        } else {
                            setFormError(true);
                        }
                    })
                    .catch((error) => {
                        setFormError(true);
                        setServerResonse(error);
                        console.error(error);
                    })
                    .finally(() => {
                        setSubmitted(false);
                    });
            }}
        >
            {({ errors, handleSubmit }) => (
                <Step title="Testovací stránka" text="">
                    <Box innerWrapper>
                        <>
                            <div className="mb-6">
                                {renderTextField(
                                    'interval',
                                    'number',
                                    'Vyplňte číslo v sekunách, jak dlouho má trvat zpracovat požadavek na serveru',
                                    undefined,
                                    undefined,
                                    '',
                                    null,
                                    99999,
                                    errors.interval,
                                    false,
                                    undefined,
                                    undefined,
                                    true
                                )}
                                {errors.interval && <FormItemError text={errors.interval} />}
                            </div>

                            <Button
                                className="mb-6"
                                variant="primary"
                                loading={submitted}
                                onClick={() => {
                                    setSubmitted(true);
                                    handleSubmit();
                                }}
                            >
                                Test odezvy
                            </Button>
                            <Label>{serverResponse ?? ''}</Label>
                        </>
                    </Box>
                </Step>
            )}
        </Formik>
    );
};
