import { ContextProps } from '../components/Layout/Layout';
import { ModelProps, ProtectionGroupProps } from '../types/model';
import { filterClientAvailability } from './filterClientAvailability';

// ### Function is used to set IsChosen attribute in data to false, when product is unavailable

export const resetIsChosen = (ctx: ContextProps): ModelProps => {
    const groups = ctx.formResult?.Participants[0].SuggestedInsurance?.ProtectionGroups;
    const regularGroups = groups ? filterClientAvailability(groups, true) : [];
    const forbiddenGroups = groups ? filterClientAvailability(groups, false) : [];

    // Set IsChosen to false when ClientAvailability === 1
    forbiddenGroups.map((g: ProtectionGroupProps) =>
        g.SuggestedProtections.map((sp) => sp.Variants.map((v) => (v.IsChosen = false)))
    );

    // Set IsChosen to false when SumInsuredChosen === 0
    regularGroups.map((g: ProtectionGroupProps) =>
        g.SuggestedProtections.map((sp) =>
            sp.Variants.map((v) => {
                if (v.SumInsuredChosen === 0) {
                    v.IsChosen = false;
                }
            })
        )
    );

    if (forbiddenGroups && Array.isArray(forbiddenGroups) && forbiddenGroups.length > 0) {
        return {
            ...ctx.formResult,
            Participants: [
                {
                    ...ctx.formResult?.Participants[0],
                    SuggestedInsurance: {
                        ...ctx.formResult?.Participants[0].SuggestedInsurance,
                        // ### Push all processed groups into ProtectionGroups
                        ProtectionGroups: [...regularGroups, ...forbiddenGroups],
                    },
                },
            ],
        };
    } else {
        return ctx.formResult;
    }
};
