import { ReactElement } from 'react';
import cn from 'classnames';
import styles from './Close.module.scss';

interface CloseProps {
    onClick: () => void;
    className?: string;
}

export const Close = ({ onClick, className }: CloseProps): ReactElement | null => {
    return (
        <button onClick={onClick} className={cn(styles.wrapper, className)}>
            <div className={styles.cross}></div>
        </button>
    );
};
