import React from 'react';
import cms from '../../../data/cms.json';
import { Icon } from '../../Icon/Icon';
import styles from './Step9loading.module.scss';

const CountdownAlert = () => {
    return (
        <div className={styles.countdownAlert}>
            <Icon name="alertMegaphone" size="large" />
            {cms.step9loader.countdownFinished}
        </div>
    );
};

export default CountdownAlert;
