import cn from 'classnames';
import { Fragment } from 'react';

import { CommonDataProps, QuestionProps } from '../types/initData';
import { resolveFieldText, resolveIdsFromName, resolveIdsFromCode } from './externalDataHandling';
import { renderQuestion } from './renderQuestion';

import { ContextProps } from '../components/Layout/Layout';
import { ValuesProps } from '../components/_pages/Step6/Step6';
import { FormikValueCustomProps } from '../types/common';

// ### Function returns tree (JSX) of questions related to current form step

export const renderQuestionTree = (
    root: QuestionProps,
    values: ValuesProps,
    commonData: CommonDataProps,
    errors: ValuesProps,
    ctx: ContextProps,
    setFieldValue?: (field: string, value: FormikValueCustomProps, shouldValidate?: boolean | undefined) => void
) => {
    let newId: string;
    const storageData = localStorage.getItem('localData');
    const parsedData = storageData ? JSON.parse(storageData) : null;
    const participant = parsedData?.Participants[0];

    // ### Function accepts questions node and optional passedIndex (which represents parent node index)
    const renderTree = (current: QuestionProps, passedIndex?: number) => {
        // ### Function renders question with its subquestions
        const addNewItem = (index?: number) => {
            // ### Add _MULTI_${INDEX} string to question code to prevent duplicity
            newId =
                index || index === 0
                    ? `${current.Code}_MULTI_${index}`
                    : passedIndex || passedIndex === 0
                    ? `${current.Code}_MULTI_${passedIndex}`
                    : current.Code;

            return (
                <Fragment key={current.Code}>
                    {renderQuestion(current, commonData, errors, newId, ctx, setFieldValue, index)}

                    {current.Questions.length > 0 &&
                        current.Questions.filter(
                            (item: QuestionProps) =>
                                // ### Filter relevant (questions wich belongs to current Question) questions
                                item.FilterByQuestionAnswerListId === null ||
                                resolveIdsFromCode(values[newId], commonData).includes(
                                    item.FilterByQuestionAnswerListId
                                ) ||
                                resolveIdsFromName(values[newId], commonData).includes(
                                    item.FilterByQuestionAnswerListId
                                )
                        ).map((item: QuestionProps) => renderTree(item, index))}

                    {passedIndex === undefined && index !== undefined && index >= 0 && current.IsMultiAnswer && (
                        <hr className="question-item-divider" />
                    )}
                </Fragment>
            );
        };

        return (
            <Fragment key={current.Code}>
                {(Array.isArray(ctx.multiAnswer[current.Code]) &&
                    ctx.multiAnswer[current.Code] &&
                    ctx.multiAnswer[current.Code]?.length > 1) ||
                current.IsMultiAnswer
                    ? // ### Render amount of question duplicates which are multiAnswer, amount is set to
                      // Context (ctx.multiAnswer) for every clicked node
                      [...(ctx.multiAnswer[current.Code] || [0])]?.map((f: number) => (
                          <div className="question-item" key={`${current.Code}-${f}`}>
                              {addNewItem(f)}
                          </div>
                      ))
                    : // ### Regular render for non-multiAnswers
                      addNewItem(passedIndex)}
            </Fragment>
        );
    };

    // ### Render root question and its subquestion tree
    return root.Questions.filter(
        (item) => resolveFieldText(item.FilterByQuestionAnswerListId || 0, commonData) === values[root.Code]
    )
        .filter((item) => item.FilterBySexId === participant.Identification.Sex || item.FilterBySexId === 1)
        .map((item) => (
            <div className={cn('question-subitem')} key={item.Code}>
                <div className={cn('question-subitem-inner')}>{renderTree(item)}</div>
            </div>
        ));
};
