import { Fragment, ReactElement, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import cms from '../../data/cms.json';
import config from '../../config.json';
import styles from './Header.module.scss';
import { resolveBoxTitles } from '../../utils/resolveBoxTitles';
import { triggerGtmEvent } from '../../utils/triggerGtmEvent';
import { resolveCoverageBoxTitle } from '../../utils/resolveCoverageBoxTitle';
import { filterClientAvailability } from '../../utils/filterClientAvailability';
import { formatPrice } from '../../utils/formatPrice';
import { getDisabilityVariantId } from '../../utils/getVariantId';

import { Button } from '../Button/Button';
import { ListItemProps, Progress } from '../Progress/Progress';
import { AppContext, ContextProps } from '../Layout/Layout';
import { Title } from '../Title/Title';
import { Logo } from '../Logo/Logo';
import { CallButton } from '../CallButton/CallButton';
import { CheckoutButton } from '../CheckoutButton/CheckoutButton';
import { PriceDiscount } from '../PriceDiscount/PriceDiscount';
import { ContactFormPopup } from '../ContactFormPopup/ContactFormPopup';
import { CoverageGroupProps, ParticipantProps, ProtectionGroupProps, ProtectionVariantProps } from '../../types/model';
import { unlockScroll } from '../../utils/bodyScrollLock';
import { getStepRelevantData } from '../../utils/getStepRelevantData';
import { SaveFormPopup } from '../SaveFormPopup/SaveFormPopup';
import { LoadFormPopup } from '../LoadFormPopup/LoadFormPopup';

export interface HeaderProps {
    items: ReadonlyArray<ListItemProps>;
    className?: string;
}

// ### Function returns price for checkout items, special situatiion is at PAC_MP and FAM_1DIS
// We need to sum all variants prices

const resolveCheckoutItemPrice = (ctx: ContextProps, group: ProtectionGroupProps) => {
    let sum = 0;
    const priceBefore = group.PremiumChosenTotal.Frequency;

    if (ctx.formStep.completed >= 8 && ctx.formResult && ctx.formResult.Participants[0].CoverageGroups.length > 0) {
        let value;
        const disabilityData =
            ctx.formResult &&
            ctx.formResult.Participants[0].CoverageGroups.filter(
                (cg: CoverageGroupProps) =>
                    cg.RiderGroupCode === config.RIDER_GROUPS.DISD123 ||
                    cg.RiderGroupCode === config.RIDER_GROUPS.DISD23 ||
                    cg.RiderGroupCode === config.RIDER_GROUPS.DISD3 ||
                    cg.RiderGroupCode === config.RIDER_GROUPS.MP_DISD123 ||
                    cg.RiderGroupCode === config.RIDER_GROUPS.MP_DISD23 ||
                    cg.RiderGroupCode === config.RIDER_GROUPS.MP_DISD3
            );
        const otherData =
            ctx.formResult &&
            ctx.formResult.Participants[0].CoverageGroups.filter(
                (cg: CoverageGroupProps) =>
                    cg.RiderGroupCode !== config.RIDER_GROUPS.DISD123 &&
                    cg.RiderGroupCode !== config.RIDER_GROUPS.DISD23 &&
                    cg.RiderGroupCode !== config.RIDER_GROUPS.DISD3
            );

        if (
            group.RiderCategoryCode === config.RIDER_CATEGORIES.DIS ||
            group.RiderCategoryCode === config.RIDER_CATEGORIES.MP
        ) {
            disabilityData.map((cg: CoverageGroupProps) =>
                cg.Coverages.map(
                    (cg2) => (sum = sum + cg2.AcceptedVariant.Summary.CoverageDetails[0].Premium.Frequency)
                )
            );
            return formatPrice(sum);
        } else {
            value =
                otherData.find(
                    (cg: CoverageGroupProps) =>
                        cg.RiderGroupCode ===
                        (group.SuggestedProtections[0].Variants.find((v) => v.IsChosen)?.RiderGroup?.Code || '')
                )?.Coverages[0].AcceptedVariant.Summary.CoverageDetails[0].Premium.Frequency || 0;
            return formatPrice(value);
        }
    } else {
        return formatPrice(priceBefore);
    }
};

// ### Function selected disability level text, later used for title

const resolveChosenDisabilityVariant = (group: ProtectionGroupProps) => {
    const variants = group.SuggestedProtections?.[1]?.Variants;
    const chosenVariant = variants.find((v: ProtectionVariantProps) => v.IsChosen)?.RiderGroup.Code;

    if (chosenVariant) {
        switch (chosenVariant) {
            case config.RIDER_GROUPS.MP_DISD123:
                return config.INSURANCE_LEVELS.find((lvl) => lvl.code === 3)?.name;
            case config.RIDER_GROUPS.MP_DISD23:
                return config.INSURANCE_LEVELS.find((lvl) => lvl.code === 2)?.name;
            case config.RIDER_GROUPS.MP_DISD3:
                return config.INSURANCE_LEVELS.find((lvl) => lvl.code === 1)?.name;
            default:
                return null;
        }
    }
    return null;
};

const checkoutPopup = (ctx: ContextProps, participant: ParticipantProps, positiveCallback?: () => void) => {
    const insurance = participant.SuggestedInsurance;
    const data = insurance && filterClientAvailability(insurance.ProtectionGroups, true);
    const disabilityData = data && data.find((item) => item.RiderCategoryCode === config.RIDER_CATEGORIES.DIS);
    const isChosenDisabilityVariant =
        disabilityData && disabilityData.SuggestedProtections[0].Variants.find((item) => item.IsChosen)?.VariantOrder;
    let nextStepPrice = 0;
    if (ctx.formStep.completed < 2) {
        const otherGroups =
            ctx.formResult?.Participants[0].SuggestedInsurance &&
            getStepRelevantData(ctx.formResult?.Participants[0].SuggestedInsurance.ProtectionGroups, 'STEP3_PRODUCTS');

        otherGroups &&
            otherGroups.map((g: ProtectionGroupProps) =>
                g.SuggestedProtections[0].Variants.map((item: ProtectionVariantProps) => {
                    if (item.IsChosen) {
                        nextStepPrice = nextStepPrice + item.PremiumChosen.Frequency;
                    }
                })
            );
    }

    return (
        <div className={cn(styles.checkoutWrapper, 'mb-6', 'mt-6', 'mb-0-m', 'mt-0-m')}>
            <Title tag="span" size="lg">
                Shrnutí vaší pojistky
            </Title>

            {insurance && insurance?.ProtectionGroups.length > 0 && (
                <table className={cn(styles.checkoutContent, 'mt-6', 'mb-10', 'mt-4-m', 'mb-6-m')}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Pojistná částka</th>
                            <th className={styles.priceForMonthCell}>Cena za měsíc</th>
                        </tr>
                    </thead>

                    <tbody>
                        {filterClientAvailability(
                            ctx.formStep.completed >= 2
                                ? insurance.ProtectionGroups
                                : getStepRelevantData(insurance.ProtectionGroups, 'STEP2_PRODUCTS'),
                            true
                        ).map(
                            (g: ProtectionGroupProps, i: number) =>
                                g.PremiumChosenTotal.Frequency > 0 && (
                                    <Fragment key={i}>
                                        <tr key={g.RiderCategoryCode}>
                                            <td>
                                                {resolveBoxTitles(g.RiderCategoryCode)?.title}
                                                {g.RiderCategoryCode === config.RIDER_CATEGORIES.MP &&
                                                    ` (${resolveChosenDisabilityVariant(g)} stupně)`}
                                            </td>
                                            <td className={styles.checkoutInsuredValue}>
                                                {g.RiderCategoryCode !== config.RIDER_CATEGORIES.DIS &&
                                                    `${formatPrice(
                                                        g.SuggestedProtections[0].Variants.find((v) => v.IsChosen)
                                                            ?.SumInsuredChosen || 0
                                                    )} ${config.CURRENCIES.CZK}`}
                                            </td>
                                            <td className={cn(styles.checkoutPrice, styles.priceForMonthCell)}>
                                                {resolveCheckoutItemPrice(ctx, g)} {config.CURRENCIES.CZK}
                                            </td>
                                        </tr>

                                        {g.RiderCategoryCode === config.RIDER_CATEGORIES.DIS &&
                                            g.SuggestedProtections[0].Variants.filter(
                                                (v) => v.VariantOrder === isChosenDisabilityVariant
                                            )
                                                .reverse()
                                                .map((filteredItem, index: number) => (
                                                    <tr
                                                        key={`${g.RiderCategoryCode}-${index}`}
                                                        className={index % 2 === 0 ? styles.highlightedRow : ''}
                                                    >
                                                        <td className={styles.checkoutSubitem}>
                                                            {resolveCoverageBoxTitle(filteredItem.RiderGroup.Code)}
                                                        </td>
                                                        <td className={styles.checkoutInsuredValue}>
                                                            {formatPrice(
                                                                g.SuggestedProtections[0]?.DisabilityDetails?.find(
                                                                    (d) =>
                                                                        d.DisabilityLevel ===
                                                                        getDisabilityVariantId(
                                                                            filteredItem.RiderGroup.Code
                                                                        )
                                                                )?.SumInsuredChosen || 0
                                                            )}{' '}
                                                            {config.CURRENCIES.CZK}
                                                        </td>
                                                        <td className={styles.priceForMonthCell}></td>
                                                    </tr>
                                                ))}
                                    </Fragment>
                                )
                        )}
                    </tbody>
                </table>
            )}

            <PriceDiscount
                titleText="Celkem vás pojištění měsíčně vyjde na"
                priceAfter={
                    (ctx.formResult &&
                    ctx.formStep.completed >= 8 &&
                    ctx.formResult.Participants[0].CoverageGroups.length > 0
                        ? ctx.formResult.Payment.AcceptedPremium.Frequency
                        : (insurance?.PremiumTotal?.Frequency || 0) - nextStepPrice) || 0
                }
            />

            {positiveCallback && (
                <Button
                    className={cn(styles.checkoutButton, 'mt-6')}
                    variant="primary"
                    onClick={() => {
                        ctx.setPopupVisible(false);
                        positiveCallback ? positiveCallback() : triggerGtmEvent('basket_go');
                        unlockScroll();
                    }}
                >
                    Pokračovat
                </Button>
            )}
        </div>
    );
};

export const cartInit = (ctx: ContextProps, participant: ParticipantProps, positiveCallback?: () => void) => {
    ctx.setCloseButton(true);
    ctx.setPopupVisible(true);
    ctx.setPopupContent(checkoutPopup(ctx, participant, positiveCallback));
};

export const Header = ({ className, items }: HeaderProps): ReactElement | null => {
    const navigate = useNavigate();
    const ctx = useContext(AppContext);
    const insurance = ctx.formResult?.Participants[0].SuggestedInsurance;
    let nextStepPrice = 0;
    let nextStepProductItems = 0;
    if (ctx.formStep.completed < 2) {
        const otherGroups =
            ctx.formResult?.Participants[0].SuggestedInsurance &&
            getStepRelevantData(ctx.formResult?.Participants[0].SuggestedInsurance.ProtectionGroups, 'STEP3_PRODUCTS');

        otherGroups &&
            otherGroups.map((g: ProtectionGroupProps) =>
                g.SuggestedProtections[0].Variants.map((item: ProtectionVariantProps) => {
                    if (item.IsChosen) {
                        nextStepPrice = nextStepPrice + item.PremiumChosen.Frequency;
                        nextStepProductItems++;
                    }
                })
            );
    }

    const handleCallButtonClick = () => {
        ctx.setPopupContent(<ContactFormPopup />);
        ctx.setPopupContentVariables({ uncloseable: false });
        ctx.setCloseButton(true);
        ctx.setPopupVisible(true);
        ctx.setPopupCloseEvent('lead_end');
        triggerGtmEvent('lead');
    };

    const handleSaveButtonClick = () => {
        ctx.formStep.current < 2 ? ctx.setPopupContent(<LoadFormPopup />) : ctx.setPopupContent(<SaveFormPopup />);
        ctx.setPopupContentVariables({ uncloseable: false });
        ctx.setCloseButton(true);
        ctx.setPopupVisible(true);
    };

    return (
        <header className={cn(styles.wrapper, className)}>
            <Logo className={styles.logo} />

            {ctx.formStep.completed > 0 && ctx.formStep.completed < 8 && insurance && (
                <CheckoutButton
                    onClick={() => {
                        ctx.formResult && cartInit(ctx, ctx.formResult.Participants[0]);
                        ctx.setPopupCloseEvent('basket_end');
                        triggerGtmEvent('basket');
                    }}
                    className={styles.checkout}
                    itemsCount={
                        (insurance.ProtectionGroups.filter(
                            (g: ProtectionGroupProps) => g.PremiumChosenTotal.Frequency > 0
                        )?.length || 0) - nextStepProductItems
                    }
                    price={
                        ctx.formStep.completed >= 8 &&
                        ctx.formResult &&
                        ctx.formResult.Participants[0].CoverageGroups.length > 0
                            ? ctx.formResult.Payment.AcceptedPremium.Frequency
                            : (insurance.PremiumTotal?.Frequency || 0) - nextStepPrice
                    }
                />
            )}

            {ctx.formStep.completed > 0 && (
                <>
                    <Button
                        className={styles.callButtonWide}
                        variant="primary"
                        outlined
                        small
                        onClick={() => handleCallButtonClick()}
                    >
                        {cms.common.callButton}
                    </Button>

                    <CallButton className={styles.callButtonNarrow} onClick={() => handleCallButtonClick()} />
                </>
            )}

            {ctx.isBackButtonVisible && (
                <Button
                    variant="tertiary"
                    small
                    outlined
                    className={cn(styles.back, ctx.formStep.completed < 8 ? '' : styles.autoMarginRight)}
                    onClick={() => navigate(-1)}
                >
                    {cms.common.backButton}
                </Button>
            )}
            {ctx.formStep.completed < 8 && (
                <Button
                    variant="tertiary"
                    small
                    outlined
                    className={styles.save}
                    onClick={() => handleSaveButtonClick()}
                >
                    {ctx.formStep.current < 2 ? cms.common.loginButton : cms.common.saveButton}
                </Button>
            )}

            <Progress className={styles.progress} items={items} />
        </header>
    );
};
