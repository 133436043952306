// ### Function is used for triggering GTM (Google Tag Manager) events

export const triggerGtmEvent = (event: string) => {
    // ### window typescript casting
    const w = window as unknown as {
        dataLayer: Record<string, unknown>[];
    };

    if (w && w.dataLayer) {
        w.dataLayer.push({ event: event });
    }
};
