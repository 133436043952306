import { DEFAULT_PHONE_PREFIX } from '../constants/defaults';
import { InitDataObjectProps } from '../types/initData';
import { PhonePrefixProps } from '../types/model';

export const getPhonePrefix = (phonePrefix: string | null, initData: InitDataObjectProps | null): PhonePrefixProps => {
    const prefix = initData?.InternationalPhonePrefixes?.find(prefix => prefix.PhonePrefix === phonePrefix);
    const country = initData?.Lovs?.Countries?.find(country => country.Id === prefix?.CountryId);

    if (prefix && country) {
        return {
            CountryCode: country?.Code,
            ExternalId: prefix?.ExternalId,
            Prefix: prefix?.PhonePrefix,
        };
    }

    const czechPrefix = initData?.InternationalPhonePrefixes?.find(prefix => prefix.PhonePrefix === DEFAULT_PHONE_PREFIX);

    return {
        CountryCode: 'CZ',
        ExternalId: czechPrefix?.ExternalId || '',
        Prefix: DEFAULT_PHONE_PREFIX,
    };
};
