import { ReactElement, useContext, useState } from 'react';
import cn from 'classnames';
import { Formik, Form } from 'formik';

import cms from '../../data/cms.json';
import styles from './ContactFormPopup.module.scss';
import { fetchResultData } from '../../utils/externalDataHandling';
import { renderTextField } from '../../utils/renderTextField';
import { triggerGtmEvent } from '../../utils/triggerGtmEvent';

import { Button } from '../Button/Button';
import PhoneNumberInputNew from '../PhoneNumberInput/PhoneNumberInput';
import { Grid } from '../Grid/Grid';
import { AppContext } from '../Layout/Layout';
import { Paragraph } from '../Paragraph/Paragraph';
import { Title } from '../Title/Title';
import { cellPhoneNumberValidationSchema } from '../../utils/validations';
import { useNavigate } from 'react-router-dom';
import { unlockScroll } from '../../utils/bodyScrollLock';
import { getPhonePrefix } from '../../utils/getPhonePrefix';

export interface Props {
    className?: string;
    resetForm?: boolean;
}

export const ContactFormPopup = ({ className, resetForm }: Props): ReactElement | null => {
    const ctx = useContext(AppContext);
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <div className={cn(styles.wrapper, className)}>
            {error ? (
                <div>
                    <Title tag="h2" className={cn('mb-3')}>
                        {cms.common.phoneConsultationErrorTitle}
                    </Title>

                    <Paragraph>{cms.common.phoneConsultationErrorText}</Paragraph>

                    <Button
                        variant="primary"
                        onClick={() => {
                            setError(false);
                            ctx.setPopupVisible(false);
                            unlockScroll();
                            triggerGtmEvent('lead_end');

                            if (resetForm) {
                                localStorage.clear();
                                navigate('/');
                                navigate(0); // ### Force page refresh to delete React Context
                            }
                        }}
                    >
                        {cms.common.closeButton}
                    </Button>
                </div>
            ) : success ? (
                <div>
                    <Title tag="h2" className={cn('mb-3')}>
                        {cms.common.phoneConsultationSuccessTitle}
                    </Title>

                    <Paragraph>{cms.common.phoneConsultationSuccessText}</Paragraph>

                    <Button
                        variant="primary"
                        onClick={() => {
                            setSuccess(false);
                            ctx.setPopupVisible(false);
                            unlockScroll();
                            triggerGtmEvent('lead_end');

                            if (resetForm) {
                                localStorage.clear();
                                navigate('/');
                                navigate(0); // ### Force page refresh to delete React Context
                            }
                        }}
                    >
                        {cms.common.closeButton}
                    </Button>
                </div>
            ) : (
                <>
                    <Title tag="h2" className="mb-3">
                        {cms.common.phoneConsultationTitle}
                    </Title>
                    <Paragraph>{cms.common.phoneConsultationText}</Paragraph>

                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            phone: ctx.formResult.Participants[0].Contact.Phone ?? '',
                            phonePrefix: ctx.formResult.Participants[0].Contact.PhonePrefix.Prefix ?? '',
                        }}
                        validateOnBlur={false}
                        validateOnChange={true}
                        validateOnMount={false}
                        validationSchema={cellPhoneNumberValidationSchema}
                        onSubmit={(values) => {
                            if (ctx.formResult) {
                                setLoading(true);
                                const body = {
                                    PhonePrefixExternalId: getPhonePrefix(values.phonePrefix, ctx.initData)?.ExternalId,
                                    PhoneNumber: values.phone.toString(),
                                    FirstName: values.firstName,
                                    LastName: values.lastName,
                                    OnlinePolicyJson:
                                        ctx.formStep.current === 0 ? null : JSON.stringify(ctx.formResult),
                                    ClientNote: '',
                                };

                                triggerGtmEvent('lead_go');
                                const result = fetchResultData('api/online/support/save-phone-contact', body);
                                result
                                    .then(() => {
                                        setSuccess(true);
                                        setLoading(false);
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                        setError(true);
                                    });
                            } else {
                                console.error('Context is missing');
                                setError(true);
                            }
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Grid cols={2} className={'mb-3-m mb-5'}>
                                    {renderTextField(
                                        'firstName',
                                        'text',
                                        'Jméno (nepovinné)',
                                        '',
                                        '',
                                        '',
                                        null,
                                        null,
                                        '',
                                        true,
                                        undefined,
                                        undefined,
                                        true
                                    )}
                                    {renderTextField(
                                        'lastName',
                                        'text',
                                        'Příjmení (nepovinné)',
                                        '',
                                        '',
                                        '',
                                        null,
                                        null,
                                        '',
                                        true,
                                        undefined,
                                        undefined,
                                        true
                                    )}
                                </Grid>

                                <div className="mb-6 flex justify-center">
                                    <PhoneNumberInputNew
                                        className="field-large"
                                        namePhone="phone"
                                        namePhonePrefix="phonePrefix"
                                        label="Váš telefon"
                                        showEditIcon
                                        error={(touched.phone && errors.phone) || ''}
                                        optional={false}
                                    />
                                </div>

                                <Button variant="primary" submit loading={loading}>
                                    {cms.common.submitButton}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </div>
    );
};
