import * as yup from 'yup';

import config from '../config.json';
import cms from '../data/cms.json';

import { formatPrice } from './formatPrice';
import { getNumberFromString } from './getNumberFromString';
import { removeWhitespace } from './removeWhitespace';

export const getPhoneValidationRegex = (prefix: string): string => {
    switch (prefix) {
        case '+420':
            return config.REGEX.CZECH_CELLPHONE_REGEX;
        case '+421':
            return config.REGEX.SLOVAK_CELLPHONE_REGEX;
        case '+48':
            return config.REGEX.POLAND_CELLPHONE_REGEX;
        default:
            return config.REGEX.OTHER_CELLPHONE_REGEX;
    }
};

// ### Cell phone number validation, per prefix
const cellPhoneNumberValidation = {
    phone: yup
        .string()
        .transform((value) => (value ? removeWhitespace(value) : value))
        .required(cms.validations.REQUIRED_FIELD)
        .test({
            name: 'phone',
            test: function (value) {
                const regex = new RegExp(getPhoneValidationRegex(this.parent?.phonePrefix));
                return !regex.test(value || '')
                    ? this.createError({
                          message: cms.validations.CELLPHONE_NUMBER,
                          path: 'phone',
                      })
                    : true;
            },
        })
        .nullable(),
};

export const cellPhoneNumberValidationSchema = yup.object({ ...cellPhoneNumberValidation });

// ### E-mail validation
const emailValidation = {
    email: yup.string().matches(new RegExp(config.REGEX.EMAIL_REGEX), cms.validations.EMAIL_FIELD),
};

export const emailValidationSchema = yup.object({ ...emailValidation });

export const emailPhoneValidationSchema = yup.object({
    ...cellPhoneNumberValidation,
    ...emailValidation,
});

// ### Compare current field value (value) with min and max range
export const validateField = (
    value: string | number,
    optional?: boolean,
    min?: string | number | null,
    max?: string | number | null,
    variant?: string
) => {
    if (((!value && value !== 0) || value === '') && !optional) {
        return cms.validations.REQUIRED_FIELD;
    }

    if ((value || value === 0) && (min || min === 0) && (max || max === 0)) {
        const numberValue = getNumberFromString(value);

        if (numberValue > Number(max) || numberValue < Number(min)) {
            return `Vyberte hodnotu v rozmezí <span class="text--nowrap">${
                variant === config.INPUT_FIELD_TYPES.PRICE ? formatPrice(min) : min
            }</span> a <span class="nowrap">${
                variant === config.INPUT_FIELD_TYPES.PRICE ? formatPrice(max) : max
            }</span>`;
        }
    }

    return null;
};

interface ValidateFieldPhoneProps {
    phone: string;
    phonePrefix: string;
    optional: boolean;
}

export const validateFieldPhone = ({ phone, phonePrefix, optional }: ValidateFieldPhoneProps): string | null => {
    if ((!phone || !phonePrefix) && !optional) {
        return cms.validations.REQUIRED_FIELD;
    }

    const regex = new RegExp(getPhoneValidationRegex(phonePrefix));
    if (phone && !regex.test(removeWhitespace(phone) || '') && !optional) {
        return cms.validations.CELLPHONE_NUMBER;
    }

    return null;
};

// ### Compare current field value (value) with passed value (target)
export const validateFieldAgainst = (target: string) =>
    yup.number().test('min', cms.validations.VALUE_TOO_LOW, function (value) {
        if (value) {
            if (getNumberFromString(this.parent[target]) > getNumberFromString(value)) {
                return false;
            }
        }
        return true;
    });
