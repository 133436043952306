import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ### Function is used to scroll to the top of window

export const scrollTop = (behavior?: 'auto' | 'smooth') => {
    // ### setTimeout workaround, because scrollTo didnt trigger
    setTimeout(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: behavior ?? 'auto',
        });
    }, 50);
};

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        scrollTop();
    }, [pathname]);

    return null;
}
