import { QuestionHeadersProps, QuestionProps } from '../types/initData';

// ### Function returns id (order) of current step

export const findQuestionHeaderId = (code: string, data?: Array<QuestionHeadersProps>) => {
    if (!data) {
        return null;
    }
    return data.find((item) => item.Code === code);
};

// ### Function returns array of [start, end] which is later used for "slicing" part of initData
// which is relevant to current step

export const findQuestionRange = (id: number, data?: Array<QuestionProps>) => {
    if (!data) {
        return null;
    }

    // ### Find first question index
    const start = data.findIndex((item) => item.QuestionHeaderId === id);
    // ### Find last question index
    const end = data
        .slice(start + 1, data.length)
        .findIndex((item) => item.QuestionHeaderId && item.QuestionHeaderId !== id);

    return [start, start + (end >= 0 ? end : data.length) + 1];
};
