import { Fragment, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import cn from 'classnames';

import styles from './Step6.module.scss';
import cms from '../../../data/cms.json';
import config from '../../../config.json';
import { QuestionProps } from '../../../types/initData';
import { resolveFieldText, resolveFieldType } from '../../../utils/externalDataHandling';
import { findQuestionHeaderId, findQuestionRange } from '../../../utils/formFiltering';
import { renderQuestionTree } from '../../../utils/renderQuestionTree';
import { renderToggleField } from '../../../utils/renderToggleField';
import { updateGlobalFormState } from '../../../utils/updateGlobalFormState';
import { scrollToError } from '../../../utils/scrollToError';
import { getFilledData } from '../../../utils/getFilledData';
import { getInitialValues } from '../../../utils/getInitialValues';
import { setCompleted } from '../../../utils/setCompleted';

import { Step } from '../../Step/Step';
import { Box } from '../../Box/Box';
import { Button } from '../../Button/Button';
import { Grid } from '../../Grid/Grid';
import { Label } from '../../Label/Label';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { FormItemError } from '../../FormItemError/FormItemError';
import { AppContext } from '../../Layout/Layout';
import { Loader } from '../../Loader/Loader';
import { KeyValueProps } from '../../../types/common';

interface Step6Props {
    className?: string;
}

export interface ValuesProps {
    [key: string | number]: any;
}

export const Step6 = ({ className }: Step6Props): ReactElement | null => {
    const stepCodeName = 'Step6';
    const navigate = useNavigate();
    const ctx = useContext(AppContext);
    const formikRef = useRef<FormikProps<any>>(null);
    const [questionsRange, setQuestionsRange] = useState<Array<number>>();
    const [currentStepId, setCurrentStepId] = useState<null | number>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [initialValues, setInitialValues] = useState<KeyValueProps | undefined>(undefined);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const initDataQ = ctx.initDataQuestionaire?.Questionnaires.Questions;

    useEffect(() => {
        localStorage.setItem('currentStep', '6');
        ctx.setIsBackButtonVisible(true);
    }, []);

    useEffect(() => {
        if (questionsRange && initDataQ) {
            const data = getFilledData(questionsRange, ctx);
            setInitialValues(data);
        }
    }, [questionsRange, initDataQ]);

    useEffect(() => {
        const step = findQuestionHeaderId(stepCodeName, ctx.initData?.Lovs?.QuestionHeaders);
        step && setCurrentStepId(step.Id);
    }, [ctx.initData]);

    useEffect(() => {
        if (currentStepId) {
            const range = findQuestionRange(currentStepId, initDataQ);
            range && setQuestionsRange(range);
        }
    }, [currentStepId, initDataQ]);

    useEffect(() => {
        if (isSubmitting) {
            setIsSubmitting(false);
        }
    }, [ctx.formResult, isSubmitting]);

    useEffect(() => {
        const values = formikRef?.current?.values;

        if (ctx.isSaving && values && questionsRange && ctx.formResult) {
            updateGlobalFormState(initDataQ?.slice(...questionsRange) || [], values, ctx, config.STEP_NAMES.STEP6);
            ctx.setIsSaving(false);
        }
    }, [ctx.isSaving]);

    return (
        <Step title={cms.step6.title} text={cms.step6.text} className={cn(styles.wrapper, className)}>
            {initDataQ && initDataQ.length && ctx.initData?.Lovs && questionsRange ? (
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues || getInitialValues(initDataQ.slice(...questionsRange)) || {}}
                    onSubmit={(values) => {
                        updateGlobalFormState(initDataQ.slice(...questionsRange), values, ctx, config.STEP_NAMES.STEP6);
                        setIsSubmitting(true);
                        setCompleted(6);
                        navigate(`/${config.SLUGS.STEP7_SLUG}/`);
                    }}
                    validateOnBlur={false}
                    validateOnChange={hasSubmitted}
                    validateOnMount={false}
                    enableReinitialize
                >
                    {({ values, errors, handleSubmit, setFieldValue }: ValuesProps) => (
                        <Form className={styles.form}>
                            <Box className={cn('mb-6', 'mb-3-m', 'question-box', styles.box)}>
                                {initDataQ.slice(...questionsRange).map((formItem: QuestionProps, i: number) => (
                                    <Fragment key={i}>
                                        <div className="question-item">
                                            <Label htmlFor={formItem.Code} help={formItem.Help}>
                                                {formItem.Name}
                                            </Label>

                                            <Grid cols="auto">
                                                {formItem.PossibleAnswersIds.map((a: number, j: number) => (
                                                    <Fragment key={j}>
                                                        {renderToggleField(
                                                            formItem.Code,
                                                            `${formItem.Code}-${j}`,
                                                            resolveFieldType(
                                                                formItem?.QuestionAnswerFormatId,
                                                                ctx.initData?.Lovs
                                                            ),
                                                            resolveFieldText(a, ctx.initData?.Lovs) || '',
                                                            '',
                                                            false,
                                                            '',
                                                            false,
                                                            errors[formItem?.Code]
                                                        )}
                                                    </Fragment>
                                                ))}
                                            </Grid>

                                            {errors[formItem?.Code] && <FormItemError text={errors[formItem?.Code]} />}
                                        </div>

                                        {formItem.Questions.length > 0 &&
                                            ctx.initData?.Lovs &&
                                            renderQuestionTree(
                                                formItem,
                                                values,
                                                ctx.initData?.Lovs,
                                                errors,
                                                ctx,
                                                setFieldValue
                                            )}
                                    </Fragment>
                                ))}
                            </Box>

                            <Button
                                variant="primary"
                                loading={isSubmitting}
                                onClick={() => {
                                    setHasSubmitted(true);
                                    scrollToError();
                                    handleSubmit();
                                }}
                            >
                                {cms.common.continueButton}
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Loader size="medium" />
            )}

            <ErrorForm />
        </Step>
    );
};
