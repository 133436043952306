import config from '../config.json';
import cms from '../data/cms.json';

// ### Function returns title for box (step2, step3) titles

export const resolveCoverageBoxTitle = (code: string) => {
    switch (code) {
        case config.RIDER_GROUPS.DISD3:
            return cms.common.thirdLevel;
        case config.RIDER_GROUPS.DISD23:
            return cms.common.secondLevel;
        case config.RIDER_GROUPS.DISD123:
            return cms.common.firstLevel;
        case config.RIDER_GROUPS.SD28:
        case config.RIDER_GROUPS.SD56:
        case config.RIDER_GROUPS.SD90:
            return cms.common.dailyAllowance;
        default:
            return cms.common.sumInsured;
    }
};
