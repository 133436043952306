import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './SmileyInput.module.scss';
import { Icon } from '../Icon/Icon';
import { FieldInputProps } from 'formik';

interface SmileyInputProps {
    id: 'good' | 'neutral' | 'bad';
    className?: string;
    field: FieldInputProps<string | number>;
    value: string;
}

export const SmileyInput = ({ className, id, field }: SmileyInputProps): ReactElement | null => {
    return (
        <div className={cn(styles.wrapper, className)}>
            <input id={id} type="radio" className={styles.checkbox} {...field} />
            <label className={cn(styles.label, styles[id])} htmlFor={id}>
                <Icon className={styles.icon} name={id} />
            </label>
        </div>
    );
};
