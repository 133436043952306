import * as yup from 'yup';

import config from '../../../config.json';
import cms from '../../../data/cms.json';
import { removeWhitespace } from '../../../utils/removeWhitespace';
import { getPhoneValidationRegex } from '../../../utils/validations';

export const validationObjectMortgage = {
    mortgageAlone: yup.string().required(cms.validations.REQUIRED_FIELD),
    mortgageInterest: yup
        .number()
        .required(cms.validations.REQUIRED_FIELD)
        .min(
            config.QUESTIONNAIRE.MORTGAGE_INTEREST.MIN,
            `Minimální hodnota je ${config.QUESTIONNAIRE.MORTGAGE_INTEREST.MIN}`
        )
        .max(
            config.QUESTIONNAIRE.MORTGAGE_INTEREST.MAX,
            `Maximální hodnota je ${config.QUESTIONNAIRE.MORTGAGE_INTEREST.MAX}`
        ),
    mortgageAmount: yup.string().required(cms.validations.REQUIRED_FIELD),
    mortgagePeriod: yup
        .number()
        .required(cms.validations.REQUIRED_FIELD)
        .min(
            config.QUESTIONNAIRE.MORTGAGE_PERIOD.MIN,
            `Minimální hodnota je ${config.QUESTIONNAIRE.MORTGAGE_PERIOD.MIN}`
        )
        .max(
            config.QUESTIONNAIRE.MORTGAGE_PERIOD.MAX,
            `Maximální hodnota je ${config.QUESTIONNAIRE.MORTGAGE_PERIOD.MAX}`
        ),
};

export const validationObjectIncome = {
    incomeSource: yup.string().required(cms.validations.REQUIRED_FIELD),
    monthlyIncome: yup.string().required(cms.validations.REQUIRED_FIELD),
    hasHealthInsurance: yup.string().when('incomeSource', {
        is: config.QUESTIONNAIRE.SELF_EMPLOYED,
        then: (schema) => schema.required(cms.validations.REQUIRED_FIELD),
    }),
};

export const validationSchemaBase = {
    sex: yup.string().required(cms.validations.REQUIRED_FIELD),
};

// email a telefon je nutné vyplnit současně nebo nevyplňovat ani jeden (kvůli GP)
export const validationObjectContactInfo = {
    email: yup
        .string()
        .test('emailPhone', cms.validations.IF_PHONE_THEN_EMAIL, function (item) {
            return (item && true) || (!item && !this.parent.phone);
        })
        .matches(new RegExp(config.REGEX.EMAIL_REGEX), cms.validations.EMAIL_FIELD)
        .nullable(),
    phone: yup
        .string()
        .test('phoneEmail', cms.validations.IF_EMIAL_THEN_PHONE, function (item) {
            return (item && true) || (!item && !this.parent.email);
        })
        .test({
            name: 'phone',
            test: function (value) {
                if (!value && !this.parent.email) {
                    return true;
                }
                const regex = new RegExp(getPhoneValidationRegex(this.parent?.phonePrefix));
                return !regex.test(value || '')
                    ? this.createError({
                        message: cms.validations.CELLPHONE_NUMBER,
                        path: 'phone',
                    })
                    : true;
            },
        })
        .transform((value) => (value ? removeWhitespace(value) : value))
        .nullable(),
};

export const validationSchemaDefault = yup.object({
    ...validationSchemaBase,
    ...validationObjectIncome,
});

export const validationSchemaMortgage = yup.object({
    ...validationSchemaBase,
    ...validationObjectMortgage,
});

export const validationSchemaDefaultWithContactInfo = yup.object({
    ...validationSchemaBase,
    ...validationObjectIncome,
    ...validationObjectContactInfo,
});

export const validationSchemaMortgageWithContactInfo = yup.object({
    ...validationSchemaBase,
    ...validationObjectMortgage,
    ...validationObjectContactInfo,
});
