import config from '../config.json';

import { ContextProps } from '../components/Layout/Layout';

// ### Function returns job Code from passed income (config.OCCUPATION_TYPES) string
// Which seems strange (returns the same), but we are assured that it is in initData

// @TODO: Search by Code and it will be cleaner

export const resolveBoundedJob = (ctx: ContextProps, income: string | null) => {
    if (!income) return '';

    if (ctx.initData?.Occupations) {
        switch (income) {
            case config.OCCUPATION_TYPES.EXEC_DIRTR:
                return ctx.initData?.Occupations.find((o) => o.Id === 353)?.Code; //'EXEC_DIRTR'
            case config.OCCUPATION_TYPES.CARER_CLSP:
                return ctx.initData?.Occupations.find((o) => o.Id === 173)?.Code; //'CARER_CLSP'
            case config.OCCUPATION_TYPES.STUDENT:
                return ctx.initData?.Occupations.find((o) => o.Id === 349)?.Code; //'STUDENT'
            case config.OCCUPATION_TYPES.DISAB_PENS:
                return ctx.initData?.Occupations.find((o) => o.Id === 348)?.Code; //'DISAB_PENS'
            case config.OCCUPATION_TYPES.OLD_PENS:
                return ctx.initData?.Occupations.find((o) => o.Id === 347)?.Code; //'OLD_PENS'
            case config.OCCUPATION_TYPES.UNEMPLOYED:
                return ctx.initData?.Occupations.find((o) => o.Id === 345)?.Code; //'UNEMPLOYED'
            case config.OCCUPATION_TYPES.MAT_PRN_LV:
                return ctx.initData?.Occupations.find((o) => o.Id === 346)?.Code; //'MAT_PRN_LV'
            default:
                return '';
        }
    }
    return '';
};
