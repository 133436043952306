// ### Function accepts ParticipantProps or string and returns string formated phone number

import { ParticipantProps } from '../types/model';

export const formatPhoneNumber = (obj: ParticipantProps | string) => {
    let phoneNumberString: string;
    let prefix: string | undefined;
    if (typeof obj === 'string') {
        phoneNumberString = obj;
    } else {
        phoneNumberString = obj.Contact.Phone ?? '';
        prefix = obj.Contact.PhonePrefix.Prefix;
    }
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
    if (match) {
        return (prefix && prefix.length > 0 ? prefix + ' ' : '') + match[1] + ' ' + match[2] + ' ' + match[3];
    }
    return null;
};
