export const DEFAULT_PHONE_PREFIX = '+420';

// TODO: add more types of notification coming from BE
type NotificationType = 'test';

export const notificationConfig: Record<NotificationType, { title: string; description: string }> = {
    test: {
        title: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
        description: 'Sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos.',
    },
};
