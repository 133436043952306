import { Step0 } from '../components/_pages/Step0/Step0';
import { Step1 } from '../components/_pages/Step1/Step1';
import { Step10 } from '../components/_pages/Step10/Step10';
import { Step11 } from '../components/_pages/Step11/Step11';
import { Step2 } from '../components/_pages/Step2/Step2';
import { Step3 } from '../components/_pages/Step3/Step3';
import { Step4 } from '../components/_pages/Step4/Step4';
import { Step5 } from '../components/_pages/Step5/Step5';
import { Step6 } from '../components/_pages/Step6/Step6';
import { Step7 } from '../components/_pages/Step7/Step7';
import { Step8 } from '../components/_pages/Step8/Step8';
import { Step9 } from '../components/_pages/Step9/Step9';
import { Step9a } from '../components/_pages/Step9a/Step9a';
import { Step9b } from '../components/_pages/Step9b/Step9b';
import { Step9c } from '../components/_pages/Step9c/Step9c';
import { Step9loading } from '../components/_pages/Step9loading/Step9loading';
import { StepConfirmData } from '../components/_pages/StepConfirmData/StepConfirmData';
import { NoEntry } from '../components/_pages/NoEntry/NoEntry';
import { Error } from '../components/_pages/Error/Error';
import config from '../config.json';
import { StepBankId } from '../components/_pages/StepBankId/StepBankId';
import { StepLead } from '../components/_pages/StepLead/StedLead';
import { Test } from '../components/_pages/Test/TestWaitForLocal';
import { Step10processing } from '../components/_pages/Step10processing/Step10processing';

export const routes = [
    {
        name: 'Úvodní volba',
        url: '/',
        component: <Step0 />,
        protected: true,
        code: 'STEP0',
        type: 'default',
    },
    {
        name: 'Základní informace o vás',
        url: `/${config.SLUGS.STEP1A_SLUG}/`,
        component: <Step1 />,
        protected: true,
        code: 'STEP1',
        type: config.INCOME_CODE,
    },
    {
        name: 'Informace o hypotéce',
        url: `/${config.SLUGS.STEP1B_SLUG}/`,
        component: <Step1 />,
        protected: true,
        code: 'STEP1',
        type: config.MORTGAGE_CODE,
    },
    {
        name: 'Doporučené pojištění',
        url: `/${config.SLUGS.STEP2A_SLUG}/`,
        component: <Step2 />,
        protected: true,
        code: 'STEP2',
        type: config.INCOME_CODE,
    },
    {
        name: 'Úvěrový balíček',
        url: `/${config.SLUGS.STEP2B_SLUG}/`,
        component: <Step2 />,
        protected: true,
        code: 'STEP2',
        type: config.MORTGAGE_CODE,
    },
    {
        name: 'Pojištění navíc',
        url: `/${config.SLUGS.STEP3_SLUG}/`,
        component: <Step3 />,
        protected: true,
        code: 'STEP3',
        type: 'default',
    },
    {
        name: 'Něco o vás',
        url: `/${config.SLUGS.STEP4_SLUG}/`,
        component: <Step4 />,
        protected: true,
        code: 'STEP4',
        type: 'default',
    },
    {
        name: 'Neřesti',
        url: `/${config.SLUGS.STEP5_SLUG}/`,
        component: <Step5 />,
        protected: true,
        code: 'STEP5',
        type: 'default',
    },
    {
        name: 'Nemoci',
        url: `/${config.SLUGS.STEP6_SLUG}/`,
        component: <Step6 />,
        protected: true,
        code: 'STEP6',
        type: 'default',
    },
    {
        name: 'Úrazy',
        url: `/${config.SLUGS.STEP7_SLUG}/`,
        component: <Step7 />,
        protected: true,
        code: 'STEP7',
        type: 'default',
    },
    {
        name: 'Poslední informace o vás',
        url: `/${config.SLUGS.STEP8_SLUG}/`,
        component: <Step8 />,
        protected: true,
        code: 'STEP8',
        type: 'default',
    },
    {
        name: 'Vyhodnocení zdravotního dotazníku',
        visible: false,
        url: `/${config.SLUGS.STEP9LOADING_SLUG}/`,
        component: <Step9loading />,
        protected: true,
        code: 'STEP9LOADING',
        type: 'default',
    },
    {
        name: 'BankID',
        url: `/${config.SLUGS.STEP9_SLUG}/`,
        component: <Step9 />,
        protected: true,
        code: 'STEP9',
        type: 'default',
    },
    {
        name: 'Nemůžeme vás pojistit',
        visible: false,
        url: `/${config.SLUGS.STEP9A_SLUG}/`,
        component: <Step9a />,
        protected: true,
        code: 'STEP9A',
        type: 'default',
    },
    {
        name: 'Přes online to nepůjde',
        visible: false,
        url: `/${config.SLUGS.STEP9B_SLUG}/`,
        component: <Step9b />,
        protected: true,
        code: 'STEP9B',
        type: 'default',
    },
    {
        name: 'Upravení nabídky',
        visible: false,
        url: `/${config.SLUGS.STEP9C_SLUG}/`,
        component: <Step9c />,
        protected: true,
        code: 'STEP9C',
        type: 'default',
    },
    {
        name: 'BankID',
        visible: false,
        url: `/${config.SLUGS.STEP_BANK_ID_SLUG}/`,
        component: <StepBankId />,
        protected: true,
        code: 'STEP_BANKID',
        type: 'default',
    },
    {
        name: 'Potvrzení údajů',
        url: `/${config.SLUGS.STEP_CONFIRMDATA_SLUG}/`,
        component: <StepConfirmData />,
        protected: true,
        code: 'STEP_CONFIRMDATA',
        type: 'default',
    },
    {
        name: 'Zpracování',
        visible: false,
        url: `/${config.SLUGS.STEP10PROCESSING_SLUG}/`,
        component: <Step10processing />,
        protected: true,
        code: 'STEP10PROCESSING',
        type: 'default',
    },
    {
        name: 'Platební údaje',
        url: `/${config.SLUGS.STEP10_SLUG}/`,
        component: <Step10 />,
        protected: true,
        code: 'STEP10',
        type: 'default',
    },
    {
        name: 'Vítejte u nás',
        visible: true,
        url: `/${config.SLUGS.STEP11_SLUG}/`,
        component: <Step11 />,
        protected: true,
        code: 'STEP11',
        type: 'default',
    },
    {
        name: 'Vstup klienta',
        visible: false,
        url: `/${config.SLUGS.STEP_LEAD_SLUG}/`,
        component: <StepLead />,
        protected: true,
        code: 'STEP_LEAD',
        type: 'default',
    },
    {
        name: 'Nemáte přístup',
        visible: false,
        url: `/${config.SLUGS.NOENTRY_SLUG}/`,
        component: <NoEntry />,
        code: 'NOENTRY',
        type: 'default',
    },
    {
        name: 'Stránka neexistuje',
        visible: false,
        url: '*',
        component: <Error code="404" />,
        code: 'NOTFOUND',
        type: 'default',
    },
    {
        name: 'Chyba na serveru',
        visible: false,
        url: `/${config.SLUGS.SERVERERR_SLUG}/`,
        component: <Error code="500" />,
        code: 'SERVERERR',
        type: 'default',
    },
    {
        name: 'Test',
        visible: false,
        url: `/${config.SLUGS.TEST}/`,
        component: <Test />,
        code: 'TEST',
        type: 'default',
    },
];
