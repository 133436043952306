import { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import axios from 'axios';

import cms from '../../../data/cms.json';
import config from '../../../config.json';
import styles from './Step10processing.module.scss';
import { triggerGtmEvent } from '../../../utils/triggerGtmEvent';
import { Step } from '../../Step/Step';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { LoadingCountdown } from '../../LoadingCountdown/LoadingCountdown';
import { AppContext } from '../../Layout/Layout';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import { CustomLocation } from '../../../types/common';
import { RetryButton } from '../../RetryButton/RetryButton';

interface Step10processingProps {
    className?: string;
}

export interface DocumentProps {
    ExternalId: string;
    FileName: string;
    DisplayName: string;
    ContentType: string;
    Length: number;
    IsValid: boolean;
}

export const Step10processing = ({ className }: Step10processingProps): ReactElement | null => {
    const ctx = useContext(AppContext);
    const location: CustomLocation = useLocation();
    const navigate = useNavigate();
    const [formError, setFormError] = useState(false);
    const BASE_URL = getBaseUrl();

    useEffect(() => {
        localStorage.setItem('currentStep', '11');
        ctx.setIsBackButtonVisible(false);
    }, []);

    useEffect(() => {
        const state = location.state;
        const externalId = state ? state.ExternalId : ctx.formResult.ExternalId;

        triggerGtmEvent('loading');

        let attemptsCount = 0;
        const timer: ReturnType<typeof setInterval> = setInterval(() => {
            attemptsCount++;

            if (attemptsCount > config.MAX_GET_ONLINE_POLICY_ATTEMPTS || formError) {
                clearInterval(timer);
                triggerGtmEvent('error');
                console.error('Max attempts exceeded. Aborting...');
                return;
            } else {
                axios
                    .get(`${BASE_URL}api/online/support/get-online-policy-evaluation-result/${externalId}`, {
                        timeout: config.FETCH_TIMEOUT,
                    })
                    .then((response) => {
                        if (response.data.ResultType === 1) {
                            console.log('get-online-policy-evaluation-result OK', response.data);
                            clearInterval(timer);
                            navigate(`/${config.SLUGS.STEP10_SLUG}/`, {
                                state: { ExternalId: externalId },
                            });
                        } else if (response.data.ResultType === 10 || response.data.ResultType === 11) {
                            console.log('get-online-policy-evaluation-result PREPARING', response.data);
                        } else {
                            console.log('get-online-policy-evaluation-result INSURABLE', response.data);
                            clearInterval(timer);
                            localStorage.setItem('completedStep', '8');
                            navigate(`/${config.SLUGS.STEP9A_SLUG}/`, { state: { ResultText: response.data.Text } });
                        }
                    })
                    .catch((error) => {
                        setFormError(true);
                        console.error(error);
                        triggerGtmEvent('error');
                    });
            }
        }, config.GET_ONLINE_POLICY_INTERVAL);
    }, [location]);

    if (formError) {
        return (
            <Step title={cms.steperror.title} text={null} className={cn(styles.wrapper, className)}>
                <RetryButton />
                <ErrorForm />
            </Step>
        );
    }

    return (
        <Step title={cms.step9loader.title} text={null} className={cn(styles.wrapper, className)}>
            <LoadingCountdown initialTime={config.LOADING_ANIMATION_TIME.STEP10} />
            <ErrorForm />
        </Step>
    );
};
