import { v4 as uuidv4 } from 'uuid';
import { ValuesProps } from '../components/_pages/Step6/Step6';

// ### Function returns array of children from passed Formik values

export const resolveChildAgeArray = (values: ValuesProps) => {
    const arr = values.childrenAge;
    const temp = [];

    if (arr && arr.length > 0 && values.hasChildren === 'Ano') {
        for (let index = 0; index < arr.length; index++) {
            const age = arr[index];
            age && temp.push({ ExternalId: uuidv4(), CurrentAge: parseInt(age) });
        }
    }
    return temp;
};
