import { ReactElement } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';

import styles from './Title.module.scss';

export interface TitleProps {
    children: string | ReactElement;
    tag: 'h1' | 'h2' | 'h3' | 'h4' | 'span';
    light?: boolean;
    color?: 'white' | 'light' | 'dark';
    size?: 'xl' | 'lg' | 'md' | 'sm';
    className?: string;
}

export const Title = ({ tag, size, children, color = 'dark', className }: TitleProps): ReactElement | null => {
    const Wrapper = tag;
    if (!size) {
        size = tag === 'h1' ? 'xl' : tag === 'h2' ? 'lg' : tag === 'h3' ? 'md' : 'sm';
    }
    return (
        <Wrapper className={cn(styles.wrapper, size && styles[size], styles[color], className)}>
            {typeof children === 'string' ? parse(children) : children}
        </Wrapper>
    );
};
