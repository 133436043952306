// ### Function gets saved data from localStorage if available

import { ContextProps } from '../components/Layout/Layout';
import { KeyValueProps } from '../types/common';
import { InitDataBaseProps, QuestionProps } from '../types/initData';
import { AnswerItemProps } from '../types/model';

export const getFilledData = (range: Array<number>, ctx: ContextProps) => {
    const storageData = localStorage.getItem('localData');
    const parsedData = storageData ? JSON.parse(storageData) : null;
    let lastParentCode: string;
    let maIndexPosition = 0;
    let maIndex = 0;

    // ### If we have data from localStorage we will use them prior to empty initialData object
    if (parsedData) {
        const participant = parsedData?.Participants[0];
        const answers = participant.Questionnaire.Answers[0]?.Items;

        // ### Grab only part of questionaire, which is needed
        const questions = ctx.initDataQuestionaire?.Questionnaires.Questions.slice(...range);

        if (answers?.length > 0 && (questions?.length || 0) > 0) {
            // ### Remove all nested structures from questions and answers (flattening)
            const flattenQuestions: Array<QuestionProps> = [];
            const flattenAnswers: Array<AnswerItemProps> = [];
            const values: KeyValueProps = {};
            let valueText: string | number | undefined;

            // ### Loop over questions recursivelly to find all questions children, push into array
            const loopQuestions = (target: Array<QuestionProps>, passMulti?: boolean) => {
                target.map((q: QuestionProps, i: number) => {
                    flattenQuestions.push({
                        ...q,
                        IsMultiAnswerChild: passMulti,
                    });

                    if (q.Questions?.length > 0) {
                        loopQuestions(
                            q.Questions,
                            // ### Pass true if (Is multiAnswer OR previous question was IsMultiAnswerChild OR previous question was IsMultiAnswer)
                            q.IsMultiAnswer || target?.[i - 1]?.IsMultiAnswerChild || passMulti
                        );
                    }
                });
            };

            // ### Loop over answers recursivelly to find all answers children, push into array
            const loopAnswers = (target: Array<AnswerItemProps>) => {
                target.map((a) => {
                    flattenAnswers.push(a);

                    // ### If we have a subanswers, loop them
                    if (a.ChildAnswerItems?.length > 0) {
                        loopAnswers(a.ChildAnswerItems);
                    }
                });
            };
            questions && loopQuestions(questions);
            answers && loopAnswers(answers);

            // ### Find question/answer pair by ExternalId/QuestionExternalId and fill its value. Those key - value pairs are used in Formik afterwards
            // We take answers and find their question by their IDs
            flattenAnswers.map((a) =>
                flattenQuestions.find((q) => {
                    if (q.ExternalId === a.QuestionExternalId) {
                        // ### Type: select
                        if (a?.AnswerValueListCode) {
                            if (
                                q.PossibleAnswersIds.length > 3 &&
                                // @TODO: TEMPORARY! Fix - Smoking radio buttons are unique case, they normally should render as Select...
                                q.Code !== 'O_STOPSMOK_YEARS' &&
                                q.Code !== 'O_PASTSMOK_PCS' &&
                                q.Code !== 'O_CURRSMOK_PCS' &&
                                (q.QuestionStyleId === 3 || q.QuestionStyleId === 10 || q.QuestionAnswerFormatId === 1)
                            ) {
                                valueText = ctx.initData?.Lovs.QuestionAnswerLists.find(
                                    (qal: InitDataBaseProps) => qal.Code === a.AnswerValueListCode
                                )?.Code; // We are interested in Code
                            }

                            // ### Type: radio
                            else {
                                valueText = ctx.initData?.Lovs.QuestionAnswerLists.find(
                                    (qal: InitDataBaseProps) => qal.Code === a.AnswerValueListCode
                                )?.NameDefault; // We are interested in Name
                            }
                        }
                        // ### Type: integer
                        else if (a?.AnswerValueInt || a?.AnswerValueInt === 0) {
                            valueText = a.AnswerValueInt;
                        }
                        // ### Type: string
                        else if (a?.AnswerValueText) {
                            valueText = a.AnswerValueText;
                        }
                        // ### Type: decimal
                        else if (a?.AnswerValueDecimal || a?.AnswerValueDecimal === 0) {
                            valueText = a.AnswerValueDecimal;
                        }

                        if (q.IsMultiAnswer || q.IsMultiAnswerChild) {
                            // It is parent (root) multiAnswer
                            if (q.IsMultiAnswer) {
                                // Reset or increase maIndexPosition
                                if (lastParentCode !== q.Code) {
                                    maIndexPosition = 0;
                                } else {
                                    maIndexPosition = maIndexPosition + 1;
                                }

                                maIndex = ctx.multiAnswer?.[q.Code]?.[maIndexPosition];
                                values[`${q.Code}_MULTI_${maIndex || 0}`] = valueText;
                                lastParentCode = q.Code;
                            }

                            // It is child (subquestion) of multiAnswer root question
                            if (q.IsMultiAnswerChild) {
                                // We need to work with parent question, which is saved in ctx.multiAnswer
                                maIndex = ctx.multiAnswer?.[lastParentCode]?.[maIndexPosition];
                                values[`${q.Code}_MULTI_${maIndex || 0}`] = valueText;
                            }
                        } else {
                            // If no multiAnswer "problems", store valueText into Formik value
                            values[q.Code] = valueText;
                        }
                    }
                })
            );
            return values;
        }
    }
};
