import { FieldProps } from 'formik';
import {
    ChangeEvent,
    Dispatch,
    ReactElement,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
    KeyboardEvent,
} from 'react';
import cn from 'classnames';

import cms from '../../data/cms.json';
import styles from './SearchInput.module.scss';
import config from '../../config.json';
import { getSportProtectedGroup } from '../../utils/getSportProtectedGroup';

import { CheckButton } from '../CheckButton/CheckButton';
import { Label } from '../Label/Label';
import { Select } from '../Select/Select';
import { Tooltip } from '../Tooltip/Tooltip';
import { AppContext } from '../Layout/Layout';
import { FormItemError } from '../FormItemError/FormItemError';
import { Loader } from '../Loader/Loader';
import { InitDataBaseProps } from '../../types/initData';
import { SearchInputSportProps } from '../../types/common';
import { Icon } from '../Icon/Icon';

export interface SearchInputProps {
    field: FieldProps['field'];
    name: string;
    label: string;
    placeholder?: string;
    className?: string;
    withHeader?: boolean;
    type: 'occupation' | 'sport';
    setFieldValue?: (field: string, value: string | number, shouldValidate?: boolean) => void;
    handleResult?: Dispatch<SetStateAction<Array<string> | Array<SearchInputSportProps>>>;
    passedResult?: any;
}

export interface SportLevelReactSelectProps {
    label: string;
    value: number;
}

type FilterType = 'frequency' | 'alphabet';

export const SearchInput = ({
    field,
    className,
    placeholder,
    name,
    label,
    withHeader,
    handleResult,
    setFieldValue,
    type,
    passedResult = [],
}: SearchInputProps): ReactElement | null => {
    const INIT_OCCUPATIONS_NUM = 10;
    const OCCUPATIONS_INCR = 20;
    const [occupationShown, setOccupationShown] = useState<number>(INIT_OCCUPATIONS_NUM);
    const [allData, setAllData] = useState<Array<InitDataBaseProps> | []>([]);
    const [result, setResult] = useState<Array<InitDataBaseProps>>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeFilter, setActiveFilter] = useState<FilterType>('frequency');
    const [sportLevels, setSportLevels] = useState<Array<SportLevelReactSelectProps> | null>(null);
    const [level, setLevel] = useState<any>();
    const ctx = useContext(AppContext);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    let lastPassedResult: Array<string>;

    const sortData = (sortBy: FilterType, emptyList?: boolean) => {
        if (type) {
            const frequencyList =
                type === config.SEARCHBOX_TYPE.OCCUPATION ? config.FREQUENT_OCCUPATIONS : config.FREQUENT_SPORTS;
            const topArray: Array<InitDataBaseProps> = []; // frequent items
            const topArraySorted: Array<InitDataBaseProps> = []; // frequent items (sorted by real frequency)
            let selectedArray: Array<InitDataBaseProps> = []; // selected items
            let restArray: Array<InitDataBaseProps> = []; // non-selected items
            let othersArray: Array<InitDataBaseProps> = []; // non-selected items && non-frequent items

            // ### Filter occupation data
            if (emptyList) {
                // ### If we know there is no selected item, just copy all data
                restArray = allData;
            } else {
                // ### Filter occupation data
                if (type === config.SEARCHBOX_TYPE.OCCUPATION) {
                    selectedArray = allData.filter((r) => passedResult.includes(r.Code));
                    restArray = allData.filter((r) => !passedResult.includes(r.Code));
                }
                // ### Filter sport data
                else {
                    selectedArray = allData.filter((r) =>
                        passedResult.find((r2: SearchInputSportProps) => r2.code === r.Code)
                    );
                    restArray = allData.filter(
                        (r) => !passedResult.find((r2: SearchInputSportProps) => r2.code === r.Code)
                    );
                }
            }

            // ### Sort by frequency
            if (sortBy === 'frequency') {
                // ### Distinct most frequent items from others
                restArray.map((item) => {
                    if (!frequencyList.includes(item.Code)) {
                        othersArray.push(item);
                    } else {
                        topArray.push(item);
                    }
                });

                // ### Get most frequent items (alphabetic at the moment) and sort them by frequency list
                config[type === config.SEARCHBOX_TYPE.OCCUPATION ? 'FREQUENT_OCCUPATIONS' : 'FREQUENT_SPORTS'].map(
                    (item) => topArray.find((item2) => item2.Code === item && topArraySorted.push(item2))
                );
            }
            // ### Sort by alphabet
            else {
                othersArray = restArray;
            }

            setResult([...selectedArray, ...topArraySorted, ...othersArray]);
        }
    };

    const filterResult = (data: Array<InitDataBaseProps>, term?: string) => {
        if (data) {
            if (type === config.SEARCHBOX_TYPE.SPORT) {
                const resultSelected = data.filter((r) =>
                    passedResult.find((r2: SearchInputSportProps) => r2.code === r.Code)
                );
                const resultRest = data.filter(
                    (r) => !passedResult.find((r2: SearchInputSportProps) => r2.code === r.Code)
                );

                if (resultSelected) {
                    data = [...resultSelected, ...resultRest];
                }

                const currentResult = data.filter((item) => {
                    if (item && item.NameTranslated) {
                        return (
                            item?.NameTranslated?.toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .match(
                                    term
                                        ?.toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '') || ''
                                ) ||
                            passedResult.indexOf(item.Code) > -1 ||
                            passedResult.find((p: SearchInputSportProps) => p.code === item.Code)
                        );
                    }
                });
                currentResult && currentResult.length > 0 && setResult(currentResult);
            } else if (type === config.SEARCHBOX_TYPE.OCCUPATION) {
                const resultSelected = data.find((r) => r.Code === passedResult[0]);
                const resultRest = data.filter((r) => r.Code !== passedResult[0]);

                if (resultSelected) {
                    data = [resultSelected, ...resultRest];
                }

                const currentResult = data.filter((item) => {
                    if (item && item.NameTranslated) {
                        return (
                            item?.NameTranslated?.toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .match(
                                    term
                                        ?.toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '') || ''
                                ) ||
                            passedResult.indexOf(item.Code) > -1 ||
                            passedResult.find((p: SearchInputSportProps) => p.code === item.Code)
                        );
                    }
                });
                currentResult && currentResult.length > 0 && setResult(currentResult);
            }
        }
    };

    useEffect(() => {
        if (type === config.SEARCHBOX_TYPE.OCCUPATION) {
            ctx.initData?.Occupations && setAllData(ctx.initData?.Occupations);
        } else if (type === config.SEARCHBOX_TYPE.SPORT) {
            ctx.initData?.Lovs.Sports && setAllData(ctx.initData?.Lovs.Sports);
            const tmp: Array<SportLevelReactSelectProps> = [];
            ctx.initData?.Lovs.SportLevels.map((level) => tmp.push({ label: level.DescTranslated, value: level.Id }));
            setSportLevels(tmp);
        }
    }, [ctx.initData]);

    useEffect(() => {
        if (level && type === config.SEARCHBOX_TYPE.SPORT && handleResult) {
            const targetIndex = passedResult.findIndex(
                (item: SearchInputSportProps) => item.code === level.code?.split('-level')[0]
            );

            if (targetIndex > -1) {
                const newState = [...passedResult];
                newState[targetIndex] = {
                    ...newState[targetIndex],
                    level: level.value,
                };
                handleResult(newState);
            }
        }
    }, [level]);

    useEffect(() => {
        if (lastPassedResult !== passedResult) {
            if (type === config.SEARCHBOX_TYPE.SPORT) {
                const resultSelected = result.filter((r) =>
                    passedResult.find((r2: SearchInputSportProps) => r2.code === r.Code)
                );
                const resultRest = result.filter(
                    (r) => !passedResult.find((r2: SearchInputSportProps) => r2.code === r.Code)
                );

                if (resultSelected) {
                    const sorted = [...resultSelected, ...resultRest];
                    setResult(sorted);
                }
            } else if (type === config.SEARCHBOX_TYPE.OCCUPATION) {
                const resultSelected = result.find((r) => r.Code === passedResult[0]);
                const resultRest = result.filter((r) => r.Code !== passedResult[0]);

                if (resultSelected) {
                    const sorted = [resultSelected, ...resultRest];
                    setResult(sorted);
                }
            }
            lastPassedResult = passedResult;
        }
    }, [passedResult]);

    useEffect(() => {
        sortData('frequency');
    }, [allData]);

    const handleClick = (code: string, id: number) => {
        if (handleResult) {
            if (type === config.SEARCHBOX_TYPE.OCCUPATION) {
                if (passedResult.indexOf(code) > -1) {
                    handleResult([]);
                } else {
                    handleResult([code]);
                }
            } else {
                // sports
                if (passedResult.find((item: SearchInputSportProps) => item?.code === code)) {
                    handleResult(passedResult.filter((item: SearchInputSportProps) => item.code !== code));
                } else {
                    handleResult((prevState: any) => [...prevState, { code: code, level: null, id }]);
                }
            }
            wrapperRef.current && wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            return false;
        }
    };

    const handleSortFilterClick = (type: FilterType) => {
        setOccupationShown(INIT_OCCUPATIONS_NUM);
        setActiveFilter(type);
        sortData(type);
        setSearchTerm('');
    };

    const clearInput = (value: string): string => {
        return value.replace(/[^a-zá-ž]/gi, '');
    };

    const renderMain = () => (
        <>
            {label && (
                <Label className={styles.label} htmlFor={name}>
                    {label}
                </Label>
            )}
            <div className={styles.searchWrapper}>
                <input
                    {...field}
                    className={cn(styles.input, className)}
                    placeholder={placeholder}
                    maxLength={50}
                    type="text"
                    autoComplete="off"
                    value={searchTerm}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        const clearedNewValue = clearInput(evt.target.value);
                        setSearchTerm(clearedNewValue);
                        filterResult(allData, clearedNewValue);
                        setOccupationShown(INIT_OCCUPATIONS_NUM);

                        if (clearedNewValue === '') {
                            setActiveFilter('frequency');
                            sortData('frequency');
                        }
                    }}
                    onKeyPress={(evt: KeyboardEvent<HTMLInputElement>) => evt.key === 'Enter' && evt.preventDefault()}
                />
                <Icon name="search" size="medium" className={styles.editIcon} />
            </div>
        </>
    );

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            {withHeader ? <div className={styles.header}>{renderMain()}</div> : renderMain()}

            <div className={styles.filters}>
                <div className={styles.filterButtons}>
                    <button
                        onClick={(e) => {
                            e?.preventDefault();
                            handleSortFilterClick('frequency');
                        }}
                        className={cn(styles.sortButton, activeFilter === 'frequency' && styles.active)}
                    >
                        Nejčastější {type === config.SEARCHBOX_TYPE.SPORT ? 'sporty' : 'pozice'}
                    </button>
                    <button
                        onClick={(e) => {
                            e?.preventDefault();
                            handleSortFilterClick('alphabet');
                        }}
                        className={cn(styles.sortButton, activeFilter === 'alphabet' && styles.active)}
                    >
                        {type === config.SEARCHBOX_TYPE.SPORT ? 'Sporty' : 'Pozice'} podle abecedy
                    </button>
                </div>

                {!withHeader && passedResult.length > 0 && (
                    <div className={styles.filterLevelLabel}>
                        Úroveň <Tooltip className={styles.tooltipLevel} text={cms.step3.sportLevelHelp} />
                    </div>
                )}
            </div>

            {result && result.length && Array.isArray(result) ? (
                <>
                    <ul className={cn(styles.jobList)}>
                        {result.slice(0, occupationShown).map((r, i: number) => (
                            <li
                                className={
                                    passedResult.indexOf(r.Code) > -1 ||
                                    passedResult.find((item: SearchInputSportProps) => item?.code === r.Code)
                                        ? styles.active
                                        : ''
                                }
                                key={i}
                            >
                                <CheckButton
                                    onClick={() => handleClick(r.Code, r.Id)}
                                    className={styles.checkButton}
                                    size="small"
                                    checked={
                                        passedResult.indexOf(r.Code) > -1 ||
                                        passedResult.find((item: SearchInputSportProps) => item?.code === r.Code)
                                    }
                                />

                                <div className={styles.jobName} onClick={() => handleClick(r.Code, r.Id)}>
                                    {r.NameTranslated}
                                </div>

                                {r.DescTranslated && r.DescTranslated !== r.NameTranslated && (
                                    <Tooltip
                                        text={r.DescTranslated}
                                        className={styles.tooltip}
                                        boxClassName={styles.tooltipBox}
                                    />
                                )}

                                {ctx.initData?.SportLevelProtectedSportGroupAssocs &&
                                    getSportProtectedGroup(
                                        ctx.initData?.SportLevelProtectedSportGroupAssocs,
                                        passedResult,
                                        r.Id,
                                        r.Code
                                    ) === 6 && (
                                        <FormItemError
                                            className={styles.inlineError}
                                            text="Na této úrovni nelze připojistit"
                                        />
                                    )}

                                {type === config.SEARCHBOX_TYPE.SPORT &&
                                    sportLevels &&
                                    passedResult.find((item: SearchInputSportProps) => item?.code === r.Code) && (
                                        <Select
                                            className={styles.select}
                                            name={`${r.Code}`}
                                            id={`${r.Code}`}
                                            options={sportLevels}
                                            setFieldValue={setFieldValue}
                                            setLevel={setLevel}
                                            level={passedResult.find(
                                                (item: SearchInputSportProps) => item?.code === r.Code
                                            )}
                                            error={
                                                ctx.initData?.SportLevelProtectedSportGroupAssocs &&
                                                getSportProtectedGroup(
                                                    ctx.initData?.SportLevelProtectedSportGroupAssocs,
                                                    passedResult,
                                                    r.Id,
                                                    r.Code
                                                ) === 6
                                            }
                                        />
                                    )}
                            </li>
                        ))}
                    </ul>
                    {result.length > occupationShown && (
                        <div
                            className={cn(styles.showMore, 'mt-4')}
                            onClick={() => setOccupationShown((prevOccupation) => prevOccupation + OCCUPATIONS_INCR)}
                        >
                            {type === config.SEARCHBOX_TYPE.SPORT
                                ? cms.common.nextSportsText
                                : cms.common.nextOccupationsText}
                        </div>
                    )}
                </>
            ) : (
                <Loader size="medium" />
            )}
        </div>
    );
};
