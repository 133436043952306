import { ReactElement, useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import cn from 'classnames';

import config from '../../config.json';
import cms from '../../data/cms.json';
import styles from './ErrorForm.module.scss';
import { ReactComponent as AlertIcon } from '../../images/vectors/alert.svg';
import { fetchResultData } from '../../utils/externalDataHandling';
import { triggerGtmEvent } from '../../utils/triggerGtmEvent';

import { Button } from '../Button/Button';
import { FormItemError } from '../FormItemError/FormItemError';
import { AppContext } from '../Layout/Layout';
import { Title } from '../Title/Title';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../utils/routes';

export interface ErrorFormProps {
    className?: string;
}

const validationSchema = yup.object({
    email: yup.string().matches(new RegExp(config.REGEX.EMAIL_REGEX), cms.validations.EMAIL_FIELD),
    message: yup.string().required(cms.validations.REQUIRED_FIELD).min(10, cms.validations.ERROR_MESSAGE_FIELD),
});

export const ErrorForm = ({ className }: ErrorFormProps): ReactElement | null => {
    const ctx = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // only way to get route url from current step id
    const routesData = routes.filter(
        (item) => item?.visible !== false && (item.type === ctx.insuranceType || item.type === 'default')
    );
    const completedStep = parseInt(localStorage.getItem('completedStep') || '-1');

    return error ? (
        <div className="mt-6 mb-6 text--center">
            <Title tag="span" size="sm">
                {cms.common.bugFormErrorTitle}
            </Title>
        </div>
    ) : success ? (
        <div className="mt-6 mb-6 text--center">
            <Title tag="span" size="sm">
                {cms.common.bugFormSuccessTitle}
            </Title>
        </div>
    ) : (
        <div className={cn(styles.wrapper, className, 'mt-8', 'mt-5')}>
            {/* {completedStep > 0 && (
                <Button
                    variant="secondary"
                    className={cn(styles.back, 'mb-8')}
                    onClick={() => navigate(routesData[Math.min(completedStep + 1, config.STEP_COUNT)].url)}
                >
                    {cms.common.retry}
                </Button>
            )} */}

            <div
                className={styles.toggleForm}
                onClick={() => {
                    setOpen(!open);
                    triggerGtmEvent(open ? 'error_form_end' : 'error_form');
                }}
            >
                <AlertIcon />
                <div className={styles.text}>{cms.common.reportErrorToggleText}</div>
            </div>

            {open && (
                <Formik
                    initialValues={{ name: '', email: ctx.formResult.Participants[0].Contact.Email ?? '', message: '' }}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                    validateOnChange={submitted}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        setLoading(true);
                        const body = {
                            BugText: values.message,
                            Name: values.name,
                            Email: values.email,
                            OnlinePolicyJson: ctx.formResult.ExternalId ? JSON.stringify(ctx.formResult) : '',
                        };

                        const result = fetchResultData('api/online/support/report-bug', body);
                        triggerGtmEvent('error_form_go');
                        result
                            .then(() => {
                                setSuccess(true);
                                setLoading(false);
                            })
                            .catch((err) => {
                                console.error(err);
                                setError(true);
                                setLoading(false);
                            });
                    }}
                >
                    {(props) => (
                        <Form className={cn(styles.form, 'mt-7', 'mt-4-m')} onSubmit={props.handleSubmit} noValidate>
                            <div className={styles.inputWrapper}>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className={styles.input}
                                    placeholder="Jméno"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.name}
                                />
                                <label htmlFor="name">Jméno</label>
                                {props.errors.name && <FormItemError position="left" text={props.errors.name} />}
                            </div>

                            <div className={styles.inputWrapper}>
                                <input
                                    type="email"
                                    name="email"
                                    className={styles.input}
                                    placeholder="E-mail"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.email}
                                />
                                <label htmlFor="email">E-mail</label>
                                {props.errors.email && <FormItemError position="left" text={props.errors.email} />}
                            </div>

                            <div className={cn(styles.inputWrapper, styles.textareaWrapper)}>
                                <textarea
                                    name="message"
                                    className={styles.message}
                                    placeholder="Zpráva nebo popis chyby"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.message}
                                    maxLength={1000}
                                />
                                <label htmlFor="message" className={styles.messageLabel}>
                                    Zpráva nebo popis chyby
                                </label>
                                {props.errors.message && <FormItemError position="left" text={props.errors.message} />}
                            </div>

                            <Button
                                submit
                                className={styles.button}
                                variant="primary"
                                outlined
                                loading={loading}
                                onClick={() => setSubmitted(true)}
                            >
                                Odeslat
                            </Button>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};
