import { Field } from 'formik';

import { Label } from '../components/Label/Label';
import { TextInput } from '../components/TextInput/TextInput';
import { validateField } from './validations';

// @deprecated - use components/TextInput/FormikTextInput
export const renderTextField = (
    name: string,
    variant: string,
    label?: string | Array<string>,
    placeholder?: string,
    suffix?: string,
    className?: string,
    min?: number | string | null,
    max?: number | string | null,
    error?: string,
    optional?: boolean,
    onHelpClick?: () => void,
    allowFieldValidation?: boolean,
    showEditIcon?: boolean
) => {
    const renderLabel = (label: string | Array<string>, id: string) => {
        if (typeof label === 'object') {
            return (
                <Label htmlFor={id} description={label[1]}>
                    {label[0]}
                </Label>
            );
        } else {
            return (
                <Label htmlFor={id} onHelpClick={onHelpClick}>
                    {label}
                </Label>
            );
        }
    };

    return (
        <div className={className} key={name}>
            {label && renderLabel(label, name)}
            <Field
                name={name}
                id={name}
                placeholder={placeholder}
                suffix={suffix}
                variant={variant}
                component={TextInput}
                error={error}
                max={max}
                showEditIcon={showEditIcon}
                validate={(value: string | number) =>
                    (allowFieldValidation || allowFieldValidation === undefined) &&
                    validateField(value, optional, min, max, variant)
                }
            />
        </div>
    );
};
