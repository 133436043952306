import { ReactElement, useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import cn from 'classnames';

import styles from '../Step2/Step2.module.scss';
import cms from '../../../data/cms.json';
import config from '../../../config.json';
import { renderCoverageItem } from '../../../utils/renderCoverageItem';
import { setCompleted } from '../../../utils/setCompleted';
import { fetchResultData } from '../../../utils/externalDataHandling';
import { runBackendValidations } from '../../../utils/runBackendValidations';
import { resolveBoxTitles } from '../../../utils/resolveBoxTitles';
import { sumAllValues } from '../../../utils/sumAllValues';
import { filterClientAvailability } from '../../../utils/filterClientAvailability';
import { resetIsChosen } from '../../../utils/resetIsChosen';
import { getStepRelevantData } from '../../../utils/getStepRelevantData';
import { formatPrice } from '../../../utils/formatPrice';
import { triggerGtmEvent } from '../../../utils/triggerGtmEvent';

import { Step } from '../../Step/Step';
import { Box } from '../../Box/Box';
import { Button } from '../../Button/Button';
import { Grid } from '../../Grid/Grid';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { AppContext } from '../../Layout/Layout';
import { PriceDiscount } from '../../PriceDiscount/PriceDiscount';
import { ConsequencesPopup, IllnessPopup } from '../Step2/PopupContent';
import { cartInit } from '../../Header/Header';
import { Loader } from '../../Loader/Loader';
import { ProtectionGroupProps, ProtectionVariantProps, SuggestedProtectionProps } from '../../../types/model';
import { handleVariantClick, SelectedVariantProps } from '../Step2/Step2';
import { SelectVariantButton } from '../../SelectVariantButton/SelectVariantButton';
import { KeyStringValueProps } from '../../../types/initData';
import { useBlocker } from '../../../utils/useBlocker';
import { Title } from '../../Title/Title';
import { Paragraph } from '../../Paragraph/Paragraph';
import { unlockScroll } from '../../../utils/bodyScrollLock';
import { ConfirmBackPopup } from '../../ConfirmBackPopup/ConfirmBackPopup';
import { Transition } from 'history';
import { scrollToError } from '../../../utils/scrollToError';

interface Step3Props {
    className?: string;
}

interface SelectedProductProps {
    name: string;
    value: number | undefined;
    price: number;
}

export const Step3 = ({ className }: Step3Props): ReactElement | null => {
    const navigate = useNavigate();
    const ctx = useContext(AppContext);
    const [loaded, setLoaded] = useState(false);
    const [activeItem, setActiveItem] = useState('');
    const [selectedProduct, setSelectedProduct] = useState<Array<SelectedProductProps>>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isRecalculating, setIsRecalculating] = useState(false);
    const [submitActivated, setSubmitActivated] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedVariants, setSelectedVariants] = useState<SelectedVariantProps>({});
    const [defaultVariantSet, setDefaultVariantSet] = useState(false);
    const [unlockNextStep, setUnlockNextStep] = useState(false);
    const [unlockPrevStep, setUnlockPrevStep] = useState(false);
    const [userMadeChange, setUserMadeChange] = useState(false);
    const bottomRef = useRef<HTMLInputElement | null>(null);
    const [lastTxTried, setLastTxTried] = useState<Transition>();
    const [tryReddirect, setTryReddirect] = useState(false);

    useEffect(() => {
        localStorage.setItem('currentStep', '3');
        ctx.setIsBackButtonVisible(true);
        ctx.setShowBlockPopup(false);
        ctx.setNavigationLocked(true);
    }, []);

    // ### Hook function for blocking navigation (routing)
    const vlocker = useBlocker((tx: Transition) => {
        // that part is called when transition is blocked, so we saved last transition tried for later progress
        setLastTxTried(tx);
    }, userMadeChange);

    useEffect(() => {
        if (tryReddirect && userMadeChange === false) {
            setTryReddirect(false);
            // retry navigation from last transition saved
            lastTxTried?.retry();
        }
    }, [tryReddirect, userMadeChange]);

    // ### Special effect for navigating to next step if unlockNextStep === true
    useEffect(() => {
        if (unlockNextStep) {
            navigate(`/${config.SLUGS.STEP4_SLUG}/`);
            triggerGtmEvent('recap_go');
        }
    }, [unlockNextStep]);

    // ### Special effect for navigating to prev step if unlockPrevStep === true
    useEffect(() => {
        if (unlockPrevStep) {
            navigate(`/${config.SLUGS.STEP2A_SLUG}/`);
        }
    }, [unlockPrevStep]);

    useEffect(() => {
        if (ctx.showBlockPopup && userMadeChange) {
            ctx.setPopupContent(
                <ConfirmBackPopup
                    onClickMoveToSave={() => {
                        // user clicked to move to save, so we just scroll to save button
                        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                    onClickContinueWithoutSave={() => {
                        // user clicked continue without save, we unblock redirect
                        setUserMadeChange(false);

                        // call retry after useState is called
                        setTryReddirect(true);
                    }}
                />
            );
            ctx.setPopupContentVariables({ uncloseable: false });
            ctx.setPopupVisible(true);
            ctx.setCloseButton(true);
            ctx.setShowBlockPopup(false);
        }
    }, [ctx.showBlockPopup, ctx.popupContentVariables, buttonLoading]);

    useEffect(() => {
        if (!defaultVariantSet && ctx.formResult?.Participants[0].SuggestedInsurance?.ProtectionGroups) {
            const data = getStepRelevantData(
                ctx.formResult.Participants[0].SuggestedInsurance.ProtectionGroups,
                'STEP3_PRODUCTS'
            );

            const tmp: SelectedVariantProps = {};
            data &&
                data.map(
                    (g) =>
                        (tmp[g.RiderCategoryCode] =
                            g.SuggestedProtections[0].Variants.find((v) => v.IsChosen)?.VariantOrder || 1)
                );

            setSelectedVariants((prevState) => ({
                ...prevState,
                ...tmp,
            }));

            setDefaultVariantSet(true);
        }
    }, [ctx.formResult]);

    // Every time user chooses product or selectedVariant, recalculate total price
    useEffect(() => {
        let previousStepPrice = 0;
        let value = 0;

        if (ctx.formStep.completed >= 3 && ctx.insuranceType === config.MORTGAGE_CODE) {
            const otherGroups = getStepRelevantData(
                ctx.formResult.Participants[0].SuggestedInsurance?.ProtectionGroups,
                'STEP2B_PRODUCTS'
            );

            otherGroups &&
                otherGroups.map((g: ProtectionGroupProps) =>
                    g.SuggestedProtections.map((prot: SuggestedProtectionProps) =>
                        prot.Variants.map((item) => {
                            if (item.IsChosen) {
                                previousStepPrice = previousStepPrice + item.PremiumChosen.Frequency;
                            }
                        })
                    )
                );

            value = selectedProduct
                .filter((sp) => !config.STEP2B_PRODUCTS.includes(sp.name))
                .reduce((acc: number, obj) => acc + obj.price, 0);
        } else {
            value = selectedProduct.reduce((acc: number, obj) => acc + obj.price, 0);
        }

        setTotalPrice(value + previousStepPrice || 0);
    }, [selectedProduct, selectedVariants]);

    useEffect(() => {
        if (!isSubmitting && !isUpdating && ctx.formResult) {
            const submittedString = localStorage.getItem('step3Submitted');
            const submitted = submittedString === 'true' ? true : false;
            const temp: Array<SelectedProductProps> = [];

            if (!submitted) {
                const insuranceData = ctx.formResult.Participants[0].SuggestedInsurance;
                insuranceData &&
                    filterClientAvailability(insuranceData.ProtectionGroups, true).map((g: ProtectionGroupProps) => {
                        // ### Find chosen Variant
                        const variantId = g.SuggestedProtections[0].Variants.find(
                            (v: ProtectionVariantProps) => v.IsChosen
                        )?.VariantOrder;

                        let price = 0;

                        // ### PAC_MP has more than one SuggestedProtections
                        if (g.SuggestedProtections.length > 1) {
                            price = g.SuggestedProtections.reduce(
                                (acc: number, obj) => acc + sumAllValues(obj.Variants, variantId),
                                0
                            );
                        } else {
                            price = sumAllValues(g.SuggestedProtections[0].Variants, variantId);
                        }

                        // ### This is later used for selectedProduct state
                        temp.push({
                            name: g.RiderCategoryCode,
                            value: variantId,
                            price: price,
                        });
                    });
            }
            // ### Load data from localStorage
            else {
                const storageData = localStorage.getItem('localData');
                const parsedData = storageData ? JSON.parse(storageData) : null;
                const participant = parsedData?.Participants[0];

                filterClientAvailability(participant.SuggestedInsurance.ProtectionGroups, true).map(
                    (g: ProtectionGroupProps) => {
                        temp.push({
                            name: g.RiderCategoryCode,
                            value: g.SuggestedProtections[0].Variants.find((v: ProtectionVariantProps) => v.IsChosen)
                                ?.VariantOrder,
                            price: sumAllValues(
                                g.SuggestedProtections[0].Variants,
                                g.SuggestedProtections[0].Variants.find((v: ProtectionVariantProps) => v.IsChosen)
                                    ?.VariantOrder || 0
                            ),
                        });
                    }
                );
            }

            setLoaded(true);
            setSelectedProduct([...temp]);
        }
    }, [ctx.formResult, ctx.initData]);

    // ### Dont show submits until data are loaded
    useEffect(() => {
        setSubmitActivated(true);
    }, [loaded]);

    useEffect(() => {
        if (ctx.formResult) {
            // ### Effect runs on recalculation or saving
            if ((isRecalculating || isSaving) && !isUpdating) {
                const calculatePremiumData = resetIsChosen(ctx);

                if (isSaving) {
                    setButtonLoading(true);
                }

                runBackendValidations(
                    'validate-premium-calculations',
                    ctx,
                    calculatePremiumData,
                    () => {
                        const data = fetchResultData('api/online/calculations/calculate-premium', calculatePremiumData);
                        data.then((res) => {
                            if (res) {
                                ctx.setFormResult(res);
                                setSubmitActivated(true);
                                setButtonLoading(false);
                                setIsRecalculating(false);
                                localStorage.setItem('localData', JSON.stringify(res));

                                if (isSaving) {
                                    setIsSaving(false);
                                    setButtonLoading(false);
                                    ctx.setNavigationLocked(false);
                                    ctx.setPopupVisible(false);
                                    unlockScroll();
                                    navigate(`/${config.SLUGS.STEP2A_SLUG}/`);
                                }
                            }
                        });
                    },
                    () => {
                        setSubmitActivated(false);
                        setButtonLoading(false);
                        setIsRecalculating(false);
                    }
                );
            }

            // ### Effect runs on submit
            if (isSubmitting && !isUpdating) {
                const calculatePremiumData = resetIsChosen(ctx);

                runBackendValidations(
                    'validate-premium-calculations',
                    ctx,
                    calculatePremiumData,
                    () => {
                        const data = fetchResultData('api/online/calculations/calculate-premium', calculatePremiumData);
                        data.then((res) => {
                            if (res) {
                                runBackendValidations(
                                    'validate-calculations',
                                    ctx,
                                    res,
                                    () => {
                                        setIsUpdating(true);
                                        setIsSubmitting(false);
                                        setButtonLoading(false);
                                        ctx.setFormResult(res);
                                        ctx.setPopupCloseEvent('recap_end');
                                        triggerGtmEvent('recap');
                                        localStorage.setItem('localData', JSON.stringify(res));

                                        cartInit(
                                            ctx,
                                            res.Participants[0],
                                            () => {
                                                setUserMadeChange(false);
                                                localStorage.setItem('step3Submitted', 'true');
                                                ctx.setFormResult(res);
                                                setCompleted(3);
                                                setUnlockNextStep(true);
                                            }
                                            // () => {
                                            //     setIsUpdating(false);
                                            //     ctx.setFormResult(res);
                                            //     triggerGtmEvent('recap_end');
                                            // }
                                        );
                                    },
                                    () => {
                                        setIsSubmitting(false);
                                        setButtonLoading(false);
                                        setIsUpdating(false);
                                    }
                                );
                            }
                        });
                    },
                    () => {
                        setIsSubmitting(false);
                        setButtonLoading(false);
                        setIsUpdating(false);
                    }
                );
            }
        }
    }, [ctx.formResult, isSubmitting, isRecalculating, isUpdating, isSaving]);

    const updateForm = () => {
        // ### Groups relevant to this step ["FAM_1PCA", "FAM_1CI"]
        const filteredGroups = getStepRelevantData(
            ctx.formResult.Participants[0].SuggestedInsurance?.ProtectionGroups,
            'STEP3_PRODUCTS'
        );

        // ### Groups irelevant to this step ["PAC_MP"] OR ["FAM_1DTH", "FAM_1SD", "FAM_1DIS"]
        const restGroups = getStepRelevantData(
            ctx.formResult.Participants[0].SuggestedInsurance?.ProtectionGroups,
            ctx.insuranceType === config.MORTGAGE_CODE ? 'STEP2B_PRODUCTS' : 'STEP2A_PRODUCTS'
        );

        // ### Set IsChosen for all Variants
        filteredGroups?.map((g) =>
            g.SuggestedProtections[0].Variants.map((v) => {
                v.IsChosen = selectedProduct.find((p) => p.name === g.RiderCategoryCode)?.value === v.VariantOrder;
            })
        );

        ctx.setFormResult((prevState) => ({
            ...prevState,
            Participants: [
                {
                    ...prevState.Participants[0],
                    SuggestedInsurance: {
                        ...prevState.Participants[0].SuggestedInsurance,
                        ProtectionGroups: [...(restGroups || []), ...(filteredGroups || [])],
                    },
                },
            ],
            Settings: {
                ...prevState.Settings,
                LastStepName: config.STEP_NAMES.STEP3,
            },
        }));
    };

    const getSelectedItemIndex = (code: string, key: number) => {
        return selectedProduct.find((item) => item.name === code)?.value === key;
    };

    const renderForm = (errors: KeyStringValueProps) => {
        let tmpTitle;
        return getStepRelevantData(
            ctx.formResult.Participants[0].SuggestedInsurance?.ProtectionGroups,
            'STEP3_PRODUCTS'
        )?.map((e, i: number) => {
            tmpTitle = resolveBoxTitles(e.RiderCategoryCode);

            return (
                <Box key={i} className={cn('mb-7', styles.box)} loading={buttonLoading}>
                    <Grid className={cn(styles.tableHeader)}>
                        <div className="box-title-wrapper text--left">
                            <div
                                className={cn(styles.help, 'help')}
                                onClick={() => {
                                    e.RiderCategoryCode === config.RIDER_CATEGORIES.PCA ? (
                                        ctx.setPopupContent(ConsequencesPopup)
                                    ) : e.RiderCategoryCode === config.RIDER_CATEGORIES.CI ? (
                                        ctx.setPopupContent(IllnessPopup)
                                    ) : (
                                        <></>
                                    );
                                    ctx.formResult.Participants[0].SuggestedInsurance;
                                    const currentAge = ctx.formResult.Participants[0].Identification.CurrentAge || 0;
                                    const duration =
                                        ctx.formResult.Participants[0].SuggestedInsurance?.ProtectionGroups[0]
                                            .SuggestedProtections[0].InsurancePeriod || 0;
                                    const replacements = {
                                        duration: duration,
                                        age: currentAge + duration,
                                        uncloseable: false,
                                    };
                                    ctx.setPopupContentVariables(replacements);
                                    ctx.setCloseButton(true);
                                    ctx.setPopupVisible(true);
                                }}
                            >
                                ?
                            </div>

                            <div className="text--bold">{tmpTitle?.title ?? ''}</div>

                            {tmpTitle?.afterTitle && <div>{tmpTitle?.afterTitle}:</div>}
                        </div>

                        <Grid cols="auto" className="table-header-buttons">
                            {Array.isArray(tmpTitle?.variants) &&
                                tmpTitle?.variants.map((v, i: number) => (
                                    <div key={i} className={cn(styles.product, 'text--bold')}>
                                        {v && (
                                            <Button
                                                variant="primary"
                                                small
                                                outlined={selectedVariants?.[e.RiderCategoryCode] !== i + 1}
                                                active={selectedVariants?.[e.RiderCategoryCode] === i + 1}
                                                onClick={() => {
                                                    setUserMadeChange(true);
                                                    setSelectedVariants((prevState) => ({
                                                        ...prevState,
                                                        [e.RiderCategoryCode]: i + 1,
                                                    }));

                                                    // ### If we click on button, we set selectedProduct to clicked variant -> checkbox will be checked

                                                    const sp = selectedProduct.find(
                                                        (item) => item.name === e.RiderCategoryCode
                                                    );

                                                    if (sp) {
                                                        const currentPrice = sumAllValues(
                                                            e.SuggestedProtections[0].Variants,
                                                            i + 1
                                                        );
                                                        sp.value = i + 1;
                                                        sp.price = currentPrice;

                                                        setSelectedProduct([...selectedProduct]);
                                                    }
                                                }}
                                            >
                                                {v}
                                            </Button>
                                        )}
                                    </div>
                                ))}
                        </Grid>
                    </Grid>

                    {/* ### Render input field */}
                    {renderCoverageItem(
                        e.SuggestedProtections[0].Variants,
                        e.SuggestedProtections[0].Variants[0].RiderGroup.Code,
                        e.RiderCategoryCode,
                        ctx,
                        activeItem,
                        setActiveItem,
                        true,
                        errors,
                        selectedVariants?.[e.RiderCategoryCode]
                    )}

                    <Grid colsUneven={[1, 2]} nonResponsive className={styles.tableFooter}>
                        <div className="text--left">{cms.common.insuranceForPrice}</div>
                        <div className={styles.levelPrice}>
                            <div
                                className={styles.levelPriceValue}
                                style={{
                                    display: (() => {
                                        if (e.RiderCategoryCode === config.RIDER_CATEGORIES.DTH) {
                                            return 'block';
                                        } else {
                                            return 'none';
                                        }
                                    })(),
                                }}
                            >
                                {formatPrice(
                                    e.SuggestedProtections[0].Variants.find(
                                        (v: ProtectionVariantProps) =>
                                            v.VariantOrder === selectedVariants?.[e.RiderCategoryCode]
                                    )?.PremiumChosen.Frequency.toFixed(0) || 0
                                )}
                                &nbsp;{config.CURRENCIES.CZK} / měsíc
                            </div>

                            <SelectVariantButton
                                className={styles.checkButton}
                                checked={getSelectedItemIndex(
                                    e.RiderCategoryCode,
                                    selectedVariants?.[e.RiderCategoryCode] || 1
                                )}
                                price={
                                    submitActivated
                                        ? formatPrice(
                                              sumAllValues(
                                                  e.SuggestedProtections[0].Variants,
                                                  selectedVariants?.[e.RiderCategoryCode]
                                              )
                                          )
                                        : '???'
                                }
                                disabled={e.RiderCategoryCode === config.RIDER_CATEGORIES.DTH}
                                onPositiveClick={() => {
                                    setUserMadeChange(true);
                                    handleVariantClick(e, selectedProduct, selectedVariants, setSelectedProduct);
                                }}
                                onNegativeClick={() => {
                                    setUserMadeChange(true);
                                    handleVariantClick(e, selectedProduct, selectedVariants, setSelectedProduct);
                                }}
                            />
                        </div>
                    </Grid>
                </Box>
            );
        });
    };

    return (
        <Step title={cms.step3.title} text={cms.step3.text} className={cn(styles.wrapper, className)}>
            <Formik
                initialValues={{}}
                validateOnBlur={false}
                validateOnChange={false}
                validateOnMount={false}
                enableReinitialize
                onSubmit={() => {
                    setUserMadeChange(false);
                    setButtonLoading(true);
                    setIsSubmitting(true);
                    setIsUpdating(false);
                    updateForm();
                }}
            >
                {({ errors }) =>
                    loaded ? (
                        <Form className={styles.form} onChange={() => setSubmitActivated(false)}>
                            {renderForm(errors)}

                            {/* <Box className={cn(styles.box, styles.sportBox, 'mb-3-m mb-6')}>{renderSportContent()}</Box> */}

                            <PriceDiscount
                                className="mb-6"
                                titleText="Celkem vás pojištění měsíčně vyjde na"
                                infoText={!submitActivated ? cms.common.priceHasChangedText : undefined}
                                priceAfter={!submitActivated ? '?' : totalPrice}
                            />

                            <Grid cols={'auto'} className={styles.buttonsWrapper}>
                                <div className={styles.hide} ref={bottomRef} />
                                {submitActivated ? (
                                    <Button className={styles.button} variant="primary" loading={buttonLoading} submit>
                                        {cms.common.likeButton}
                                    </Button>
                                ) : (
                                    <Button
                                        className={styles.buttonCalculate}
                                        variant="secondary"
                                        loading={buttonLoading}
                                        onClick={() => {
                                            if (!Object.keys(errors).length) {
                                                setUserMadeChange(false);
                                                setIsUpdating(true);
                                                setIsRecalculating(true);
                                                setButtonLoading(true);
                                                updateForm();
                                            } else {
                                                scrollToError();
                                            }
                                        }}
                                    >
                                        {cms.common.recalculateButton}
                                    </Button>
                                )}
                            </Grid>
                        </Form>
                    ) : (
                        <Loader size="medium" />
                    )
                }
            </Formik>

            <ErrorForm />
        </Step>
    );
};
