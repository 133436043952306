import * as yup from 'yup';

import config from '../../../config.json';
import cms from '../../../data/cms.json';

import { getNumberFromString } from '../../../utils/getNumberFromString';
import { formatPrice } from '../../../utils/formatPrice';
import { validateFieldAgainst } from '../../../utils/validations';
import { SelectedProductProps } from '../../../types/initData';

// ### Validation schema for income scenario
export const validationSchema = (data?: Array<SelectedProductProps>, variantOrder?: number) => {
    let disabilityValidation = {};

    if (data) {
        const targetData = data.find((item) => item.name === config.RIDER_CATEGORIES.DIS);

        // ### Validate field values against others (you must have higher or same value on higher level)
        if (targetData && (targetData.value || variantOrder) && targetData.value !== -1) {
            disabilityValidation = {
                [`${config.RIDER_GROUPS.DISD3}-${config.DISD_ORDER_INDEX}-${targetData.value || variantOrder}`]:
                    validateFieldAgainst(
                        `${config.RIDER_GROUPS.DISD23}-${config.DISD_ORDER_INDEX}-${targetData.value || variantOrder}`
                    ),
                [`${config.RIDER_GROUPS.DISD23}-${config.DISD_ORDER_INDEX}-${targetData.value || variantOrder}`]:
                    validateFieldAgainst(
                        `${config.RIDER_GROUPS.DISD123}-${config.DISD_ORDER_INDEX}-${targetData.value || variantOrder}`
                    ),
            };
        } else {
            disabilityValidation = {};
        }
    }

    return yup.object({
        hasChildren: yup.string().required(cms.validations.REQUIRED_FIELD),
        mortgage: yup.string().required(cms.validations.REQUIRED_FIELD),
        mortgageAlone: yup.string().when('mortgage', {
            is: 'Ano',
            then: (schema) => schema.required(cms.validations.REQUIRED_FIELD),
        }),
        mortgageInterest: yup.number().when('mortgage', {
            is: 'Ano',
            then: (schema) =>
                schema
                    .required(cms.validations.REQUIRED_FIELD)
                    .min(
                        config.QUESTIONNAIRE.MORTGAGE_INTEREST.MIN,
                        `Minimální hodnota je ${config.QUESTIONNAIRE.MORTGAGE_INTEREST.MIN}`
                    )
                    .max(
                        config.QUESTIONNAIRE.MORTGAGE_INTEREST.MAX,
                        `Maximální hodnota je ${config.QUESTIONNAIRE.MORTGAGE_INTEREST.MAX}`
                    ),
        }),
        mortgageAmount: yup.number().when('mortgage', {
            is: 'Ano',
            then: (schema) => schema.required(cms.validations.REQUIRED_FIELD),
        }),
        mortgagePeriod: yup.number().when('mortgage', {
            is: 'Ano',
            then: (schema) =>
                schema
                    .required(cms.validations.REQUIRED_FIELD)
                    .min(
                        config.QUESTIONNAIRE.MORTGAGE_PERIOD.MIN,
                        `Minimální hodnota je ${config.QUESTIONNAIRE.MORTGAGE_PERIOD.MIN}`
                    )
                    .max(
                        config.QUESTIONNAIRE.MORTGAGE_PERIOD.MAX,
                        `Maximální hodnota je ${config.QUESTIONNAIRE.MORTGAGE_PERIOD.MAX}`
                    ),
        }),
        ...disabilityValidation,
    });
};

// ### Validation schema for mortgage scenario
export const validationSchemaMortgage = (min: number, max: number) =>
    yup.object({
        amount: yup
            .string()
            .required(cms.validations.REQUIRED_FIELD)
            .test(
                'max',
                `Vyberte hodnotu v rozmezí <span class="text--nowrap">${formatPrice(
                    min || 0
                )}</span> a <span class="nowrap">${formatPrice(max || 0)}</span>`,
                (value) => (value ? getNumberFromString(value) : 0) <= max
            )
            .test(
                'min',
                `Vyberte hodnotu v rozmezí <span class="text--nowrap">${formatPrice(
                    min || 0
                )}</span> a <span class="nowrap">${formatPrice(max || 0)}</span>`,
                (value) => (value ? getNumberFromString(value) : 0) >= min
            ),
    });
