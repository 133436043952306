// ### Function is used to scroll to the first error in Formik form

export const scrollToError = () => {
    setTimeout(() => {
        const error = document.querySelector('.formItemError');
        error &&
            window &&
            window.scrollTo({
                top: error.getBoundingClientRect().y + window.scrollY - 100,
                left: 0,
                behavior: 'smooth',
            });
    }, 250);
};
