import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './SelectedValues.module.scss';
import { Label } from '../Label/Label';

export interface FormikActions<Values> {
    setFieldValue<Field extends keyof Values>(field: Field, value: Values[Field], shouldValidate?: boolean): void;
}

interface SelectedValuesProps {
    values: ReadonlyArray<{
        name: string;
        code: number;
        value: number;
    }>;
    active: number | null;
    setActive: (code: number | null) => void;
    inputChanged: boolean;
    label?: string;
    defaultValues: Array<number> | null;
    setFieldValue: (field: string, value: number, shouldValidate?: boolean | undefined) => void;
    callback?: () => void;
    className?: string;
}

export const SelectedValues = ({
    values,
    label,
    active,
    setActive,
    callback,
    inputChanged,
    defaultValues,
    setFieldValue,
    className,
}: SelectedValuesProps): ReactElement | null => (
    <div className={cn(styles.wrapper, className)}>
        {label && <Label className={styles.label}>{label}</Label>}

        {active && (
            <div className={styles.inner}>
                {values.map((v) => (
                    <div
                        key={v.code}
                        className={cn(styles.value, active >= v.code && styles.valueActive)}
                        title={`${v.name} stupeň`}
                        onClick={() => {
                            setActive(v.code);
                            !inputChanged && defaultValues && setFieldValue('amount', defaultValues[v.code - 1]);
                            callback && callback();
                        }}
                    >
                        {v.value}
                    </div>
                ))}
            </div>
        )}
    </div>
);
