import { BrowserRouter as Router } from 'react-router-dom';

import ScrollToTop from './utils/scrollToTop';
import { Layout } from './components/Layout/Layout';

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Layout />
        </Router>
    );
};

export default App;
