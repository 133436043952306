import { ReactElement, useContext } from 'react';
import cn from 'classnames';
import cms from '../../data/cms.json';
import { Icon } from '../Icon/Icon';
import { Title } from '../Title/Title';
import { Paragraph } from '../Paragraph/Paragraph';
import { Button } from '../Button/Button';
import { AppContext } from '../Layout/Layout';
import { unlockScroll } from '../../utils/bodyScrollLock';
import styles from './ConfirmBackPopup.module.scss';

export interface ConfirmBackPopupProps {
    onClickMoveToSave: () => void;
    onClickContinueWithoutSave: () => void;
    className?: string;
}

export const ConfirmBackPopup = ({
    className,
    onClickMoveToSave,
    onClickContinueWithoutSave,
}: ConfirmBackPopupProps): ReactElement | null => {
    const ctx = useContext(AppContext);

    return (
        <div className={styles.confirmBackPopup}>
            <Title tag="h1" className="mb-4">
                {cms.common.YourChangesAreNotSaveTitle}
            </Title>

            <Paragraph>{cms.common.blockingUnsavedChangeText}</Paragraph>

            <Button
                className={'mb-4'}
                variant="primary"
                onClick={() => {
                    unlockScroll();

                    ctx.setPopupVisible(false);
                    ctx.setPopupContentVariables(null);
                    onClickMoveToSave();
                }}
            >
                {cms.common.moveToSaveButton}
            </Button>

            <a
                onClick={(e) => {
                    e.preventDefault();
                    ctx.setNavigationLocked(false);
                    ctx.setPopupVisible(false);
                    unlockScroll();
                    onClickContinueWithoutSave();
                }}
                className={styles.continueWithoutSaveButton}
                href="#"
            >
                {cms.common.continueWithoutSaveButton}
            </a>
        </div>
    );
};
