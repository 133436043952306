import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './CallButton.module.scss';
import { Icon } from '../Icon/Icon';

export interface CallButtonProps {
    onClick: () => void;
    className?: string;
}

export const CallButton = ({ className, onClick }: CallButtonProps): ReactElement | null => {
    return (
        <button className={cn(styles.wrapper, className)} onClick={onClick}>
            <Icon className={styles.icon} name="phone" size="small" />
        </button>
    );
};
