import { ProtectionGroupProps } from '../types/model';

// ### Function filteres out non-available client products from passed data
export const filterClientAvailability = (
    input: Array<ProtectionGroupProps>,
    getAvailable: boolean
): Array<ProtectionGroupProps> | [] => {
    const result = input.filter((g) => {
        if (getAvailable) {
            return g.ClientAvailability === 1;
        } else {
            return g.ClientAvailability !== 1;
        }
    });

    return result ?? [];
};
