import config from '../config.json';

const commonVariants = ['Nižší', 'Doporučené', 'Vyšší'];

// ### Function accepts rider category code and returns object with box data (step2, step3)

export const resolveBoxTitles = (code: string) => {
    const titles = [
        {
            code: config.RIDER_CATEGORIES.DTH,
            title: 'Smrt',
            afterTitle: '- mám tyto závazky',
            variants: ['Žádné', 'Děti/hypotéka'],
        },
        {
            code: config.RIDER_CATEGORIES.DIS,
            title: 'Invalidita',
            afterTitle: '- zajistit příjem na',
            variants: ['80 %', '100 %', '120 %'],
        },
        {
            code: config.RIDER_CATEGORIES.SD,
            title: 'Pracovní neschopnost',
            afterTitle: '- mám úspory na',
            variants: ['3 měsíce a déle', '2 měsíce', '1 měsíc'],
        },
        {
            code: config.RIDER_CATEGORIES.CI,
            title: 'Závažná onemocnění',
            afterTitle: '- zvolené krytí',
            variants: commonVariants,
        },
        {
            code: config.RIDER_CATEGORIES.PCA,
            title: 'Trvalé následky úrazu',
            afterTitle: '- zvolené krytí',
            variants: commonVariants,
        },
        {
            code: config.RIDER_CATEGORIES.MP,
            title: 'Smrt a invalidita',
            afterTitle: '',
            variants: commonVariants,
        },
    ];

    return titles.find((t) => t.code === code);
};
