import { ReactElement, useContext, useState } from 'react';
import cn from 'classnames';
import { Formik, Form } from 'formik';

import cms from '../../data/cms.json';
import styles from './LoadFormPopup.module.scss';
import { fetchResultData } from '../../utils/externalDataHandling';
import { renderTextField } from '../../utils/renderTextField';

import { Button } from '../Button/Button';
import { FormItemError } from '../FormItemError/FormItemError';
import { Grid } from '../Grid/Grid';
import { AppContext } from '../Layout/Layout';
import { Paragraph } from '../Paragraph/Paragraph';
import { Title } from '../Title/Title';
import { emailValidationSchema } from '../../utils/validations';
import { useNavigate } from 'react-router-dom';
import { unlockScroll } from '../../utils/bodyScrollLock';
import { removeWhitespace } from '../../utils/removeWhitespace';
import { stepResolver } from '../../utils/stepResolver';
import { SuggestedInsuranceCalculationKind } from '../../enums/suggestedInsuranceCalculationKind';
import { parseJson } from '../../utils/helpers';

export interface Props {
    className?: string;
    resetForm?: boolean;
}

interface ResponseAuthenticationCodeProps {
    OnlinePolicyExternalId: string;
    TokenExternalId: string;
    ChannelIdentificationValue: string;
}

export const LoadFormPopup = ({ className, resetForm }: Props): ReactElement | null => {
    const ctx = useContext(AppContext);
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [authResponse, setAuthResponse] = useState<ResponseAuthenticationCodeProps | null>(null);

    const hideNumber = (phoneNo: string | null | undefined) => {
        return phoneNo && phoneNo.substring(0, 4) + ' *** *** ' + phoneNo.substring(phoneNo.length - 3);
    };

    return (
        <div className={cn(styles.wrapper, className)}>
            {error ? (
                <div className={styles.result}>
                    <Title tag="h2" className={cn('mb-3', styles.resultTitle)}>
                        {cms.common.loadFormPopupErrorTitle}
                    </Title>

                    <Paragraph className={styles.resultText}>
                        {errorMessage ?? cms.common.loadFormPopupErrorText}
                    </Paragraph>

                    <Button
                        variant="primary"
                        onClick={() => {
                            setError(false);
                            ctx.setPopupVisible(false);
                            unlockScroll();

                            if (resetForm) {
                                localStorage.clear();
                                navigate('/');
                                navigate(0); // ### Force page refresh to delete React Context
                            }
                        }}
                    >
                        {cms.common.closeButton}
                    </Button>
                </div>
            ) : success ? (
                <div className={styles.result}>
                    <Title tag="h2" className={cn('mb-3', styles.resultTitle)}>
                        {cms.common.loadFormPopupTitle}
                    </Title>

                    <Paragraph className={styles.resultText}>
                        {cms.common.loadFormPopupSuccessText + hideNumber(authResponse?.ChannelIdentificationValue)}
                    </Paragraph>

                    <Formik
                        initialValues={{
                            authCode: '',
                        }}
                        validateOnBlur={false}
                        validateOnChange={true}
                        validateOnMount={false}
                        validationSchema={emailValidationSchema}
                        onSubmit={(values) => {
                            if (ctx.formResult) {
                                setLoading(true);
                                const body = {
                                    OnlinePolicyExternalId: authResponse?.OnlinePolicyExternalId,
                                    TokenExternalId: authResponse?.TokenExternalId,
                                    AuthCode: removeWhitespace(values.authCode),
                                };

                                const data = fetchResultData('api/online/model-data/load', body);
                                data.then((res) => {
                                    if (res) {
                                        const json = parseJson(res);
                                        ctx.setFormResult(json);
                                        ctx.setNavigationLocked(false);
                                        ctx.setPopupVisible(false);
                                        unlockScroll();
                                        ctx.setInsuranceType(
                                            json.Participants[0].SuggestedInsuranceCalculationType ===
                                                SuggestedInsuranceCalculationKind.Income
                                                ? 'INCOME'
                                                : 'MORTGAGE'
                                        );
                                        navigate(
                                            stepResolver(
                                                json.Settings.LastStepName,
                                                json.Participants[0].SuggestedInsuranceCalculationType
                                            )
                                        );
                                        setLoading(false);
                                    }
                                }).catch((err) => {
                                    console.error(err);
                                    setError(true);
                                    setErrorMessage(err);
                                });
                            } else {
                                console.error('Context is missing');
                                setError(true);
                            }
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Grid cols={1} className={'mb-3-m mb-5'}>
                                    <div>
                                        {renderTextField(
                                            'authCode',
                                            'number',
                                            'Autorizační kód',
                                            undefined,
                                            undefined,
                                            '',
                                            null,
                                            999999999,
                                            (touched.authCode && errors.authCode) || '',
                                            undefined,
                                            undefined,
                                            true,
                                            true
                                        )}

                                        {touched.authCode && errors.authCode && (
                                            <FormItemError text={errors.authCode} />
                                        )}
                                    </div>
                                </Grid>
                                <Button variant="primary" className="mb-6" submit loading={loading}>
                                    {cms.common.loadButton}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : (
                <>
                    <Title tag="h2" className="mb-3">
                        {cms.common.loadFormPopupTitle}
                    </Title>
                    <Paragraph>{cms.common.loadFormPopupText}</Paragraph>

                    <Formik
                        initialValues={{
                            email: ctx.formResult.Participants[0].Contact.Email ?? '',
                        }}
                        validateOnBlur={false}
                        validateOnChange={true}
                        validateOnMount={false}
                        validationSchema={emailValidationSchema}
                        onSubmit={(values) => {
                            if (ctx.formResult) {
                                setLoading(true);
                                const body = {
                                    LoginName: values.email,
                                };
                                const resultCheck = fetchResultData(
                                    'api/online/authentications/check-login-name',
                                    body
                                );
                                resultCheck
                                    .then((res) => {
                                        if (res) {
                                            if (res.ResultType === 1) {
                                                const result = fetchResultData(
                                                    'api/online/authentications/request-authentication-code',
                                                    body
                                                );
                                                result
                                                    .then((res) => {
                                                        if (res) {
                                                            setAuthResponse(res);
                                                            setSuccess(true);
                                                            ctx.setPopupContentVariables({ uncloseable: true });
                                                        } else {
                                                            setError(true);
                                                            setErrorMessage(null);
                                                        }
                                                        setLoading(false);
                                                    })
                                                    .catch((err) => {
                                                        console.error(err);
                                                        setError(true);
                                                    });
                                            } else if (res.ResultType === 2) {
                                                setError(true);
                                                setErrorMessage(cms.errors.requestAuthentication.ResultType2);
                                            } else if (res.ResultType === 3) {
                                                setError(true);
                                                setErrorMessage(cms.errors.requestAuthentication.ResultType3);
                                            } else if (res.ResultType === 4) {
                                                setError(true);
                                                setErrorMessage(cms.errors.requestAuthentication.ResultType4);
                                            }
                                        } else {
                                            setError(true);
                                            setErrorMessage(null);
                                        }
                                        setLoading(false);
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                        setError(true);
                                        setErrorMessage(null);
                                    });
                            } else {
                                console.error('Context is missing');
                                setError(true);
                                setErrorMessage(null);
                            }
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Grid cols={1} className={'mb-3-m mb-5'}>
                                    <div>
                                        {renderTextField(
                                            'email',
                                            'text',
                                            'Email (přihlašovací jméno)',
                                            undefined,
                                            undefined,
                                            '',
                                            undefined,
                                            undefined,
                                            (touched.email && (errors.email as string)) || '',
                                            false,
                                            undefined,
                                            true,
                                            true
                                        )}
                                        {errors.email && touched.email && (
                                            <FormItemError text={errors.email as string} />
                                        )}
                                    </div>
                                </Grid>
                                <Button variant="primary" className="mb-6" submit loading={loading}>
                                    {cms.common.loginButton}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </div>
    );
};
