import { createContext, Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import cn from 'classnames';

import styles from './Layout.module.scss';
import config from '../../config.json';
import ModelInitialState from '../../data/ModelInitialState.json';
import { getDataWithCallback } from '../../utils/externalDataHandling';
import { routes } from '../../utils/routes';

import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { Banner } from '../Banner/Banner';
import { InsuranceType } from '../_pages/Step0/Step0';
import { Popup } from '../Popup/Popup';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';
import { InitDataObjectProps, InitDataQuestionnaireObjectProps } from '../../types/initData';
import { GlobalErrorProps, MultiAnswerProps } from '../../types/common';
import { ModelProps } from '../../types/model';
// TODO: uncomment and install the @simplea/shared-ui package once the the npm login in TeamCity is resolved
// import { notificationConfig } from '../../constants/defaults';

// import { Notification } from '@simplea/shared-ui';

export interface FormStepProps {
    current: number;
    completed: number;
}

interface PopupContentVariablesProps {
    [key: string]: boolean | number | string;
}

export interface ContextProps {
    popupVisible: boolean;
    setPopupVisible: (visible: boolean) => void;
    closeButton: boolean;
    setCloseButton: (visible: boolean) => void;
    popupContent: null | ReactElement;
    setPopupContent: (content: ReactElement) => void;
    popupContentVariables: PopupContentVariablesProps | null;
    setPopupContentVariables: (contentVariables: PopupContentVariablesProps | null) => void;
    initData: InitDataObjectProps | null;
    setInitData: (data: InitDataObjectProps) => void;
    initDataQuestionaire: InitDataQuestionnaireObjectProps | null;
    setInitDataQuestionaire: (data: InitDataQuestionnaireObjectProps) => void;
    insuranceType: InsuranceType;
    setInsuranceType: (insuranceType: InsuranceType) => void;
    formResult: ModelProps;
    setFormResult: Dispatch<SetStateAction<ModelProps>>;
    diseaseCount: number;
    setDiseaseCount: (data: number) => void;
    formStep: FormStepProps;
    setFormStep: (data: FormStepProps) => void;
    multiAnswer: MultiAnswerProps;
    setMultiAnswer: (data: MultiAnswerProps) => void;
    globalError: Array<GlobalErrorProps>;
    setGlobalError: (data: Array<GlobalErrorProps>) => void;
    popupCloseEvent: string | null;
    setPopupCloseEvent: (data: string | null) => void;
    popupCloseCallback: () => void;
    setPopupCloseCallback: (callback: () => void) => void;
    isBackButtonVisible: boolean;
    setIsBackButtonVisible: (visible: boolean) => void;
    navigationLocked: boolean;
    setNavigationLocked: (value: boolean) => void;
    showBlockPopup: boolean;
    setShowBlockPopup: (value: boolean) => void;
    isSaving: boolean;
    setIsSaving: (value: boolean) => void;
}

export const AppContext = createContext<ContextProps>({
    popupVisible: false,
    setPopupVisible: () => false,
    closeButton: false,
    setCloseButton: () => false,
    popupContent: null,
    setPopupContent: () => false,
    popupContentVariables: null,
    setPopupContentVariables: () => false,
    initData: null,
    setInitData: () => false,
    initDataQuestionaire: null,
    setInitDataQuestionaire: () => false,
    insuranceType: null,
    setInsuranceType: () => false,
    formResult: ModelInitialState,
    setFormResult: () => ModelInitialState,
    diseaseCount: 0,
    setDiseaseCount: () => null,
    formStep: { current: 0, completed: -1 },
    setFormStep: () => null,
    multiAnswer: {},
    setMultiAnswer: () => null,
    globalError: [],
    setGlobalError: () => null,
    popupCloseEvent: '',
    setPopupCloseEvent: () => null,
    popupCloseCallback: () => null,
    setPopupCloseCallback: () => () => null,
    isBackButtonVisible: false,
    setIsBackButtonVisible: () => false,
    navigationLocked: false,
    setNavigationLocked: () => false,
    showBlockPopup: false,
    setShowBlockPopup: () => false,
    isSaving: false,
    setIsSaving: () => false,
});

const initIntersectionObserver = () => {
    const body = document.querySelector('body');
    const target = document.querySelector('#page-footer');
    const cls = 'scrolled-down';
    const classList = body?.classList;
    let intersecting: boolean;

    if (body && target && classList) {
        const observer = new IntersectionObserver((entries) => {
            intersecting = entries[0].isIntersecting;
            intersecting ? classList.add(cls) : classList.remove(cls);
        });
        observer.observe(target);
    }
};

const getStaticData = (
    localStorageProperty: string,
    property: unknown,
    callback: (arg: SetStateAction<any>) => void
) => {
    if (!property) {
        const data = localStorage.getItem(localStorageProperty);
        if (data) {
            const parsedData = JSON.parse(data);
            callback(parsedData);
        }
    } else {
        localStorage.setItem(localStorageProperty, JSON.stringify(property));
    }
};

export const Layout = (): ReactElement | null => {
    const [popupVisible, setPopupVisible] = useState(false);
    const [closeButton, setCloseButton] = useState(true);
    const [popupContent, setPopupContent] = useState<ReactElement | null>(null);
    const [popupContentVariables, setPopupContentVariables] = useState<PopupContentVariablesProps | null>(null);
    const [initData, setInitData] = useState<InitDataObjectProps | null>(null);
    const [initDataQuestionaire, setInitDataQuestionaire] = useState<InitDataQuestionnaireObjectProps | null>(null);
    const [insuranceType, setInsuranceType] = useState<InsuranceType>(null);
    const [formResult, setFormResult] = useState<ModelProps>(ModelInitialState);
    const [diseaseCount, setDiseaseCount] = useState<number>(1);
    const [formStep, setFormStep] = useState<FormStepProps>({ current: 0, completed: -1 });
    const [multiAnswer, setMultiAnswer] = useState<MultiAnswerProps>({});
    const [globalError, setGlobalError] = useState<Array<GlobalErrorProps>>([]);
    const [popupCloseEvent, setPopupCloseEvent] = useState<string | null>(null);
    const [popupCloseCallback, setPopupCloseCallback] = useState<() => void>(() => null);
    const [isBackButtonVisible, setIsBackButtonVisible] = useState(true);
    const [navigationLocked, setNavigationLocked] = useState(false);
    const [showBlockPopup, setShowBlockPopup] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const headerData = routes.filter((item) => item?.visible !== false);
    const location = useLocation();
    const is500 = location.pathname === '/500';

    // @TODO: send the NotificationType from BE if it should be displayed or null if not
    // const { title, description } = notificationConfig['test'];

    useEffect(() => {
        if (!initData) {
            getDataWithCallback('api/online/initializations/init-form', setInitData);
        }
        if (!initDataQuestionaire) {
            getDataWithCallback('api/online/initializations/init-questionnaire', setInitDataQuestionaire);
        }

        if (window.IntersectionObserver) {
            initIntersectionObserver();
        }
    }, []);

    // ### After component is mounted, set or get data from localStorage
    useEffect(() => {
        getStaticData('localData', formResult.Participants[0].ExternalId ? formResult : null, setFormResult);
    }, [formResult]);

    // ### After component is mounted, set or get data from localStorage
    useEffect(() => {
        getStaticData('insuranceType', insuranceType, setInsuranceType);
    }, [insuranceType]);

    useEffect(() => {
        // @TODO: Not great, not terrible. We are going to change this to serverside solution.
        setFormStep({
            current: parseInt(localStorage.getItem('currentStep') || '0'),
            completed: parseInt(localStorage.getItem('completedStep') || '-1'),
        });

        setGlobalError([]);
    }, [location]);

    const resolveAllowed = (code: string) => {
        const completed = parseInt(localStorage.getItem('completedStep') || '-1');
        const current = parseInt(localStorage.getItem('currentStep') || '-1');

        switch (code) {
            case 'STEP0':
                return completed === -1;
            case 'STEP1':
                return completed === 0;
            case 'STEP2':
                return completed < 8 && completed >= 1;
            case 'STEP3':
                return completed < 8 && completed >= 2;
            case 'STEP4':
                return completed < 8 && completed >= 3;
            case 'STEP5':
                return completed < 8 && completed >= 4;
            case 'STEP6':
                return completed < 8 && completed >= 5;
            case 'STEP7':
                return completed < 8 && completed >= 6;
            case 'STEP8':
                return completed === 7 && current === 8;
            case 'STEP9LOADING':
                return completed !== 8 && current >= 8;
            case 'STEP9A':
            case 'STEP9B':
            case 'STEP9C':
                return completed === 8;
            case 'STEP9':
                return completed >= 8 && current === 9;
            case 'STEP_BANKID':
                return completed >= 8;
            case 'STEP_CONFIRMDATA':
                return completed >= 9;
            case 'STEP10PROCESSING':
            case 'STEP10':
                return completed >= 10;
            case 'STEP11':
                return completed === 11;
            default:
                return true;
        }
    };

    return (
        <AppContext.Provider
            value={{
                popupVisible,
                setPopupVisible,
                closeButton,
                setCloseButton,
                popupContent,
                setPopupContent,
                popupContentVariables,
                setPopupContentVariables,
                initData,
                setInitData,
                initDataQuestionaire,
                setInitDataQuestionaire,
                insuranceType,
                setInsuranceType,
                formResult,
                setFormResult,
                diseaseCount,
                setDiseaseCount,
                formStep,
                setFormStep,
                multiAnswer,
                setMultiAnswer,
                globalError,
                setGlobalError,
                popupCloseEvent,
                setPopupCloseEvent,
                popupCloseCallback,
                setPopupCloseCallback,
                isBackButtonVisible,
                setIsBackButtonVisible,
                navigationLocked,
                setNavigationLocked,
                showBlockPopup,
                setShowBlockPopup,
                isSaving,
                setIsSaving,
            }}
        >
            {/* {<Notification title={title} description={description} />} */}
            <div className={cn(styles.wrapper, is500 && styles.noPadding)}>
                {!is500 && <Header items={headerData} />}

                <main className={styles.content}>
                    <Routes>
                        {routes.map((r, i) =>
                            r.protected ? (
                                <Route
                                    key={i}
                                    path={r.url}
                                    element={
                                        <ProtectedRoute isAllowed={resolveAllowed(r.code)}>
                                            {r.component}
                                        </ProtectedRoute>
                                    }
                                />
                            ) : (
                                <Route key={i} path={r.url} element={r.component} />
                            )
                        )}
                    </Routes>
                </main>

                {(location.pathname === '/' ||
                    location.pathname === `/${config.SLUGS.STEP1A_SLUG}/` ||
                    location.pathname === `/${config.SLUGS.STEP1B_SLUG}/`) && <Banner />}

                <Footer disableLogoLink={is500} />

                {popupVisible && <Popup />}
            </div>
        </AppContext.Provider>
    );
};
