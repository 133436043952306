import { ReactElement } from 'react';
import cn from 'classnames';
import styles from './OrderedList.module.scss';

export interface OrderedListProps {
    items: Array<string>;
    className?: string;
}

export const OrderedList = ({ items, className }: OrderedListProps): ReactElement | null => {
    if (items.length === 0) {
        return null;
    }

    return (
        <ol className={cn(styles.wrapper, className)}>
            {items.map((item, i) => (
                <li key={i} className={styles.item}>
                    <span>{item}</span>
                </li>
            ))}
        </ol>
    );
};
