import { ReactElement } from 'react';
import { Field, FieldProps, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import styles from './StepConfirmData.module.scss';
import cms from '../../../data/cms.json';
import config from '../../../config.json';
import { Step } from '../../Step/Step';
import { ValuesProps } from '../Step6/Step6';
import { Button } from '../../Button/Button';
import { scrollToError } from '../../../utils/scrollToError';
import { Box } from '../../Box/Box';
import { Grid } from '../../Grid/Grid';
import { Label } from '../../Label/Label';
import { formatDate } from '../../../utils/formatDate';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { CheckButton } from '../../CheckButton/CheckButton';
import { PEPPopup } from '../Step2/PopupContent';
import { Select } from '../../Select/Select';
import { validationSchemaDefault } from './validations';
import { validateField } from '../../../utils/validations';
import { FormItemError } from '../../FormItemError/FormItemError';
import { AmountButton } from '../../AmountButton/AmountButton';
import DatePicker from 'react-date-picker';
import PhoneNumberInputNew from '../../PhoneNumberInput/PhoneNumberInput';
import { FormikTextInput } from '../../TextInput/FormikTextInput';
import { DataPlaceholder } from '../../DataPlaceholder/DataPlaceholder';
import { participantInitialValues } from './utils';
import { useStepConfirmData } from './useStepConfirmData';
import dayjs from 'dayjs';

interface StepConfirmDataProps {
    className?: string;
}

export const StepConfirmData = ({ className }: StepConfirmDataProps): ReactElement | null => {
    const {
        hasBirthplace,
        hasIssuer,
        participant,
        isSubmitting,
        isSupportedPrefix,
        submitted,
        setSubmitted,
        onSubmit,
        isPhoneLoading,
        phoneNumber,
        constructSelectCountriesOptions,
        constructSelectBankCodesOptions,
        showSecondCitizenship,
        setShowSecondCitizenship,
        renderAddress,
        isPep,
        handleIsPepClick,
        bankIdUrl,
        ctx,
        idType,
        hasBirthCountry,
        birthCountry,
        hasCitizenship,
        citizenship,
        hasSecondCitizenship,
        secondCitizenship,
        issueDate,
    } = useStepConfirmData();

    return (
        <Step
            title={cms.step_confirmdata.title}
            text={cms.step_confirmdata.text}
            className={cn(styles.wrapper, className)}
        >
            <Formik
                enableReinitialize
                initialValues={participantInitialValues(participant, isSupportedPrefix)}
                validateOnBlur={false}
                validateOnChange={submitted}
                validateOnMount={false}
                validate={(values) => {
                    !submitted && setSubmitted(true);
                    try {
                        validateYupSchema(values, validationSchemaDefault(!!issueDate), true, values);
                    } catch (err) {
                        return yupToFormErrors(err);
                    }
                    return {};
                }}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleSubmit }: ValuesProps) => (
                    <Form className={styles.form}>
                        <Box innerWrapper loading={isSubmitting}>
                            <Grid cols={2} className="mb-8">
                                <FormikTextInput label="Titul před" name="titleBefore" />
                                <FormikTextInput label="Titul za" name="titleAfter" />
                            </Grid>
                            <Grid cols={2} className={'mb-8'}>
                                <DataPlaceholder label="Jméno">
                                    {participant.Identification.PersonalIdentificationDetail?.FirstName}
                                </DataPlaceholder>
                                <DataPlaceholder label="Příjmení">
                                    {participant.Identification.PersonalIdentificationDetail?.LastName}
                                </DataPlaceholder>
                            </Grid>
                            <Grid cols={2} className={'mb-8'}>
                                <FormikTextInput label="Email" name="email" error={touched.email && errors.email} />
                                <div style={{ maxWidth: '20rem', margin: '0.5rem auto' }}>
                                    <PhoneNumberInputNew
                                        className="field-large"
                                        namePhone="phone"
                                        namePhonePrefix="phonePrefix"
                                        label={
                                            ctx.insuranceType === config.MORTGAGE_CODE ? '<b>Telefon</b>' : 'Telefon'
                                        }
                                        showEditIcon
                                        error={(touched.phone && errors.phone) || ''}
                                        optional={false}
                                        loading={isPhoneLoading}
                                    />
                                    {!isSupportedPrefix && !isPhoneLoading && !touched.phone && (
                                        <>
                                            <div></div>
                                            <FormItemError
                                                text={`Telefonní číslo ${phoneNumber} není námi podporováno, prosíme o vyplnění telefonního čísla včetně předvolby výše.`}
                                            />
                                        </>
                                    )}
                                </div>
                            </Grid>
                            <Grid cols={2} className={'mb-8'}>
                                <DataPlaceholder label="Datum narození">
                                    {formatDate(participant.Identification.BirthDt)}
                                </DataPlaceholder>
                                {hasBirthplace ? (
                                    <DataPlaceholder label="Místo narození">
                                        {participant.Identification.PersonalIdentificationDetail?.Birthplace}
                                    </DataPlaceholder>
                                ) : (
                                    <FormikTextInput
                                        label="Místo narození"
                                        name="birthplace"
                                        error={touched.birthplace && errors.birthplace}
                                    />
                                )}
                            </Grid>
                            <Grid cols={2} className={'mb-8'}>
                                {hasBirthCountry ? (
                                    <DataPlaceholder label="Stát narození">{birthCountry}</DataPlaceholder>
                                ) : (
                                    <div>
                                        <Label>Stát narození</Label>
                                        <Field
                                            id="birthCountry"
                                            name="birthCountry"
                                            className={cn(styles.select)}
                                            options={constructSelectCountriesOptions()}
                                            component={Select}
                                            validate={(value: string | number) => validateField(value, false)}
                                            error={errors.birthCountry}
                                        />
                                        {errors.birthCountry && touched.birthCountry && (
                                            <FormItemError text={errors.birthCountry as string} />
                                        )}
                                    </div>
                                )}
                                {hasCitizenship ? (
                                    <DataPlaceholder label="Státní občanství">{citizenship}</DataPlaceholder>
                                ) : (
                                    <div>
                                        <Label>Státní občanství</Label>
                                        <Field
                                            id="citizenship"
                                            name="citizenship"
                                            className={cn(styles.select)}
                                            options={constructSelectCountriesOptions()}
                                            component={Select}
                                            error={errors.citizenship}
                                        />
                                    </div>
                                )}
                            </Grid>
                            <Grid cols={1} className={'mb-8'}>
                                {showSecondCitizenship || hasSecondCitizenship ? (
                                    <>
                                        {hasSecondCitizenship ? (
                                            <DataPlaceholder label="Druhé státní občanství">
                                                {secondCitizenship}
                                            </DataPlaceholder>
                                        ) : (
                                            <>
                                                <Label>Druhé státní občanství</Label>
                                                <div className={cn(styles.toggleForm)}>
                                                    <Field
                                                        id="secondCitizenship"
                                                        name="secondCitizenship"
                                                        className={cn(styles.select, styles.nomargin)}
                                                        options={constructSelectCountriesOptions()}
                                                        component={Select}
                                                        error={errors.secondCitizenship}
                                                    />
                                                    <AmountButton
                                                        className={cn(styles.ml1, 'addMore text--left')}
                                                        title={cms.common.removeItemButton}
                                                        actionType="decrease"
                                                        onClick={() => {
                                                            setShowSecondCitizenship(false);
                                                            values['validateSecondCitizenship'] = false;
                                                            values['secondCitizenship'] = null;
                                                        }}
                                                    />
                                                </div>
                                                {errors.secondCitizenship && touched.secondCitizenship && (
                                                    <FormItemError text={errors.secondCitizenship as string} />
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <div className={styles.toggleForm}>
                                        <AmountButton
                                            className="addMore"
                                            title={cms.common.addItemButton}
                                            actionType="increase"
                                            onClick={() => {
                                                setShowSecondCitizenship(true);
                                                values['validateSecondCitizenship'] = true;
                                            }}
                                        />
                                        <div className={styles.label}>Přidat další občanství</div>
                                    </div>
                                )}
                            </Grid>
                            <Grid cols={1} className={'mb-8'}>
                                <div>
                                    <Label>Trvalá adresa</Label>
                                    {renderAddress(participant.Identification.PermanentAddress)}
                                </div>
                            </Grid>
                            {participant.Identification.ContactAddress &&
                                participant.Identification.ContactAddress?.CityName.length > 0 && (
                                    <Grid cols={1} className={'mb-8'}>
                                        <div>
                                            <Label>Kontaktní adresa</Label>
                                            {renderAddress(participant.Identification.ContactAddress)}
                                        </div>
                                    </Grid>
                                )}
                            <Label className="text--bold">Doklad</Label>
                            <Grid cols={2} className={'mb-8'}>
                                <DataPlaceholder label="Typ dokladu">{idType}</DataPlaceholder>
                                <DataPlaceholder label="Číslo dokladu">
                                    {participant.Identification.IdCard?.IdCardNo}
                                </DataPlaceholder>
                            </Grid>
                            <Grid cols={2} className={'mb-8'}>
                                <div className="flex-col flex-a-center">
                                    <Label>Vydán kdy</Label>
                                    {participant.Identification.IdCard?.IssuedDt &&
                                    participant.Identification.IdCard?.IssuedDt !== null ? (
                                        <div className={styles.boxValue}>
                                            {formatDate(participant.Identification.IdCard?.IssuedDt)}
                                        </div>
                                    ) : (
                                        <>
                                            <Field id="issueDate" name="issueDate" error={errors.issueDate}>
                                                {({ field, form }: FieldProps) => (
                                                    <DatePicker
                                                        {...field}
                                                        name="issueDate"
                                                        onChange={async (value: Date) => {
                                                            await form.setFieldValue(field.name, value);
                                                        }}
                                                        format="dd-MM-yyyy"
                                                        disableCalendar
                                                        dayPlaceholder="dd"
                                                        monthPlaceholder="mm"
                                                        yearPlaceholder="rrrr"
                                                        maxDate={dayjs().year(9999).toDate()}
                                                        locale="cs-CZ"
                                                        required
                                                        className={errors.issueDate && submitted ? styles.dtError : ''}
                                                    />
                                                )}
                                            </Field>
                                        </>
                                    )}
                                    {errors.issueDate && submitted && <FormItemError text={errors.issueDate} />}
                                </div>
                                <DataPlaceholder label="Platnost do">
                                    {formatDate(participant.Identification.IdCard?.ExpiryDt)}
                                </DataPlaceholder>
                            </Grid>
                            <Grid cols={1} className={'mb-8'}>
                                <FormikTextInput
                                    label="Vydán kým"
                                    name="issuer"
                                    readonly={!!hasIssuer}
                                    error={touched.issuer && errors.issuer}
                                />
                            </Grid>
                            <Label className="text--bold">Bankovní účet</Label>
                            <Grid cols={3} className={'mb-8'}>
                                <FormikTextInput label="Předčíslí" name="bankAccountPrefix" variant="number" />
                                <FormikTextInput
                                    label="Číslo účtu"
                                    name="bankAccountNo"
                                    variant="number"
                                    error={touched.bankAccountNo && errors.bankAccountNo}
                                />
                                <div key="bankAccountCode">
                                    <Label htmlFor="bankAccountCode">Banka</Label>
                                    <Field
                                        id="bankAccountCode"
                                        name="bankAccountCode"
                                        className={cn(styles.select)}
                                        options={constructSelectBankCodesOptions()}
                                        component={Select}
                                        error={errors.bankAccountCode}
                                    />
                                </div>
                                {((errors.bankAccountNo && touched.bankAccountNo) ||
                                    (errors.bankAccountCode && touched.bankAccountCode)) && (
                                    <>
                                        <div></div>
                                        {errors.bankAccountNo && touched.bankAccountNo && (
                                            <FormItemError text={errors.bankAccountNo as string} />
                                        )}
                                        {errors.bankAccountCode && touched.bankAccountCode && (
                                            <FormItemError text={errors.bankAccountCode as string} />
                                        )}
                                    </>
                                )}
                            </Grid>
                            <Grid cols={1} className={'mb-8'}>
                                <div className={styles.toggleForm}>
                                    <CheckButton
                                        className={styles.checkButton}
                                        onClick={() => handleIsPepClick()}
                                        size="small"
                                        checked={isPep}
                                    />
                                    <div className={styles.label} onClick={() => handleIsPepClick()}>
                                        Já nebo blízká osoba je Politicky exponovaná
                                    </div>
                                    <div
                                        className={cn(styles.help, 'help')}
                                        onClick={() => {
                                            ctx.setPopupContent(PEPPopup);
                                            ctx.setPopupContentVariables({ uncloseable: false });
                                            ctx.setCloseButton(true);
                                            ctx.setPopupVisible(true);
                                        }}
                                    >
                                        ?
                                    </div>
                                </div>
                            </Grid>
                            <Button
                                className="mb-6"
                                variant="primary"
                                loading={isSubmitting}
                                onClick={(e) => {
                                    e?.preventDefault();
                                    handleSubmit();
                                    scrollToError();
                                }}
                            >
                                {cms.common.confirmButton}
                            </Button>
                            <div className={styles.newBankId}>
                                <div className={styles.text}>
                                    {cms.common.bugBankId + ' '}
                                    <span
                                        className={styles.decoration}
                                        onClick={(e) => {
                                            e?.preventDefault();
                                            if (ctx.formResult) {
                                                bankIdUrl &&
                                                    window &&
                                                    window.open(bankIdUrl.replace('{#nonce}', uuidv4()), '_self');
                                            }
                                        }}
                                    >
                                        {cms.common.resetBankId}
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <ErrorForm />
                    </Form>
                )}
            </Formik>
        </Step>
    );
};
