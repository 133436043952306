import { ReactElement } from 'react';
import cn from 'classnames';

import cms from '../../data/cms.json';
import styles from './Banner.module.scss';
import pomeranc from '../../images/pomeranc.png';
import lilek from '../../images/lilek.png';
import banan from '../../images/banan.png';
import { Title } from '../Title/Title';
import he from 'he';

interface BannerProps {
    className?: string;
}

export const Banner = ({ className }: BannerProps): ReactElement | null => {
    const resolveImage = (key: string) => {
        switch (key) {
            case 'pomeranc':
                return pomeranc;
            case 'lilek':
                return lilek;
            case 'banan':
                return banan;
            default:
                break;
        }
    };
    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={styles.inner}>
                <Title tag="h2" className={cn(styles.title, 'mb-4')}>
                    {cms.banner.title}
                </Title>

                <p className={cn('mb-9 mb-6-m', styles.text)}>{cms.banner.text}</p>

                <ul className={styles.list}>
                    {cms.banner.items.map((item, i: number) => (
                        <li key={i} className={styles.item}>
                            <img src={resolveImage(item.image)} alt={item.title} className={styles.image} />
                            <Title tag="h3" className={styles.itemTitle}>
                                {item.title}
                            </Title>
                            <div>{he.decode(item.text)}</div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
