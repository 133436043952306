import { ReactElement } from 'react';
import cn from 'classnames';

import cms from '../../data/cms.json';

import { Icon } from '../Icon/Icon';
import styles from './Download.module.scss';
import { DocumentProps } from '../_pages/Step10/Step10';
import { getBaseUrl } from '../../utils/getBaseUrl';

interface DownloadProps {
    files: ReadonlyArray<DocumentProps>;
    className?: string;
}

export const Download = ({ files, className }: DownloadProps): ReactElement | null => {
    return (
        <div className={cn(styles.wrapper, className)}>
            {files.map((f, i) => (
                <div key={i} className={styles.file}>
                    <Icon name="document" className={styles.documentIcon} />
                    <span className={styles.fileName}>{f.DisplayName}</span>
                    <a
                        className={styles.download}
                        href={`${getBaseUrl()}api/online/misc/get-document-content/${f.ExternalId}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Icon name="download" className={styles.downloadIcon} />
                        {cms.common.downloadButton}
                    </a>
                </div>
            ))}
        </div>
    );
};
