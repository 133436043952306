import config from '../config.json';
import { MortgageProps } from '../types/model';
import { getNumberFromString } from './getNumberFromString';

// ### Function takes Formik values (key: value) and changes previous mortgage object which is
// later sent to API

export const constructMortgageObject = (
    prevStateMortgage: MortgageProps | null,
    values: { [key: string]: string | number | null }
) => {
    // ### If any of following is not filled, abort object construction and return null
    if (
        (!values.mortgageInterest && !prevStateMortgage?.InterestRatePct) ||
        (!values.mortgageAlone && !prevStateMortgage?.IsOnlyPayer) ||
        (!values.mortgageAmount && !prevStateMortgage?.RemainingAmount) ||
        (!values.mortgagePeriod && !prevStateMortgage?.RemainingYears)
    ) {
        return null;
    }

    return {
        ...prevStateMortgage,
        InterestRatePct: values.mortgageInterest
            ? getNumberFromString(values.mortgageInterest)
            : prevStateMortgage?.InterestRatePct || null,
        IsOnlyPayer: values.mortgageAlone
            ? values.mortgageAlone === config.QUESTIONNAIRE.ANSWER_YES
                ? true
                : false
            : prevStateMortgage?.IsOnlyPayer || null,
        RemainingAmount: values.mortgageAmount
            ? getNumberFromString(values.mortgageAmount)
            : prevStateMortgage?.RemainingAmount || null,
        RemainingYears: values.mortgagePeriod
            ? getNumberFromString(values.mortgagePeriod)
            : prevStateMortgage?.RemainingYears || null,
    };
};
