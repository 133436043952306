import axios from 'axios';

import config from '../config.json';
import { CommonDataProps } from '../types/initData';
import { ModelProps } from '../types/model';
import { getBaseUrl } from './getBaseUrl';

// ### GET request for API calls, with callback
export const getDataWithCallback = (endpoint: string, callback: (data: any) => void) => {
    return axios
        .get(`${getBaseUrl()}${endpoint}`)
        .then((res) => res.data)
        .then((data) => {
            callback(data);
        })
        .catch((err) => console.error(err));
};

// ### POST request for API calls
export const fetchResultData = (endpoint: string, body: any) => {
    return axios
        .post(`${getBaseUrl()}${endpoint}`, body)
        .then((res) => res.data)
        .then((data) => data)
        .catch((err) => console.error(err));
};

// ### POST request for validation through API
export const validateForm = (endpoint: string, data: ModelProps) => {
    return axios
        .post(`${getBaseUrl()}api/online/validations/${endpoint}`, data)
        .then((res) => res.data)
        .catch((err) => {
            console.error(err);
            throw new Error(err);
        });
};

// ### Function returns array of codes (string) which are found in initData by code (string)
export const resolveIdsFromCode = (code: string, commonData: CommonDataProps) => {
    const result: Array<number> = [];
    commonData?.QuestionAnswerLists?.map((answer) => answer.Code === code && result.push(answer.Id));
    return result;
};

// ### Function returns array of ids (number) which are found in initData by name (string)
export const resolveIdsFromName = (name: string, commonData: CommonDataProps) => {
    const result: Array<number> = [];
    commonData?.QuestionAnswerLists?.map((answer) => answer.NameDefault === name && result.push(answer.Id));
    return result;
};

// ### Function finds field text, which is used as label in Formik form
export const resolveFieldText = (id: number, commonData?: CommonDataProps) => {
    return commonData?.QuestionAnswerLists?.find((answer) => answer.Id === id)?.NameDefault || '';
};

// ### Function finds question code
export const resolveFieldCode = (id: number, commonData: CommonDataProps) => {
    return commonData?.QuestionAnswerLists?.find((answer) => answer.Id === id)?.Code || '';
};

// ### Function finds proper HTML input type by initData field type
export const resolveFieldType = (id: number | null, commonData?: CommonDataProps) => {
    if (id && commonData && commonData?.QuestionAnswerFormats && Array.isArray(commonData?.QuestionAnswerFormats)) {
        const result = commonData?.QuestionAnswerFormats?.find((item) => item.Id === id)?.Code;
        if (result) {
            switch (result) {
                case config.FIELD_TYPES.LIST:
                    return 'radio';
                case config.FIELD_TYPES.INTEGER:
                    return 'number';
                case config.FIELD_TYPES.DECIMAL:
                    return 'decimal';
                case config.FIELD_TYPES.MONTH:
                case config.FIELD_TYPES.DATE:
                    return 'date';
                default:
                    return 'text';
            }
        } else {
            return 'text';
        }
    } else {
        return 'text';
    }
};

// ### Function return min for specific question code
export const resolveFieldMin = (code: string | null) => {
    if (code) {
        switch (code) {
            case 'HEIGHT':
                return 30;
            case 'WEIGHT':
                return 1;
            default:
                return null;
        }
    }
    return null;
};

// ### Function makes array of object for Select.tsx component (react-select)
export const constructSelectOptions = (data: CommonDataProps, ids: Array<number> | null) => {
    const arr: Array<{ label: string; value: string }> = [];
    let curr;
    let currCode;

    ids?.map((id) => {
        curr = resolveFieldText(id, data);
        currCode = resolveFieldCode(id, data);

        if (curr) {
            arr.push({ label: curr, value: String(currCode) });
        }
    });

    return arr;
};
