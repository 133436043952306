import config from '../config.json';

import { ContextProps } from '../components/Layout/Layout';
import { EmploymentTypeProps } from '../types/initData';
import { KeyValueProps } from '../types/common';

// ### Function finds employment Id according to values filled in Formik form

export const resolveEmploymentTypeId = (ctx: ContextProps, values: KeyValueProps) => {
    const types = ctx.initData?.Lovs.EmploymentTypes;
    const findId = (code: string) => types?.find((item: EmploymentTypeProps) => item.Code === code)?.Id;

    if (values.incomeSource === config.QUESTIONNAIRE.SELF_EMPLOYED) {
        if (values.hasHealthInsurance === config.QUESTIONNAIRE.ANSWER_YES) {
            return findId('SI');
        } else {
            return findId('SE');
        }
    } else if (values.incomeSource === config.QUESTIONNAIRE.EMPLOYEE) {
        return findId('EY');
    } else if (values.incomeSource === config.OCCUPATION_TYPES.EXEC_DIRTR) {
        return findId('OTHER_INC');
    }

    return findId('NO_INC');
};
