import config from '../config.json';

// ### Function accepts occupation code and returns its name, which is later used for incomeSource value

export const resolveEmploymentName = (code: string, employmentId: number) => {
    switch (code) {
        case 'EXEC_DIRTR':
            return config.OCCUPATION_TYPES.EXEC_DIRTR;
        case 'CARER_CLSP':
            return config.OCCUPATION_TYPES.CARER_CLSP;
        case 'STUDENT':
            return config.OCCUPATION_TYPES.STUDENT;
        case 'OLD_PENS':
            return config.OCCUPATION_TYPES.OLD_PENS;
        case 'DISAB_PENS':
            return config.OCCUPATION_TYPES.DISAB_PENS;
        case 'UNEMPLOYED':
            return config.OCCUPATION_TYPES.UNEMPLOYED;
        case 'MAT_PRN_LV':
            return config.OCCUPATION_TYPES.MAT_PRN_LV;
        default:
            if (employmentId === 3 || employmentId === 4) {
                return config.QUESTIONNAIRE.SELF_EMPLOYED;
            } else if (employmentId === 2) {
                return config.QUESTIONNAIRE.EMPLOYEE;
            }
            return '';
    }
};
