import config from '../../config.json';
import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
    isAllowed: boolean;
    redirectPath?: string;
    children: ReactElement;
}

export const ProtectedRoute = ({
    isAllowed,
    redirectPath = `/${config.SLUGS.NOENTRY_SLUG}`,
    children,
}: ProtectedRouteProps): ReactElement => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};
