import { forwardRef, ReactElement, ReactNode, Ref } from 'react';
import cn from 'classnames';

import styles from './Grid.module.scss';

interface GridProps {
    cols?: number | 'auto';
    colsUneven?: number[];
    children: ReactNode;
    nonResponsive?: boolean;
    tag?: 'fieldset' | 'div';
    id?: string;
    inline?: boolean;
    className?: string;
    nogap?: boolean;
}

export const Grid = forwardRef(
    (
        { cols, colsUneven, id, children, tag = 'div', inline, nogap, className, nonResponsive }: GridProps,
        ref: Ref<HTMLFieldSetElement & HTMLDivElement>
    ): ReactElement | null => {
        const Wrapper = tag;

        return (
            <Wrapper
                className={cn(
                    styles.wrapper,
                    className,
                    inline && styles.inline,
                    nogap && styles['no-gap'],
                    cols && styles[`cols-${cols}`],
                    colsUneven && styles[`cols-${colsUneven.join('-')}`],
                    nonResponsive && styles.nonResponsive
                )}
                id={id}
                ref={ref}
            >
                <>{children}</>
            </Wrapper>
        );
    }
);

Grid.displayName = 'Grid';
