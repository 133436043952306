import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { ReactComponent as Arrow } from '../../images/vectors/next.svg';
import styles from './Popup.module.scss';
import { triggerGtmEvent } from '../../utils/triggerGtmEvent';

import { Box } from '../Box/Box';
import { Close } from '../Close/Close';
import { AppContext } from '../Layout/Layout';
import { lockScroll, unlockScroll } from '../../utils/bodyScrollLock';

interface PopupProps {
    className?: string;
}

export const Popup = ({ className }: PopupProps): ReactElement | null => {
    const targetRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [showScrollHint, setShowScrollHint] = useState(false);
    const [scrollCheckComplete, setScrollCheckComplete] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const scrollThreshold = 20; // ### Do not show scroll hint if there is a scroll space shorter then this value

    const handleScroll = useCallback(
        (evt: Event) => {
            if (showScrollHint) {
                const target = evt.target as HTMLElement;

                if (target) {
                    // ### Hide hint button when scrolled to bottom (minus scrollThreshold)
                    if (target.scrollHeight - target.scrollTop - scrollThreshold <= target.clientHeight) {
                        setShowScrollHint(false);
                        contentRef.current && contentRef.current.removeEventListener('scroll', handleScroll);
                    }
                }
            }
        },
        [showScrollHint, contentRef]
    );

    useEffect(() => {
        // ### I had to add slight delay, because there was some content shift and therefore miscalculation
        setTimeout(() => {
            if (contentRef && contentRef.current) {
                setShowScrollHint(contentRef?.current?.scrollHeight > contentRef?.current?.clientHeight);

                // ### This boolean is used to prevent premature handleScroll binding (with implicit showScrollHint)
                setScrollCheckComplete(true);
            }
        }, 100);
    }, [contentRef]);

    useEffect(() => {
        lockScroll(setScrollPosition);
        return () => unlockScroll(scrollPosition);
    }, []);

    useEffect(() => {
        if (contentRef.current && scrollCheckComplete) {
            contentRef.current.addEventListener('scroll', handleScroll);

            return () => {
                contentRef.current && contentRef.current.removeEventListener('scroll', handleScroll);
            };
        }
    }, [contentRef, scrollCheckComplete, handleScroll]);

    return (
        <AppContext.Consumer>
            {({
                setPopupVisible,
                popupContent,
                closeButton,
                popupCloseEvent,
                setPopupCloseEvent,
                popupCloseCallback,
                popupContentVariables,
            }) => {
                const handleClose = () => {
                    window.pageYOffset > 0 && setScrollPosition(window.pageYOffset);
                    unlockScroll(window.pageYOffset);
                    setPopupVisible(false);
                    setPopupCloseEvent(null);
                    popupCloseCallback && popupCloseCallback();
                };

                return (
                    <>
                        <Box className={cn(styles.wrapper, className)} ref={targetRef}>
                            <div className={styles.content} ref={contentRef}>
                                {popupContent}

                                {closeButton && (
                                    <Close
                                        className={styles.close}
                                        onClick={() => {
                                            unlockScroll(scrollPosition);
                                            popupCloseEvent && triggerGtmEvent(popupCloseEvent);
                                            handleClose();
                                        }}
                                    />
                                )}
                            </div>

                            {showScrollHint && (
                                <div
                                    className={styles.scrollDownButton}
                                    onClick={() => {
                                        contentRef.current?.scrollTo({
                                            top: contentRef.current.scrollHeight,
                                            left: 0,
                                            behavior: 'smooth',
                                        });
                                    }}
                                >
                                    <Arrow />
                                </div>
                            )}
                        </Box>

                        <div
                            className={styles.overlay}
                            onClick={() => {
                                if (!popupContentVariables?.uncloseable) {
                                    unlockScroll(scrollPosition);
                                    popupCloseEvent && triggerGtmEvent(popupCloseEvent);
                                    handleClose();
                                }
                            }}
                        ></div>
                    </>
                );
            }}
        </AppContext.Consumer>
    );
};
