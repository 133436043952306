import { forwardRef, ReactElement, ReactNode, Ref } from 'react';
import cn from 'classnames';
import styles from './Box.module.scss';

export interface BoxProps {
    children: ReactNode;
    dark?: boolean;
    className?: string;
    loading?: boolean;
    innerWrapper?: boolean;
}

export const Box = forwardRef(
    ({ children, dark, innerWrapper, loading, className }: BoxProps, ref: Ref<HTMLDivElement>): ReactElement => {
        return (
            <div ref={ref} className={cn(styles.wrapper, dark && styles.dark, className, loading && styles.loading)}>
                {innerWrapper ? <div className={cn(styles.inner, 'boxInner')}>{children}</div> : children}
            </div>
        );
    }
);
