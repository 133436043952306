import { ReactElement, useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import styles from './Step11.module.scss';
import cms from '../../../data/cms.json';
import { renderToggleField } from '../../../utils/renderToggleField';
import { fetchResultData } from '../../../utils/externalDataHandling';

import { Step } from '../../Step/Step';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { Box } from '../../Box/Box';
import { Button } from '../../Button/Button';
import { Grid } from '../../Grid/Grid';
import { Label } from '../../Label/Label';
import { Textarea } from '../../Textarea/Textarea';
import { Title } from '../../Title/Title';
import { AppContext } from '../../Layout/Layout';
import { CustomLocation } from '../../../types/common';
import { useLocalStorage } from '../../../hooks/useLocalStorageHook';

interface Step11Props {
    className?: string;
}

const feedbackKey = 'feedback';

export const Step11 = ({ className }: Step11Props): ReactElement | null => {
    const ctx = useContext(AppContext);
    const location: CustomLocation = useLocation();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [feedback, setFeedback] = useLocalStorage(feedbackKey, '');

    useEffect(() => {
        localStorage.setItem('currentStep', '12');
        ctx.setIsBackButtonVisible(true);
    }, []);

    return (
        <Step title={cms.step11.title} text={cms.step11.text} className={cn(styles.wrapper, className)}>
            <Box loading={loading}>
                {error ? (
                    <Title tag="span" size="md">
                        {cms.common.feedbackErrorTitle}
                    </Title>
                ) : success ? (
                    <div className={styles.feedbackText}>
                        <Title tag="span" size="md">
                            {cms.common.feedbackSuccessTitle}
                        </Title>
                        <Button
                            variant="secondary"
                            onClick={() => window && window.open('https://www.simplea.cz', '_self')}
                        >
                            {cms.common.navigateToSimplea}
                        </Button>
                    </div>
                ) : (
                    <Formik
                        initialValues={{
                            leadSource: '',
                            message: feedback || '',
                            rating: '',
                        }}
                        enableReinitialize
                        onSubmit={(values) => {
                            const state = location?.state;
                            const externalId = state?.ExternalId;

                            if (externalId) {
                                setLoading(true);
                                const body = {
                                    FeedbackText: values.message,
                                    OnlinePolicyExternalId: externalId,
                                    LeadSourceText: values.leadSource,
                                    ClientsSatisfactionLevel: values.rating ? parseInt(values.rating) : null,
                                };

                                setFeedback(values.message);

                                const result = fetchResultData('api/online/support/send-feedback', body);
                                result
                                    .then(() => {
                                        setSuccess(true);
                                        setLoading(false);
                                    })
                                    .catch((err: Error | AxiosError) => {
                                        console.error(err);
                                        setError(true);
                                        setLoading(false);
                                    });
                            } else {
                                console.error('ExternalId is missing');
                                setError(true);
                            }
                        }}
                    >
                        {() => (
                            <Form className={styles.form}>
                                <Label htmlFor="leadSource">Jak jste se o nás dozvěděli?</Label>
                                <Grid cols="auto" className="mb-8">
                                    {renderToggleField('leadSource', 'tv', 'radio', 'Televize', '', false, '', true)}
                                    {renderToggleField('leadSource', 'radio', 'radio', 'Rádio', '', false, '', true)}
                                    {renderToggleField(
                                        'leadSource',
                                        'billboard',
                                        'radio',
                                        'Billboard',
                                        '',
                                        false,
                                        '',
                                        true
                                    )}
                                    {renderToggleField(
                                        'leadSource',
                                        'social',
                                        'radio',
                                        'Sociální síť',
                                        '',
                                        false,
                                        '',
                                        true
                                    )}
                                    {renderToggleField('leadSource', 'video', 'radio', 'Video', '', false, '', true)}
                                    {renderToggleField('leadSource', 'banner', 'radio', 'Banner', '', false, '', true)}
                                    {renderToggleField('leadSource', 'google', 'radio', 'Google', '', false, '', true)}
                                    {renderToggleField(
                                        'leadSource',
                                        'article',
                                        'radio',
                                        'Článek na webu',
                                        '',
                                        false,
                                        '',
                                        true
                                    )}
                                    {renderToggleField(
                                        'leadSource',
                                        'recommendation',
                                        'radio',
                                        'Na doporučení',
                                        '',
                                        false,
                                        '',
                                        true
                                    )}
                                    {renderToggleField(
                                        'leadSource',
                                        'mediator',
                                        'radio',
                                        'Zprostředkovatel',
                                        '',
                                        false,
                                        '',
                                        true
                                    )}
                                    {renderToggleField(
                                        'leadSource',
                                        'podcast',
                                        'radio',
                                        'Podcast',
                                        '',
                                        false,
                                        '',
                                        true
                                    )}
                                    {renderToggleField('leadSource', 'print', 'radio', 'Tisk', '', false, '', true)}
                                </Grid>

                                <Label htmlFor="rating">Jak se vám líbilo naše online sjednání?</Label>
                                <Grid cols="auto" className="mb-8">
                                    {renderToggleField('rating', 'good', 'radio', '1', undefined, true, '', true)}
                                    {renderToggleField('rating', 'neutral', 'radio', '5', undefined, true, '', true)}
                                    {renderToggleField('rating', 'bad', 'radio', '10', undefined, true, '', true)}
                                </Grid>

                                <Label htmlFor="message">Chcete nám něco říct?</Label>
                                <Field
                                    className={styles.textarea}
                                    name="message"
                                    id="message"
                                    maxLenght={1000}
                                    component={Textarea}
                                />

                                <Button variant="primary" submit loading={loading}>
                                    Odeslat
                                </Button>
                            </Form>
                        )}
                    </Formik>
                )}
            </Box>

            <ErrorForm />
        </Step>
    );
};
