import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './PriceDiscount.module.scss';
import config from '../../config.json';
import { formatPrice } from '../../utils/formatPrice';

import { Title } from '../Title/Title';
import { Paragraph } from '../Paragraph/Paragraph';

export interface PriceDiscountProps {
    titleText: string;
    priceBefore?: number | string;
    priceAfter: number | string;
    suffix?: string;
    infoText?: string;
    className?: string;
}

export const PriceDiscount = ({
    titleText,
    priceBefore,
    priceAfter,
    suffix,
    infoText,
    className,
}: PriceDiscountProps): ReactElement | null => {
    return (
        <div className={styles.wrapper}>
            <Title className={styles.title} tag="h3">
                {titleText}
            </Title>

            <div className={cn(styles.wrapper, className)}>
                {priceBefore && priceBefore < priceAfter && (
                    <div className={cn(styles.priceBefore, styles.price)}>
                        {formatPrice(priceBefore)}&nbsp;{config.CURRENCIES.CZK}
                        {suffix && ` / ${suffix}`}
                    </div>
                )}
                <div className={cn(styles.priceAfter, styles.price)}>
                    {formatPrice(priceAfter)}&nbsp;{config.CURRENCIES.CZK}
                    {suffix && ` / ${suffix}`}
                </div>
            </div>

            {infoText && <Paragraph className={cn(styles.infoText, 'mb-6')}>{infoText}</Paragraph>}
        </div>
    );
};
