// ### Functions removes all whitespace from string

import config from '../config.json';
import { SuggestedInsuranceCalculationKind } from '../enums/suggestedInsuranceCalculationKind';
import { setCompleted } from './setCompleted';

export const stepResolver = (input: string, suggestedInsuranceCalculationType: SuggestedInsuranceCalculationKind) => {
    if (input === config.STEP_NAMES.STEP2) {
        setCompleted(1);
        if (suggestedInsuranceCalculationType === SuggestedInsuranceCalculationKind.Income) {
            return `/${config.SLUGS.STEP2A_SLUG}/`;
        } else {
            return `/${config.SLUGS.STEP2B_SLUG}/`;
        }
    } else if (input === config.STEP_NAMES.STEP3) {
        setCompleted(2);
        return `/${config.SLUGS.STEP3_SLUG}/`;
    } else if (input === config.STEP_NAMES.STEP4) {
        setCompleted(3);
        return `/${config.SLUGS.STEP4_SLUG}/`;
    } else if (input === config.STEP_NAMES.STEP5) {
        setCompleted(4);
        return `/${config.SLUGS.STEP5_SLUG}/`;
    } else if (input === config.STEP_NAMES.STEP6) {
        setCompleted(5);
        return `/${config.SLUGS.STEP6_SLUG}/`;
    } else if (input === config.STEP_NAMES.STEP7) {
        setCompleted(6);
        return `/${config.SLUGS.STEP7_SLUG}/`;
    } else if (input === config.STEP_NAMES.STEP8) {
        setCompleted(7);
        return `/${config.SLUGS.STEP8_SLUG}/`;
    }
    setCompleted(2);
    return `/${config.SLUGS.STEP3_SLUG}/`;
};
