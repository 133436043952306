import { ReactElement, useContext } from 'react';
import cn from 'classnames';

import styles from './Logo.module.scss';
import logo from '../../images/simplea-logo.png';
import logoRetina from '../../images/simplea-logo@2x.png';
import { AppContext, FormStepProps } from '../Layout/Layout';
import { LeavingPopup } from './LeavingPopup';

interface LogoProps {
    withLink?: boolean;
    className?: string;
}

const isLastStep = (formStep: FormStepProps) => formStep.completed === 11 && formStep.current === 12;

export const Logo = ({ className, withLink = true }: LogoProps): ReactElement => {
    const { setPopupContentVariables, setPopupContent, setPopupVisible, setCloseButton, formStep } =
        useContext(AppContext);
    const image = <img src={logo} srcSet={`${logo}, ${logoRetina} 2x`} alt="Simplea logo" className={styles.logo} />;
    return (
        <div className={cn(styles.wrapper, className)}>
            {withLink ? (
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!isLastStep(formStep)) {
                            setPopupContent(<LeavingPopup />);
                            setPopupContentVariables({ uncloseable: false });
                            setPopupVisible(true);
                            setCloseButton(true);
                        } else {
                            window.open('https://www.simplea.cz', '_self');
                        }
                    }}
                    className={styles.link}
                    href="#"
                >
                    {image}
                </a>
            ) : (
                <div className={styles.link}>{image}</div>
            )}
        </div>
    );
};
