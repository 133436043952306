import { ReactElement, useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import cms from '../../../data/cms.json';
import config from '../../../config.json';
import styles from './Step9.module.scss';

import { Step } from '../../Step/Step';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { Box } from '../../Box/Box';
import { Title } from '../../Title/Title';
import { Paragraph } from '../../Paragraph/Paragraph';
import { OrderedList } from '../../OrderedList/OrderedList';
import { Button } from '../../Button/Button';
import { AppContext } from '../../Layout/Layout';
import { ContactFormPopup } from '../../ContactFormPopup/ContactFormPopup';

interface Step9Props {
    className?: string;
}

export const Step9 = ({ className }: Step9Props): ReactElement | null => {
    const [submitting, setSubmitting] = useState(false);
    const [bankIdUrl, setBankIdUrl] = useState<string | null>(null);
    const ctx = useContext(AppContext);

    useEffect(() => {
        ctx.setIsBackButtonVisible(false);
    }, []);

    useEffect(() => {
        // ### Set url for bankID button
        if (!bankIdUrl) {
            const baseUrl = window.location.origin;
            const url = ctx.initData?.OnlineConfigurationData.BankIdApprovalUrl;
            const targetUrl = baseUrl + '/' + config.SLUGS.STEP_BANK_ID_SLUG;
            const externalId = ctx.formResult.ExternalId;
            const replaceTargetUrl = '{#returnUrl}';
            const replaceTargetApprover = '{#approverId}';

            if (url && externalId) {
                setBankIdUrl(url?.replace(replaceTargetUrl, targetUrl).replace(replaceTargetApprover, externalId));
            }
        }
    }, [ctx, bankIdUrl]);

    return (
        <Step title={cms.step9.title} text={cms.step9.text} className={cn(styles.wrapper, className)}>
            <Box innerWrapper>
                <AppContext.Consumer>
                    {({ setPopupVisible, setPopupContent }) => (
                        <Title tag="h3" className="mb-6">
                            <>
                                {cms.step9.aboutBankId.title}
                                <div
                                    onClick={() => {
                                        setPopupContent(
                                            <div className={styles.popup}>
                                                <Title tag="h3" className="mb-3">
                                                    {cms.step9.aboutBankId.popup.title}
                                                </Title>

                                                <Paragraph small className="mb-6">
                                                    {cms.step9.aboutBankId.popup.text}
                                                </Paragraph>

                                                <OrderedList
                                                    className={cn(styles.list, 'mb-4')}
                                                    items={cms.step9.aboutBankId.popup.list}
                                                />

                                                <Title tag="h4" className="mb-3">
                                                    {cms.step9.aboutBankId.popup.title2}
                                                </Title>

                                                <Paragraph small>{cms.step9.aboutBankId.popup.text2}</Paragraph>
                                            </div>
                                        );
                                        setPopupVisible(true);
                                    }}
                                    className="help"
                                >
                                    ?
                                </div>
                            </>
                        </Title>
                    )}
                </AppContext.Consumer>

                <OrderedList className={cn(styles.list, 'mb-4')} items={cms.step9.aboutBankId.list} />

                <Button
                    className="mb-6"
                    variant="primary"
                    loading={submitting}
                    onClick={(e) => {
                        e?.preventDefault();
                        if (ctx.formResult) {
                            setSubmitting(true);
                            bankIdUrl && window && window.open(bankIdUrl.replace('{#nonce}', uuidv4()), '_self');
                        }
                    }}
                >
                    {cms.common.continueBankIdButton}
                </Button>
                <div
                    onClick={() => {
                        ctx.setPopupContent(<ContactFormPopup />);
                        ctx.setPopupContentVariables({ uncloseable: false });
                        ctx.setCloseButton(true);
                        ctx.setPopupVisible(true);
                    }}
                    className={cn(styles.noBankIdLink, 'text--underline', submitting && styles.loading)}
                >
                    {cms.common.noBankIdButton}
                </div>
            </Box>

            <ErrorForm />
        </Step>
    );
};
