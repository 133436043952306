import { v4 as uuidv4 } from 'uuid';

import config from '../config.json';

import { ContextProps } from '../components/Layout/Layout';
import { QuestionProps } from '../types/initData';
import { constructFormReply } from './constructFormReply';
import { resolveEmploymentTypeId } from './resolveEmploymentTypeId';
import { AnswerItemProps } from '../types/model';
import { KeyValueProps } from '../types/common';
import { formatSimpleaDate } from './formatSimpleaDate';

// ### Function updates Participants[0].Questionnaire object

export const updateGlobalFormState = (
    input: Array<QuestionProps>,
    values: KeyValueProps,
    ctx: ContextProps,
    stepName?: string,
    passSpecialFields?: boolean
) => {
    if (input) {
        const newIds: Array<string> = []; // Ids of new answers
        const result = constructFormReply(input, values, ctx);
        const occupationDetails: {
            HasHealthInsurance: boolean;
            EmploymentType: number | null;
        } = { HasHealthInsurance: false, EmploymentType: null };

        // ### Filter result to prevent duplicate entries
        result.map((a: AnswerItemProps) => newIds.push(a.QuestionExternalId));

        // ### There is very specific situation in step8 when we want to update
        // EmploymentType and HasHealthInsurance
        if (passSpecialFields) {
            occupationDetails.EmploymentType = resolveEmploymentTypeId(ctx, values) || null;
            occupationDetails.HasHealthInsurance =
                values.hasHealthInsurance === config.QUESTIONNAIRE.ANSWER_YES ||
                values.incomeSource === config.QUESTIONNAIRE.EMPLOYEE;
        } else {
            occupationDetails.EmploymentType = ctx.formResult.Participants[0].Occupation.EmploymentType;
            occupationDetails.HasHealthInsurance = ctx.formResult.Participants[0].Occupation.HasHealthInsurance;
        }

        if (result) {
            ctx.setFormResult((prevState) => ({
                ...prevState,
                Participants: [
                    {
                        ...prevState.Participants[0],
                        Occupation: {
                            ...prevState.Participants[0].Occupation,
                            ...occupationDetails,
                        },
                        Questionnaire: {
                            ...prevState.Participants[0].Questionnaire,
                            Answers: [
                                {
                                    ...prevState.Participants[0].Questionnaire.Answers[0],
                                    AnswerDt: formatSimpleaDate(new Date()),
                                    ExternalId:
                                        prevState.Participants[0].Questionnaire.Answers[0]?.ExternalId ?? uuidv4(),
                                    ...{
                                        Items: [
                                            // ### Remove existing answer with certain QuestionExternalId and replace it with new
                                            ...(prevState.Participants[0].Questionnaire.Answers[0]?.Items.filter(
                                                (item) => !newIds.includes(item.QuestionExternalId)
                                            ) || []),
                                            ...result,
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
                Settings: {
                    ...prevState.Settings,
                    LastStepName: stepName ?? config.STEP_NAMES.STEP4,
                },
            }));
        }
    }
};
