import {ReactElement, useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import cn from 'classnames';

import cms from '../../data/cms.json';
import styles from './SaveFormPopup.module.scss';
import { fetchResultData } from '../../utils/externalDataHandling';
import { renderTextField } from '../../utils/renderTextField';

import { Button } from '../Button/Button';
import { FormItemError } from '../FormItemError/FormItemError';
import { Grid } from '../Grid/Grid';
import { AppContext } from '../Layout/Layout';
import { Paragraph } from '../Paragraph/Paragraph';
import { Title } from '../Title/Title';
import { emailPhoneValidationSchema } from '../../utils/validations';
import PhoneNumberInputNew from '../PhoneNumberInput/PhoneNumberInput';
import { unlockScroll } from '../../utils/bodyScrollLock';
import { LoadFormPopup } from '../LoadFormPopup/LoadFormPopup';
import { removeWhitespace } from '../../utils/removeWhitespace';
import axios, { AxiosError } from 'axios';
import { getBaseUrl } from '../../utils/getBaseUrl';
import { getPhonePrefix } from '../../utils/getPhonePrefix';

export interface Props {
    className?: string;
    resetForm?: boolean;
}

interface FormikValuesProps {
    email: string;
    phone: string;
    phonePrefix: string;
}

export const SaveFormPopup = ({ className, resetForm }: Props): ReactElement | null => {
    const ctx = useContext(AppContext);
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [warning, setWarning] = useState(false);
    const [loading, setLoading] = useState(false);
    const [save, setSave] = useState(false);
    const [formikValues, setFormikValues] = useState<FormikValuesProps>();

    function saveDraft(values: any) {
        const body = {
            PhonePrefixExternalId: getPhonePrefix(values.phonePrefix, ctx.initData)?.ExternalId,
            PhoneNumber: removeWhitespace(values.phone),
            LoginName: values.email,
            JsonOnlineModel: ctx.formStep.current === 0 ? null : JSON.stringify(ctx.formResult),
        };

        axios
            .post(`${getBaseUrl()}api/online/model-data/save-draft`, body)
            .then(() => {
                setWarning(false);
                setLoading(false);
                setSuccess(true);
            })
            .catch((err: Error | AxiosError) => {
                console.error(err);
                setError(true);
            });
        
        setSave(false);
    }
    
    useEffect(() => {
        if (save) {
            saveDraft(formikValues);
        }
    }, [save]);

    return (
        <div className={cn(styles.wrapper, className)}>
            {error ? (
                <div className={styles.result}>
                    <Title tag="h2" className={cn('mb-3', styles.resultTitle)}>
                        {cms.common.saveFormPopupErrorTitle}
                    </Title>

                    <Paragraph className={styles.resultText}>{cms.common.saveFormPopupErrorText}</Paragraph>

                    <Button
                        variant="primary"
                        onClick={() => {
                            setError(false);
                            ctx.setPopupVisible(false);
                            unlockScroll();

                            if (resetForm) {
                                localStorage.clear();
                                navigate('/');
                                navigate(0); // ### Force page refresh to delete React Context
                            }
                        }}
                    >
                        {cms.common.closeButton}
                    </Button>
                </div>
            ) : warning ? (
                <>
                    <div className={styles.result}>
                        <Title tag="h2" className={cn('mb-3', styles.resultTitle)}>
                            {cms.common.saveFormPopupTitle}
                        </Title>

                        <Paragraph className={styles.resultText}>{cms.common.saveFormPopupWarningText}</Paragraph>

                        <Button
                            variant="primary"
                            onClick={() => {
                                setSave(true);
                            }}
                        >
                            {cms.common.replaceButton}
                        </Button>
                    </div>
                </>
            ) : success ? (
                <div className={styles.result}>
                    <Title tag="h2" className={cn('mb-3', styles.resultTitle)}>
                        {cms.common.saveFormPopupSuccessTitle}
                    </Title>

                    <Paragraph className={styles.resultText}>{cms.common.saveFormPopupSuccessText}</Paragraph>

                    <Button
                        variant="primary"
                        onClick={() => {
                            setSuccess(false);
                            ctx.setPopupVisible(false);
                            unlockScroll();

                            if (resetForm) {
                                localStorage.clear();
                                navigate('/');
                                navigate(0); // ### Force page refresh to delete React Context
                            }
                        }}
                    >
                        {cms.common.closeButton}
                    </Button>
                </div>
            ) : (
                <>
                    <Title tag="h2" className="mb-3">
                        {cms.common.saveFormPopupTitle}
                    </Title>
                    <Paragraph>{cms.common.saveFormPopupText}</Paragraph>

                    <Formik
                        initialValues={{
                            email: '',
                            phone: '',
                            phonePrefix: ctx.formResult.Participants[0].Contact.PhonePrefix.Prefix ?? '',
                        }}
                        validateOnBlur={false}
                        validateOnChange={true}
                        validateOnMount={false}
                        validationSchema={emailPhoneValidationSchema}
                        onSubmit={(values) => {
                            if (ctx.formResult) {
                                ctx.setIsSaving(true);
                                setLoading(true);
                                const body = {
                                    LoginName: values.email,
                                };
                                const resultCheck = fetchResultData(
                                    'api/online/authentications/check-login-name',
                                    body
                                );
                                resultCheck
                                    .then((res) => {
                                        if (res) {
                                            setFormikValues(values);
                                            // LoginName already exist with active draft
                                            if (res.ResultType === 1) {
                                                setLoading(false);
                                                setWarning(true);
                                            } else {
                                                setSave(true);
                                            }
                                        } else {
                                            setError(true);
                                        }
                                        setLoading(false);
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                        setError(true);
                                    });
                            } else {
                                console.error('Context is missing');
                                setError(true);
                            }
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Grid cols={2} className={'mb-3-m mb-5'}>
                                    <div>
                                        {renderTextField(
                                            'email',
                                            'text',
                                            'Email (přihlašovací jméno)',
                                            undefined,
                                            undefined,
                                            '',
                                            undefined,
                                            undefined,
                                            (touched.email && (errors.email as string)) || '',
                                            false,
                                            undefined,
                                            true,
                                            true
                                        )}
                                        {errors.email && touched.email && (
                                            <FormItemError text={errors.email as string} />
                                        )}
                                    </div>
                                    <div className="flex justify-center">
                                        <PhoneNumberInputNew
                                            className="field-large"
                                            namePhone="phone"
                                            namePhonePrefix="phonePrefix"
                                            label="Telefon (sem přijde heslo)"
                                            showEditIcon
                                            error={(touched.phone && errors.phone) || ''}
                                        />
                                    </div>
                                </Grid>
                                <Button variant="primary" className="mb-6" submit loading={loading}>
                                    {cms.common.saveButton}
                                </Button>
                                <div className={styles.text}>
                                    {cms.common.loadSavedQuestion + ' '}
                                    <span
                                        className={styles.decoration}
                                        onClick={(e) => {
                                            ctx.setPopupContent(<LoadFormPopup />);
                                            ctx.setPopupContentVariables({ uncloseable: false });
                                            ctx.setCloseButton(true);
                                            ctx.setPopupVisible(true);
                                        }}
                                    >
                                        {cms.common.loginText}
                                    </span>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </div>
    );
};
