import { v4 as uuidv4 } from 'uuid';
import { ContextProps } from '../components/Layout/Layout';
import { FormikValueCustomProps, KeyValueProps } from '../types/common';
import { QuestionProps } from '../types/initData';
import { AnswerItemProps } from '../types/model';
import { resolveIdsFromName, resolveIdsFromCode } from './externalDataHandling';
import { formatSimpleaDate } from './formatSimpleaDate';
import { getNumberFromString } from './getNumberFromString';

export interface QuestionAnswerListItemProps {
    Id: number;
    NameDefault: string;
    NameTranslated: string;
    Code: string;
    DescDefault: string | null;
    DescTranslated: string;
    IsValid: boolean;
}

// ### Function filteres relevant questions by their Id

const filterRelevantQuestions = (
    questionsArray: Array<QuestionProps>,
    current: FormikValueCustomProps,
    ctx: ContextProps
) => {
    return questionsArray.filter(
        (item: QuestionProps) =>
            item.FilterByQuestionAnswerListId === null ||
            (ctx.initData?.Lovs &&
                typeof current === 'string' &&
                resolveIdsFromCode(current, ctx.initData?.Lovs).includes(item.FilterByQuestionAnswerListId)) ||
            (ctx.initData?.Lovs &&
                typeof current === 'string' &&
                resolveIdsFromName(current, ctx.initData?.Lovs).includes(item.FilterByQuestionAnswerListId))
    );
};

// ### Function creates main part of object for Participants[0].Questionnaire.Answers[0].Items array

const createCurrentObject = (a: QuestionProps, val: FormikValueCustomProps, list: string | null) => ({
    AnswerFormat: a.QuestionAnswerFormatId ?? null,
    AnswerValueListCode: a.QuestionAnswerFormatId === 1 && list ? list : null,
    AnswerValueInt:
        a.QuestionAnswerFormatId === 3 && val && (typeof val === 'string' || typeof val === 'number')
            ? getNumberFromString(val)
            : null,
    AnswerValueText: a.QuestionAnswerFormatId === 2 && val && typeof val === 'string' ? val : null,
    AnswerValueDt: a.QuestionAnswerFormatId === 4 && val ? formatSimpleaDate(val as Date) : null,
    AnswerValueDecimal: a.QuestionAnswerFormatId === 5 && val ? parseFloat(val as string) : null,
    QuestionExternalId: a.ExternalId,
    ExternalId: uuidv4(),
});

// ### Function takes questions set and Formik values (key: value) and transforms them into desired
// object which is later sent to API

export const constructFormReply = (
    questions: Array<QuestionProps>,
    values: KeyValueProps,
    ctx: ContextProps,
    multiAnswerIndex?: string
) => {
    let currentVal: FormikValueCustomProps;
    let currentListCode: string | null | undefined;
    let currentSubquestions: Array<QuestionProps> = [];
    const result: Array<AnswerItemProps> = [];

    questions
        .map((a) => {
            if (a.IsMultiAnswer) {
                // ### Get all keys from Formik values (example: O_BLOOD_ENDO, O_CANCER)
                const formikKeys = Object.keys(values);
                const multiAnswerKeys: Array<string> = [];

                // ### Fill all duplicate keys which are equal (contain substring) to current a.Code
                formikKeys.map((fk: string) => {
                    if (fk.indexOf(a.Code) !== -1) {
                        multiAnswerKeys.push(fk);
                    }
                });

                // ### Iterate over "pure" (it has explicit IsMultiAnswer: true) multiAnswer answers
                multiAnswerKeys.map((current) => {
                    currentVal = values[current];
                    currentSubquestions = filterRelevantQuestions(a.Questions, currentVal, ctx);

                    // @TODO: Not DRY, Make separate function
                    if (currentVal && a.QuestionAnswerFormatId === 1) {
                        const questionAnswer = ctx.initData?.Lovs.QuestionAnswerLists.find(
                            (item: QuestionAnswerListItemProps) => item.Code === currentVal || item.NameDefault === currentVal
                        );
                        currentListCode = questionAnswer ? questionAnswer.Code : null;
                    } else {
                        currentListCode = null;
                    }

                    currentVal &&
                        result.push({
                            ...createCurrentObject(a, currentVal, currentListCode),
                            ChildAnswerItems:
                                currentSubquestions.length > 0
                                    ? constructFormReply(
                                          currentSubquestions,
                                          values,
                                          ctx,
                                          current.split('_MULTI_')[1]
                                      ).filter(Boolean)
                                    : [],
                        });
                });
            } else {
                // ### multiAnswerIndex tells us it is multiAnswer child
                currentVal = multiAnswerIndex ? values[`${a.Code}_MULTI_${multiAnswerIndex}`] : values[a.Code];
                currentSubquestions = filterRelevantQuestions(a.Questions, currentVal, ctx);

                if (currentVal && a.QuestionAnswerFormatId === 1) {
                    const questionAnswer = ctx.initData?.Lovs.QuestionAnswerLists.find(
                        (item: QuestionAnswerListItemProps) => item.Code === currentVal || item.NameDefault === currentVal
                    );
                    currentListCode = questionAnswer ? questionAnswer.Code : null;
                } else {
                    currentListCode = null;
                }

                // ### Push regular answers and children of multiAnswer answers
                currentVal &&
                    result.push({
                        ...createCurrentObject(a, currentVal, currentListCode),
                        ChildAnswerItems:
                            currentSubquestions.length > 0
                                ? constructFormReply(currentSubquestions, values, ctx, multiAnswerIndex).filter(Boolean)
                                : [],
                    });
            }
        })
        .filter(Boolean);

    return result;
};
