import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import styles from './StepConfirmData.module.scss';
import config from '../../../config.json';
import { AppContext } from '../../Layout/Layout';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import axios from 'axios';
import { runBackendValidations } from '../../../utils/runBackendValidations';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { setCompleted } from '../../../utils/setCompleted';
import { removeWhitespace } from '../../../utils/removeWhitespace';
import { formatSimpleaDate } from '../../../utils/formatSimpleaDate';
import { AddressProps } from '../../../types/model';
import dayjs from 'dayjs';

export const useStepConfirmData = () => {
    const ctx = useContext(AppContext);
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isPep, setIsPep] = useState<boolean>(false);
    const [showSecondCitizenship, setShowSecondCitizenship] = useState(false);
    const [bankIdUrl, setBankIdUrl] = useState<string | null>(null);
    const participant = ctx.formResult?.Participants[0];
    const issueDate = participant.Identification.IdCard?.IssuedDt;

    useEffect(() => {
        localStorage.setItem('currentStep', '10');
        ctx.setIsBackButtonVisible(false);
    }, []);

    useEffect(() => {
        if (participant) {
            setIsPep(participant.IsPep);
        }
    }, [ctx.formResult]);

    useEffect(() => {
        if (isSubmitting && !isUpdating) {
            if (parseInt(localStorage.getItem('completedStep') || '0') >= 10) {
                setIsSubmitting(false);
                setIsUpdating(false);
                navigate(`/${config.SLUGS.STEP10PROCESSING_SLUG}/`, {
                    state: { ExternalId: ctx.formResult.ExternalId },
                });
            } else {
                runBackendValidations(
                    'validate-finish',
                    ctx,
                    null,
                    () => {
                        axios
                            .post(`${getBaseUrl()}api/online/approvals/confirm-bank-id-data`, {
                                jsonOnlineModel: JSON.stringify(ctx.formResult),
                            })
                            .then((response) => {
                                if (response.status == 200) {
                                    setCompleted(10);
                                    navigate(`/${config.SLUGS.STEP10PROCESSING_SLUG}/`, {
                                        state: { ExternalId: ctx.formResult.ExternalId },
                                    });
                                }
                            })
                            .catch((err) => console.error(err))
                            .finally(() => {
                                setIsSubmitting(false);
                                setIsUpdating(false);
                            });
                    },
                    () => {
                        setIsSubmitting(false);
                        setIsUpdating(false);
                    }
                );
            }
        }
    }, [ctx.formResult, isSubmitting, isUpdating]);

    useEffect(() => {
        // ### Set url for bankID button
        if (!bankIdUrl) {
            const baseUrl = window.location.origin;
            const url = ctx.initData?.OnlineConfigurationData.BankIdApprovalUrl;
            const targetUrl = baseUrl + '/' + config.SLUGS.STEP_BANK_ID_SLUG;
            const externalId = ctx.formResult.ExternalId;
            const replaceTargetUrl = '{#returnUrl}';
            const replaceTargetApprover = '{#approverId}';

            if (url && externalId) {
                setBankIdUrl(url?.replace(replaceTargetUrl, targetUrl).replace(replaceTargetApprover, externalId));
            }
        }
    }, [ctx, bankIdUrl]);

    const handleIsPepClick = () => {
        setIsPep(!isPep);
    };

    const renderAddress = (address: AddressProps | null) => {
        return (
            <div className={cn(styles.boxValue, styles.boxValueWider)}>
                {address?.StreetName} {address?.HouseNo}, {address?.CityName}, {address?.Zip},{' '}
                {ctx.initData &&
                    ctx.initData?.Lovs.Countries.find((country) => country.Code === address?.Country)?.NameTranslated}
            </div>
        );
    };

    // ### Function makes array Countries of object for Select.tsx component (react-select)
    const constructSelectCountriesOptions = () => {
        const arr: Array<{ label: string; value: string }> = [];
        ctx.initData?.Lovs.Countries.forEach((country) => {
            arr.push({ label: country.NameTranslated, value: country.Code });
        });
        return arr;
    };

    // ### Function makes array Bank Codes of object for Select.tsx component (react-select)
    const constructSelectBankCodesOptions = () => {
        const arr: Array<{ label: string; value: string }> = [];
        ctx.initData?.Banks.forEach((bank) => {
            arr.push({ label: bank.BankCode + ' - ' + bank.Name, value: bank.BankCode });
        });
        return arr;
    };
    // TODO: typing
    const onSubmit = useCallback(
        (values: any) => {
            const prefixCountryId = ctx.initData?.InternationalPhonePrefixes.find(
                (prefix) => prefix.PhonePrefix === values.phonePrefix
            )?.CountryId;

            const countryCode =
                ctx.initData?.Lovs?.Countries?.find((prefix) => prefix.Id === prefixCountryId)?.Code || '';

            setIsUpdating(true);
            setIsSubmitting(true);
            ctx.setFormResult((prevState) => {
                const [participant] = prevState.Participants;

                const hasIssueDate = issueDate && issueDate !== null;

                const result = {
                    ...prevState,
                    Participants: [
                        {
                            ...participant,
                            Contact: {
                                ...participant.Contact,
                                Email: values.email,
                                Phone: values.phone.replaceAll(' ', ''),
                                PhonePrefix: {
                                    ...participant.Contact.PhonePrefix,
                                    CountryCode: countryCode,
                                    Prefix: values.phonePrefix,
                                },
                            },
                            Identification: {
                                ...participant.Identification,
                                PersonalIdentificationDetail: participant.Identification.PersonalIdentificationDetail
                                    ? {
                                          ...participant.Identification.PersonalIdentificationDetail,
                                          TitleBefore: values.titleBefore.length !== 0 ? values.titleBefore : null,
                                          TitleAfter: values.titleAfter.length !== 0 ? values.titleAfter : null,
                                          Birthplace: values.birthplace.length !== 0 ? values.birthplace : null,
                                          BirthCountry: values.birthCountry,
                                          Citizenship: values.citizenship,
                                          SecondCitizenship: values.secondCitizenship,
                                      }
                                    : null,
                                IdCard: participant.Identification.IdCard
                                    ? {
                                          ...participant.Identification.IdCard,
                                          Issuer: values.issuer.length !== 0 ? values.issuer : null,
                                          IssuedDt: hasIssueDate
                                              ? issueDate
                                              : values.issueDate
                                              ? formatSimpleaDate(dayjs(values.issueDate).toDate())
                                              : null,
                                      }
                                    : null,
                                BankAccount: participant.Identification.BankAccount
                                    ? {
                                          ...participant.Identification.BankAccount,
                                          Prefix:
                                              values.bankAccountPrefix.length !== 0
                                                  ? removeWhitespace(values.bankAccountPrefix)
                                                  : null,
                                          BankAccountNo:
                                              values.bankAccountNo.length !== 0
                                                  ? removeWhitespace(values.bankAccountNo)
                                                  : null,
                                          Code: values.bankAccountCode,
                                          // kontola ibanu zda odpovida cislu uctu
                                          // pokud ne, iban vymazat (na serveru se pak vygeneruje odpovidajici iban)
                                          Iban:
                                              participant.Identification.BankAccount.Iban?.includes(
                                                  removeWhitespace(values.bankAccountPrefix)
                                              ) &&
                                              participant.Identification.BankAccount.Iban?.includes(
                                                  removeWhitespace(values.bankAccountNo)
                                              ) &&
                                              participant.Identification.BankAccount.Iban?.includes(
                                                  values.bankAccountCode ?? ''
                                              )
                                                  ? participant.Identification.BankAccount.Iban
                                                  : null,
                                      }
                                    : {
                                          Prefix:
                                              values.bankAccountPrefix.length !== 0
                                                  ? removeWhitespace(values.bankAccountPrefix)
                                                  : null,
                                          BankAccountNo:
                                              values.bankAccountNo.length !== 0
                                                  ? removeWhitespace(values.bankAccountNo)
                                                  : null,
                                          Code: values.bankAccountCode,
                                          Iban: null,
                                      },
                            },
                            IsPep: isPep,
                        },
                    ],
                    Settings: {
                        ...prevState.Settings,
                        LastStepName: config.STEP_NAMES.STEP_CONFIRM_DATA,
                    },
                };
                return result;
            });
            setIsUpdating(false);
        },
        [ctx.initData?.Lovs, ctx.initData?.InternationalPhonePrefixes]
    );

    const prefixes = ctx.initData?.InternationalPhonePrefixes.map((prefix) => prefix.PhonePrefix);

    const isSupportedPrefix = prefixes?.includes(participant.Contact.PhonePrefix.Prefix);

    const isPhoneLoading = !(ctx.initData && participant.Contact.Phone);

    const phoneNumber =
        participant.Contact.Phone && participant.Contact.PhonePrefix
            ? `${participant.Contact.PhonePrefix.Prefix} ${formatPhoneNumber(participant.Contact.Phone || '')}`
            : '';

    const hasBirthplace =
        participant.Identification.PersonalIdentificationDetail?.Birthplace &&
        participant.Identification.PersonalIdentificationDetail?.Birthplace.length > 0;

    const hasIssuer = participant.Identification.IdCard?.Issuer && participant.Identification.IdCard?.Issuer.length > 0;
    const idType =
        ctx.initData &&
        ctx.initData?.Lovs.IdCardTypes.find((id) => id.Id === participant.Identification.IdCard?.Kind)?.NameTranslated;

    const hasBirthCountry =
        participant.Identification.PersonalIdentificationDetail?.BirthCountry &&
        participant.Identification.PersonalIdentificationDetail?.BirthCountry.length > 0;

    const birthCountry =
        ctx.initData &&
        ctx.initData?.Lovs.Countries.find(
            (country) => country.Code === participant.Identification.PersonalIdentificationDetail?.BirthCountry
        )?.NameTranslated;

    const hasCitizenship =
        participant.Identification.PersonalIdentificationDetail?.Citizenship &&
        participant.Identification.PersonalIdentificationDetail?.Citizenship.length > 0;

    const citizenship =
        ctx.initData &&
        ctx.initData?.Lovs.Countries.find(
            (country) => country.Code === participant.Identification.PersonalIdentificationDetail?.Citizenship
        )?.NameTranslated;

    const hasSecondCitizenship =
        participant.Identification.PersonalIdentificationDetail?.SecondCitizenship &&
        participant.Identification.PersonalIdentificationDetail?.SecondCitizenship.length > 0;

    const secondCitizenship =
        ctx.initData &&
        ctx.initData?.Lovs.Countries.find(
            (country) => country.Code === participant.Identification.PersonalIdentificationDetail?.SecondCitizenship
        )?.NameTranslated;

    return {
        hasBirthplace,
        hasIssuer,
        isSubmitting,
        participant,
        isSupportedPrefix,
        submitted,
        setSubmitted,
        onSubmit,
        isPhoneLoading,
        phoneNumber,
        constructSelectCountriesOptions,
        showSecondCitizenship,
        setShowSecondCitizenship,
        renderAddress,
        isPep,
        handleIsPepClick,
        constructSelectBankCodesOptions,
        bankIdUrl,
        ctx,
        idType,
        hasBirthCountry,
        birthCountry,
        hasCitizenship,
        citizenship,
        hasSecondCitizenship,
        secondCitizenship,
        issueDate,
    };
};
