import { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import styles from './Step0.module.scss';
import config from '../../../config.json';
import cms from '../../../data/cms.json';
import calculatorImg from '../../../images/calculator.png';
import houseImg from '../../../images/house.png';
import { Step } from '../../Step/Step';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { Box } from '../../Box/Box';
import { Title } from '../../Title/Title';
import { Paragraph } from '../../Paragraph/Paragraph';
import { Button } from '../../Button/Button';
import { AppContext } from '../../Layout/Layout';
import { setCompleted } from '../../../utils/setCompleted';
import { ModelProps } from '../../../types/model';
import { CustomLocation } from '../../../types/common';
import { Loader } from '../../Loader/Loader';

export interface Step0Props {
    className?: string;
}

export type InsuranceType = 'INCOME' | 'MORTGAGE' | null;

interface ItemProps {
    title: string;
    codename: string;
    text: string;
    image: string;
}

export const Step0 = ({ className }: Step0Props): ReactElement | null => {
    const ctx = useContext(AppContext);
    const location: CustomLocation = useLocation();
    const navigate = useNavigate();
    const [isRedirecting, setIsRedirecting] = useState(false);

    useEffect(() => {
        localStorage.setItem('currentStep', '0');
        ctx.setIsBackButtonVisible(false);
    }, []);

    useEffect(() => {
        const search = location.search;
        if (location.key && (search.substring(1).includes('prijem') || search.substring(1).includes('hypoteka'))) {
            setIsRedirecting(true);
        }
    }, [location]);

    useEffect(() => {
        if (ctx.initData && ctx.initDataQuestionaire && isRedirecting) {
            handleClick(location.search.substring(1).includes('prijem') ? 'INCOME' : 'MORTGAGE');
        }
    }, [ctx.initData, ctx.initDataQuestionaire]);

    const handleClick = (insuranceType: InsuranceType) => {
        // v locatin muze prijit ExternalId s kterym se ma zalozit pojistka
        const state = location?.state;
        const externalId = state?.ExternalId; // OnlinePolicy.ExternalId
        // ### Context is set to store insurance type and form model,
        // which is updated in the end of each step
        ctx.setInsuranceType(insuranceType);
        if (ctx.initData) {
            ctx.setFormResult((prevState: ModelProps) => ({
                ...prevState,
                ExternalId: externalId ?? uuidv4(),
                Participants: [
                    {
                        ...prevState.Participants[0],
                        ExternalId: uuidv4(),
                        ClientExternalId: uuidv4(),
                        Contact: {
                            ...prevState.Participants[0].Contact,
                            PhonePrefix: {
                                ...prevState.Participants[0].Contact.PhonePrefix,
                                ExternalId:
                                    (ctx.initData &&
                                        ctx.initData?.InternationalPhonePrefixes.find(
                                            (prefix) => prefix.CountryId === 1
                                        )?.ExternalId) ??
                                    null,
                            },
                        },
                        Roles: [
                            {
                                ...prevState.Participants[0].Roles[0],
                                Kind:
                                    (ctx.initData &&
                                        ctx.initData?.Lovs.RoleTypes.find((role) => role.Code === config.ROLES.INS)
                                            ?.Id) ??
                                    0,
                                ExternalId: uuidv4(),
                            },
                            {
                                ...prevState.Participants[0].Roles[1],
                                Kind:
                                    (ctx.initData &&
                                        ctx.initData?.Lovs.RoleTypes.find((role) => role.Code === config.ROLES.OWN)
                                            ?.Id) ??
                                    0,
                                ExternalId: uuidv4(),
                            },
                        ],
                        Questionnaire: {
                            ...prevState.Participants[0].Questionnaire,
                            Answers: [],
                            Code: ctx.initDataQuestionaire?.Questionnaires.Code ?? null,
                            ExternalId: ctx.initDataQuestionaire?.Questionnaires.ExternalId ?? null,
                            Name: ctx.initDataQuestionaire?.Questionnaires.NameDefault ?? null,
                            QuestionnaireKind: ctx.initDataQuestionaire?.Questionnaires.QuestionnaireTypeId ?? null,
                            Version: ctx.initDataQuestionaire?.Questionnaires.Version ?? null,
                        },
                    },
                ],
                ProductVersion: {
                    ...prevState.ProductVersion,
                    Code: ctx.initData?.Product.ProductVersionCode ?? null,
                    Name: ctx.initData?.Product.ProductVersionNameDefault ?? null,
                    Product: {
                        ...prevState.ProductVersion.Product,
                        FamilyName: ctx.initData?.Product.ProductNameDefault ?? null,
                        Code: ctx.initData?.Product.ProductCode ?? null,
                    },
                    Limits: {
                        ...prevState.ProductVersion.Limits,
                        ChildAgeMax: ctx.initData?.Product.InitDataProductVersionLimit.ChildAgeMax ?? null,
                        InsurancePeriodMin:
                            ctx.initData?.Product.InitDataProductVersionLimit.InsurancePeriodMin ?? null,
                        LegalRepresentativeDemandShorteningInMonths:
                            ctx.initData?.Product.InitDataProductVersionLimit
                                .LegalRepresentativeDemandShorteningInMonths ?? null,
                        OwnerAgeMin: ctx.initData?.Product.InitDataProductVersionLimit.OwnerAgeMin ?? null,
                    },
                },
            }));
        }
        localStorage.setItem('localData', JSON.stringify(ctx.formResult));
        setCompleted(0);
        navigate(insuranceType === 'INCOME' ? `/${config.SLUGS.STEP1A_SLUG}/` : `/${config.SLUGS.STEP1B_SLUG}/`);
    };

    if (isRedirecting) {
        return (
            <Step title={cms.step0.title} text={cms.step0.text} className={cn(styles.wrapper, className)}>
                <Loader size="medium" />
                <ErrorForm />
            </Step>
        );
    }

    return (
        <div className={cn(styles.wrapper, className)}>
            <Step title={cms.step0.title} text={cms.step0.text}>
                <ul className={styles.list}>
                    {cms.step0.items.map((item: ItemProps) => (
                        <li key={item.title}>
                            <Box className={styles.box}>
                                <img
                                    src={item.image === 'calculator' ? calculatorImg : houseImg}
                                    alt={item.title}
                                    className={cn(styles.image, 'mb-3')}
                                />

                                <Title tag="h3" className="mb-3">
                                    {item.title}
                                </Title>

                                <Paragraph small>{item.text}</Paragraph>

                                <Button
                                    className={styles.button}
                                    variant="primary"
                                    loading={!(ctx.initData && ctx.initDataQuestionaire)}
                                    onClick={() => {
                                        handleClick(item.codename as InsuranceType);
                                    }}
                                >
                                    {cms.common.herewegoButton}
                                </Button>
                            </Box>
                        </li>
                    ))}
                </ul>
            </Step>

            <ErrorForm />
        </div>
    );
};
