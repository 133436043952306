import { ReactElement } from 'react';
import parse from 'html-react-parser';
import cn from 'classnames';

import styles from './Label.module.scss';

import { Tooltip } from '../Tooltip/Tooltip';

interface LabelProps {
    children: string;
    htmlFor?: string;
    description?: string;
    className?: string;
    help?: string;
    onClick?: () => void;
    onHelpClick?: () => void;
}

export const Label = ({
    children,
    description,
    className,
    onClick,
    help,
    onHelpClick,
}: LabelProps): ReactElement | null => {
    return (
        <label className={cn(styles.wrapper, className)} onClick={onClick}>
            {parse(children)}

            {help && <Tooltip className={styles.tooltip} text={help} />}

            {onHelpClick && (
                <div className={cn(styles.help, 'help')} onClick={onHelpClick}>
                    ?
                </div>
            )}

            {description && <p className={styles.description}>{description}</p>}
        </label>
    );
};
