import { ReactElement, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Field, Formik } from 'formik';
import cn from 'classnames';

import styles from './Step9a.module.scss';
import cms from '../../../data/cms.json';
import { fetchResultData } from '../../../utils/externalDataHandling';
import { cellPhoneNumberValidationSchema } from '../../../utils/validations';

import { Step } from '../../Step/Step';
import { Box } from '../../Box/Box';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { Label } from '../../Label/Label';
import { Textarea } from '../../Textarea/Textarea';
import { Button } from '../../Button/Button';
import { ValuesProps } from '../Step6/Step6';
import PhoneNumberInputNew from '../../PhoneNumberInput/PhoneNumberInput';
import { AppContext } from '../../Layout/Layout';
import { Title } from '../../Title/Title';
import { Paragraph } from '../../Paragraph/Paragraph';
import { CustomLocation } from '../../../types/common';
import { triggerGtmEvent } from '../../../utils/triggerGtmEvent';
import { getPhonePrefix } from '../../../utils/getPhonePrefix';

interface Step9aProps {
    className?: string;
}

export const Step9a = ({ className }: Step9aProps): ReactElement | null => {
    const ctx = useContext(AppContext);
    const location: CustomLocation = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resultText, setResultText] = useState<string | null>(null);

    useEffect(() => {
        ctx.setIsBackButtonVisible(false);
        triggerGtmEvent('rejected');
    }, []);

    useEffect(() => {
        const state = location.state;
        const resultText = state ? state.ResultText : null;
        setResultText(resultText);
    }, [location]);

    return (
        <div className={cn(styles.wrapper, className)}>
            <Formik
                validateOnBlur={false}
                validateOnChange={submitted}
                validateOnMount={false}
                initialValues={{
                    phone: ctx.formResult.Participants[0].Contact.Phone ?? '',
                    phonePrefix: ctx.formResult.Participants[0].Contact.PhonePrefix.Prefix ?? '',
                    message: '',
                }}
                validationSchema={cellPhoneNumberValidationSchema}
                onSubmit={(values) => {
                    setLoading(true);
                    const storageData = localStorage.getItem('localData');

                    if (storageData) {
                        const body = {
                            PhonePrefixExternalId: getPhonePrefix(values.phonePrefix, ctx.initData)?.ExternalId,
                            PhoneNumber: values.phone.toString(),
                            OnlinePolicyJson: storageData,
                            ClientNote: values.message,
                        };

                        const result = fetchResultData('api/online/support/save-phone-contact', body);
                        result
                            .then(() => {
                                setSuccess(true);
                                setLoading(false);
                            })
                            .catch((err) => {
                                console.error(err);
                                setError(true);
                                setLoading(false);
                            });
                    } else {
                        setError(true);
                    }
                }}
            >
                {({ errors, handleSubmit }: ValuesProps) => (
                    <Step title={cms.step9a.title} text={resultText ?? cms.step9a.text}>
                        <Box innerWrapper>
                            {error ? (
                                <div className={styles.result}>
                                    <Title tag="h2" className={cn('mb-3', styles.resultTitle)}>
                                        {cms.common.phoneConsultationErrorTitle}
                                    </Title>

                                    <Paragraph className={styles.resultText}>
                                        {cms.common.phoneConsultationErrorText}
                                    </Paragraph>

                                    <Button
                                        variant="primary"
                                        loading={loading}
                                        onClick={() => {
                                            setError(false);
                                            ctx.setPopupVisible(false);
                                            localStorage.clear();
                                            navigate('/');
                                            navigate(0);
                                        }}
                                    >
                                        {cms.common.closeButton}
                                    </Button>
                                </div>
                            ) : success ? (
                                <div className={styles.result}>
                                    <Title tag="h2" className={cn('mb-3', styles.resultTitle)}>
                                        {cms.common.phoneConsultationSuccessTitle}
                                    </Title>

                                    <Paragraph className={styles.resultText}>
                                        {cms.common.phoneConsultationSuccessText}
                                    </Paragraph>

                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            setSuccess(false);
                                            ctx.setPopupVisible(false);
                                            localStorage.clear();
                                            navigate('/');
                                            navigate(0);
                                        }}
                                    >
                                        {cms.common.closeButton}
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    {resultText ? ( // odstranit poznámku, pokdu je klient vyhodnocen např., že je pep, existující kl. apod.
                                        <></>
                                    ) : (
                                        <>
                                            <Label htmlFor="message">
                                                Pokud chcete, abychom se na to ještě podívali, sdělte nám více o vašem
                                                zdravotním stavu. Dáme vám vědět, jestli bychom pro vás nemohli něco
                                                vymyslet
                                            </Label>

                                            <Field
                                                className={cn(styles.textarea, 'mb-6')}
                                                name="message"
                                                id="message"
                                                maxLenght={1000}
                                                component={Textarea}
                                            />
                                        </>
                                    )}

                                    <div className="mb-6">
                                        <PhoneNumberInputNew
                                            className="field-large"
                                            namePhone="phone"
                                            namePhonePrefix="phonePrefix"
                                            label="Vyplňte vaše číslo a my se vám ozveme"
                                            showEditIcon
                                            error={errors.phone}
                                            optional={false}
                                        />
                                    </div>

                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            triggerGtmEvent('rejected_go');
                                            setSubmitted(true);
                                            handleSubmit();
                                        }}
                                    >
                                        {cms.common.submitButton}
                                    </Button>
                                </>
                            )}
                        </Box>
                        <ErrorForm />
                    </Step>
                )}
            </Formik>
        </div>
    );
};
