import { ContextProps } from '../components/Layout/Layout';
import cms from '../data/cms.json';

// ### Function returns title for step2 page

export const resolvePageTitle = (ctx: ContextProps) => {
    const titleFallback = cms.step2.titleFallback;
    const titleStart = cms.step2.title;
    const age = ctx.formResult.Participants[0].Identification.CurrentAge;
    const sexId = ctx.formResult.Participants[0].Identification.Sex;
    const sex = sexId ? (sexId === 2 ? cms.common.pageTitleMan : cms.common.pageTitleWoman) : null;
    const agePostfix = sexId ? (sexId === 2 ? cms.common.pageTitleAgeMan : cms.common.pageTitleAgeWoman) : null;

    if (age && sex) {
        return `${titleStart} ${age}${agePostfix} ${sex}`;
    }

    return titleFallback;
};
