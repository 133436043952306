import { Fragment, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import cn from 'classnames';

import styles from './Step8.module.scss';
import cms from '../../../data/cms.json';
import config from '../../../config.json';
import { QuestionProps } from '../../../types/initData';
import { resolveFieldText, resolveFieldType } from '../../../utils/externalDataHandling';
import { renderToggleField } from '../../../utils/renderToggleField';
import { findQuestionHeaderId, findQuestionRange } from '../../../utils/formFiltering';
import { renderQuestionTree } from '../../../utils/renderQuestionTree';
import { updateGlobalFormState } from '../../../utils/updateGlobalFormState';
import { scrollToError } from '../../../utils/scrollToError';
import { getFilledData } from '../../../utils/getFilledData';
import { getInitialValues } from '../../../utils/getInitialValues';
import { setCompleted } from '../../../utils/setCompleted';
import { runBackendValidations } from '../../../utils/runBackendValidations';

import { Step } from '../../Step/Step';
import { Box } from '../../Box/Box';
import { Button } from '../../Button/Button';
import { Grid } from '../../Grid/Grid';
import { Label } from '../../Label/Label';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { FormItemError } from '../../FormItemError/FormItemError';
import { ValuesProps } from '../Step6/Step6';
import { AppContext } from '../../Layout/Layout';
import { Loader } from '../../Loader/Loader';
import { KeyValueProps } from '../../../types/common';

interface Step8Props {
    className?: string;
}

export const Step8 = ({ className }: Step8Props): ReactElement | null => {
    const stepCodeName = 'Step8';
    const navigate = useNavigate();
    const ctx = useContext(AppContext);
    const formikRef = useRef<FormikProps<any>>(null);
    const [questionsRange, setQuestionsRange] = useState<Array<number>>();
    const [currentStepId, setCurrentStepId] = useState<null | number>(null);
    // const [promoVisible, setPromoVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [initialValues, setInitialValues] = useState<KeyValueProps | undefined>(undefined);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isBackendValidating, setIsBackendValidating] = useState(false);
    const initDataQ = ctx.initDataQuestionaire?.Questionnaires.Questions;

    useEffect(() => {
        ctx.setIsBackButtonVisible(true);
    }, []);

    useEffect(() => {
        if (questionsRange && initDataQ) {
            const data = getFilledData(questionsRange, ctx);
            data && Object.keys(data).length === 0 ? setInitialValues(undefined) : setInitialValues(data);
        }
    }, [questionsRange, initDataQ]);

    useEffect(() => {
        const step = findQuestionHeaderId(stepCodeName, ctx.initData?.Lovs?.QuestionHeaders);
        step && setCurrentStepId(step.Id);
    }, [ctx.initData?.Lovs]);

    useEffect(() => {
        if (currentStepId) {
            const range = findQuestionRange(currentStepId, initDataQ);
            range && setQuestionsRange(range);
        }
    }, [currentStepId, initDataQ]);

    useEffect(() => {
        if (isBackendValidating) {
            runBackendValidations(
                'validate-questionnaires',
                ctx,
                null,
                () => {
                    setIsSubmitting(false);
                    setIsBackendValidating(false);
                    localStorage.setItem('localData', JSON.stringify(ctx.formResult));
                    navigate(`/${config.SLUGS.STEP9LOADING_SLUG}/`);
                },
                () => {
                    setIsSubmitting(false);
                    setIsBackendValidating(false);
                }
            );
        }
    }, [ctx.formResult, isBackendValidating]);

    useEffect(() => {
        const values = formikRef?.current?.values;

        if (ctx.isSaving && values && questionsRange && ctx.formResult) {
            updateGlobalFormState(initDataQ?.slice(...questionsRange) || [], values, ctx, config.STEP_NAMES.STEP8);
            ctx.setIsSaving(false);
        }
    }, [ctx.isSaving]);

    return (
        <Step title={cms.step8.title} text={cms.step8.text} className={cn(styles.wrapper, className)}>
            {questionsRange && initDataQ && ctx.initData?.Lovs ? (
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues || getInitialValues(initDataQ.slice(...questionsRange)) || {}}
                    onSubmit={(values) => {
                        setIsSubmitting(true);
                        if (ctx.formResult) {
                            updateGlobalFormState(
                                initDataQ.slice(...questionsRange),
                                values,
                                ctx,
                                config.STEP_NAMES.STEP8,
                                ctx.insuranceType === config.MORTGAGE_CODE
                            );
                            setIsBackendValidating(true);
                        }
                    }}
                    validateOnBlur={false}
                    validateOnChange={hasSubmitted}
                    validateOnMount={false}
                    enableReinitialize
                >
                    {({ values, errors, touched, handleSubmit, setFieldValue }: ValuesProps) => (
                        <Form className={styles.form}>
                            <Box className="mb-6 mb-3-m question-box" loading={isSubmitting}>
                                {initDataQ &&
                                    ctx.initData?.Lovs &&
                                    questionsRange &&
                                    initDataQ.slice(...questionsRange).map((formItem: QuestionProps, i: number) => (
                                        <Fragment key={`fragment-${i}`}>
                                            <div className="question-item">
                                                <Label htmlFor={formItem.Code} help={formItem.Help}>
                                                    {formItem.Name}
                                                </Label>

                                                <Grid cols="auto">
                                                    {formItem.PossibleAnswersIds.map((a: number, i: number) =>
                                                        renderToggleField(
                                                            formItem.Code,
                                                            `${formItem.Code}-${i}`,
                                                            resolveFieldType(
                                                                formItem?.QuestionAnswerFormatId,
                                                                ctx.initData?.Lovs
                                                            ),
                                                            resolveFieldText(a, ctx.initData?.Lovs) || '',
                                                            '',
                                                            false,
                                                            '',
                                                            false,
                                                            errors[formItem.Code] && touched[formItem.Code]
                                                        )
                                                    )}
                                                </Grid>

                                                {errors[formItem.Code] && touched[formItem.Code] && (
                                                    <FormItemError text={errors[formItem.Code]} />
                                                )}
                                            </div>

                                            {ctx.initData?.Lovs &&
                                                renderQuestionTree(
                                                    formItem,
                                                    values,
                                                    ctx.initData?.Lovs,
                                                    errors,
                                                    ctx,
                                                    setFieldValue
                                                )}
                                        </Fragment>
                                    ))}
                                {ctx.insuranceType === config.MORTGAGE_CODE && (
                                    <>
                                        <div className="question-item">
                                            <>
                                                <Label htmlFor="incomeSource">Jaký je váš hlavní zdroj příjmů</Label>
                                                <Grid cols="auto">
                                                    {renderToggleField(
                                                        'incomeSource',
                                                        'incomeSource_EMPLOYEE',
                                                        'radio',
                                                        config.QUESTIONNAIRE.EMPLOYEE,
                                                        '',
                                                        false,
                                                        '',
                                                        false,
                                                        errors['incomeSource']
                                                    )}
                                                    {renderToggleField(
                                                        'incomeSource',
                                                        'incomeSource_SELFEMPLOYEE',
                                                        'radio',
                                                        config.QUESTIONNAIRE.SELF_EMPLOYED,
                                                        '',
                                                        false,
                                                        '',
                                                        false,
                                                        errors['incomeSource']
                                                    )}
                                                </Grid>

                                                {errors['incomeSource'] && (
                                                    <FormItemError text={errors['incomeSource']} />
                                                )}
                                            </>
                                        </div>

                                        {values['incomeSource'] === config.QUESTIONNAIRE.SELF_EMPLOYED && (
                                            <div className="question-item">
                                                <>
                                                    <Label htmlFor="insured">
                                                        Máte dobrovolné nemocenské pojištění?
                                                    </Label>
                                                    <Grid cols="auto">
                                                        {renderToggleField(
                                                            'hasHealthInsurance',
                                                            'hasHealthInsurance_YES',
                                                            'radio',
                                                            'Ano',
                                                            '',
                                                            false,
                                                            '',
                                                            false,
                                                            errors['hasHealthInsurance']
                                                        )}
                                                        {renderToggleField(
                                                            'hasHealthInsurance',
                                                            'hasHealthInsurance_NO',
                                                            'radio',
                                                            'Ne',
                                                            '',
                                                            false,
                                                            '',
                                                            false,
                                                            errors['hasHealthInsurance']
                                                        )}
                                                    </Grid>

                                                    {errors['hasHealthInsurance'] && (
                                                        <FormItemError text={errors['hasHealthInsurance']} />
                                                    )}
                                                </>
                                            </div>
                                        )}
                                    </>
                                )}
                                {/* <div className="question-item">
                                    <Label
                                        className={cn(styles.promoLabel)}
                                        onClick={() => setPromoVisible(!promoVisible)}
                                    >
                                        {cms.common.usePromoCodeText}
                                    </Label>
                                    {promoVisible && (
                                        <Field
                                            className={styles.promoField}
                                            name="promocode"
                                            component={TextInput}
                                            autoFocus
                                        />
                                    )}
                                </div> */}
                            </Box>

                            <Button
                                variant="primary"
                                loading={isSubmitting}
                                onClick={() => {
                                    setHasSubmitted(true);
                                    handleSubmit();
                                    scrollToError();
                                }}
                            >
                                {cms.common.continueButton}
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Loader size="medium" />
            )}

            <ErrorForm />
        </Step>
    );
};
