import { ReactElement } from 'react';
import cn from 'classnames';

import config from '../../config.json';
import { ReactComponent as BasketIcon } from '../../images/vectors/basket.svg';
import styles from './CheckoutButton.module.scss';
import { formatPrice } from '../../utils/formatPrice';

import { Button } from '../Button/Button';

export interface CheckoutButtonProps {
    price: number;
    itemsCount: number;
    onClick: () => void;
    className?: string;
}

export const CheckoutButton = ({ onClick, price, itemsCount, className }: CheckoutButtonProps): ReactElement | null => {
    return (
        <Button className={cn(styles.wrapper, className)} onClick={onClick} variant="tertiary" small outlined>
            <>
                <div className={styles.basket}>
                    <BasketIcon />
                    <div className={styles.count}>{itemsCount}</div>
                </div>
                {formatPrice(price)} {config.CURRENCIES.CZK}
            </>
        </Button>
    );
};
