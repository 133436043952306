import { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import cms from '../../../data/cms.json';
import config from '../../../config.json';
import styles from './Step9loading.module.scss';
import { triggerGtmEvent } from '../../../utils/triggerGtmEvent';

import { Step } from '../../Step/Step';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { AppContext } from '../../Layout/Layout';
import { LoadingCountdown } from '../../LoadingCountdown/LoadingCountdown';
import { CustomLocation } from '../../../types/common';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import axios from 'axios';
import { parseJson } from '../../../utils/helpers';
import { setCompleted } from '../../../utils/setCompleted';
import CountdownAlert from './CountdownAlert';

let loopTimer: ReturnType<typeof setTimeout>;

interface Step9loadingProps {
    className?: string;
}

interface FormResultProps {
    ResultType: number;
    JsonOnlineModel: string;
}

export const Step9loading = ({ className }: Step9loadingProps): ReactElement | null => {
    const [loading, setLoading] = useState(true);
    const [formError, setFormError] = useState(false);
    const [result, setResult] = useState<FormResultProps | null>(null);
    const [processCode, setProcessCode] = useState(localStorage.getItem('processCode'));
    const ctx = useContext(AppContext);
    const navigate = useNavigate();
    const location: CustomLocation = useLocation();

    useEffect(() => {
        ctx.setIsBackButtonVisible(false);
    }, []);

    useEffect(() => {
        const localData = localStorage.getItem('localData');
        if (localData && !processCode) {
            axios
                .post(`${getBaseUrl()}api/online/questionnaires/evaluate-no-wait`, parseJson(localData))
                .then((response) => response.data)
                .then((result: { VerificationCode: string }) => {
                    // console.log('result', result);
                    setProcessCode(result.VerificationCode);
                    localStorage.setItem('processCode', result.VerificationCode);
                })
                .catch(() => {
                    setFormError(true);
                    // console.error(error);
                    triggerGtmEvent('error');
                });
        }

        if (!localData) {
            setFormError(true);
        }
    }, []);

    const loadEvaluateData = () => {
        axios
            .post(`${getBaseUrl()}api/online/questionnaires/evaluate-get-ready-data`, {
                verificationCode: processCode,
                onlinePolicyExternalId: ctx.formResult.ExternalId,
            })
            .then((res) => {
                const data = res.data;
                if (data) {
                    if (data.ResultType && data.JsonOnlineModel) {
                        setResult(data);
                        ctx.setFormResult(JSON.parse(data.JsonOnlineModel));
                        localStorage.setItem('localData', data.JsonOnlineModel);
                        setLoading(false);
                    } else {
                        setFormError(true);
                        // console.error('bad result type', data);
                        triggerGtmEvent('error');
                    }
                }
            })
            .catch(() => {
                setFormError(true);
                // console.error(error);
                triggerGtmEvent('error');
            });
    };

    const checkEvaluateIsReady = () => {
        if (ctx.formResult.ExternalId) {
            axios
                .post(`${getBaseUrl()}api/online/questionnaires/evaluate-is-ready`, {
                    verificationCode: processCode,
                    onlinePolicyExternalId: ctx.formResult.ExternalId,
                })
                .then((response) => response.data)
                .then((result: number) => {
                    // console.log('result', result);
                    // Not ready
                    if (result === 1) {
                        startLoop();
                    }
                    // Evaluation completed
                    if (result === 2) {
                        loadEvaluateData();
                    }
                    // Expired
                    if (result === 3) {
                        setFormError(true);
                        triggerGtmEvent('error');
                    }
                })
                .catch(() => {
                    setFormError(true);
                    // console.error(error);
                    triggerGtmEvent('error');
                });
        }
    };

    const startLoop = () => {
        clearTimeout(loopTimer);
        loopTimer = setTimeout(checkEvaluateIsReady, 3000);
    };

    useEffect(() => {
        if (processCode) {
            startLoop();
        }
    }, [processCode]);

    useEffect(() => {
        if (loading) {
            triggerGtmEvent('loading');
        }
    }, [loading]);

    useEffect(() => {
        if (!loading && result?.JsonOnlineModel && result?.ResultType && !location?.state?.hasExclusions) {
            triggerGtmEvent('loading_end');
            if (result?.ResultType === 1) {
                // ### Ok, contract was approved without changes
                if (localStorage.getItem('evaluateErrorPageCode')) {
                    localStorage.setItem('evaluateErrorPageCode', '');
                }
                navigate(`/${config.SLUGS.STEP9_SLUG}/`);
                setCompleted(8);
            } else if (result?.ResultType === 2) {
                // ### Ok, but price has changed or exclusions have been added
                localStorage.setItem('evaluateErrorPageCode', 'STEP9C');
                localStorage.setItem('completedStep', '8');
                const model = JSON.parse(result?.JsonOnlineModel);
                navigate(`/${config.SLUGS.STEP9C_SLUG}/`, {
                    state: {
                        coverageGroups: model.Participants[0].CoverageGroups,
                        previousPrice: model.Participants[0].SuggestedInsurance.PremiumTotal.Frequency,
                        currentPrice: model.Payment.AcceptedPremium.Frequency,
                    },
                });
            } else if (result?.ResultType === 3) {
                // ### Cannot insure online
                localStorage.setItem('completedStep', '8');
                localStorage.setItem('evaluateErrorPageCode', 'STEP9B');
                navigate(`/${config.SLUGS.STEP9B_SLUG}/`);
            } else if (result?.ResultType === 4) {
                // ### Cannot arrange at all
                localStorage.setItem('completedStep', '8');
                localStorage.setItem('evaluateErrorPageCode', 'STEP9A');
                navigate(`/${config.SLUGS.STEP9A_SLUG}/`);
            }
        }
    }, [loading, result, location]);

    if (formError) {
        return (
            <Step title={cms.steperror.title} text={null} className={cn(styles.wrapper, className)}>
                <ErrorForm />
            </Step>
        );
    }

    return (
        <Step title={cms.step9loader.title} text={null} className={cn(styles.wrapper, className)}>
            <LoadingCountdown
                initialTime={config.LOADING_ANIMATION_TIME.STEP9}
                countdownFinished={<CountdownAlert />}
            />
            <ErrorForm />
        </Step>
    );
};
