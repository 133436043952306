import { Field } from 'formik';

import { Label } from '../components/Label/Label';
import { ToggleInput } from '../components/ToggleInput/ToggleInput';
import { SmileyInput } from '../components/SmileyInput/SmileyInput';
import { validateField } from './validations';

// ### Function reduces complexity of adding new radio (toggle) field to Formik

export const renderToggleField = (
    name: string,
    id: string,
    variant: string,
    value: string,
    label?: string,
    smileys?: boolean,
    className?: string,
    optional?: boolean,
    error?: boolean,
    valueLabel?: string
) => {
    return (
        <div className={className} key={id}>
            {label && <Label htmlFor={name}>{label}</Label>}
            <Field
                name={name}
                id={id}
                value={value}
                type={variant}
                label={valueLabel}
                component={smileys ? SmileyInput : ToggleInput}
                validate={(value: string | number) => validateField(value, optional)}
                error={error}
            />
        </div>
    );
};
