import { ReactElement, useContext } from 'react';

import styles from './RetryButton.module.scss';
import { Button } from '../Button/Button';
import { routes } from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../Layout/Layout';
import config from '../../config.json';
import cms from '../../data/cms.json';
import { resetProcess } from '../../utils/resetProcess';

export interface RetryButtonProps {
    text?: string;
    variant?: 'primary' | 'secondary' | 'tertiary' | 'link';
}

export const RetryButton = ({
    text = cms.common.retry,
    variant = 'secondary',
}: RetryButtonProps): ReactElement | null => {
    const navigate = useNavigate();
    const ctx = useContext(AppContext);

    // only way to get route url from current step id
    const routesData = routes.filter(
        (item) => item?.visible !== false && (item.type === ctx.insuranceType || item.type === 'default')
    );

    const completedStep = parseInt(localStorage.getItem('completedStep') || '-1');

    const evaluateErrorPageCode = localStorage.getItem('evaluateErrorPageCode');

    return (
        <Button
            variant={variant}
            className={styles.back}
            onClick={() => {
                if (completedStep < 0) {
                    resetProcess(navigate);
                } else if (completedStep === 8 && localStorage.getItem('currentStep') === '8') {
                    navigate(
                        evaluateErrorPageCode
                            ? routes.find((route) => route.code === evaluateErrorPageCode)?.url || ''
                            : ''
                    );
                } else {
                    navigate(routesData[Math.min(completedStep + 1, config.STEP_COUNT)].url);
                }
            }}
        >
            {text}
        </Button>
    );
};
