// ### Functions are for locking scroll on body element
// It's useful especially when popup is active and we want to scroll in it

// We can pass a position where we want to scroll after enabling body scroll
export const unlockScroll = (yPos?: number) => {
    document.body.style.removeProperty('overflow');
    window.scrollTo(0, yPos || 0);
};

// We can set a position when locking
export const lockScroll = (setYPos?: (y: number) => void) => {
    const yOffset = window.pageYOffset;

    document.body.style.overflow = 'hidden';

    if (setYPos && yOffset > 0) {
        setYPos(yOffset);
    }
};
