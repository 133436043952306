import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './SelectVariantButton.module.scss';
import config from '../../config.json';
import cms from '../../data/cms.json';
import { CheckButton } from '../CheckButton/CheckButton';

export interface SelectVariantButtonProps {
    checked: boolean;
    price: string;
    onPositiveClick: () => void;
    onNegativeClick: () => void;
    className?: string;
    disabled?: boolean;
}

export const SelectVariantButton = ({
    checked,
    price,
    className,
    onPositiveClick,
    onNegativeClick,
    disabled,
}: SelectVariantButtonProps): ReactElement | null => (
    <div className={cn(styles.wrapper, disabled && styles.disabled, checked && styles.checked, className)}>
        <div
            className={cn(styles.positiveToggler, styles.toggler, checked && styles.positiveTogglerActive)}
            onClick={onPositiveClick}
        >
            <CheckButton className={styles.checkButton} checked={checked} />
            <div className={styles.price}>
                {price} {config.CURRENCIES.CZK}
            </div>
        </div>

        <div
            className={cn(styles.negativeToggler, styles.toggler, !checked && styles.negativeTogglerActive)}
            onClick={onNegativeClick}
        >
            {cms.common.notInterestedButton}
        </div>
    </div>
);
