import { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import cms from '../../../data/cms.json';
import config from '../../../config.json';
import { AppContext } from '../../Layout/Layout';
import { Step } from '../../Step/Step';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import axios from 'axios';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { Loader } from '../../Loader/Loader';
import { v4 as uuidv4 } from 'uuid';
import { RetryButton } from '../../RetryButton/RetryButton';
import { triggerGtmEvent } from '../../../utils/triggerGtmEvent';

interface StepLeadProps {
    className?: string;
}

export const StepLead = ({ className }: StepLeadProps): ReactElement | null => {
    const ctx = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [formError, setFormError] = useState(false);
    const BASE_URL = getBaseUrl();

    useEffect(() => {
        const search = location.search;
        const leadQuery = search.substring(1);
        const externalId = uuidv4();

        axios
            .post(
                `${BASE_URL}api/online/leads/create-lead`,
                {
                    onlinePolicyExternalId: externalId,
                    leadQueryString: leadQuery,
                },
                { timeout: config.FETCH_TIMEOUT }
            )
            .then((response) => {
                if (response) {
                    localStorage.setItem('isLeadCreated', 'true');
                    navigate(`/`, { state: { ExternalId: externalId } });
                } else {
                    triggerGtmEvent('error');
                    setFormError(true);
                }
            })
            .catch((error) => {
                triggerGtmEvent('error');
                setFormError(true);
                console.error(error);
            });
    }, [location]);

    if (formError) {
        return (
            <Step title={cms.steperror.title} text={null} className={cn(className)}>
                <RetryButton />
                <ErrorForm />
            </Step>
        );
    }

    return (
        <Step title={cms.lead.title} text={cms.lead.text} className={cn(className)}>
            <Loader size="medium" />
        </Step>
    );
};
