import { Fragment } from 'react';
import { Field } from 'formik';
import cn from 'classnames';

import styles from '../components/_pages/Step6/Step6.module.scss';
import cms from '../data/cms.json';
import { CommonDataProps, QuestionProps } from '../types/initData';
import { validateField } from './validations';
import { constructSelectOptions, resolveFieldText } from './externalDataHandling';
import { renderToggleField } from './renderToggleField';
import { renderTextField } from './renderTextField';

import { Grid } from '../components/Grid/Grid';
import { Label } from '../components/Label/Label';
import { Select } from '../components/Select/Select';
import { FormItemError } from '../components/FormItemError/FormItemError';
import { ValuesProps } from '../components/_pages/Step6/Step6';
import { ContextProps } from '../components/Layout/Layout';
import { AmountButton } from '../components/AmountButton/AmountButton';
import { FormikValueCustomProps } from '../types/common';

// ### @TODO: Temporary ugly solution for diopter placeholder
// Get placeholder data ideally from API, when available

const addDioptrePlaceholder = (id: string) => {
    if (
        id.startsWith('O_EYD01_NO_DIOPTRE_R') ||
        id.startsWith('O_EYD01_NO_DIOPTRE_L') ||
        id.startsWith('O_EYD02_NO_DIOPTRE_R') ||
        id.startsWith('O_EYD02_NO_DIOPTRE_L')
    ) {
        return 'např. 1,25';
    }
    return '';
};

// ### Function returns one question (JSX) in questionnaire form

export const renderQuestion = (
    q: QuestionProps,
    commonData: CommonDataProps,
    errors: ValuesProps,
    id: string,
    ctx: ContextProps,
    setFieldValue?: (field: string, value: FormikValueCustomProps, shouldValidate?: boolean) => void,
    index?: number
) => {
    const currentMA = ctx.multiAnswer?.[q.Code];

    return (
        <div key={q.Code} className="question-item">
            <Label help={q.Help}>{q.Name}</Label>
            <Grid cols="auto" className={styles.questionWrapper}>
                
                { // generate empty div due +- buttons align
                q.IsMultiAnswer && <div className={styles.amountButtons}></div>}
                
                {q.PossibleAnswersIds.length > 3 &&
                (q.QuestionStyleId === 3 || q.QuestionStyleId === 10 || q.QuestionAnswerFormatId === 1) ? (
                    // Input type: select
                    <Field
                        id={id}
                        name={id}
                        className={cn(styles.select)}
                        options={constructSelectOptions(commonData, q.PossibleAnswersIds)}
                        component={Select}
                        validate={(value: string | number) => validateField(value, false)}
                        error={errors[id]}
                    />
                ) : q.QuestionAnswerFormatId === 5 ? (
                    // Input type: float
                    renderTextField(id, 'decimal', '', addDioptrePlaceholder(id), '', '', null, undefined, errors[id], undefined, undefined, undefined, true)
                ) : q.QuestionAnswerFormatId === 3 ? (
                    // Input type: number
                    renderTextField(id, 'number', '', '', '', 'field-small', null, 1000000000, errors[id], undefined, undefined, undefined, true)
                ) : (
                    // Input type: radio (toggle)
                    q.PossibleAnswersIds?.map((a) => (
                        <Fragment key={`${id}-${a}`}>
                            {renderToggleField(
                                id,
                                `${id}-${a}`,
                                'radio',
                                resolveFieldText(a, commonData),
                                '',
                                false,
                                '',
                                false,
                                errors[id]
                            )}
                        </Fragment>
                    ))
                )}

                {/* ### Add increase and decrease buttons form multiAnswers */}
                {q.IsMultiAnswer && (
                    <div className={styles.amountButtons}>
                        {(!currentMA || currentMA[currentMA.length - 1] === index) && (
                            <AmountButton
                                className="addMore"
                                title={cms.common.addItemButton}
                                actionType="increase"
                                onClick={() => {
                                    const current = { ...ctx.multiAnswer };
                                    const data = current[q.Code] || [0];
                                    ctx.setMultiAnswer({
                                        ...current,
                                        [q.Code]: [...data, Math.max(...data, -1) + 1],
                                    });
                                }}
                            />
                        )}

                        {ctx.multiAnswer[q.Code]?.length > 1 && (
                            <AmountButton
                                className="addMore"
                                title={cms.common.removeItemButton}
                                actionType="decrease"
                                onClick={() => {
                                    setFieldValue && setFieldValue(`${q.Code}_MULTI_${index}`, undefined);
                                    const current = { ...ctx.multiAnswer };
                                    ctx.setMultiAnswer({
                                        ...current,
                                        [q.Code]: current[q.Code].filter((item: number) => item !== index),
                                    });
                                }}
                            />
                        )}
                    </div>
                )}
            </Grid>

            {errors[id] && <FormItemError text={errors[id]} />}
        </div>
    );
};
