import { useContext, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';

import { AppContext } from '../Layout/Layout';
import { OptionProps, Select } from '../Select/Select';
import { Label } from '../Label/Label';
import { TextInput, TextInputProps } from '../TextInput/TextInput';
import config from '../../config.json';

import styles from './PhoneNumberInput.module.scss';
import formStyles from '../_pages/StepConfirmData/StepConfirmData.module.scss';
import { FormItemError } from '../FormItemError/FormItemError';
import { validateFieldPhone } from '../../utils/validations';

interface PhoneNumberInputNewProps {
    className?: string;
    namePhone: string;
    namePhonePrefix: string;
    phoneNumber?: string;
    label?: string;
    isDisabled?: boolean;
    suffix?: string;
    showEditIcon?: boolean;
    error?: string;
    optional?: boolean;
    allowFieldValidation?: boolean;
    loading?: boolean;
    readonly?: boolean;
}

const max = 999999999999;

const PhoneNumberInputNew = ({
    className,
    namePhone,
    namePhonePrefix,
    label,
    isDisabled = false,
    suffix,
    showEditIcon = false,
    error,
    optional = true,
    allowFieldValidation = true,
    loading = false,
    phoneNumber,
}: PhoneNumberInputNewProps) => {
    const { initData } = useContext(AppContext);
    const { values: formikValues } = useFormikContext();

    const options = useMemo<OptionProps[]>(
        () =>
            (initData?.InternationalPhonePrefixes || [])
                .sort((prefixA, prefixB) => (prefixA.FormOrder > prefixB.FormOrder ? 1 : 0))
                .map((prefix) => ({
                    label: prefix.PhonePrefix,
                    value: prefix.PhonePrefix,
                })),
        [initData]
    );

    const readonly = isDisabled && phoneNumber && !loading;

    if (readonly)
        return (
            <div>
                <Label>Telefon</Label>
                <div className={formStyles.boxValue}>{phoneNumber}</div>
            </div>
        );

    return (
        <div className={className}>
            {label && <Label>{label}</Label>}
            <div className="flex justify-center">
                {options && (
                    <Field
                        component={Select}
                        className={styles.prefix}
                        id={namePhonePrefix}
                        name={namePhonePrefix}
                        isDisabled={isDisabled}
                        options={options}
                        placeholder={''}
                        error={error}
                        isSearchable={false}
                    />
                )}
                <Field
                    component={TextInput}
                    className={styles.phone}
                    type="number"
                    variant={config.INPUT_FIELD_TYPES.DECIMAL}
                    id={namePhone}
                    name={namePhone}
                    isCenter
                    isDisabled={isDisabled}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    allowLeadingZeros={false}
                    thousandSeparator=" "
                    decimalSeparator={undefined}
                    isNumericString
                    max={max}
                    suffix={suffix}
                    showEditIcon={showEditIcon}
                    error={error}
                    // FIXME: options prop is probably expecting an array of strings or objects, but receiving boolean instead
                    options={optional}
                    validate={(value: string) =>
                        allowFieldValidation &&
                        validateFieldPhone({
                            phone: value,
                            phonePrefix: formikValues
                                ? // eslint-disable-next-line
                                  (formikValues as any)[namePhonePrefix]
                                : '',
                            optional,
                        })
                    }
                >
                    {(props: JSX.IntrinsicAttributes & TextInputProps) => {
                        return (
                            <TextInput
                                {...props}
                                className={styles.phone}
                                key={props.field.name}
                                id={props.field.name}
                                name={props.field.name}
                                loading={loading}
                                showEditIcon
                                error={error}
                                variant="number"
                                max={max}
                            />
                        );
                    }}
                </Field>
            </div>
            {error && <FormItemError text={error} />}
        </div>
    );
};

export default PhoneNumberInputNew;
