import { ReactElement, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import styles from './NoEntry.module.scss';
import cms from '../../../data/cms.json';
import { Step } from '../../Step/Step';
import { AppContext } from '../../Layout/Layout';
import { Label } from '../../Label/Label';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { resetProcess } from '../../../utils/resetProcess';
import { RetryButton } from '../../RetryButton/RetryButton';

interface NoEntryProps {
    className?: string;
}

export const NoEntry = ({ className }: NoEntryProps): ReactElement | null => {
    const navigate = useNavigate();
    const ctx = useContext(AppContext);

    useEffect(() => {
        ctx.setPopupVisible(false);
        ctx.setIsBackButtonVisible(false);
    }, []);

    return (
        <Step title={cms.noentry.title} text={cms.noentry.topText} className={cn(styles.wrapper, className)}>
            <div className={'mb-4'}>
                <RetryButton text={cms.noentry.continueButton} variant="primary" />
            </div>
            <div className={'mb-6'}>
                <a
                    onClick={() => {
                        resetProcess(navigate);
                    }}
                    className="text--underline"
                    href="#"
                >
                    {cms.common.resetButton}
                </a>
            </div>
            <Label>{cms.noentry.bottomText}</Label>
            <ErrorForm />
        </Step>
    );
};
