import config from '../config.json';

// ### Function returns rider group code id (number) to compare with DisabilityLevel

export const getDisabilityVariantId = (code: string) => {
    switch (code) {
        case config.RIDER_GROUPS.DISD123:
            return 1;
        case config.RIDER_GROUPS.DISD23:
            return 2;
        case config.RIDER_GROUPS.DISD3:
            return 3;
        default:
            return 0;
    }
};

export const getSDVariantId = (code: string) => {
    switch (code) {
        case config.RIDER_GROUPS.SD90:
            return 1;
        case config.RIDER_GROUPS.SD56:
            return 2;
        case config.RIDER_GROUPS.SD28:
            return 3;
        default:
            return 0;
    }
};
