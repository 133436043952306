import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './CheckButton.module.scss';
import { ReactComponent as Checked } from '../../images/vectors/checked.svg';

export interface CheckButtonProps {
    checked: boolean;
    size?: 'small' | 'large';
    onClick?: () => void;
    className?: string;
}

export const CheckButton = ({ checked, className, size = 'large', onClick }: CheckButtonProps): ReactElement | null => (
    <div
        onClick={onClick ? onClick : undefined}
        className={cn(styles.button, checked && styles.isActive, size && styles[size], className)}
    >
        <Checked className={cn(checked && styles.checkIconVisible, styles.checkIcon)} />
    </div>
);
