import { ContextProps } from '../components/Layout/Layout';
import { ModelProps } from '../types/model';
import { validateForm } from './externalDataHandling';
import { scrollTop } from './scrollToTop';

// ### Function calls API, validates passed data and shows global error if validations
// didn't pass or there is an error

interface ValidationResultItemProps {
    Path: string;
    AttributeName: string;
    Message: string;
    Duration: string;
    Level: number;
    ValidationName: string;
}

export const runBackendValidations = (
    endpoint:
        | 'validate-form'
        | 'validate-premium-calculations'
        | 'validate-calculations'
        | 'validate-questionnaires'
        | 'validate-finish',
    ctx: ContextProps,
    model: ModelProps | null,
    positiveCallback?: () => void,
    negativeCallback?: () => void
) => {
    const validation = validateForm(endpoint, model !== null ? model : ctx.formResult);

    validation
        .then((res) => {
            if (res) {
                if (!res.HasError && res.Passed) {
                    positiveCallback && positiveCallback();
                    ctx.setGlobalError([]);
                } else {
                    if (res.ValidationResults.length > 0) {
                        const result: Array<{ target: string; message: string }> = [];
                        res.ValidationResults.map((vr: ValidationResultItemProps) =>
                            result.push({ target: vr.Path, message: vr.Message })
                        );
                        ctx.setGlobalError(result);
                        scrollTop('smooth');
                    }
                    negativeCallback && negativeCallback();
                }
            }
        })
        .catch((err) => {
            console.error(err);

            try {
                negativeCallback && negativeCallback();
            } catch (error) {
                console.error('negativeCallback error - ' + error);
            }
        });
};
