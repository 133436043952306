import { ReactElement } from 'react';
import cn from 'classnames';

import { ReactComponent as EmailIcon } from '../../images/vectors/mail.svg';
import { ReactComponent as PhoneIcon } from '../../images/vectors/phone.svg';
import { ReactComponent as LinkIcon } from '../../images/vectors/link.svg';
import styles from './ContactItem.module.scss';

type ContactTypeType = 'email' | 'phone' | 'link';

export interface ContactItemProps {
    value: string;
    type: ContactTypeType;
    className?: string;
}

const getIcon = (name: ContactTypeType) => {
    switch (name) {
        case 'email':
            return <EmailIcon />;
        case 'phone':
            return <PhoneIcon />;
        case 'link':
            return <LinkIcon />;
    }
};

const getHref = (name: ContactTypeType, value: string) => {
    switch (name) {
        case 'email':
            return `mailto:${value.replace(/\s/g, '')}`;
        case 'phone':
            return `tel:${value.replace(/\s/g, '')}`;
        case 'link':
            return value;
    }
};

export const ContactItem = ({ value, type, className }: ContactItemProps): ReactElement | null => {
    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={styles.icon}>{getIcon(type)}</div>
            <a className={styles.link} href={getHref(type, value)}>
                {value}
            </a>
        </div>
    );
};
