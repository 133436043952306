const dict: Map<string, string> = new Map([
    ['OnlinePolicyModel.Participants[0].Contact.Email', 'Email'],
    ['OnlinePolicyModel.Participants[0].Contact.Phone', 'Telefon'],
    ['OnlinePolicyModel.Participants[0].Identification.BankAccount.BankAccountNo', 'Číslo účtu'],
    ['OnlinePolicyModel.Participants[0].Identification.BankAccount.Code', 'Kód banky'],
    ['OnlinePolicyModel.Participants[0].Identification.BankAccount.Iban', 'Iban'],
    ['OnlinePolicyModel.Participants[0].Identification.BankAccount.Prefix', 'Předčíslí účtu'],
    ['OnlinePolicyModel.Participants[0].Identification.BirthDt', 'Datum narození'],
    ['OnlinePolicyModel.Participants[0].Identification.IdCard.ExpiryDt', 'Platnost do'],
    ['OnlinePolicyModel.Participants[0].Identification.IdCard.IdCardNo', 'Číslo dokladu'],
    ['OnlinePolicyModel.Participants[0].Identification.IdCard.IssuedDt', 'Vydán kdy'],
    ['OnlinePolicyModel.Participants[0].Identification.IdCard.Issuer', 'Vydán kým'],
    ['OnlinePolicyModel.Participants[0].Identification.IdCard.Kind', 'Typ dokladu'],
    ['OnlinePolicyModel.Participants[0].Identification.PermanentAddress.CityName', 'Město'],
    ['OnlinePolicyModel.Participants[0].Identification.PermanentAddress.Country', 'Stát'],
    ['OnlinePolicyModel.Participants[0].Identification.PermanentAddress.HouseNo', 'Číslo popisné/orientační'],
    ['OnlinePolicyModel.Participants[0].Identification.PermanentAddress.StreetName', 'Ulice'],
    ['OnlinePolicyModel.Participants[0].Identification.PermanentAddress.Zip', 'PSČ'],
    ['OnlinePolicyModel.Participants[0].Identification.PersonalIdentificationDetail.BirthCountry', 'Stát narození'],
    ['OnlinePolicyModel.Participants[0].Identification.PersonalIdentificationDetail.Birthplace', 'Místo narození'],
    ['OnlinePolicyModel.Participants[0].Identification.PersonalIdentificationDetail.Citizenship', 'Státní občanství'],
    ['OnlinePolicyModel.Participants[0].Identification.PersonalIdentificationDetail.FirstName', 'Jméno'],
    ['OnlinePolicyModel.Participants[0].Identification.PersonalIdentificationDetail.LastName', 'Příjmení'],
    ['OnlinePolicyModel.Participants[0].Identification.PersonalIdentificationDetail.MiddleName', 'Prostřední jméno'],
    [
        'OnlinePolicyModel.Participants[0].Identification.PersonalIdentificationDetail.PersonalIdentifier',
        'Osobní identifikátor',
    ],
    [
        'OnlinePolicyModel.Participants[0].Identification.PersonalIdentificationDetail.PersonalIdentifierType',
        'Typ osobního identifikátoru',
    ],
    [
        'OnlinePolicyModel.Participants[0].Identification.PersonalIdentificationDetail.SecondCitizenship',
        'Druhé státní občanství',
    ],
    ['OnlinePolicyModel.Participants[0].Identification.PersonalIdentificationDetail.TitleAfter', 'Titul za'],
    ['OnlinePolicyModel.Participants[0].Identification.PersonalIdentificationDetail.TitleBefore', 'Titul před'],
    ['OnlinePolicyModel.Participants[0].Identification.Sex', 'Pohlaví'],
]);

export const translate = (en: string) => {
    return dict.get(en) ?? en.substring(en.lastIndexOf('.') + 1);
};
