import { ReactElement } from 'react';
import parse from 'html-react-parser';
import cn from 'classnames';

import styles from './Tooltip.module.scss';

interface TooltipProps {
    text: string;
    className?: string;
    position?: 'left' | 'right' | 'center';
    boxClassName?: string;
}

export const Tooltip = ({ text, position = 'right', className, boxClassName }: TooltipProps): ReactElement | null => {
    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={cn(styles.tooltipBox, boxClassName, styles[`tooltipBox-${position}`])}>{parse(text)}</div>
            <div className={styles.tooltipTrigger}>?</div>
        </div>
    );
};
