import { Fragment, ReactElement, SetStateAction, useContext, useEffect, useState, useRef } from 'react';
import {
    FieldArray,
    Form,
    Formik,
    FormikErrors,
    FormikProps,
    FormikValues,
    validateYupSchema,
    yupToFormErrors,
} from 'formik';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import parse from 'html-react-parser';

import cms from '../../../data/cms.json';
import config from '../../../config.json';
import styles from './Step2.module.scss';
import {
    InsuranceLevelProps,
    KeyNumberValueProps,
    KeyStringValueProps,
    ProtectionWithDisabilityProps,
    SelectedProductProps,
} from '../../../types/initData';

import { validationSchema, validationSchemaMortgage } from './validations';
import { scrollToError } from '../../../utils/scrollToError';
import { formatYears } from '../../../utils/formatYears';
import { renderCoverageItem } from '../../../utils/renderCoverageItem';
import { fetchResultData } from '../../../utils/externalDataHandling';
import { getNumberFromString } from '../../../utils/getNumberFromString';
import { renderTextField } from '../../../utils/renderTextField';
import { renderToggleField } from '../../../utils/renderToggleField';
import { resolveBoxTitles } from '../../../utils/resolveBoxTitles';
import { resolvePageTitle } from '../../../utils/resolvePageTitle';
import { resolveChildAgeArray } from '../../../utils/resolveChildAgeArray';
import { constructMortgageObject } from '../../../utils/constructMortgageObject';
import { setCompleted } from '../../../utils/setCompleted';
import { sumAllValues } from '../../../utils/sumAllValues';
import { formatPrice } from '../../../utils/formatPrice';
import { triggerGtmEvent } from '../../../utils/triggerGtmEvent';
import { filterClientAvailability } from '../../../utils/filterClientAvailability';
import { resetIsChosen } from '../../../utils/resetIsChosen';
import { getStepRelevantData } from '../../../utils/getStepRelevantData';

import { Step } from '../../Step/Step';
import { Box } from '../../Box/Box';
import { Title } from '../../Title/Title';
import { PriceDiscount } from '../../PriceDiscount/PriceDiscount';
import { Grid } from '../../Grid/Grid';
import { SelectVariantButton } from '../../SelectVariantButton/SelectVariantButton';
import { Button } from '../../Button/Button';
import { ErrorForm, ErrorFormProps } from '../../ErrorForm/ErrorForm';
import { Label } from '../../Label/Label';
import { AppContext, ContextProps } from '../../Layout/Layout';
import { AmountButton } from '../../AmountButton/AmountButton';
import { ValuesProps } from '../Step6/Step6';
import { FormItemError } from '../../FormItemError/FormItemError';
import { DeathPopup, IncapacityPopup, InvalidityPopup } from './PopupContent';
import { SelectedValues } from '../../SelectedValues/SelectedValues';
import { Loader } from '../../Loader/Loader';
import {
    ModelProps,
    ParticipantProps,
    ProtectionGroupProps,
    ProtectionVariantProps,
    SuggestedProtectionProps,
} from '../../../types/model';
import { RecalculateButton } from '../../RecalculateButton/RecalculateButton';
import { runBackendValidations } from '../../../utils/runBackendValidations';
import { unlockScroll } from '../../../utils/bodyScrollLock';
import { FormikValueCustomProps, KeyValueProps } from '../../../types/common';
import { Transition } from 'history';
import { ConfirmBackPopup } from '../../ConfirmBackPopup/ConfirmBackPopup';
import { RetryButton } from '../../RetryButton/RetryButton';

interface Step2Props {
    className?: string;
}

export interface SelectedVariantProps {
    [code: string]: number;
}

export interface BoxTitleProps {
    code: string;
    title: string;
    afterTitle: string;
    variants: Array<null | string>;
}

export const handleVariantClick = (
    group: ProtectionGroupProps,
    selectedProduct: Array<SelectedProductProps>,
    selectedVariants: SelectedVariantProps,
    setSelectedProduct: (value: SetStateAction<SelectedProductProps[]>) => void
) => {
    // ### Find selectedProduct whose CheckButton user clicked
    const targetItem = selectedProduct.find((item) => item.name === group.RiderCategoryCode);

    if (targetItem) {
        // ### Prevent state change on death variant (config.RIDER_CATEGORIES.DTH), which is mandatory
        if (group.RiderCategoryCode !== config.RIDER_CATEGORIES.DTH) {
            if (targetItem.value === selectedVariants?.[group.RiderCategoryCode]) {
                // ### Mark as deselected and target price 0
                targetItem.value = -1;
                targetItem.price = 0;
            } else {
                // ### Else set its variant (selectedVariants?.[group.RiderCategoryCode]) to targetItem.value and sum all of its variants
                targetItem.value = selectedVariants?.[group.RiderCategoryCode] || 1;
                targetItem.price = sumAllValues(
                    group.SuggestedProtections[0].Variants,
                    selectedVariants?.[group.RiderCategoryCode]
                );
            }

            // ### All selectedProduct excluding clicked one
            const filteredResult = selectedProduct.filter((v) => v.name !== targetItem.name);
            setSelectedProduct([...filteredResult, targetItem]);
        }
    }
};

export const Step2 = ({ className }: Step2Props): ReactElement | null => {
    const navigate = useNavigate();
    const ctx = useContext(AppContext);
    const [, setModalActive] = useState(true);
    const [activeItem, setActiveItem] = useState(''); // Keeps information of which row of disability is selected (for toggle action)
    const [selectedProduct, setSelectedProduct] = useState<Array<SelectedProductProps>>([]); // Sets selected product (coverage) on user interactions
    const [loaded, setLoaded] = useState(false); // Loading status (show/hide loader)
    const [pageTitle, setPageTitle] = useState(''); // Custom page title (e.g. "Idealni pojisteni pro stopadesatiletou zenu")
    const [initialValues, setInitialValues] = useState<KeyValueProps>({}); // Initial values for Formik
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [submittedPopup, setSubmittedPopup] = useState(false);
    const [submitActivated, setSubmitActivated] = useState(true); // Show recalculate or submit button
    const [buttonLoading, setButtonLoading] = useState(false); // On async actions show loader for buttons
    const [isRecalculating, setIsRecalculating] = useState(false);
    const [hasData, setHasData] = useState(false); // Set to true when we get data from API
    const [max, setMax] = useState(0); // Max value for field, only in mortgage scenario
    const [min, setMin] = useState(0); // Min value for field, only in mortgage scenario
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedVariants, setSelectedVariants] = useState<SelectedVariantProps>({}); // Variant is e.g. Nizsi/Stredni/Vyssi
    const [selectedProductMortgage, setSelectedProductMortgage] = useState<null | number>(null); // Sets selected product (coverage) on user interactions
    const [defaultValues, setDefaultValues] = useState<Array<number> | null>(null); // Values for mortgage fields
    const [userChange, setUserChange] = useState(false); // Indicator if user changed anything in form
    const [mortgageData, setMortgageData] = useState<Array<ProtectionGroupProps> | null>(null);
    const [formError, setFormError] = useState(false);
    const [defaultVariantSet, setDefaultVariantSet] = useState(false); // Prevent re-runing of effect of setSelectedVariants
    const bottomRef = useRef<HTMLInputElement | null>(null);
    const [lastTxTried, setLastTxTried] = useState<Transition>();
    const [userMadeChange, setUserMadeChange] = useState(false);
    const [forceValidation, setForceValidation] = useState(false);
    const [tryReddirect, setTryReddirect] = useState(false);
    const [firstTwoVariantsAreSame, setFirstTwoVariantsAreSame] = useState(
        JSON.parse(localStorage.getItem('firstTwoVariantsAreSame') ?? 'true')
    );
    const [lastTwoVariantsAreSame, setLastTwoVariantsAreSame] = useState(
        JSON.parse(localStorage.getItem('lastTwoVariantsAreSame') ?? 'true')
    );
    const [removeDuplicateVariantsExecuted, setRemoveDuplicateVariantsExecuted] = useState(false); // chceme volat pouze jednou

    useEffect(() => {
        localStorage.setItem('currentStep', '2');
        ctx.setIsBackButtonVisible(false);
        ctx.setShowBlockPopup(false);
    }, []);

    useEffect(() => {
        //set same variants to local storage for the step back reinitialization
        localStorage.setItem('firstTwoVariantsAreSame', String(firstTwoVariantsAreSame));
        localStorage.setItem('lastTwoVariantsAreSame', String(lastTwoVariantsAreSame));
    }, [firstTwoVariantsAreSame, lastTwoVariantsAreSame]);

    useEffect(() => {
        if (tryReddirect && userMadeChange === false) {
            setTryReddirect(false);
            // retry navigation from last transition saved
            lastTxTried?.retry();
        }
    }, [tryReddirect, userMadeChange]);

    useEffect(() => {
        if (ctx.showBlockPopup && userMadeChange) {
            ctx.setPopupContent(
                <ConfirmBackPopup
                    onClickMoveToSave={() => {
                        // user clicked to move to save, so we just scroll to save button
                        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                    onClickContinueWithoutSave={() => {
                        // user clicked continue without save, we unblock redirect
                        setUserMadeChange(false);

                        // call retry after useState is called
                        setTryReddirect(true);
                    }}
                />
            );
            ctx.setPopupContentVariables({ uncloseable: false });
            ctx.setPopupVisible(true);
            ctx.setCloseButton(true);
            ctx.setShowBlockPopup(false);
        }
    }, [ctx.showBlockPopup, ctx.popupContentVariables, buttonLoading]);

    // ### Effect sets chosen variant (e.g. "Mám děti či hypotéku") on pageload
    useEffect(() => {
        const groups = ctx.formResult?.Participants[0].SuggestedInsurance?.ProtectionGroups;
        if (groups && groups.length > 0 && !defaultVariantSet) {
            const data = getStepRelevantData(groups, 'STEP2A_PRODUCTS');
            const tmp: SelectedVariantProps = {};
            data &&
                data.map(
                    (g) =>
                        (tmp[g.RiderCategoryCode] =
                            g.SuggestedProtections[0].Variants.find((v) => v.IsChosen)?.VariantOrder || 1)
                );

            setSelectedVariants((prevState) => ({
                ...prevState,
                ...tmp,
            }));

            setDefaultVariantSet(true);
        }
    }, [ctx.formResult, defaultVariantSet]);

    useEffect(() => {
        if (ctx.popupVisible && ctx.formStep.current === 3) {
            ctx.setFormStep({ completed: 2, current: 3 });
            setCompleted(2);
            localStorage.setItem('completedStep', '2');
            ctx.setPopupVisible(false);
        }
    }, [submittedPopup]);

    // ### Effect catches selectedProduct AND selectedVariant change - every time user chooses product, recalculate total price [income scenario]
    useEffect(() => {
        let nextStepPrice = 0;
        // ### Before step 2 submission, we need to subtract the amounts from the following step (3) which has not yet been displayed
        if (ctx.formStep.completed < 2) {
            const otherGroups =
                ctx.formResult?.Participants[0].SuggestedInsurance &&
                getStepRelevantData(
                    ctx.formResult?.Participants[0].SuggestedInsurance.ProtectionGroups,
                    'STEP3_PRODUCTS'
                );

            otherGroups &&
                otherGroups.map((g: ProtectionGroupProps) =>
                    g.SuggestedProtections[0].Variants.map((item: ProtectionVariantProps) => {
                        if (item.IsChosen) {
                            nextStepPrice = nextStepPrice + item.PremiumChosen.Frequency;
                        }
                    })
                );
        }

        const value = selectedProduct.reduce((acc: number, obj) => acc + obj.price, 0);
        setTotalPrice(value - nextStepPrice || 0);
    }, [selectedProduct]);

    // ### Cancel updating after new formResult
    useEffect(() => {
        setIsUpdating(false);
    }, [ctx.formResult]);

    // ### Effect sets relevant data to mortgageData and sets defaultValues for SelectedValues toggler [mortgage scenario]
    useEffect(() => {
        if (
            ctx.formResult &&
            ctx.insuranceType === config.MORTGAGE_CODE &&
            ctx.formResult?.Participants[0]?.SuggestedInsurance
        ) {
            const filteredGroups = getStepRelevantData(
                ctx.formResult?.Participants[0].SuggestedInsurance.ProtectionGroups,
                'STEP2B_PRODUCTS'
            );

            if (filteredGroups && filteredGroups[0]) {
                setMortgageData(filteredGroups);
                const variants = filteredGroups[0].SuggestedProtections[1].Variants.map(
                    (v: ProtectionVariantProps) => v.SumInsuredChosen
                );
                variants && variants.length > 0 && setDefaultValues(variants.reverse());
            }
        }
    }, [ctx.formResult, ctx.insuranceType]);

    // ### Effect is used to set min and max field values [mortgage scenario]
    useEffect(() => {
        if (mortgageData && ctx.insuranceType === config.MORTGAGE_CODE) {
            const variantId = config.INSURANCE_LEVELS.find((level) => level.value === selectedProductMortgage)?.code;
            const variants = mortgageData?.[0]?.SuggestedProtections?.[1]?.Variants;

            if (variants && Array.isArray(variants)) {
                const item = variants.find((item) => item.VariantOrder === variantId);
                if (item && item?.SumInsuredMin && item?.SumInsuredMax) {
                    setMin(item.SumInsuredMin);
                    setMax(item.SumInsuredMax);
                }
            }
        }
    }, [mortgageData, ctx.insuranceType, selectedProductMortgage]);

    // ### Every time user chooses product, recalculate and set total price [mortgage scenario]
    useEffect(() => {
        if (mortgageData && ctx.insuranceType === config.MORTGAGE_CODE) {
            // ### mortgageData is an array of groups, we need to find PAC_MP
            const target = mortgageData.find((item) => item.RiderCategoryCode === config.RIDER_CATEGORIES.MP);
            const variantId = config.INSURANCE_LEVELS.find((item) => item.code === selectedProductMortgage)?.value;
            let nextStepPrice = 0;

            if (target) {
                // ### Get array of all values from selected variantId and reduce them to single value
                const result = target.SuggestedProtections.map((item) =>
                    item.Variants.filter((item2) => item2.VariantOrder === variantId).reduce(
                        (acc: number, obj) => acc + obj.PremiumChosen.Frequency,
                        0
                    )
                ).reduce((acc: number, item: number) => acc + item, 0);

                // ### After step 2 submission, we need to separate groups from other step (2) and sum them up
                if (ctx.formStep.completed >= 2) {
                    const otherGroups =
                        ctx.formResult?.Participants[0].SuggestedInsurance &&
                        getStepRelevantData(
                            ctx.formResult?.Participants[0].SuggestedInsurance.ProtectionGroups,
                            'STEP3_PRODUCTS'
                        );

                    otherGroups &&
                        otherGroups.map((g: ProtectionGroupProps) =>
                            g.SuggestedProtections[0].Variants.map((item: ProtectionVariantProps) => {
                                if (item.IsChosen) {
                                    nextStepPrice = nextStepPrice + item.PremiumChosen.Frequency;
                                }
                            })
                        );
                }

                setTotalPrice(result + nextStepPrice || 0);
            }
        }
    }, [mortgageData, selectedProductMortgage, isRecalculating]);

    // ### Effect is watching mortgageData and sets selectedProductMortgage (1,2,3) [mortgage scenario]
    useEffect(() => {
        if (mortgageData && ctx.insuranceType === config.MORTGAGE_CODE) {
            const data = mortgageData[0].SuggestedProtections[1];

            if (data) {
                const variant = data.Variants.find((item) => item.IsChosen)?.VariantOrder;
                const variantId = config.INSURANCE_LEVELS.find((item) => item.code === variant)?.value;
                variantId && setSelectedProductMortgage(variantId);
            }
        }
    }, [mortgageData, ctx.insuranceType]);

    useEffect(() => {
        if (ctx.formResult) {
            if (!loaded && !hasData) {
                setPageTitle(resolvePageTitle(ctx));
                // ### localStorage doesn't support boolean
                const submittedString = localStorage.getItem('step2Submitted');
                const submitted = submittedString === 'true' ? true : false;

                // ### Load data from API (only first call)
                if (!submitted) {
                    getDataFromApi();
                }

                // ### Load data from localStorage
                else {
                    const storageData = localStorage.getItem('localData');
                    const parsedData = storageData ? JSON.parse(storageData) : null;
                    const participant = parsedData?.Participants[0];

                    if (ctx.insuranceType === config.INCOME_CODE) {
                        const coverage: KeyNumberValueProps = {};
                        const filteredGroups =
                            ctx.formResult?.Participants[0].SuggestedInsurance &&
                            getStepRelevantData(
                                ctx.formResult?.Participants[0].SuggestedInsurance.ProtectionGroups,
                                'STEP2A_PRODUCTS'
                            );

                        filteredGroups &&
                            filteredGroups.length > 0 &&
                            filteredGroups.map((g: ProtectionGroupProps, gIndex: number) =>
                                g.SuggestedProtections[0].Variants.map(
                                    (v) =>
                                        (coverage[`${v.SuggestedRiderVersion.Code}-${gIndex}-${v?.VariantOrder}`] =
                                            v.SumInsuredChosen)
                                )
                            );

                        const disabilityCoverage: KeyNumberValueProps = fillDisabilityCoverageFields(coverage);

                        const temp: Array<SelectedProductProps> = [];
                        filterClientAvailability(participant.SuggestedInsurance.ProtectionGroups, true).map(
                            (g: ProtectionGroupProps) => {
                                temp.push({
                                    name: g.RiderCategoryCode,
                                    value: g.SuggestedProtections[0].Variants.find(
                                        (v: ProtectionVariantProps) => v.IsChosen
                                    )?.VariantOrder,
                                    price: sumAllValues(
                                        g.SuggestedProtections[0].Variants,
                                        g.SuggestedProtections[0].Variants.find(
                                            (v: ProtectionVariantProps) => v.IsChosen
                                        )?.VariantOrder ?? 0
                                    ),
                                });
                            }
                        );
                        setSelectedProduct([...temp]);

                        const values = constructChildrenMortgageValues(participant);
                        setInitialValues({ ...coverage, ...disabilityCoverage, ...values });
                    } else if (ctx.insuranceType === config.MORTGAGE_CODE) {
                        const f = filterClientAvailability(participant.SuggestedInsurance.ProtectionGroups, true);

                        if (Array.isArray(f) && f.length > 0) {
                            setInitialValues({
                                amount:
                                    f.find(
                                        (item: ProtectionGroupProps) =>
                                            item.RiderCategoryCode === config.RIDER_CATEGORIES.MP
                                    )?.SuggestedProtections[0].Variants[0].SumInsuredChosen || 0,
                            });
                        }
                    }
                    setButtonLoading(false);
                    setLoaded(true);
                }
            }
        }
    }, [ctx.formResult]);

    useEffect(() => {
        if (ctx.formResult && isSubmitting && !isUpdating) {
            setIsUpdating(true);
            setButtonLoading(true);
            const calculatePremiumData = resetIsChosen(ctx);

            runBackendValidations(
                'validate-premium-calculations',
                ctx,
                calculatePremiumData,
                () => {
                    const data = fetchResultData('api/online/calculations/calculate-premium', calculatePremiumData);
                    data.then((res) => {
                        if (res) {
                            ctx.setFormResult(res);
                            setIsSubmitting(false);
                            setButtonLoading(false);
                            localStorage.setItem('step2Submitted', 'true');
                            localStorage.setItem('localData', JSON.stringify(res));
                            setCompleted(2);
                            navigate(`/${config.SLUGS.STEP3_SLUG}/`);
                        }
                    });
                },
                () => {
                    setIsSubmitting(false);
                    setButtonLoading(false);
                }
            );
        }
    }, [ctx.formResult, isSubmitting, isUpdating]);

    // # Function is called only once and set variables for hide duplicate variants
    const removeDuplicateVariants = (variants: Array<ProtectionVariantProps>) => {
        // Pokud jsou u invalidity shodné částky u dvou a více variant, zobrazit pouze rozdílné varinaty (varianta 100% bude zobrazena vždy)
        if (variants.length === 9) {
            // voláme pouze jednou na začátku, pokud uživatel změní částky, tak varianty už neporovnáváme
            if (!removeDuplicateVariantsExecuted) {
                // U invalidity je v modelu 9 variant, které jsou pro uživatel složeny do třech variant
                // proto je potreba porovnávat jen trojice [1. 2. 3.], [4. 5. 6.] a [7. 8. 9.]
                for (let i = 0; i < variants.length - 1; i++) {
                    if (i === 2 || i === 5) {
                        continue; // přeskočit porovnání 3. varianty se 4. variantou a 6. varianty se 7. sedmou
                    }
                    if (variants[i].SumInsuredChosen !== variants[i + 1].SumInsuredChosen) {
                        if (i === 0 || i === 3 || i === 6) {
                            setFirstTwoVariantsAreSame(false);
                        }
                        if (i === 1 || i === 4 || i === 7) {
                            setLastTwoVariantsAreSame(false);
                        }
                        if (!firstTwoVariantsAreSame && !lastTwoVariantsAreSame) {
                            break; // all variants are diffent, we can end the comparison loop
                        }
                    }
                }
                setRemoveDuplicateVariantsExecuted(true);
            }
        }
    };

    // # Function hide duplicate variants
    const hideDuplicateVariantsFromBoxTitle = (boxTitle: BoxTitleProps | null) => {
        // Pokud jsou u invalidity shodné částky u dvou a více variant, zobrazit pouze rozdílné varinaty (varianta 100% bude zobrazena vždy)
        if (boxTitle && boxTitle.code === config.RIDER_CATEGORIES.DIS && boxTitle.variants.length === 3) {
            // boxTitle contains 3 varinats: 80%, 100%, 120%
            if (firstTwoVariantsAreSame) {
                boxTitle.variants[0] = null; // hide 80%
            }
            if (lastTwoVariantsAreSame) {
                boxTitle.variants[2] = null; // hide 120%
            }
        }
    };

    // ### Function accepts filtered Formik fields and returns Formik default values for disability
    const fillDisabilityCoverageFields = (coverage: KeyNumberValueProps) => {
        const result: KeyNumberValueProps = {};

        for (let v = 1; v <= 3; v++) {
            const level3 = `${config.RIDER_GROUPS.DISD3}-${config.DISD_ORDER_INDEX}-${v}`;
            const level23 = `${config.RIDER_GROUPS.DISD23}-${config.DISD_ORDER_INDEX}-${v}`;
            const level123 = `${config.RIDER_GROUPS.DISD123}-${config.DISD_ORDER_INDEX}-${v}`;

            // ### Filter out fields which are not available (<group>.ClientAvailability !== 1)
            if (
                (coverage[level3] || coverage[level3] === 0) &&
                (coverage[level23] || coverage[level23] === 0) &&
                (coverage[level123] || coverage[level123] === 0)
            ) {
                result[level3] = coverage[level3] + coverage[level23] + coverage[level123];
                result[level23] = coverage[level23] + coverage[level123];
                result[level123] = coverage[level123];
            }
        }
        return result;
    };

    const constructChildrenMortgageValues = (p: ParticipantProps) => ({
        hasChildren: p.ChildrenSummary.HasChildren ? config.QUESTIONNAIRE.ANSWER_YES : config.QUESTIONNAIRE.ANSWER_NO,
        childrenAge:
            p.ChildrenSummary.HasChildren && p.ChildrenSummary.Children?.length > 0
                ? [...p.ChildrenSummary.Children.map((c) => c.CurrentAge?.toString())]
                : [''],
        mortgage: p.LoanSummary.HasMortgage ? config.QUESTIONNAIRE.ANSWER_YES : config.QUESTIONNAIRE.ANSWER_NO,
        mortgageAlone:
            typeof p.LoanSummary.Mortgage?.IsOnlyPayer === 'boolean'
                ? p.LoanSummary.Mortgage?.IsOnlyPayer
                    ? config.QUESTIONNAIRE.ANSWER_YES
                    : config.QUESTIONNAIRE.ANSWER_NO
                : '',
        mortgageAmount: p.LoanSummary.Mortgage?.RemainingAmount ?? '',
        mortgageInterest: p.LoanSummary.Mortgage?.InterestRatePct ?? '',
        mortgagePeriod: p.LoanSummary.Mortgage?.RemainingYears ?? '',
    });

    const getPreviousDisabilityRiderGroup = (currentGroup: string) => {
        if (currentGroup === config.RIDER_GROUPS.DISD3) {
            return config.RIDER_GROUPS.DISD23;
        } else if (currentGroup === config.RIDER_GROUPS.DISD23) {
            return config.RIDER_GROUPS.DISD123;
        }
        return false;
    };

    const updateForm = (ctx: ContextProps, values: ValuesProps) => {
        let previousRiderGroup;
        let previousItemValue;
        let currentItemValue;

        const filteredGroups = ctx.formResult?.Participants[0].SuggestedInsurance
            ? getStepRelevantData(
                  ctx.formResult?.Participants[0].SuggestedInsurance.ProtectionGroups,
                  'STEP2A_PRODUCTS'
              )
            : [];
        const restGroups = ctx.formResult?.Participants[0].SuggestedInsurance
            ? getStepRelevantData(ctx.formResult?.Participants[0].SuggestedInsurance.ProtectionGroups, 'STEP3_PRODUCTS')
            : [];

        filteredGroups &&
            filteredGroups.length > 0 &&
            filteredGroups.map((g: ProtectionGroupProps, gIndex: number) =>
                g.SuggestedProtections[0].Variants.map((v) => {
                    previousRiderGroup = getPreviousDisabilityRiderGroup(v.RiderGroup.Code);
                    currentItemValue = values[`${v.RiderGroup.Code}-${gIndex}-${v?.VariantOrder}`];
                    previousItemValue = values[`${previousRiderGroup}-${gIndex}-${v?.VariantOrder}`] || 0;

                    v.SumInsuredChosen = currentItemValue
                        ? getNumberFromString(currentItemValue) - getNumberFromString(previousItemValue)
                        : 0;
                    v.IsChosen = selectedProduct.find((p) => p.name === g.RiderCategoryCode)?.value === v?.VariantOrder;
                })
            );

        return [...(filteredGroups || []), ...(restGroups || [])];
    };

    const updateFormMortgage = (ctx: ContextProps, values: ValuesProps) => {
        const newGroup =
            ctx.formResult?.Participants[0].SuggestedInsurance?.ProtectionGroups.filter(
                (g: ProtectionGroupProps) => g.RiderCategoryCode === config.RIDER_CATEGORIES.MP
            ) || [];

        const otherGroups =
            ctx.formResult?.Participants[0].SuggestedInsurance?.ProtectionGroups.filter(
                (g: ProtectionGroupProps) =>
                    g.RiderCategoryCode === config.RIDER_CATEGORIES.PCA ||
                    g.RiderCategoryCode === config.RIDER_CATEGORIES.CI
            ) || [];

        const amount = getNumberFromString(values.amount);
        const variantId = config.INSURANCE_LEVELS.find((item) => item.code === selectedProductMortgage)?.value;

        newGroup &&
            newGroup[0].SuggestedProtections.map((protection: SuggestedProtectionProps) => {
                protection.DisabilityDetails?.map((d) => {
                    d.SumInsuredChosen = amount;
                });
                protection.Variants.map((v) => {
                    v.SumInsuredChosen = amount;
                    v.IsChosen = v.VariantOrder === variantId;
                });
            });

        return {
            ...ctx.formResult,
            Participants: [
                {
                    ...ctx.formResult?.Participants[0],
                    SuggestedInsurance: {
                        ...ctx.formResult?.Participants[0].SuggestedInsurance,
                        ProtectionGroups: [...newGroup, ...otherGroups],
                    },
                },
            ],
            Settings: {
                ...ctx.formResult?.Settings,
                LastStepName: config.STEP_NAMES.STEP2,
            },
        };
    };

    // ### Function is called when user comes from previous step or submits form
    const getDataFromApi = (passedValues?: ValuesProps) => {
        let newResult;
        setButtonLoading(true);

        // ### If we pass existing values (passedValues), construct object (newResult) to pass
        if (passedValues) {
            const groups = updateForm(ctx, passedValues);

            if (groups && groups.length > 0) {
                newResult = {
                    ...ctx.formResult,
                    Participants: [
                        {
                            ...ctx.formResult?.Participants[0],
                            ChildrenSummary: {
                                HasChildren:
                                    passedValues.hasChildren === config.QUESTIONNAIRE.ANSWER_YES &&
                                    passedValues.childrenAge.length > 0,
                                Children: [...resolveChildAgeArray(passedValues)],
                            },
                            LoanSummary: {
                                ...ctx.formResult?.Participants[0].LoanSummary,
                                HasMortgage: passedValues.mortgage === config.QUESTIONNAIRE.ANSWER_YES,
                                Mortgage: constructMortgageObject(
                                    ctx.formResult?.Participants[0].LoanSummary.Mortgage,
                                    passedValues
                                ),
                            },
                            SuggestedInsurance: {
                                ...ctx.formResult?.Participants[0].SuggestedInsurance,
                                ProtectionGroups: groups,
                            },
                        },
                    ],
                    Settings: {
                        ...ctx.formResult?.Settings,
                        LastStepName: config.STEP_NAMES.STEP2,
                    },
                };
            }
        }

        const insuranceType = localStorage.getItem('insuranceType');
        const _insuranceType = insuranceType?.replace(/['"]+/g, '');

        const endpoint =
            ctx.insuranceType === config.INCOME_CODE || _insuranceType === config.INCOME_CODE
                ? config.INCOME_ENDPOINT
                : ctx.insuranceType === config.MORTGAGE_CODE || _insuranceType === config.MORTGAGE_CODE
                ? config.MORTGAGE_ENDPOINT
                : null;

        if (endpoint) {
            const fallbackData = localStorage.getItem('localData');
            const _fallbackData = fallbackData && JSON.parse(fallbackData);

            // ### If we get newResult, send it to API endpoint
            const data = fetchResultData(
                `api/online/calculations/calculate-suggested-insurance/${endpoint}`,
                passedValues ? newResult : ctx.formResult.Participants[0].ExternalId ? ctx.formResult : _fallbackData
            );

            data &&
                data.then((res: ModelProps) => {
                    if (res) {
                        setHasData(true);
                        ctx.setFormResult(res); // ### Store data in Context
                        const participant = res?.Participants[0];

                        // ### Based on INCOME_CODE we want STEP2A_PRODUCTS OR STEP2B_PRODUCTS
                        const filterCodes =
                            ctx.insuranceType === config.INCOME_CODE ? 'STEP2A_PRODUCTS' : 'STEP2B_PRODUCTS';

                        // ### Filter groups by filterCodes
                        const filteredGroups = res.Participants[0].SuggestedInsurance
                            ? getStepRelevantData(res.Participants[0].SuggestedInsurance.ProtectionGroups, filterCodes)
                            : [];

                        if (ctx.insuranceType === config.INCOME_CODE) {
                            const coverage: KeyNumberValueProps = {};

                            // ### Loop over filteredGroups (if any) and create coverage object
                            filteredGroups &&
                                filteredGroups.map((g, gIndex: number) =>
                                    g.SuggestedProtections[0].Variants.map(
                                        (v: ProtectionVariantProps) =>
                                            (coverage[`${v.SuggestedRiderVersion.Code}-${gIndex}-${v?.VariantOrder}`] =
                                                v.SumInsuredChosen)
                                    )
                                );

                            const disabilityCoverage: KeyNumberValueProps = fillDisabilityCoverageFields(coverage);

                            const disabilityProtection = participant.SuggestedInsurance?.ProtectionGroups.find(
                                (item: ProtectionGroupProps) => item.RiderCategoryCode === config.RIDER_CATEGORIES.DIS
                            );

                            if (!passedValues) {
                                const newProtectionGroups: Array<SelectedProductProps> = [];
                                participant.SuggestedInsurance &&
                                    filterClientAvailability(participant.SuggestedInsurance.ProtectionGroups, true).map(
                                        (g: ProtectionGroupProps) => {
                                            newProtectionGroups.push({
                                                name: g.RiderCategoryCode,
                                                value: g.SuggestedProtections[0].Variants.find(
                                                    (v: ProtectionVariantProps) => v.IsChosen
                                                )?.VariantOrder,
                                                price: sumAllValues(
                                                    g.SuggestedProtections[0].Variants,
                                                    g.SuggestedProtections[0].Variants.find(
                                                        (v: ProtectionVariantProps) => v.IsChosen
                                                    )?.VariantOrder ?? 0
                                                ),
                                            });
                                        }
                                    );
                                // ### selectedProduct holds current groups data and updates them every time user selects product Variant
                                setSelectedProduct([...newProtectionGroups]);
                            }
                            setLoaded(true);
                            setButtonLoading(false);
                            setInitialValues({
                                ...coverage,
                                ...disabilityCoverage,
                                ...constructChildrenMortgageValues(participant),
                            });
                            if (disabilityProtection?.SuggestedProtections[0].Variants) {
                                removeDuplicateVariants(disabilityProtection?.SuggestedProtections[0].Variants);
                            }
                        } else if (ctx.insuranceType === config.MORTGAGE_CODE) {
                            setLoaded(true);
                            setButtonLoading(false);

                            const group = participant.SuggestedInsurance?.ProtectionGroups.find(
                                (item: ProtectionGroupProps) => item.RiderCategoryCode === config.RIDER_CATEGORIES.MP
                            );

                            if (group) {
                                const result = filterClientAvailability([group], true);

                                if (result && Array.isArray(result) && result.length > 0) {
                                    setInitialValues({
                                        amount:
                                            group.SuggestedProtections[0].Variants.find(
                                                (v: ProtectionVariantProps) => v.IsChosen
                                            )?.SumInsuredChosen || 0,
                                    });
                                } else {
                                    const newResult = resetIsChosen(ctx);
                                    ctx.setFormResult(newResult);
                                    setCompleted(8);
                                    navigate(`/${config.SLUGS.STEP9B_SLUG}/`);
                                }
                            }
                        }
                    } else {
                        setFormError(true);
                        triggerGtmEvent('error');
                    }
                });
        }
    };

    // ### Function is called when user hits recalculate button
    const recalculateForm = (errors: ErrorFormProps, values: ValuesProps, fromPopup?: boolean) => {
        setButtonLoading(true);
        setUserMadeChange(false);

        if (ctx.insuranceType === config.INCOME_CODE) {
            const groups = updateForm(ctx, values);

            if (groups && groups.length > 0) {
                const newResult = {
                    ...ctx.formResult,
                    Participants: [
                        {
                            ...ctx.formResult?.Participants[0],
                            ChildrenSummary: {
                                HasChildren:
                                    values.hasChildren === config.QUESTIONNAIRE.ANSWER_YES &&
                                    values.childrenAge.length > 0,
                                Children: [...resolveChildAgeArray(values)],
                            },
                            LoanSummary: {
                                ...ctx.formResult?.Participants[0].LoanSummary,
                                HasMortgage: values.mortgage === config.QUESTIONNAIRE.ANSWER_YES,
                                Mortgage: constructMortgageObject(
                                    ctx.formResult?.Participants[0].LoanSummary.Mortgage,
                                    values
                                ),
                            },
                            SuggestedInsurance: {
                                ...ctx.formResult?.Participants[0].SuggestedInsurance,
                                ProtectionGroups: groups,
                            },
                        },
                    ],
                    Settings: {
                        ...ctx.formResult?.Settings,
                        LastStepName: config.STEP_NAMES.STEP2,
                    },
                };

                runBackendValidations(
                    'validate-premium-calculations',
                    ctx,
                    newResult,
                    () => {
                        // ### Call calculate-premium endpoint
                        const data = fetchResultData('api/online/calculations/calculate-premium', newResult);

                        data.then((res) => {
                            res && ctx.setFormResult(res);
                            const temp: Array<SelectedProductProps> = [];
                            filterClientAvailability(res.Participants[0].SuggestedInsurance.ProtectionGroups, true).map(
                                (g: ProtectionGroupProps) => {
                                    temp.push({
                                        name: g.RiderCategoryCode,
                                        value: g.SuggestedProtections[0].Variants.find(
                                            (v: ProtectionVariantProps) => v.IsChosen
                                        )?.VariantOrder,
                                        price: sumAllValues(
                                            g.SuggestedProtections[0].Variants,
                                            g.SuggestedProtections[0].Variants.find(
                                                (v: ProtectionVariantProps) => v.IsChosen
                                            )?.VariantOrder ?? 0
                                        ),
                                    });
                                }
                            );
                            setSelectedProduct([...temp]);
                            setSubmitActivated(true);
                            setButtonLoading(false);

                            // ### Update Formik values in popup form
                            if (fromPopup) {
                                const participant = res.Participants[0];

                                if (participant) {
                                    const values = constructChildrenMortgageValues(participant);
                                    setInitialValues({ ...initialValues, ...values });
                                }
                            }
                        });
                    },
                    () => {
                        setSubmitActivated(false);
                        setButtonLoading(false);
                    }
                );
            }
        } else if (ctx.insuranceType === config.MORTGAGE_CODE) {
            setIsRecalculating(true);

            const newResult = updateFormMortgage(ctx, values);

            if (newResult) {
                runBackendValidations(
                    'validate-premium-calculations',
                    ctx,
                    newResult,
                    () => {
                        const data = fetchResultData('api/online/calculations/calculate-premium', newResult);
                        data.then((res) => {
                            res && ctx.setFormResult(res);
                            setSubmitActivated(true);
                            setButtonLoading(false);
                            setIsRecalculating(false);
                        }).catch((err) => console.error(err));
                    },
                    () => {
                        setSubmitActivated(false);
                        setButtonLoading(false);
                    }
                );
            }
        }

        if (!Object.keys(errors).length) {
            scrollToError();
        }
    };

    const formikRef = useRef<FormikProps<typeof initialValues> | null>(null);

    useEffect(() => {
        if (forceValidation && formikRef.current) {
            formikRef.current.validateForm();
            setForceValidation(false);
        }
    }, [forceValidation]);

    // ### Mortgage popup
    const popupContent = (
        <div className={styles.popup}>
            <Title tag="h2" className="mb-7">
                {cms.step2a.title}
            </Title>

            {cms.step2a.text && <div className={cn(styles.text, 'mb-7')}>{parse(cms.step2a.text)}</div>}

            {loaded ? (
                <div className={styles.content}>
                    {initialValues && (
                        <Formik
                            innerRef={formikRef}
                            initialValues={initialValues}
                            validateOnBlur={false}
                            validateOnChange={submittedPopup}
                            validateOnMount={false}
                            validationSchema={validationSchema}
                            enableReinitialize
                            validate={(values) => {
                                try {
                                    validateYupSchema(values, validationSchema, true, values);
                                } catch (err) {
                                    scrollToError();
                                    return yupToFormErrors(err);
                                }
                                return {};
                            }}
                            onSubmit={(values) => {
                                getDataFromApi(values);
                                unlockScroll();
                                ctx.setPopupVisible(false);
                                setModalActive(false);
                            }}
                        >
                            {({ values, errors, handleSubmit }: ValuesProps) => (
                                <Form
                                    className={styles.form}
                                    onChange={() => {
                                        setUserMadeChange(true);
                                        setSubmitActivated(false);
                                    }}
                                >
                                    <div className="mb-8 w-100">
                                        <Label htmlFor="hasChildren">Máte děti?</Label>
                                        <Grid cols="auto">
                                            {renderToggleField(
                                                'hasChildren',
                                                'hasChildren-yes',
                                                'radio',
                                                config.QUESTIONNAIRE.ANSWER_YES,
                                                undefined,
                                                undefined,
                                                errors.hasChildren
                                            )}
                                            {renderToggleField(
                                                'hasChildren',
                                                'hasChildren-no',
                                                'radio',
                                                config.QUESTIONNAIRE.ANSWER_NO,
                                                undefined,
                                                undefined,
                                                errors.hasChildren
                                            )}
                                        </Grid>
                                        {errors.hasChildren && <FormItemError text={errors.hasChildren} />}
                                    </div>

                                    {values['hasChildren'] === config.QUESTIONNAIRE.ANSWER_YES && (
                                        <>
                                            <div className="mb-8 w-100">
                                                <Label
                                                    htmlFor="childrenAge"
                                                    children={'<b>Věk vašich dětí</b>'}
                                                    description={`Vyplňte pouze děti mladší než ${
                                                        config.QUESTIONNAIRE.CHILD_AGE.MAX + 1
                                                    } let`}
                                                />

                                                <Grid cols="auto">
                                                    <FieldArray
                                                        name="childrenAge"
                                                        render={(arrayHelpers) => (
                                                            <>
                                                                {values['childrenAge'].map(
                                                                    (item: string, index: number) => (
                                                                        <Fragment key={index}>
                                                                            {renderTextField(
                                                                                `childrenAge[${index}]`,
                                                                                'number',
                                                                                '',
                                                                                '',
                                                                                formatYears(item),
                                                                                'field-small',
                                                                                null,
                                                                                config.QUESTIONNAIRE.CHILD_AGE.MAX,
                                                                                errors?.childrenAge?.[index] ?? '',
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                true
                                                                            )}
                                                                        </Fragment>
                                                                    )
                                                                )}

                                                                {values['childrenAge'].length <
                                                                    config.QUESTIONNAIRE.MAX_CHILDS && (
                                                                    <AmountButton
                                                                        text={cms.common.addChildButton}
                                                                        onClick={() => {
                                                                            setUserMadeChange(true);
                                                                            arrayHelpers.push('');
                                                                        }}
                                                                    />
                                                                )}

                                                                {values['childrenAge'].length > 1 && (
                                                                    <AmountButton
                                                                        text={cms.common.removeItemButton}
                                                                        actionType="decrease"
                                                                        onClick={() => {
                                                                            setUserMadeChange(true);
                                                                            // remove s indexem, protoze strictMode vola arrayHelpers.pop(); dvakrat
                                                                            arrayHelpers.remove(
                                                                                values['childrenAge'].length - 1
                                                                            );
                                                                            // Po odstranění prvku zavoláme validaci znovu pomocí useEffect,
                                                                            // neboť docházelo k validaci dříve, než byl prvek odstraněn a vladice tak byla chybná.
                                                                            if (submittedPopup) {
                                                                                setForceValidation(true);
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>
                                                {errors.childrenAge && (
                                                    <FormItemError text={cms.validations.REQUIRED_FIELD} />
                                                )}
                                            </div>
                                        </>
                                    )}

                                    <div className="mb-8 w-100">
                                        <Label htmlFor="mortgage">Máte hypotéku?</Label>
                                        <Grid cols="auto">
                                            {renderToggleField(
                                                'mortgage',
                                                'mortgage-yes',
                                                'radio',
                                                config.QUESTIONNAIRE.ANSWER_YES,
                                                undefined,
                                                undefined,
                                                errors.mortgage,
                                                true
                                            )}
                                            {renderToggleField(
                                                'mortgage',
                                                'mortgage-no',
                                                'radio',
                                                config.QUESTIONNAIRE.ANSWER_NO,
                                                undefined,
                                                undefined,
                                                errors.mortgage,
                                                true
                                            )}
                                        </Grid>
                                        {errors.mortgage && <FormItemError text={errors.mortgage} />}
                                    </div>

                                    {values && values['mortgage'] === config.QUESTIONNAIRE.ANSWER_YES && (
                                        <div className={cn(styles.fullsize, 'mb-8', 'w-100')}>
                                            <Grid cols={2} className={styles.mortgageGrid}>
                                                <div>
                                                    <Label
                                                        htmlFor="mortgageAlone"
                                                        description="Tedy nemáte spoludlužníka - např. partnera"
                                                        children={'<b>Platíte hypotéku sám?</b>'}
                                                    />
                                                    <Grid cols="auto">
                                                        {renderToggleField(
                                                            'mortgageAlone',
                                                            'mortgageAlone-yes',
                                                            'radio',
                                                            config.QUESTIONNAIRE.ANSWER_YES,
                                                            undefined,
                                                            undefined,
                                                            '',
                                                            false,
                                                            errors.mortgageAlone
                                                        )}
                                                        {renderToggleField(
                                                            'mortgageAlone',
                                                            'mortgageAlone-no',
                                                            'radio',
                                                            config.QUESTIONNAIRE.ANSWER_NO,
                                                            undefined,
                                                            undefined,
                                                            '',
                                                            false,
                                                            errors.mortgageAlone
                                                        )}
                                                    </Grid>
                                                    {errors.mortgageAlone && (
                                                        <FormItemError text={errors.mortgageAlone} />
                                                    )}
                                                </div>

                                                <div>
                                                    {renderTextField(
                                                        'mortgageInterest',
                                                        'number',
                                                        [
                                                            '<b>Jaký máte u hypotéky úrok?</b>',
                                                            'Stačí zhruba na celá procenta',
                                                        ],
                                                        '',
                                                        '%',
                                                        'field-small',
                                                        undefined,
                                                        99,
                                                        errors.mortgageInterest,
                                                        undefined,
                                                        undefined,
                                                        false,
                                                        true
                                                    )}
                                                    {errors.mortgageInterest && (
                                                        <FormItemError text={errors.mortgageInterest} />
                                                    )}
                                                </div>

                                                <div>
                                                    {renderTextField(
                                                        'mortgageAmount',
                                                        'price',
                                                        [
                                                            '<b>Jaká je nesplacená výše hypotéky?</b>',
                                                            'Stačí zaokrouhleně',
                                                        ],
                                                        '',
                                                        config.CURRENCIES.CZK,
                                                        'field-medium',
                                                        0,
                                                        config.QUESTIONNAIRE.MORTGAGE_MAXAMOUNT,
                                                        errors.mortgageAmount,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        true
                                                    )}
                                                    {errors.mortgageAmount && (
                                                        <FormItemError text={errors.mortgageAmount} />
                                                    )}
                                                </div>

                                                <div>
                                                    {renderTextField(
                                                        'mortgagePeriod',
                                                        'number',
                                                        [
                                                            '<b>Jak dlouho ještě budete splácet?</b>',
                                                            'Stačí zaokrouhleně na celé roky',
                                                        ],
                                                        '',
                                                        formatYears(values['mortgagePeriod']),
                                                        'field-small',
                                                        null,
                                                        99,
                                                        errors.mortgagePeriod,
                                                        undefined,
                                                        undefined,
                                                        false,
                                                        true
                                                    )}
                                                    {errors.mortgagePeriod && (
                                                        <FormItemError text={errors.mortgagePeriod} />
                                                    )}
                                                </div>
                                            </Grid>
                                        </div>
                                    )}

                                    <Grid cols="auto" className={styles.buttonsWrapper}>
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                setSubmittedPopup(true);
                                                unlockScroll();
                                                triggerGtmEvent('suggest_go');
                                                handleSubmit();
                                            }}
                                        >
                                            {cms.common.saveButton}
                                        </Button>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            ) : (
                <Loader size="medium" />
            )}
        </div>
    );

    const getSelectedItemIndex = (code: string, key: number) => {
        return selectedProduct.find((item) => item.name === code)?.value === key;
    };

    const renderIncomeForm = (values: FormikValues, errors: FormikErrors<FormikValues>) => {
        let tmpTitle: BoxTitleProps | null;

        const filteredGroups = ctx.formResult?.Participants[0].SuggestedInsurance?.ProtectionGroups
            ? getStepRelevantData(
                  ctx.formResult?.Participants[0].SuggestedInsurance.ProtectionGroups,
                  'STEP2A_PRODUCTS'
              )
            : [];
        let protection;

        return (
            <Form
                className={cn(styles.form, styles.desktopForm)}
                onChange={() => {
                    setSubmitActivated(false);
                    setUserMadeChange(true);
                }}
            >
                {filteredGroups &&
                    filteredGroups.length > 0 &&
                    filteredGroups.map((e, i: number) => {
                        tmpTitle = resolveBoxTitles(e.RiderCategoryCode) ?? null;
                        protection = e.SuggestedProtections[0] as ProtectionWithDisabilityProps;
                        hideDuplicateVariantsFromBoxTitle(tmpTitle);

                        return (
                            <Box key={i} className={cn('mb-7', styles.box)} loading={buttonLoading}>
                                <Grid className={cn(styles.tableHeader, styles['cols-4-all'])}>
                                    <div className="box-title-wrapper text--left">
                                        <div
                                            className={cn(styles.help, 'help')}
                                            onClick={() => {
                                                setUserMadeChange(true);
                                                // ### Select popup which should render by RiderCategoryCode
                                                e.RiderCategoryCode === config.STEP2A_PRODUCTS[2] ? (
                                                    ctx.setPopupContent(InvalidityPopup)
                                                ) : e.RiderCategoryCode === config.STEP2A_PRODUCTS[0] ? (
                                                    ctx.setPopupContent(DeathPopup)
                                                ) : e.RiderCategoryCode === config.STEP2A_PRODUCTS[1] ? (
                                                    ctx.setPopupContent(
                                                        IncapacityPopup(ctx.formResult?.Participants[0])
                                                    )
                                                ) : (
                                                    <></>
                                                );
                                                ctx.formResult?.Participants[0].SuggestedInsurance;
                                                const currentAge =
                                                    ctx.formResult?.Participants[0].Identification.CurrentAge || 0;
                                                const duration = ctx.formResult?.Participants[0].SuggestedInsurance
                                                    ? ctx.formResult?.Participants[0].SuggestedInsurance
                                                          .ProtectionGroups[0].SuggestedProtections[0].InsurancePeriod
                                                    : 0;
                                                const replacements = {
                                                    duration: duration,
                                                    age: currentAge + duration,
                                                    uncloseable: false,
                                                };
                                                ctx.setPopupContentVariables(replacements);
                                                ctx.setCloseButton(true);
                                                ctx.setPopupVisible(true);
                                            }}
                                        >
                                            ?
                                        </div>

                                        <div className="text--bold">{tmpTitle?.title ?? ''}</div>

                                        {tmpTitle?.afterTitle && <div>{tmpTitle?.afterTitle}:</div>}
                                    </div>

                                    <Grid cols="auto" className="table-header-buttons">
                                        {Array.isArray(tmpTitle?.variants) &&
                                            tmpTitle?.variants.map((v, i: number) => (
                                                <div key={i} className={cn(styles.product, 'text--bold')}>
                                                    {v && (
                                                        <Button
                                                            variant="primary"
                                                            small
                                                            outlined={selectedVariants?.[e.RiderCategoryCode] !== i + 1}
                                                            active={selectedVariants?.[e.RiderCategoryCode] === i + 1}
                                                            onClick={() => {
                                                                setUserMadeChange(true);
                                                                setSelectedVariants((prevState) => ({
                                                                    ...prevState,
                                                                    [e.RiderCategoryCode]: i + 1,
                                                                }));

                                                                // ### If we click on button, we set selectedProduct to clicked variant -> checkbox will be checked

                                                                const sp = selectedProduct.find(
                                                                    (item) => item.name === e.RiderCategoryCode
                                                                );

                                                                if (sp) {
                                                                    const currentPrice = sumAllValues(
                                                                        e.SuggestedProtections[0].Variants,
                                                                        i + 1
                                                                    );
                                                                    sp.value = i + 1;
                                                                    sp.price = currentPrice;

                                                                    setSelectedProduct([...selectedProduct]);
                                                                }

                                                                // ### Validate on button click
                                                                try {
                                                                    validateYupSchema(
                                                                        values,
                                                                        validationSchema(selectedProduct, i + 1),
                                                                        true,
                                                                        values
                                                                    );
                                                                } catch (err) {
                                                                    scrollToError();
                                                                    return yupToFormErrors(err);
                                                                }

                                                                // ### If we click death + kids/mortgage variant, show popup

                                                                if (
                                                                    e.RiderCategoryCode ===
                                                                        config.RIDER_CATEGORIES.DTH &&
                                                                    i > 0
                                                                ) {
                                                                    ctx.setCloseButton(true);
                                                                    ctx.setPopupVisible(true);
                                                                    popupContent && ctx.setPopupContent(popupContent);
                                                                    ctx.setPopupCloseEvent('suggest_end');
                                                                    triggerGtmEvent('suggest');
                                                                }
                                                            }}
                                                        >
                                                            {v}
                                                        </Button>
                                                    )}
                                                </div>
                                            ))}
                                    </Grid>
                                </Grid>

                                {/* @TODO: Consider transforming this into component */}
                                {renderCoverageItem(
                                    protection.Variants,
                                    protection.Variants[0].RiderGroup.Code,
                                    e.RiderCategoryCode,
                                    ctx,
                                    activeItem,
                                    setActiveItem,
                                    false,
                                    errors,
                                    selectedVariants?.[e.RiderCategoryCode],
                                    i,
                                    popupContent,
                                    buttonLoading,
                                    protection.DisabilityDetails,
                                    (selectedProduct.find((item) => item.name === e.RiderCategoryCode)?.value || 0) > 0
                                )}

                                <Grid colsUneven={[1, 2]} nonResponsive className={styles.tableFooter}>
                                    <div className="text--left">{cms.common.insuranceForPrice}</div>
                                    <div className={styles.levelPrice}>
                                        <div
                                            className={styles.levelPriceValue}
                                            style={{
                                                display: (() => {
                                                    if (e.RiderCategoryCode === config.RIDER_CATEGORIES.DTH) {
                                                        return 'block';
                                                    } else {
                                                        return 'none';
                                                    }
                                                })(),
                                            }}
                                        >
                                            {/* ### Sum all values in one column */}
                                            {submitActivated
                                                ? formatPrice(
                                                      sumAllValues(
                                                          e.SuggestedProtections[0].Variants,
                                                          selectedVariants?.[e.RiderCategoryCode]
                                                      )
                                                  )
                                                : '???'}
                                            &nbsp;{config.CURRENCIES.CZK} / měsíc
                                        </div>

                                        {e.RiderCategoryCode !== config.RIDER_CATEGORIES.DTH && (
                                            <SelectVariantButton
                                                className={styles.checkButton}
                                                checked={getSelectedItemIndex(
                                                    e.RiderCategoryCode,
                                                    selectedVariants?.[e.RiderCategoryCode] || 1
                                                )}
                                                price={
                                                    submitActivated
                                                        ? formatPrice(
                                                              sumAllValues(
                                                                  e.SuggestedProtections[0].Variants,
                                                                  selectedVariants?.[e.RiderCategoryCode]
                                                              )
                                                          )
                                                        : '???'
                                                }
                                                onPositiveClick={() =>
                                                    handleVariantClick(
                                                        e,
                                                        selectedProduct,
                                                        selectedVariants,
                                                        setSelectedProduct
                                                    )
                                                }
                                                onNegativeClick={() =>
                                                    handleVariantClick(
                                                        e,
                                                        selectedProduct,
                                                        selectedVariants,
                                                        setSelectedProduct
                                                    )
                                                }
                                            />
                                        )}

                                        {!submitActivated && (
                                            <RecalculateButton
                                                onClick={() => {
                                                    recalculateForm(errors, values);
                                                }}
                                                className={styles.recalculateButton}
                                                loading={buttonLoading}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Box>
                        );
                    })}
            </Form>
        );
    };

    const renderMortgageForm = (
        validateForm: (FormikValues?: KeyValueProps) => Promise<FormikErrors<any>>,
        setFieldValue: (field: string, value: FormikValueCustomProps, shouldValidate?: boolean | undefined) => void,
        errors?: KeyStringValueProps
    ) => {
        const filteredGroups = getStepRelevantData(
            ctx.formResult?.Participants[0].SuggestedInsurance?.ProtectionGroups,
            'STEP2B_PRODUCTS'
        );

        const data = filteredGroups?.[0]?.SuggestedProtections[1];
        const interestRate = data?.InterestRatePct;

        // ### Run validation with slight delay, because setFieldValue has to precede validation
        const validateWithTimeout = () => {
            setTimeout(() => {
                validateForm();
            }, 10);
        };

        if (data) {
            return (
                <Form
                    className={cn(styles.form, styles.desktopForm)}
                    onChange={() => {
                        setUserMadeChange(true);
                        setSubmitActivated(false);
                        !userChange && setUserChange(true);
                        validateWithTimeout();
                    }}
                >
                    <Box className={cn('mb-7', styles.box, styles.mortgageBox)} loading={buttonLoading}>
                        {interestRate && (
                            <Title tag="h2" size="md" className="mb-6">
                                <>
                                    Smrt a invalidita&nbsp;
                                    {
                                        config.INSURANCE_LEVELS.find(
                                            (item: InsuranceLevelProps) => item.code === selectedProductMortgage
                                        )?.name
                                    }
                                    &nbsp;stupně s klesající pojistnou částkou dle úroku&nbsp;
                                    {interestRate}
                                    &nbsp;%
                                </>
                            </Title>
                        )}

                        <Grid cols={'auto'} className={styles.mortgageGrid}>
                            <div>
                                {selectedProductMortgage &&
                                    renderTextField(
                                        'amount',
                                        'price',
                                        'Pojistná částka',
                                        '',
                                        config.CURRENCIES.CZK,
                                        cn(styles.mortgageInput, 'field-medium'),
                                        undefined,
                                        undefined,
                                        errors?.amount,
                                        undefined,
                                        undefined,
                                        undefined,
                                        true
                                    )}
                                {errors?.amount && <FormItemError text={errors?.amount} />}
                            </div>

                            {selectedProductMortgage && (
                                <SelectedValues
                                    className={styles.selectedValues}
                                    active={selectedProductMortgage}
                                    setActive={setSelectedProductMortgage}
                                    defaultValues={defaultValues}
                                    setFieldValue={setFieldValue}
                                    inputChanged={userChange}
                                    callback={() => validateWithTimeout()}
                                    values={config.INSURANCE_LEVELS}
                                    label={cms.common.invalidityLevelText}
                                />
                            )}
                        </Grid>
                    </Box>
                </Form>
            );
        }
    };

    if (formError) {
        return (
            <Step title={cms.steperror.title} text={null} className={cn(styles.wrapper, className)}>
                <RetryButton />
                <ErrorForm />
            </Step>
        );
    }

    return (
        <Step
            className={cn(styles.wrapper, className)}
            title={pageTitle}
            text={ctx.insuranceType === config.MORTGAGE_CODE ? cms.step2b.text : cms.step2.text}
        >
            {ctx.formResult && (
                <Formik
                    initialValues={{ ...initialValues }}
                    validateOnBlur={false}
                    validateOnChange={ctx.insuranceType !== config.MORTGAGE_CODE}
                    validateOnMount={false}
                    validate={(values) => {
                        try {
                            validateYupSchema(
                                values,
                                ctx.insuranceType === config.MORTGAGE_CODE
                                    ? validationSchemaMortgage(min, max)
                                    : validationSchema(selectedProduct),
                                true,
                                values
                            );
                        } catch (err) {
                            scrollToError();
                            return yupToFormErrors(err);
                        }
                        return {};
                    }}
                    onSubmit={(values) => {
                        setUserMadeChange(false);
                        setIsUpdating(true);
                        setIsSubmitting(true);

                        if (ctx.insuranceType === config.INCOME_CODE) {
                            const updated = updateForm(ctx, values);
                            ctx.setFormResult((prevState) => ({
                                ...prevState,
                                Participants: [
                                    {
                                        ...prevState.Participants[0],
                                        SuggestedInsurance: {
                                            ...prevState.Participants[0].SuggestedInsurance,
                                            ProtectionGroups: updated,
                                        },
                                    },
                                ],
                                Settings: {
                                    ...prevState.Settings,
                                    LastStepName: config.STEP_NAMES.STEP2,
                                },
                            }));
                        } else if (ctx.insuranceType === config.MORTGAGE_CODE) {
                            const updated = updateFormMortgage(ctx, values);
                            ctx.setFormResult(updated);
                        }
                    }}
                    enableReinitialize
                >
                    {({ values, errors, handleSubmit, validateForm, setFieldValue }) =>
                        loaded &&
                        (ctx.insuranceType === config.MORTGAGE_CODE || ctx.insuranceType === config.INCOME_CODE) ? (
                            <>
                                {ctx.insuranceType === config.INCOME_CODE
                                    ? renderIncomeForm(values, errors)
                                    : renderMortgageForm(validateForm, setFieldValue, errors as KeyStringValueProps)}

                                <PriceDiscount
                                    className="mb-6"
                                    titleText="Celkem vás pojištění měsíčně vyjde na"
                                    infoText={!submitActivated ? cms.common.priceHasChangedText : undefined}
                                    priceAfter={!submitActivated ? '?' : totalPrice}
                                />

                                <Grid cols={'auto'} className={styles.buttonsWrapper}>
                                    <div className={styles.hide} ref={bottomRef} />
                                    {submitActivated ? (
                                        <Button
                                            className={styles.button}
                                            variant="primary"
                                            loading={buttonLoading}
                                            onClick={() => {
                                                handleSubmit();
                                                scrollToError();
                                            }}
                                        >
                                            {cms.common.likeButton}
                                        </Button>
                                    ) : (
                                        <Button
                                            className={styles.buttonCalculate}
                                            variant="secondary"
                                            loading={buttonLoading}
                                            onClick={() => {
                                                recalculateForm(errors, values);
                                            }}
                                        >
                                            {cms.common.recalculateButton}
                                        </Button>
                                    )}
                                </Grid>
                            </>
                        ) : (
                            <Loader size="medium" />
                        )
                    }
                </Formik>
            )}
            <ErrorForm />
        </Step>
    );
};
