import { ReactElement, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import cms from '../../../data/cms.json';
import config from '../../../config.json';
import styles from './Step9c.module.scss';
import { InitDataExclusionProps, ExclusionProps, InitDataRiderVersionProps } from '../../../types/initData';

import { Step } from '../../Step/Step';
import { Box } from '../../Box/Box';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { Button } from '../../Button/Button';
import { Title } from '../../Title/Title';
import { AppContext } from '../../Layout/Layout';
import { UnorderedList } from '../../UnorderedList/UnorderedList';
import { PriceDiscount } from '../../PriceDiscount/PriceDiscount';
import { CoverageGroupProps } from '../../../types/model';
import { CustomLocation } from '../../../types/common';
import { triggerGtmEvent } from '../../../utils/triggerGtmEvent';

export const Step9c = (): ReactElement | null => {
    const ctx = useContext(AppContext);
    const navigate = useNavigate();
    const specificExclusionId = ctx.initData?.Lovs.ExclusionCategories.find(
        (cat) => cat.Code === config.SPECIFIC_EXCLUSION_CODE
    )?.Id; //4
    const { state }: CustomLocation = useLocation();
    const { coverageGroups, previousPrice, currentPrice } = state;

    const findExclusions = (exclusions: Array<ExclusionProps>) => {
        const result: Array<InitDataExclusionProps> = [];

        exclusions
            .filter((ex1) => ex1.Category === specificExclusionId)
            .map((e) => {
                const exclusion = ctx.initData?.Exclusions.find((ex2: InitDataExclusionProps) => ex2.Code === e.Code);
                exclusion && result.push(exclusion);
            });

        return result.sort((a, b) => (a.OrderNo || 0) - (b.OrderNo || 0));
    };

    const findRiderVersionName = (riderVersionCode: string) => {
        return ctx.initData?.RiderVersions.find((irv: InitDataRiderVersionProps) => irv.Code === riderVersionCode)
            ?.NameTranslated;
    };

    useEffect(() => {
        ctx.setIsBackButtonVisible(false);
        triggerGtmEvent('price_change');
    }, []);

    return (
        <Step title={cms.step9c.title} text={cms.step9c.text} className={styles.wrapper}>
            {coverageGroups &&
                coverageGroups?.map((cGroup: CoverageGroupProps, i: number) => {
                    if (cGroup.Coverages.length > 0) {
                        const coverages = cGroup.Coverages;

                        return coverages.map((c) => {
                            const cDetail = c.AcceptedVariant.Summary.CoverageDetails[0];
                            const currentFilteredExclusions = findExclusions(cDetail.Exclusions);
                            const notes =
                                (cDetail.UwrEvaluationNotes &&
                                    cDetail.UwrEvaluationNotes.length > 0 &&
                                    cDetail.UwrEvaluationNotes.map((item) => item.Note)) ||
                                [];

                            if (cDetail.CoverageRating.Uwr > 1.0 || currentFilteredExclusions?.length > 0) {
                                return (
                                    <Box className={cn(styles.box, 'mb-6', 'mb-4-m')} key={i}>
                                        <Title size="md" tag="h2" className={cn(styles.title, 'mb-4')}>
                                            {findRiderVersionName(c.RiderVersion.Code) ?? c.RiderVersion.Name}
                                        </Title>

                                        {cDetail.CoverageRating.Uwr > 1.0 && (
                                            <div className={cn(styles.boxItem, 'text-left')}>
                                                <div className={styles.leftColumn}>{cms.step9c.boxIncreaseText}</div>
                                                <div>
                                                    {cDetail.Premium.Frequency} {config.CURRENCIES.CZK} (+&nbsp;
                                                    {((cDetail.CoverageRating.Uwr - 1) * 100).toFixed()}&thinsp;%)
                                                </div>
                                            </div>
                                        )}

                                        {currentFilteredExclusions?.length > 0 && (
                                            <div className={cn(styles.boxItem, 'text-left')}>
                                                <div className={styles.leftColumn}>{cms.step9c.boxExclusionText}</div>
                                                <UnorderedList
                                                    className={styles.list}
                                                    items={currentFilteredExclusions.map((e) => e.Description)}
                                                />
                                            </div>
                                        )}

                                        {notes.length > 0 && (
                                            <div className={cn(styles.boxItem, 'text-left')}>
                                                <div className={styles.leftColumn}>
                                                    {cms.step9c.boxExclusionNoteText}
                                                </div>
                                                <UnorderedList className={styles.list} items={notes} />
                                            </div>
                                        )}
                                    </Box>
                                );
                            }
                        });
                    }

                    return false;
                })}

            {previousPrice && currentPrice && (
                <PriceDiscount
                    className={'mb-6'}
                    titleText="Celkem vás pojištění vyjde na"
                    priceAfter={currentPrice}
                    priceBefore={previousPrice}
                    suffix={'měsíc'}
                />
            )}

            <Button
                variant="primary"
                onClick={(e) => {
                    e?.preventDefault();
                    triggerGtmEvent('price_change_go');
                    navigate(`/${config.SLUGS.STEP9_SLUG}/`, {
                        state: {
                            hasExclusions: true,
                        },
                    });
                }}
            >
                {cms.common.continueButton}
            </Button>

            <ErrorForm />
        </Step>
    );
};
