import { ProtectionVariantProps } from '../types/model';

// ### Function sums all values (PremiumChosen) in one product column in step2 and step3

export const sumAllValues = (variants: Array<ProtectionVariantProps>, key?: number) => {
    if (!key) {
        return 0;
    }

    const result = variants
        .filter((v) => v?.VariantOrder === key)
        .reduce((acc: number, obj) => acc + obj?.PremiumChosen?.Frequency, 0);

    return result || 0;
};
