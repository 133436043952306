import { ReactElement } from 'react';
import cn from 'classnames';
import CarouselComponent, { ArrowProps, DotProps } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import config from '../../config.json';
import styles from './Carousel.module.scss';

import { Box } from '../Box/Box';
import { Icon } from '../Icon/Icon';
import { Title } from '../Title/Title';
import { Paragraph } from '../Paragraph/Paragraph';

interface CarouselItemProps {
    title: string;
    text: string;
}

interface CarouselProps {
    data: ReadonlyArray<CarouselItemProps>;
    className?: string;
}

export const Carousel = ({ data, className }: CarouselProps): ReactElement | null => {
    const responsive = {
        mobile: {
            breakpoint: { max: 9999, min: 0 },
            items: 1,
        },
    };

    const CustomArrow = ({ className, onClick }: ArrowProps & { className?: string }) => {
        return (
            <button className={className} onClick={() => (onClick ? onClick() : false)}>
                <Icon name="next" size="small" />
            </button>
        );
    };

    const CustomDot = ({ onClick, active }: DotProps & { className?: string }) => {
        return <button className={cn(active ? 'active' : 'inactive')} onClick={() => (onClick ? onClick() : false)} />;
    };

    return (
        <Box className={cn(styles.wrapper, className)}>
            <CarouselComponent
                responsive={responsive}
                showDots={true}
                customDot={<CustomDot />}
                customLeftArrow={<CustomArrow className={cn(styles.left, styles.arrow)} />}
                customRightArrow={<CustomArrow className={cn(styles.right, styles.arrow)} />}
                autoPlay
                autoPlaySpeed={config.CAROUSEL_AUTOPLAY_TIME}
                infinite
                pauseOnHover={false}
                containerClass={styles.carousel}
            >
                {data.map((item, i) => (
                    <div className={styles.item} key={i}>
                        <Title tag="h3" size="xl" className="mb-6">
                            {item.title}
                        </Title>
                        <Paragraph>{item.text}</Paragraph>
                    </div>
                ))}
            </CarouselComponent>
        </Box>
    );
};
