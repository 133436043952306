import { ReactElement } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';

import styles from './FormItemError.module.scss';

interface FormItemErrorProps {
    text?: string;
    position?: 'left' | 'center' | 'right';
    className?: string;
}

export const FormItemError = ({ text, position = 'center', className }: FormItemErrorProps): ReactElement | null => {
    if (!text) {
        return null;
    }
    return <div className={cn(styles.wrapper, styles[position], className, 'formItemError')}>{parse(text)}</div>;
};
