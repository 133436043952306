import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './AmountButton.module.scss';

interface AmountButtonProps {
    onClick: () => void;
    actionType?: 'increase' | 'decrease';
    text?: string;
    title?: string;
    className?: string;
}

export const AmountButton = ({
    text,
    actionType = 'increase',
    onClick,
    title,
    className,
}: AmountButtonProps): ReactElement | null => {
    return (
        <button type="button" className={cn(styles.wrapper, className)} onClick={onClick} title={title ?? undefined}>
            <div className={cn(styles.icon, actionType === 'increase' ? styles.increase : styles.decrease)}></div>
            {text && <span className={styles.text}>{text}</span>}
        </button>
    );
};
