import { ReactElement } from 'react';
import NumberFormat from 'react-number-format';
import { FieldInputProps } from 'formik';
import cn from 'classnames';

import config from '../../config.json';
import styles from './TextInput.module.scss';
import { Icon } from '../Icon/Icon';
import { Loader } from '../Loader/Loader';

export interface TextInputProps {
    field: FieldInputProps<string | number>;
    id: string;
    name: string;
    variant?: 'text' | 'number' | 'price' | 'email' | 'decimal';
    suffix?: string;
    min?: number | string; // string is for date range
    max?: number | string;
    placeholder?: string;
    className?: string;
    autoFocus?: boolean;
    error?: string;
    showEditIcon?: boolean;
    loading?: boolean;
    readonly?: boolean;
}

export const TextInput = ({
    field,
    className,
    placeholder,
    suffix,
    min = 0,
    max = 99999999,
    variant = 'text',
    autoFocus,
    error,
    showEditIcon,
    loading = false,
    readonly,
}: TextInputProps): ReactElement | null => {
    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={styles.loading}>
                {loading && (
                    <div className={styles.loader}>
                        <Loader size="small" />
                    </div>
                )}
                {variant === config.INPUT_FIELD_TYPES.PRICE ||
                variant === config.INPUT_FIELD_TYPES.NUMBER ||
                variant === config.INPUT_FIELD_TYPES.DECIMAL ? (
                    <NumberFormat
                        className={cn(styles.input, suffix && styles.inputWithSuffix, error && styles.hasError)}
                        allowNegative={false}
                        fixedDecimalScale={false}
                        decimalScale={variant === config.INPUT_FIELD_TYPES.DECIMAL ? undefined : 0}
                        value={field.value}
                        name={field.name}
                        id={field.name}
                        onChange={field.onChange}
                        data-lpignore="true"
                        disabled={readonly}
                        allowLeadingZeros={false}
                        thousandSeparator=" "
                        decimalSeparator={variant === config.INPUT_FIELD_TYPES.DECIMAL ? ',' : undefined}
                        isNumericString={true}
                        placeholder={placeholder}
                        inputMode={
                            variant === config.INPUT_FIELD_TYPES.PRICE || variant === config.INPUT_FIELD_TYPES.DECIMAL
                                ? 'decimal'
                                : 'numeric'
                        }
                        isAllowed={
                            // cisla a desetina cisla lze zadat jen v rozmezi min, max
                            // cenu neumoznit zadat zapornou, pro maximálni hodnotu pouzit INT_MAX (2 147 483 647),
                            // Number.MAX_SAFE_INTEGER vraci 9 007 199 254 740 992, coz nelze pouzit => pada backend
                            variant === config.INPUT_FIELD_TYPES.NUMBER || variant === config.INPUT_FIELD_TYPES.DECIMAL
                                ? ({ floatValue }) =>
                                      Number(min) <= (floatValue || 0) && (floatValue || 0) <= Number(max)
                                : variant === config.INPUT_FIELD_TYPES.PRICE
                                ? ({ floatValue }) => 0 <= (floatValue || 0) && (floatValue || 0) <= config.INT_MAX
                                : undefined
                        }
                    />
                ) : (
                    // ### Regular text input
                    <input
                        data-lpignore="true"
                        className={cn(styles.input, suffix && styles.inputWithSuffix, error && styles.hasError)}
                        placeholder={placeholder}
                        type={variant}
                        name={field.name}
                        id={field.name}
                        value={field.value || ''}
                        max={max}
                        disabled={readonly}
                        onChange={field.onChange}
                        autoFocus={autoFocus}
                    />
                )}
                {suffix && <span className={styles.suffix}>{suffix}</span>}
                {showEditIcon && !readonly && <Icon name="edit" size="medium" className={styles.editIcon} />}
            </div>
        </div>
    );
};
