import { InitDataSportLevelProtectedProps } from '../types/initData';
import { SearchInputSportProps } from '../types/common';

// ### Function finds protected group of passed sport

export const getSportProtectedGroup = (
    groups: Array<InitDataSportLevelProtectedProps>,
    result: Array<SearchInputSportProps>,
    id: number,
    code: string
) => {
    if (!groups) {
        return;
    }

    return (
        groups.find(
            (item) => item.SportId === id && item.SportLevelId === result.find((item) => item?.code === code)?.level
        )?.ProtectedSportGroupId || null
    );
};
