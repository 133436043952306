import { QuestionProps } from '../types/initData';

// ### Function returns all field names / Codes (string) from given root data (usually slice of initDataQuestionnaire)

const getFieldNames = (root: Array<QuestionProps>) => {
    const result: Array<string> = [];

    // ### Loop children recursivelly
    const getSubItems = (parent: QuestionProps) => {
        if (parent.Questions.length > 0) {
            parent.Questions.map((child) => {
                result.push(child.Code);
                getSubItems(child);
            });
        }
    };

    // ### Loop root question
    root.map((parent) => {
        result.push(parent.Code);
        getSubItems(parent);
    });

    return result;
};

// ### Function is used to fill initialValues to Formik with empty values
// (you need even empty values for form validation!)

export const getInitialValues = (data: Array<QuestionProps>) => {
    const result: { [key: string]: string } = {};
    const fields = getFieldNames(data);
    fields.map((f) => (result[f] = ''));
    return result;
};
