import { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import cn from 'classnames';
import dayjs from 'dayjs';
import DatePicker from 'react-date-picker';

import styles from './Step1.module.scss';
import config from '../../../config.json';
import cms from '../../../data/cms.json';
import { EmploymentTypeProps, InitDataBaseProps } from '../../../types/initData';
import { renderTextField } from '../../../utils/renderTextField';
import { renderToggleField } from '../../../utils/renderToggleField';
import { resolveEmploymentTypeId } from '../../../utils/resolveEmploymentTypeId';
import { scrollToError } from '../../../utils/scrollToError';
import { formatYears } from '../../../utils/formatYears';
import { resolveEmploymentName } from '../../../utils/resolveEmploymentName';
import { setCompleted } from '../../../utils/setCompleted';
import { constructMortgageObject } from '../../../utils/constructMortgageObject';
import { runBackendValidations } from '../../../utils/runBackendValidations';
import { resolveBoundedJob } from '../../../utils/resolveBoundedJob';
import { triggerGtmEvent } from '../../../utils/triggerGtmEvent';

import { Box } from '../../Box/Box';
import { Step } from '../../Step/Step';
import { Grid } from '../../Grid/Grid';
import { SearchInput } from '../../SearchInput/SearchInput';
import { Label } from '../../Label/Label';
import { Button } from '../../Button/Button';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { AppContext } from '../../Layout/Layout';
import { Paragraph } from '../../Paragraph/Paragraph';
import { Title } from '../../Title/Title';
import { FormItemError } from '../../FormItemError/FormItemError';
import { NetIncomePopup } from '../Step2/PopupContent';
import { ContactFormPopup } from '../../ContactFormPopup/ContactFormPopup';
import PhoneNumberInputNew from '../../PhoneNumberInput/PhoneNumberInput';
import { getSexCode } from '../../../utils/getSexCode';
import {
    validationSchemaDefault,
    validationSchemaDefaultWithContactInfo,
    validationSchemaMortgage,
    validationSchemaMortgageWithContactInfo,
} from './validations';
import { unlockScroll } from '../../../utils/bodyScrollLock';
import { formatSimpleaDate } from '../../../utils/formatSimpleaDate';
import { SuggestedInsuranceCalculationKind } from '../../../enums/suggestedInsuranceCalculationKind';
import { removeWhitespace } from '../../../utils/removeWhitespace';
import axios from 'axios';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import { SexType } from '../../../enums/sexType';
import { getPhonePrefix } from '../../../utils/getPhonePrefix';
import { DEFAULT_PHONE_PREFIX } from '../../../constants/defaults';

interface Step1Props {
    className?: string;
}

export const Step1 = ({ className }: Step1Props): ReactElement | null => {
    const ctx = useContext(AppContext);
    const navigate = useNavigate();
    const [job, setJob] = useState<string[]>([]);
    const [age, setAge] = useState(0);
    const [error, setError] = useState<string | null>(null);
    const [submitted, setSubmitted] = useState(false);
    const [initialData, setInitialData] = useState<{ [key: string]: string | null }>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [birthDateValue, setBirthDateValue] = useState<Date>();
    const [showContactInfo, setShowContactInfo] = useState(true);
    const [requireContactInfo, setRequireContactInfo] = useState(false);

    useEffect(() => {
        const res = dayjs().diff(dayjs(birthDateValue), 'year');
        setAge(res);
    }, [birthDateValue]);

    // ### Terms agreement popup
    const popupContent = (
        <div className={styles.popup}>
            <Title tag="h2" className="mb-3">
                {cms.common.automaticCalculationTitle}
            </Title>
            <Paragraph>{cms.common.acceptAutomaticCalculationText}</Paragraph>
            <Button
                className="mb-3"
                variant="primary"
                onClick={() => {
                    triggerGtmEvent('auto_price_go');
                    ctx.setPopupVisible(false);
                    ctx.setPopupContentVariables(null);
                    unlockScroll();

                    localStorage.setItem('localData', JSON.stringify(ctx.formResult));

                    showContactInfo && createLeadCampaign();

                    // ### If user has disability pension -> insurable
                    const hasDisabilityPension =
                        ctx.formResult.Participants[0].Occupation.OccupationCode === config.DISABILITY_PENSION_CODE;
                    setCompleted(hasDisabilityPension ? 8 : 1);
                    navigate(
                        hasDisabilityPension
                            ? `/${config.SLUGS.STEP9B_SLUG}/`
                            : ctx.insuranceType === config.INCOME_CODE
                            ? `/${config.SLUGS.STEP2A_SLUG}/`
                            : `/${config.SLUGS.STEP2B_SLUG}/`
                    );

                    setIsSubmitting(false);
                }}
            >
                {cms.common.agreeButton}
            </Button>
            <Paragraph
                className={cn(styles.popupInfo, 'mb-0')}
                small
                children={
                    <>
                        {cms.common.declineAutomaticCalculationText1}
                        <span
                            className={styles.openContactFormLink}
                            onClick={() => {
                                ctx.setPopupContent(<ContactFormPopup resetForm />);
                                ctx.setPopupContentVariables({ uncloseable: false });
                                ctx.setPopupVisible(true);
                                ctx.setCloseButton(true);

                                // ### We need to provide higher order function, otherwise function will be immediatelly invoked
                                ctx.setPopupCloseCallback(() => () => {
                                    ctx.setPopupContent(popupContent);
                                    ctx.setPopupContentVariables({ uncloseable: true });
                                    ctx.setPopupVisible(true);
                                    ctx.setCloseButton(false);
                                    ctx.setPopupCloseCallback(() => null);
                                });
                            }}
                        >
                            {cms.common.declineAutomaticCalculationText2}
                        </span>
                    </>
                }
            />
        </div>
    );

    // pokud klient přišel přes lead, nevyžadovat po něm již kontaktní informace (skrýt inputy)
    const shouldShowContactInfo = () => {
        if (localStorage.getItem('isLeadCreated') === 'true') {
            setShowContactInfo(false);
            triggerGtmEvent('started_contacts_lead');
        } else {
            setShowContactInfo(true);
            triggerGtmEvent('started_contacts_yes');
        }
    };

    const shouldContactInfoBeRequired = () => {
        // nejprve zkontrolujem z localStorage zda už nemáme informaci zda jsou kontakty povinné
        if (localStorage.getItem('requireContactInfo') === 'true') {
            setRequireContactInfo(true);
        } else if (localStorage.getItem('requireContactInfo') === 'false') {
            setRequireContactInfo(false);
        } else {
            // pokud nemáme informaci zda kontaktní informace požadovat, tak dle pravdepodobnosti vygenerovat
            const usersWithRequiredContactInfoPct: number =
                (ctx.initData &&
                    ctx.initData?.Configurations.find((obj) => obj.Code === config.USERS_WITH_REQUIRED_CONTACT_INFO_PCT)
                        ?.ValueDecimal) ??
                0;
            const random: number = Math.floor(Math.random() * 100) + 1; // 1, 2, ... 100
            if (random <= usersWithRequiredContactInfoPct) {
                setRequireContactInfo(true);
                localStorage.setItem('requireContactInfo', 'true');
            } else {
                setRequireContactInfo(false);
                localStorage.setItem('requireContactInfo', 'false');
            }
        }
    };

    const createLeadCampaign = () => {
        // nezakládat lead pokud se nevyplní oba kontaktní údaje
        if (
            ctx.formResult.Participants[0].Contact.Email !== null &&
            ctx.formResult.Participants[0].Contact.Phone !== null
        ) {
            axios
                .post(
                    `${getBaseUrl()}api/online/leads/create-lead-campaign-1`,
                    {
                        onlinePolicyExternalId: ctx.formResult.ExternalId,
                        clientDataJson: JSON.stringify({
                            Email: ctx.formResult.Participants[0].Contact.Email,
                            Phone: {
                                PhoneNo: ctx.formResult.Participants[0].Contact.Phone,
                                PhonePrefix: ctx.formResult.Participants[0].Contact.PhonePrefix.Prefix,
                            },
                        }),
                    },
                    { timeout: config.FETCH_TIMEOUT }
                )
                .then((response) => {
                    if (response) {
                        localStorage.setItem('isLeadCreated', 'true');
                    } else {
                        triggerGtmEvent('error');
                    }
                })
                .catch((error) => {
                    triggerGtmEvent('error');
                    console.error(error);
                });
        }
    };

    // ### Effect is used to set step2Submitted, which tells us, user already submitted this step,
    // when comming back to this step
    useEffect(() => {
        localStorage.setItem('step2Submitted', 'false');
        localStorage.setItem('currentStep', '1');
        ctx.setPopupVisible(false);
        ctx.setIsBackButtonVisible(false);
        shouldShowContactInfo();
    }, []);

    useEffect(() => {
        if (ctx.initData) {
            shouldContactInfoBeRequired();
            const storageData = localStorage.getItem('localData');
            const parsedData = storageData ? JSON.parse(storageData) : null;

            // ### If we have data stored in localStorage, fill them into Formik values
            if (parsedData) {
                const p = parsedData.Participants[0];
                const values = {
                    hasHealthInsurance:
                        p.Occupation.HasHealthInsurance === null
                            ? ''
                            : p.Occupation.HasHealthInsurance
                            ? config.QUESTIONNAIRE.ANSWER_YES
                            : config.QUESTIONNAIRE.ANSWER_NO,
                    hasOfficeOccupation:
                        p.Occupation.HasOfficeOccupation === null
                            ? null
                            : p.Occupation.HasOfficeOccupation
                            ? config.QUESTIONNAIRE.ANSWER_YES
                            : config.QUESTIONNAIRE.ANSWER_NO,
                    incomeSource: resolveEmploymentName(p.Occupation.OccupationCode, p.Occupation.EmploymentType),
                    job: p.Occupation.OccupationCode || '',
                    monthlyIncome: p.Income?.NetMonthlyIncome?.toString() || '',
                    mortgage: p.LoanSummary.HasMortgage
                        ? config.QUESTIONNAIRE.ANSWER_YES
                        : config.QUESTIONNAIRE.ANSWER_NO,
                    mortgageAlone:
                        typeof p.LoanSummary.Mortgage?.IsOnlyPayer === 'boolean'
                            ? p.LoanSummary.Mortgage?.IsOnlyPayer
                                ? config.QUESTIONNAIRE.ANSWER_YES
                                : config.QUESTIONNAIRE.ANSWER_NO
                            : '',
                    mortgageAmount: p.LoanSummary.Mortgage?.RemainingAmount ?? '',
                    mortgageInterest: p.LoanSummary.Mortgage?.InterestRatePct ?? '',
                    mortgagePeriod: p.LoanSummary.Mortgage?.RemainingYears ?? '',
                    sex: getSexCode(p.Identification.Sex, ctx),
                    phone: null,
                    phonePrefix: DEFAULT_PHONE_PREFIX,
                    email: null,
                };

                values && setInitialData(values);
            }
        }
    }, [ctx.initData]);

    // ### If no job selected, show error (this is out of Formik validation funcionality)
    useEffect(() => {
        job.length > 0 && setError(null);
    }, [job]);

    // ### Fill job wehen comming back from next steps
    useEffect(() => {
        if (initialData?.job) {
            setJob([initialData.job]);
        }
    }, [initialData]);

    useEffect(() => {
        if (isSubmitting && ctx.formResult) {
            runBackendValidations(
                'validate-form',
                ctx,
                null,
                () => {
                    ctx.setPopupContent(popupContent);
                    ctx.setPopupContentVariables({ uncloseable: true });
                    ctx.setCloseButton(false);
                    ctx.setPopupVisible(true);
                    triggerGtmEvent('auto_price');
                },
                () => setIsSubmitting(false)
            );
        }
    }, [isSubmitting, ctx.formResult]);

    // ### RENDER

    return (
        <Step
            className={cn(styles.wrapper, className)}
            title={cms.step1.title}
            text={showContactInfo ? cms.step1.text2 : cms.step1.text}
        >
            <Formik
                enableReinitialize
                initialValues={
                    initialData || {
                        hasHealthInsurance: '',
                        hasOfficeOccupation: null,
                        incomeSource: '',
                        job: '',
                        monthlyIncome: '',
                        mortgage: '',
                        mortgageAlone: '',
                        mortgageAmount: '',
                        mortgageInterest: '',
                        mortgagePeriod: '',
                        sex: '',
                        phone: '',
                        phonePrefix: DEFAULT_PHONE_PREFIX,
                        email: '',
                    }
                }
                validateOnBlur={false}
                validateOnChange={submitted}
                validateOnMount={false}
                validate={(values) => {
                    !submitted && setSubmitted(true);
                    try {
                        validateYupSchema(
                            values,
                            ctx.insuranceType === config.MORTGAGE_CODE
                                ? showContactInfo
                                    ? validationSchemaMortgageWithContactInfo
                                    : validationSchemaMortgage
                                : showContactInfo
                                ? validationSchemaDefaultWithContactInfo
                                : validationSchemaDefault,
                            true,
                            values
                        );
                    } catch (err) {
                        return yupToFormErrors(err);
                    }
                    return {};
                }}
                onSubmit={(values) => {
                    let occupation: string | undefined;

                    // if income source has job to be setted
                    if (
                        values.incomeSource === config.QUESTIONNAIRE.SELF_EMPLOYED ||
                        values.incomeSource === config.QUESTIONNAIRE.EMPLOYEE ||
                        ctx.insuranceType === config.MORTGAGE_CODE
                    ) {
                        if (values['hasOfficeOccupation'] === config.QUESTIONNAIRE.ANSWER_YES) {
                            // if has office occupation, than we presset admin off and set it to data aswell
                            occupation = 'ADM_OFF';
                            setJob(['ADM_OFF']);
                        } else {
                            // else we set selected job
                            occupation = job[0];
                        }
                    } else {
                        values['hasOfficeOccupation'] = null;
                        occupation = resolveBoundedJob(ctx, values.incomeSource);
                    }

                    // ### Block next step if no job selected or it is mortgage scenario
                    if (
                        occupation === null &&
                        (values['incomeSource'] === config.QUESTIONNAIRE.SELF_EMPLOYED ||
                            values['incomeSource'] === config.QUESTIONNAIRE.EMPLOYEE ||
                            ctx.insuranceType === config.MORTGAGE_CODE)
                    ) {
                        setError(cms.validations.REQUIRED_OCCUPATION_FIELD);
                        return;
                    }

                    // Block next step if HasOfficeOccupation is not setted and its need to be
                    if (
                        values['hasOfficeOccupation'] === null &&
                        (values['incomeSource'] === config.QUESTIONNAIRE.SELF_EMPLOYED ||
                            values['incomeSource'] === config.QUESTIONNAIRE.EMPLOYEE ||
                            ctx.insuranceType === config.MORTGAGE_CODE)
                    ) {
                        setError(cms.validations.REQUIRED_HASOFFICEOCCUPATION_FIELD);
                        return;
                    }

                    // ### Block next step if age outside range
                    if (age < config.QUESTIONNAIRE.AGE.MIN || age >= config.QUESTIONNAIRE.AGE.MAX) {
                        return;
                    }

                    ctx.setFormResult((prevState) => ({
                        ...prevState,
                        BeginningDt: formatSimpleaDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)), // zítřek
                        IllustrationDt: formatSimpleaDate(new Date()),
                        Participants: [
                            {
                                ...prevState.Participants[0],
                                SuggestedInsuranceCalculationType:
                                    ctx.insuranceType === config.INCOME_CODE
                                        ? SuggestedInsuranceCalculationKind.Income
                                        : SuggestedInsuranceCalculationKind.Mortgage,
                                Income: values.monthlyIncome
                                    ? {
                                          NetMonthlyIncome: parseInt(values.monthlyIncome.replace(/ /g, '')),
                                      }
                                    : null,
                                Identification: {
                                    ...prevState.Participants[0].Identification,
                                    BirthDt: formatSimpleaDate(birthDateValue),
                                    CurrentAge: age,
                                    Sex:
                                        ctx.initData?.Lovs.Sex.find((obj: InitDataBaseProps) => obj.Code === values.sex)
                                            ?.Id ?? null,
                                },
                                Occupation: {
                                    ...prevState.Participants[0].Occupation,
                                    OccupationCode: occupation ?? null,
                                    OccupationCategory:
                                        ctx.initData?.Occupations.find(
                                            (o: EmploymentTypeProps) => o.Code === occupation
                                        )?.OccupationCategoryId || 0,
                                    EmploymentType: resolveEmploymentTypeId(ctx, values) ?? null,
                                    HasHealthInsurance:
                                        values.hasHealthInsurance === config.QUESTIONNAIRE.ANSWER_YES ||
                                        values.incomeSource === config.QUESTIONNAIRE.EMPLOYEE,
                                    HasOfficeOccupation:
                                        values.hasOfficeOccupation === config.QUESTIONNAIRE.ANSWER_YES
                                            ? true
                                            : values.hasOfficeOccupation === config.QUESTIONNAIRE.ANSWER_NO
                                            ? false
                                            : null,
                                },
                                LoanSummary: {
                                    HasMortgage:
                                        values.mortgage === config.QUESTIONNAIRE.ANSWER_YES ||
                                        ctx.insuranceType === config.MORTGAGE_CODE,
                                    Mortgage: constructMortgageObject(
                                        prevState?.Participants[0].LoanSummary.Mortgage,
                                        values
                                    ),
                                },
                                Contact: {
                                    ...prevState.Participants[0].Contact,
                                    Phone:
                                        values.phone != null
                                            ? values.phone.length === 0
                                                ? null
                                                : removeWhitespace(values.phone)
                                            : values.phone,
                                    PhonePrefix: getPhonePrefix(values.phonePrefix, ctx.initData),
                                    Email: values.email != null && values.email.length === 0 ? null : values.email,
                                },
                            },
                        ],
                        Settings: {
                            ...prevState.Settings,
                            LastStepName: config.STEP_NAMES.STEP1,
                        },
                    }));

                    setIsSubmitting(true);
                }}
            >
                {({ values, errors, touched, handleSubmit }) => (
                    <Form className={styles.form}>
                        <Box className="mb-6 mb-3-m" innerWrapper loading={isSubmitting}>
                            <Grid cols={2} className="mb-8">
                                <div>
                                    <Label
                                        htmlFor="sex"
                                        className={ctx.insuranceType === config.MORTGAGE_CODE ? 'text--bold' : ''}
                                    >
                                        {cms.step1.box.genderLabel}
                                    </Label>
                                    <Grid cols="auto" id="sex" tag="fieldset">
                                        {renderToggleField(
                                            'sex',
                                            'male',
                                            'radio',
                                            SexType.Male,
                                            '',
                                            false,
                                            '',
                                            false,
                                            !!(touched.sex && errors.sex),
                                            'Muž'
                                        )}
                                        {renderToggleField(
                                            'sex',
                                            'female',
                                            'radio',
                                            SexType.Female,
                                            '',
                                            false,
                                            '',
                                            false,
                                            !!(touched.sex && errors.sex),
                                            'Žena'
                                        )}
                                    </Grid>
                                    {errors.sex && touched.sex && <FormItemError text={errors.sex as string} />}
                                </div>

                                <div className="flex-col flex-a-center">
                                    <Label className={ctx.insuranceType === config.MORTGAGE_CODE ? 'text--bold' : ''}>
                                        {cms.step1.box.birthdateLabel}
                                    </Label>

                                    <DatePicker
                                        onChange={setBirthDateValue}
                                        value={birthDateValue}
                                        format="dd-MM-y"
                                        disableCalendar
                                        dayPlaceholder="dd"
                                        monthPlaceholder="mm"
                                        yearPlaceholder="rrrr"
                                        maxDate={new Date()}
                                        locale="cs-CZ"
                                        required
                                        className={
                                            (birthDateValue === undefined ||
                                                age < config.QUESTIONNAIRE.AGE.MIN ||
                                                age >= config.QUESTIONNAIRE.AGE.MAX) &&
                                            submitted
                                                ? styles.birthDtError
                                                : ''
                                        }
                                    />

                                    {(age < config.QUESTIONNAIRE.AGE.MIN || age >= config.QUESTIONNAIRE.AGE.MAX) &&
                                        submitted &&
                                        birthDateValue && <FormItemError text={cms.validations.INSURABLE_PERSON} />}

                                    {!birthDateValue && submitted && (
                                        <FormItemError text={cms.validations.REQUIRED_VALID_DATE_FIELD} />
                                    )}
                                </div>
                            </Grid>
                            {showContactInfo ? (
                                <Grid cols={2} className="mb-8">
                                    <div>
                                        {renderTextField(
                                            'email',
                                            'text',
                                            ctx.insuranceType === config.MORTGAGE_CODE ? '<b>Email</b>' : 'Email',
                                            undefined,
                                            undefined,
                                            'field-large',
                                            undefined,
                                            undefined,
                                            (touched.email && (errors.email as string)) || '',
                                            !requireContactInfo,
                                            undefined,
                                            true,
                                            true
                                        )}

                                        {errors.email && touched.email && (
                                            <FormItemError text={errors.email as string} />
                                        )}
                                    </div>
                                    <div>
                                        <PhoneNumberInputNew
                                            className="field-large"
                                            namePhone="phone"
                                            namePhonePrefix="phonePrefix"
                                            label={
                                                ctx.insuranceType === config.MORTGAGE_CODE
                                                    ? '<b>Telefon</b>'
                                                    : 'Telefon'
                                            }
                                            showEditIcon
                                            error={(touched.phone && errors.phone) || ''}
                                            optional={!requireContactInfo}
                                        />
                                    </div>
                                </Grid>
                            ) : (
                                <></>
                            )}

                            {ctx.insuranceType === config.MORTGAGE_CODE ? (
                                <>
                                    <Grid cols={2} className="mb-8">
                                        <div>
                                            <Label
                                                htmlFor="mortgageAlone"
                                                description="Tedy nemáte spoludlužníka - např. partnera"
                                                children={'<b>Platíte hypotéku sám?</b>'}
                                            />
                                            <Grid cols="auto" tag="fieldset">
                                                {renderToggleField(
                                                    'mortgageAlone',
                                                    'mortgageAlone-yes',
                                                    'radio',
                                                    config.QUESTIONNAIRE.ANSWER_YES,
                                                    '',
                                                    false,
                                                    '',
                                                    false,
                                                    !!(errors.mortgageAlone && touched.mortgageAlone)
                                                )}
                                                {renderToggleField(
                                                    'mortgageAlone',
                                                    'mortgageAlone-no',
                                                    'radio',
                                                    config.QUESTIONNAIRE.ANSWER_NO,
                                                    '',
                                                    false,
                                                    '',
                                                    false,
                                                    !!(errors.mortgageAlone && touched.mortgageAlone)
                                                )}
                                            </Grid>
                                            {errors.mortgageAlone && touched.mortgageAlone && (
                                                <FormItemError text={errors.mortgageAlone as string} />
                                            )}
                                        </div>
                                        <div>
                                            {renderTextField(
                                                'mortgageInterest',
                                                'number',
                                                ['<b>Jaký máte u hypotéky úrok?</b>', 'Stačí zhruba na celá procenta'],
                                                undefined,
                                                '%',
                                                'field-small',
                                                undefined,
                                                undefined,
                                                (touched.mortgageInterest && (errors.mortgageInterest as string)) || '',
                                                undefined,
                                                undefined,
                                                undefined,
                                                true
                                            )}

                                            {errors.mortgageInterest && touched.mortgageInterest && (
                                                <FormItemError text={errors.mortgageInterest as string} />
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid cols={2} className="mb-8">
                                        <div>
                                            {renderTextField(
                                                'mortgageAmount',
                                                'price',
                                                ['<b>Jaká je nesplacená výše hypotéky?</b>', 'Stačí zaokrouhleně'],
                                                undefined,
                                                config.CURRENCIES.CZK,
                                                'field-medium',
                                                0,
                                                config.QUESTIONNAIRE.MORTGAGE_MAXAMOUNT,
                                                (touched.mortgageAmount && (errors.mortgageAmount as string)) || '',
                                                undefined,
                                                undefined,
                                                undefined,
                                                true
                                            )}

                                            {errors.mortgageAmount && touched.mortgageAmount && (
                                                <FormItemError text={errors.mortgageAmount as string} />
                                            )}
                                        </div>
                                        <div>
                                            {renderTextField(
                                                'mortgagePeriod',
                                                'number',
                                                [
                                                    '<b>Jak dlouho ještě budete splácet?</b>',
                                                    'Stačí zaokrouhleně na celé roky',
                                                ],
                                                undefined,
                                                formatYears(values['mortgagePeriod']),
                                                'field-small',
                                                undefined,
                                                undefined,
                                                (touched.mortgagePeriod && (errors.mortgagePeriod as string)) || '',
                                                undefined,
                                                undefined,
                                                undefined,
                                                true
                                            )}

                                            {errors.mortgagePeriod && touched.mortgagePeriod && (
                                                <FormItemError text={errors.mortgagePeriod as string} />
                                            )}
                                        </div>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <div className="mb-8">
                                        <Label htmlFor="incomeSource">{cms.step1.box.sourceOfIncomeLabel}</Label>
                                        <Grid cols="auto" tag="fieldset">
                                            {renderToggleField(
                                                'incomeSource',
                                                'zamestnani',
                                                'radio',
                                                config.QUESTIONNAIRE.EMPLOYEE,
                                                '',
                                                false,
                                                '',
                                                false,
                                                !!(touched.incomeSource && errors.incomeSource)
                                            )}
                                            {renderToggleField(
                                                'incomeSource',
                                                'osvc',
                                                'radio',
                                                config.QUESTIONNAIRE.SELF_EMPLOYED,
                                                '',
                                                false,
                                                '',
                                                false,
                                                !!(touched.incomeSource && errors.incomeSource)
                                            )}
                                            {renderToggleField(
                                                'incomeSource',
                                                'materska',
                                                'radio',
                                                config.OCCUPATION_TYPES.MAT_PRN_LV,
                                                '',
                                                false,
                                                '',
                                                false,
                                                !!(touched.incomeSource && errors.incomeSource)
                                            )}
                                            {renderToggleField(
                                                'incomeSource',
                                                'podnikani',
                                                'radio',
                                                config.OCCUPATION_TYPES.EXEC_DIRTR,
                                                '',
                                                false,
                                                '',
                                                false,
                                                !!(touched.incomeSource && errors.incomeSource)
                                            )}
                                            {renderToggleField(
                                                'incomeSource',
                                                'student',
                                                'radio',
                                                config.OCCUPATION_TYPES.STUDENT,
                                                '',
                                                false,
                                                '',
                                                false,
                                                !!(touched.incomeSource && errors.incomeSource)
                                            )}
                                            {renderToggleField(
                                                'incomeSource',
                                                'podpora',
                                                'radio',
                                                config.OCCUPATION_TYPES.UNEMPLOYED,
                                                '',
                                                false,
                                                '',
                                                false,
                                                !!(touched.incomeSource && errors.incomeSource)
                                            )}
                                            {renderToggleField(
                                                'incomeSource',
                                                'starobni-duchod',
                                                'radio',
                                                config.OCCUPATION_TYPES.OLD_PENS,
                                                '',
                                                false,
                                                '',
                                                false,
                                                !!(touched.incomeSource && errors.incomeSource)
                                            )}
                                            {renderToggleField(
                                                'incomeSource',
                                                'pecujici-osoba',
                                                'radio',
                                                config.OCCUPATION_TYPES.CARER_CLSP,
                                                '',
                                                false,
                                                '',
                                                false,
                                                !!(touched.incomeSource && errors.incomeSource)
                                            )}
                                            {renderToggleField(
                                                'incomeSource',
                                                'invalidni-duchod',
                                                'radio',
                                                config.OCCUPATION_TYPES.DISAB_PENS,
                                                '',
                                                false,
                                                '',
                                                false,
                                                !!(touched.incomeSource && errors.incomeSource)
                                            )}
                                        </Grid>
                                        {touched.incomeSource && errors.incomeSource && (
                                            <FormItemError text={errors.incomeSource as string} />
                                        )}
                                    </div>

                                    <Grid
                                        cols={
                                            values['incomeSource'] === config.QUESTIONNAIRE.SELF_EMPLOYED ? 2 : 'auto'
                                        }
                                        className="mb-8"
                                    >
                                        <div>
                                            {renderTextField(
                                                'monthlyIncome',
                                                'price',
                                                cms.step1.box.netmonthlyLabel,
                                                undefined,
                                                config.CURRENCIES.CZK,
                                                'field-medium',
                                                0,
                                                9999999,
                                                (touched.monthlyIncome && (errors.monthlyIncome as string)) || '',
                                                false,
                                                () => {
                                                    ctx.setPopupContent(NetIncomePopup);
                                                    ctx.setPopupContentVariables({ uncloseable: false });
                                                    ctx.setCloseButton(true);
                                                    ctx.setPopupVisible(true);
                                                },
                                                undefined,
                                                true
                                            )}
                                            {touched.monthlyIncome && errors.monthlyIncome && (
                                                <FormItemError text={errors.monthlyIncome as string} />
                                            )}
                                        </div>

                                        {values['incomeSource'] === config.QUESTIONNAIRE.SELF_EMPLOYED && (
                                            <div>
                                                <Label htmlFor="hasHealthInsurance">
                                                    {cms.step1.box.voluntaryhealthinsuranceLabel}
                                                </Label>
                                                <Grid cols="auto" tag="fieldset">
                                                    {renderToggleField(
                                                        'hasHealthInsurance',
                                                        'hasHealthInsurance-yes',
                                                        'radio',
                                                        config.QUESTIONNAIRE.ANSWER_YES,
                                                        '',
                                                        false,
                                                        '',
                                                        false,
                                                        !!(errors.hasHealthInsurance && touched.hasHealthInsurance)
                                                    )}
                                                    {renderToggleField(
                                                        'hasHealthInsurance',
                                                        'hasHealthInsurance-no',
                                                        'radio',
                                                        config.QUESTIONNAIRE.ANSWER_NO,
                                                        '',
                                                        false,
                                                        '',
                                                        false,
                                                        !!(errors.hasHealthInsurance && touched.hasHealthInsurance)
                                                    )}
                                                </Grid>
                                                {errors.hasHealthInsurance && touched.hasHealthInsurance && (
                                                    <FormItemError text={errors.hasHealthInsurance as string} />
                                                )}
                                            </div>
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Box>
                        {(values['incomeSource'] === config.QUESTIONNAIRE.SELF_EMPLOYED ||
                            values['incomeSource'] === config.QUESTIONNAIRE.EMPLOYEE ||
                            ctx.insuranceType === config.MORTGAGE_CODE) && (
                            <Box className="mb-6 mb-3-m" loading={isSubmitting}>
                                <div>
                                    <Label
                                        htmlFor="hasOfficeOccupation"
                                        children={'<b>Děláte převážně kancelářskou práci?</b>'}
                                        description={'Ať už na počítači nebo administrativu.'}
                                    />
                                    <Grid cols="auto" tag="fieldset">
                                        {renderToggleField(
                                            'hasOfficeOccupation',
                                            'hasOfficeOccupation-yes',
                                            'radio',
                                            config.QUESTIONNAIRE.ANSWER_YES,
                                            '',
                                            false,
                                            '',
                                            false,
                                            !!(errors.hasOfficeOccupation && touched.hasOfficeOccupation)
                                        )}
                                        {renderToggleField(
                                            'hasOfficeOccupation',
                                            'hasOfficeOccupation-no',
                                            'radio',
                                            config.QUESTIONNAIRE.ANSWER_NO,
                                            '',
                                            false,
                                            '',
                                            false,
                                            !!(errors.hasOfficeOccupation && touched.hasOfficeOccupation)
                                        )}
                                    </Grid>
                                    {errors.hasOfficeOccupation && touched.hasOfficeOccupation && (
                                        <FormItemError text={errors.hasOfficeOccupation as string} />
                                    )}
                                </div>
                            </Box>
                        )}

                        {(values['incomeSource'] === config.QUESTIONNAIRE.SELF_EMPLOYED ||
                            values['incomeSource'] === config.QUESTIONNAIRE.EMPLOYEE ||
                            ctx.insuranceType === config.MORTGAGE_CODE) &&
                            values['hasOfficeOccupation'] === config.QUESTIONNAIRE.ANSWER_NO &&
                            setJob &&
                            job && (
                                <Box className={cn(styles.searchBox, 'mb-8', 'mb-3-m')} loading={isSubmitting}>
                                    <Field
                                        name="search"
                                        id="search"
                                        label={cms.common.occupationNameQuestion}
                                        placeholder="Začněte psát pro hledání..."
                                        withHeader
                                        component={SearchInput}
                                        handleResult={setJob}
                                        passedResult={job}
                                        type={config.SEARCHBOX_TYPE.OCCUPATION}
                                    />
                                    {error && <FormItemError className="mt-4" position="left" text={error} />}
                                </Box>
                            )}

                        <Button
                            variant="primary"
                            onClick={() => {
                                handleSubmit();
                                scrollToError();
                            }}
                            loading={isSubmitting}
                        >
                            {cms.common.calculateButton}
                        </Button>
                    </Form>
                )}
            </Formik>

            <ErrorForm />
        </Step>
    );
};
