import cn from 'classnames';

import styles from './Step2.module.scss';

import { Title } from '../../Title/Title';
import { Paragraph } from '../../Paragraph/Paragraph';
import { UnorderedList } from '../../UnorderedList/UnorderedList';
import { ParticipantProps } from '../../../types/model';

export const DeathPopup = (
    <div className={cn(styles.helpPopup)}>
        <Title tag="h2" size="lg" className="mb-4">
            Smrt
        </Title>

        <Paragraph
            children={
                '<span>V Simplea neřešíme, jak k úmrtí došlo, jestli to bylo&nbsp;</span><strong>následkem úrazu</strong><span>&nbsp;nebo nad vámi zvítězila&nbsp;</span><strong>těžká nemoc.</strong><span>&nbsp;Můžete se spolehnout, že když dojde na nejhorší, vaše rodina to finančně zvládne. Pojišťujeme smrt z jakékoli příčiny.</span>'
            }
        />

        <UnorderedList
            className={cn(styles.list, 'mb-6')}
            items={[
                'Neřešíme příčinu a plníme.',
                'Pojistná částka je lineárně klesající – to znamená, že po každém roce se pojistná částka sníží o 1/{duration}.',
                'Pojištění bude trvat {duration} let, tj. do věku {age} let.',
                'Důležité zejména pro živitele rodiny a klienty s hypotékou.',
            ]}
        />
    </div>
);

export const InvalidityPopup = (
    <div className={cn(styles.helpPopup)}>
        <Title tag="h2" size="lg" className="mb-4">
            Invalidita
        </Title>

        <Paragraph
            children={
                '<span>Pojištění pro případ, že následkem vážné a&nbsp;dlouhodobé&nbsp;</span><strong>nemoci</strong><span>&nbsp;nebo&nbsp;</span><strong>úrazu</strong><span>&nbsp;nemůžete dále plnohodnotně pracovat, a&nbsp;dlouhodobě tak přicházíte o část svých příjmů. Klesne váš životní standard, potřebujete platit závazky, a do toho máte další náklady na léčbu nebo přestavbu domova. Sociální správa určí stupeň invalidity (1 až 3) a přizná invalidní důchod, který ale zdaleka nenahradí váš dosavadní příjem.</span>'
            }
        />

        <UnorderedList
            className={cn(styles.list, 'mb-6')}
            items={[
                'Nikdy nezpochybňujeme rozhodnutí sociální správy o přidělení invalidity, a v jasných případech na ně dokonce ani nemusíte čekat a plnění od nás dostanete rovnou.',
                'Pojistná částka je lineárně klesající – to znamená, že po každém roce se pojistná částka sníží o 1/{duration}.',
                'Pojištění bude trvat {duration} let, tj. do věku {age} let.',
                '<strong>Automatickou součástí je i pojištění rekreačních a&nbsp;rizikových<span>&nbsp;</span><a target="_blank" href="https://www.simplea.cz/userfiles/tabulka-sportu-simplea-12-2022_16709382740916.pdf" class="simple-link" rel="noopener">sportů</a><span>&nbsp;</span>ZDARMA.</strong>',
                'Důležité úplně pro všechny.',
                'Proč zajistit příjmy na 120&thinsp;%? Protože v případě invalidity často náklady oproti současnosti vzrostou o výlohy na léčbu, adaptaci bydlení apod.',
            ]}
        />
    </div>
);

export const IncapacityPopup = (participant: ParticipantProps) => {
    return (
        <div className={cn(styles.helpPopup)}>
            <Title tag="h2" size="lg" className="mb-4">
                Dočasný výpadek příjmu
            </Title>

            {participant && participant?.Occupation?.HasHealthInsurance ? (
                <Paragraph>
                    <>
                        Pokud jste v pracovní neschopnosti, pobíráte od státu nemocenskou, která ale plně nenahradí váš
                        dosavadní příjem. Pro tyto případy slouží <strong>pojištění pracovní neschopnosti</strong>, jež
                        vám pomůže zachovat dosavadní životní standard, než se plně uzdravíte.
                    </>
                </Paragraph>
            ) : (
                <Paragraph>
                    <>
                        Pokud jste v pracovní neschopnosti, nemáte jako živnostník bez dobrovolného nemocenského
                        pojištění nárok na žádnou dávku od státu. Pro tyto případy slouží{' '}
                        <strong>pojištění pracovní neschopnosti</strong>, jež vám pomůže zachovat dosavadní životní
                        standard, než se plně uzdravíte.
                    </>
                </Paragraph>
            )}

            <UnorderedList
                className={cn(styles.list, 'mb-6')}
                items={[
                    'Je nám jedno, co způsobilo vaši pracovní neschopnost. Zdali je to vážnější nemoc či úraz.',
                    'Pojištění bude trvat {duration} let, tj. do věku {age} let.',
                    'Pojištění pracovní neschopnosti pomůže zachovat životní standard díky konstantní, ve smlouvě stanovené denní dávce vyplácené měsíčně, a to od 2. měsíce trvání (mám úspory na 1 měsíc), 3.&nbsp;měsíce trvání (mám úspory na 2 měsíce) nebo 4. měsíce trvání (mám úspory na déle).',
                    participant && participant?.Identification?.Sex === 3
                        ? 'Ačkoli je trend v pojišťovnictví opačný, kryjeme i výpadek příjmu spojený s rizikovým těhotenstvím. A to v případě, že si stav vyžádá lékařsky nutnou hospitalizaci alespoň po dobu 5&nbsp;dní (120&nbsp;hodin).'
                        : undefined,
                    'Důležité zejména pro osoby bez dostatečné finanční rezervy.',
                ]}
            />
        </div>
    );
};

export const ConsequencesPopup = (
    <div className={cn(styles.helpPopup)}>
        <Title tag="h2" size="lg" className="mb-4">
            Trvalé následky úrazu
        </Title>

        <Paragraph
            children={
                '<strong>Pojištění trvalých následků úrazu</strong><span>&nbsp;</span>je doplňkem k pojištění invalidity, které řeší jednorázové náklady spojené s úrazem, jenž zanechal trvalý následek. Výhodou je, že plníme hned po ustálení následků a nezávisle na rozhodování sociální správy. Prostředky tedy můžete použít třeba na bionickou ruku nebo rehabilitace po úrazu. Důležité zejména pro manuálně pracující klienty a osoby bez větší finanční rezervy. Jak hodnotíme jednotlivé trvalé následky zjistíte v <a target="_blank" href="https://www.simplea.cz/userfiles/trvale-nasledky-simplea-12-2022_16709378291901.pdf">oceňovací tabulce</a>.'
            }
        />

        <UnorderedList
            className={cn(styles.list, 'mb-6')}
            items={['Pojištění bude trvat {duration} let, tj. do věku {age} let.']}
        />
    </div>
);

export const IllnessPopup = (
    <div className={cn(styles.helpPopup)}>
        <Title tag="h2" size="lg" className="mb-4">
            Závažná onemocnění
        </Title>

        <Paragraph
            children={
                '<strong>Pojištění závažných onemocnění</strong><span>&nbsp;</span>je doplňkem k pojištění invalidity, které řeší jednorázové náklady spojené s nemocí. Výhodou je, že plníme hned při stanovení diagnózy a nezávisle na rozhodování sociální správy. Zároveň kryjeme všechny lékařsky uznávané nemoci a neschováváme se za omezené seznamy vybraných chorob. Důležité pro osoby bez větší finanční rezervy. Nemoci a stavy, které hodnotíme jako závažné, zjistíte v <a target="_blank" href="https://www.simplea.cz/userfiles/zavazna-onemocneni-simplea-12-2022_1670937828106.pdf">oceňovací tabulce</a>.'
            }
        />

        <UnorderedList
            className={cn(styles.list, 'mb-6')}
            items={[
                'Pojistná částka je lineárně klesající – to znamená, že po každém roce se pojistná částka sníží o 1/{duration}.',
                'Pojištění bude trvat {duration} let, tj. do věku {age} let.',
            ]}
        />
    </div>
);

export const NetIncomePopup = (
    <div className={cn(styles.helpPopup)}>
        <Title tag="h2" size="lg" className="mb-4">
            Čistý měsíční příjem
        </Title>

        <Paragraph children={'<span>Čistým měsíčním příjmem se rozumí:</span>'} />

        <UnorderedList
            className={cn(styles.list, 'mb-6')}
            items={[
                'v případě <strong>zaměstnance</strong> čistá mzda (příjmy ze závislé činnosti po odpočtu daní a odvodů) v průměru za posledních 12 měsíců nebo za poslední 3 měsíce (podle toho, co je pro Vás výhodnější).',
                'v případě <strong>OSVČ</strong> příjmy ze samostatné činnosti snížené o výdaje vynaložené na jejich dosažení, zajištění a udržení nebo snížené o paušální výdaje odpovídající druhu činnosti podle zákona o daních z příjmů (včetně jejich stanovené maximální výše).',
            ]}
        />
    </div>
);

export const PEPPopup = (
    <div className={cn(styles.helpPopup)}>
        <Title tag="h2" size="lg" className="mb-4">
            Politicky exponovaná osoba
        </Title>

        <Paragraph children={'<span>Politicky exponovanou osobou se rozumí:</span>'} />

        <UnorderedList
            className={cn(styles.list, 'mb-6')}
            items={[
                '1. fyzická osoba, která je ve významné veřejné funkci s celostátním nebo regionálním významem, jako je zejména hlava státu, předseda vlády, vedoucí ústředního orgánu státní správy a jeho zástupce (náměstek, státní tajemník), člen parlamentu, člen řídícího orgánu politické strany, vedoucí představitel územní samosprávy, soudce nejvyššího soudu, ústavního soudu nebo jiného nejvyššího justičního orgánu, proti jehož rozhodnutí obecně až na výjimky nelze použít opravné prostředky, člen bankovní rady centrální banky, vysoký důstojník ozbrojených sil nebo sboru, člen nebo zástupce člena, je-li jím právnická osoba, statutárního orgánu obchodní korporace ovládané státem, velvyslanec nebo vedoucí diplomatické mise, anebo fyzická osoba, která obdobnou funkci vykonává nebo vykonávala v jiném státě, v orgánu Evropské unie anebo v mezinárodní organizaci.',
                '',
                '2. fyzická osoba, která je:<br/>&emsp;1. osobou blízkou osobě uvedené v bodě 1.,<br/>&emsp;2. společníkem nebo skutečným majitelem stejné právnické osoby, popřípadě svěřeneckého fondu nebo jiného právního uspořádání bez právní osobnosti, jako osoba uvedená v bodě 1., nebo je o ní povinné osobě známo, že je v jakémkoliv jiném blízkém podnikatelském vztahu s osobou uvedenou v bodě 1., nebo<br/>&emsp;3. skutečným majitelem právnické osoby, popřípadě svěřeneckého fondu nebo jiného právního uspořádání bez právní osobnosti, o kterých je povinné osobě známo, že byly vytvořeny ve prospěch osoby uvedené v bodě 1.',
            ]}
        />
    </div>
);
