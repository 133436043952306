import { ReactElement, useContext } from 'react';
import parse from 'html-react-parser';
import cn from 'classnames';
import styles from './UnorderedList.module.scss';
import { AppContext } from '../Layout/Layout';

export interface UnorderedListProps {
    items: Array<string | undefined>;
    className?: string;
}

export const UnorderedList = ({ items, className }: UnorderedListProps): ReactElement | null => {
    const ctx = useContext(AppContext);
    const replacements = ctx.popupContentVariables;

    if (items.length === 0) {
        return null;
    }

    // @TODO: refactor it
    if (replacements !== null && replacements.duration && replacements.age) {
        for (let i = 0; i < items.length; i++) {
            if (items[i]) {
                items[i] = items?.[i]
                    ?.replace('{duration}', replacements.duration.toString())
                    .replace('{age}', replacements.age.toString());
            }
        }
    }

    return (
        <ol className={cn(styles.wrapper, className)}>
            {items.map(
                (item, i) =>
                    item && (
                        <li key={i} className={styles.item}>
                            <span>{parse(item)}</span>
                        </li>
                    )
            )}
        </ol>
    );
};
