import { ReactNode } from 'react';
import { Label } from '../Label/Label';
import styles from '../_pages/StepConfirmData/StepConfirmData.module.scss';

type Props = {
    label: string;
    children: ReactNode;
};

export const DataPlaceholder = ({ label, children }: Props) => (
    <div>
        <Label>{label}</Label>
        <div className={styles.boxValue}>{children}</div>
    </div>
);
