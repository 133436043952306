// ### Function stores completed and current step in the localStorage

export const setCompleted = (newCompleted: number) => {
    if (window.localStorage) {
        const prevCompleted = parseInt(localStorage.getItem('completedStep') || '0');
        localStorage.setItem('currentStep', (newCompleted + 1).toString());

        if (prevCompleted > newCompleted) {
            localStorage.setItem('completedStep', prevCompleted.toString());
        } else {
            localStorage.setItem('completedStep', newCompleted.toString());
        }
    }
};
