const YEAR_DECLENSION = {
    DEFAULT: 'let',
    SINGLE: 'rok',
    MULTIPLE: 'roky',
};

// ### Function returns proper years string (czech declension)

export const formatYears = (value: string | null) => {
    if (!value) return;

    return parseInt(value) === 1
        ? YEAR_DECLENSION.SINGLE
        : parseInt(value) < 5
        ? YEAR_DECLENSION.MULTIPLE
        : YEAR_DECLENSION.DEFAULT;
};
