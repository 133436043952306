import config from '../config.json';

import { ProtectionGroupProps } from '../types/model';
import { filterClientAvailability } from './filterClientAvailability';

// ### Function gets only data for step relevant (rider category) products
// and also filters unavailable products

export const getStepRelevantData = (
    groups: Array<ProtectionGroupProps> | undefined,
    target: 'STEP2A_PRODUCTS' | 'STEP2B_PRODUCTS' | 'STEP2_PRODUCTS' | 'STEP3_PRODUCTS'
) => {
    const result =
        groups && Array.isArray(groups) ? groups.filter((g) => config[target].indexOf(g.RiderCategoryCode) > -1) : [];
    return filterClientAvailability(result, true);
};
