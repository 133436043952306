import { ReactElement } from 'react';
import parse from 'html-react-parser';
import cn from 'classnames';

import styles from './Paragraph.module.scss';

export interface ParagraphProps {
    small?: boolean;
    children: string | ReactElement;
    className?: string;
}

export const Paragraph = ({ children, small, className }: ParagraphProps): ReactElement | null => (
    <p className={cn(styles.wrapper, className, small && styles.small)}>
        {typeof children === 'string' ? <>{parse(children)}</> : children}
    </p>
);
