import { Field } from 'formik';
import { TextInput, TextInputProps } from './TextInput';
import { Label } from '../Label/Label';
import { validateField } from '../../utils/validations';
import { FormItemError } from '../FormItemError/FormItemError';

type Variant = 'text' | 'number' | 'price' | 'email' | 'decimal';

type Props = {
    name: string;
    label?: string;
    loading?: boolean;
    allowFieldValidation?: boolean;
    variant?: Variant;
    placeholder?: string;
    suffix?: string;
    className?: string;
    min?: number | string | null;
    max?: number | string | null;
    error?: string;
    optional?: boolean;
    readonly?: boolean;
};

export const FormikTextInput = ({
    label,
    name,
    loading,
    error,
    allowFieldValidation,
    variant = 'text',
    optional = true,
    placeholder,
    suffix,
    max = 999999999999,
    min,
    readonly,
}: Props) => {
    return (
        <div>
            {label && <Label>{label}</Label>}
            <Field
                id={name}
                name={name}
                placeholder={placeholder}
                suffix={suffix}
                variant={variant}
                error={error}
                max={max}
                min={min}
                showEditIcon
                validate={(value: string | number) =>
                    (allowFieldValidation || allowFieldValidation === undefined) &&
                    validateField(value, optional, min, max, variant)
                }
            >
                {(props: JSX.IntrinsicAttributes & TextInputProps) => {
                    return (
                        <TextInput
                            {...props}
                            id={name}
                            key={name}
                            name={name}
                            suffix={suffix}
                            variant={variant}
                            loading={loading}
                            showEditIcon
                            error={error}
                            readonly={readonly}
                        />
                    );
                }}
            </Field>
            {(error || !optional) && <FormItemError text={error} />}
        </div>
    );
};
