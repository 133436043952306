import { Dispatch, ReactElement } from 'react';
import { FormikErrors, FormikValues } from 'formik';
import cn from 'classnames';

import config from '../config.json';
import cms from '../data/cms.json';
import styles from '../components/_pages/Step2/Step2.module.scss';
import { renderTextField } from './renderTextField';
import { formatPrice } from './formatPrice';
import { resolveCoverageBoxTitle } from './resolveCoverageBoxTitle';

import { ToggleButton } from '../components/ToggleButton/ToggleButton';
import { Grid } from '../components/Grid/Grid';
import { FormItemError } from '../components/FormItemError/FormItemError';
import { getDisabilityVariantId, getSDVariantId } from './getVariantId';
import { DisabilityDetailProps, DisabilityLevelConsequenceProps } from '../types/initData';
import { ContextProps } from '../components/Layout/Layout';
import { ProtectionVariantProps } from '../types/model';
import { triggerGtmEvent } from './triggerGtmEvent';
import { Icon } from '../components/Icon/Icon';

// ### Function returns content form boxes in step2 and step3

export const renderCoverageItem = (
    data: Array<ProtectionVariantProps>,
    code: string,
    groupCode: string,
    ctx: ContextProps,
    activeItem: string,
    setActiveItem: Dispatch<React.SetStateAction<string>>,
    simple: boolean,
    errors: FormikErrors<FormikValues>,
    selectedVariant?: number,
    parentIndex?: number,
    popupContent?: ReactElement,
    buttonLoading?: boolean,
    details?: Array<DisabilityDetailProps>,
    hasCheckedCheckbox?: boolean
) => {
    const csq = ctx.formResult.Participants[0].InsuredEventConsequence;
    const childrenSummary = ctx.formResult.Participants[0].ChildrenSummary;
    const orphansPension = csq?.DeathConsequence.OrphansPension || 0;
    const sicknessBenefit = csq?.ShortTermDisabilityConsequence?.SicknessBenefit || 0;
    const disabilityConsequences: Array<DisabilityLevelConsequenceProps> =
        csq?.DisabilityConsequence?.DisabilityLevelConsequences || [];

    const hasChildren = (selectedVariant || 0) > 1 && childrenSummary.HasChildren;

    // ### Separate variants for disability for passed data and make a new array (only in disability group)
    const newData = [];
    if (code === config.RIDER_GROUPS.DIS123 || code === config.RIDER_GROUPS.DISD123) {
        const variant3 = data.filter((itm) => itm.SuggestedRiderVersion.Code === config.RIDER_GROUPS.DISD3);
        const variant2 = data.filter((itm) => itm.SuggestedRiderVersion.Code === config.RIDER_GROUPS.DISD23);
        const variant1 = data.filter((itm) => itm.SuggestedRiderVersion.Code === config.RIDER_GROUPS.DISD123);
        newData.push(variant3, variant2, variant1);
    } else {
        newData.push(data);
    }

    return newData.map((item, j: number) => {
        const currentItem = item[j];
        const selectedItem = item[(selectedVariant || 1) - 1];
        const errorsArray = errors[`${selectedItem.SuggestedRiderVersion.Code}-${parentIndex}-${selectedVariant}`];
        const processedError =
            Array.isArray(errorsArray) && errorsArray.length > 0 ? (errorsArray[0] as string) : (errorsArray as string);

        return (
            <Grid colsUneven={[1, 2]} nonResponsive key={j} className={styles.productList}>
                
                {!simple &&  
                    (groupCode !== config.RIDER_CATEGORIES.DTH || hasChildren) ? ( /* toggle button for death only if person has childrens*/
                    <div className={styles.productsLeftTitle}
                    onClick={() =>
                    setActiveItem(
                        currentItem.SuggestedRiderVersion.Code === activeItem
                            ? ''
                            : currentItem.SuggestedRiderVersion.Code
                    )
                    }>
                    <ToggleButton
                        className={styles.toggleButton}
                        opened={currentItem.SuggestedRiderVersion.Code === activeItem}
                        
                    />
                    <div className={styles.pointer}>
                        <strong>{resolveCoverageBoxTitle(currentItem.SuggestedRiderVersion.Code)}</strong>
                        {groupCode === config.RIDER_CATEGORIES.DIS && <span> - Pojistná částka:</span>}
                    </div>
                    </div>
                ) : (
                    <div className={styles.productsLeftTitle}>
                        <div>
                            <strong>{resolveCoverageBoxTitle(currentItem.SuggestedRiderVersion.Code)}</strong>
                            {groupCode === config.RIDER_CATEGORIES.DIS && <span> - Pojistná částka:</span>}
                        </div>
                    </div>
                )}
                    
                

                {/* Render text field and edit button */}

                <div className={styles.level}>
                    {selectedItem.IsEditable ? (
                        <div className={styles.levelContent}>
                            {renderTextField(
                                `${selectedItem.SuggestedRiderVersion.Code}-${parentIndex}-${selectedVariant}`,
                                'price',
                                '',
                                '',
                                config.CURRENCIES.CZK,
                                styles.levelField,
                                groupCode === config.RIDER_CATEGORIES.DIS
                                    ? details?.find(
                                          (detail) =>
                                              detail.DisabilityLevel ===
                                              getDisabilityVariantId(item[0].SuggestedRiderVersion.Code)
                                      )?.SumInsuredMin
                                    : selectedItem.SumInsuredMin,
                                groupCode === config.RIDER_CATEGORIES.DIS
                                    ? details?.find(
                                          (detail) =>
                                              detail.DisabilityLevel ===
                                              getDisabilityVariantId(item[0].SuggestedRiderVersion.Code)
                                      )?.SumInsuredMax
                                    : selectedItem.SumInsuredMax,
                                hasCheckedCheckbox && processedError ? processedError : undefined,
                                false,
                                undefined,
                                hasCheckedCheckbox,
                                true // vzdy generuj tuzticku, protoze selectedItem.IsEditable 
                            )}

                            {errors[`${selectedItem.SuggestedRiderVersion.Code}-${parentIndex}-${selectedVariant}`] &&
                                hasCheckedCheckbox && <FormItemError className={styles.error} text={processedError} />}

                            {groupCode === config.RIDER_CATEGORIES.DTH && (selectedVariant || 0) > 1 && (
                                <div
                                    onClick={() => {
                                        ctx.setCloseButton(true);
                                        ctx.setPopupVisible(true);
                                        popupContent && ctx.setPopupContent(popupContent);
                                        ctx.setPopupContentVariables({ uncloseable: false });
                                        ctx.setPopupCloseEvent('suggest_end');
                                        triggerGtmEvent('suggest');
                                    }}
                                    className={cn(styles.adjustButton, buttonLoading && styles.isLoading)}
                                >
                                    <Icon className={styles.adjustIcon} name="edit" />
                                    {cms.common.editButton}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={styles.fixedPrice}>
                            {formatPrice(selectedItem.SumInsuredChosen)} {config.CURRENCIES.CZK}
                        </div>
                    )}
                </div>

                {!simple && currentItem.SuggestedRiderVersion.Code === activeItem && (
                    <>
                        {groupCode === config.RIDER_CATEGORIES.DTH &&
                             hasChildren && (
                                <>
                                    <div className="text--left">Od státu budou děti dostávat sirotčí důchod:</div>
                                    <div>
                                        {formatPrice(orphansPension)}
                                        &nbsp;{config.CURRENCIES.CZK}
                                    </div>
                                </>
                            )}

                        {groupCode === config.RIDER_CATEGORIES.DIS && (
                            <>
                                <div className="text--left">Invalidní důchod od státu:</div>

                                <div>
                                    {formatPrice(
                                        disabilityConsequences.find(
                                            (cons) =>
                                                cons.DisabilityLevel ===
                                                getDisabilityVariantId(item[0].SuggestedRiderVersion.Code)
                                        )?.DisabilityPension || 0
                                    )}
                                    &nbsp;{config.CURRENCIES.CZK}
                                </div>

                                {item[0].SuggestedRiderVersion.Code !== config.RIDER_GROUPS.DISD3 && (
                                    <>
                                        <div className="text--left">Zvládnete vydělat:</div>
                                        <div>
                                            {formatPrice(
                                                disabilityConsequences.find(
                                                    (cons) =>
                                                        cons.DisabilityLevel ===
                                                        getDisabilityVariantId(item[0].SuggestedRiderVersion.Code)
                                                )?.PossibleIncome || 0
                                            )}
                                            &nbsp;{config.CURRENCIES.CZK}
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {groupCode === config.RIDER_CATEGORIES.SD && (
                            <>
                                <div className="text--left">Dávka je vyplácena:</div>
                                {groupCode === config.RIDER_CATEGORIES.SD &&
                                    getSDVariantId(config.RIDER_GROUPS.SD28) === selectedVariant && (
                                        <div>od 28. dne</div>
                                    )}
                                {groupCode === config.RIDER_CATEGORIES.SD &&
                                    getSDVariantId(config.RIDER_GROUPS.SD56) === selectedVariant && (
                                        <div>od 56. dne</div>
                                    )}
                                {groupCode === config.RIDER_CATEGORIES.SD &&
                                    getSDVariantId(config.RIDER_GROUPS.SD90) === selectedVariant && (
                                        <div>od 90. dne</div>
                                    )}

                                {sicknessBenefit > 0 && (
                                    <>
                                        <div className="text--left">Nemocenská od státu:</div>
                                        <div>
                                            {formatPrice(sicknessBenefit || 0)} {config.CURRENCIES.CZK}
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {(groupCode === config.RIDER_CATEGORIES.SD || groupCode === config.RIDER_CATEGORIES.DIS) &&
                            item.filter((target) => target?.LossOfIncome > 0).length > 0 && (
                                <>
                                    <div className="text--left">Výpadek příjmu:</div>
                                    <div>
                                        {formatPrice(selectedItem.LossOfIncome || 0)} {config.CURRENCIES.CZK}
                                    </div>
                                </>
                            )}

                        {groupCode === config.RIDER_CATEGORIES.DIS && (
                            <>
                                <div className="text--left">Pojištění dorovná příjmy na:</div>
                                <div>{selectedItem.IncomeCoveredForMonths || 0} měsíců</div>
                            </>
                        )}
                    </>
                )}
            </Grid>
        );
    });
};
