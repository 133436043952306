import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './RecalculateButton.module.scss';
import cms from '../../data/cms.json';

import { Icon } from '../Icon/Icon';

interface RecalculateButtonProps {
    onClick: () => void;
    loading?: boolean;
    className?: string;
}

export const RecalculateButton = ({ onClick, className, loading }: RecalculateButtonProps): ReactElement | null => {
    return (
        <div className={cn(styles.wrapper, loading && styles.isLoading, className)} onClick={onClick}>
            <Icon className={styles.icon} size="medium" name="refresh" /> {cms.common.recalculateButton}
        </div>
    );
};
